module.exports = Em.Route.extend({
    startGuide: Em.inject.service(),

    beforeModel: function() {
        this.get('startGuide').setCurrentRouteName(this.get('routeName'))
    },

    actions: {
        goToNextStep: function() {
            this.transitionTo(this.get('startGuide.nextStep'))
        }
    }
})

var t = require('i18n').t
var NOTIFICATION_KEYS = require('../notificationKeys')

module.exports = Em.Object.extend({
    // 10MB in byte format
    totalFilesSizeLimit: 10485760,

    uploadedFilesSizeLimit: 0,

    resource: null,

    attachments: Em.computed.oneWay('resource.attachments'),

    segment: Ember.inject.service(),

    trackingContext: null,

    queue: function() {
        return []
    }.property(),

    created: function() {
        return []
    }.property(),

    deleted: function() {
        return []
    }.property(),

    isUploading: function() {
        return this.get('queue.length') > 0
    }.property('queue.length'),

    increaseTotalFilesSize: function(fileSize) {
        var newTotalSize = this.get('uploadedFilesSizeLimit') + fileSize
        this.set('uploadedFilesSizeLimit', newTotalSize)
    },

    decreaseTotalFilesSize: function(fileSize) {
        var newTotalSize = this.get('uploadedFilesSizeLimit') - fileSize
        this.set('uploadedFilesSizeLimit', newTotalSize)
    },

    add: function(file) {
        var self = this
        var fileSizeLimit = this.get('totalFilesSizeLimit')

        if (file instanceof FileList || Array.isArray(file)) {
            for (var i = 0; i < file.length; i++) {
                this.add(file[i])
            }
            return
        }

        self.increaseTotalFilesSize(file.size)

        if (this.get('uploadedFilesSizeLimit') > fileSizeLimit) {
            this.decreaseTotalFilesSize(file.size)
            this.container.lookup('util:notification').notify(NOTIFICATION_KEYS.FILE_SIZE_LIMIT, t('upload_file_size_error', {
                size: 10
            }), 'error')
            return
        }

        var queue = this.get('queue')
        var req = this.container.lookup('util:v2-file-upload-request', { singleton: false })
        req.set('file', file)
        queue.addObject(req)
        req.upload()
            .then(function(result) {
                var attachment = self.container.lookup('store:main').createRecord('v2-attachment', {
                    url: result.url,
                    downloadUrl: result.downloadUrl,
                    name: file.name,
                    priority: self.getNextPriority(),
                    size: file.size
                })
                self.get('resource.attachments').addObject(attachment)
                self.get('created').addObject(attachment)
            }, function(e) {
                self.decreaseTotalFilesSize(file.size)
                /* eslint-disable no-console */
                console.error('Attachments upload error')
                console.error(e.stack || e)
                /* eslint-enable no-console */
                self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.ATTACHMENT_ADD, t('attachments_changeset.upload_failed'))
            })
            .finally(function() {
                queue.removeObject(req)
                self.get('segment').track('File Uploaded (FE)', {
                    context: self.get('trackingContext')
                })
            })
    },

    getNextPriority: function() {
        return 1 + this.get('attachments').reduce(function(max, attachment) {
            return Math.max(max, attachment.get('priority'))
        }, 0)
    },

    deleteAttachment: function(attachment) {
        this.decreaseTotalFilesSize(attachment._attributes.size)
        attachment.deleteRecord()
        this.get('deleted').addObject(attachment)
    },

    abort: function(req) {
        req.abort()
        this.get('queue').removeObject(req)
    },

    save: function() {
        return Em.RSVP.all([
            this.saveCreated(),
            this.saveDeleted()
        ])
    },

    saveCreated: function() {
        var created = this.get('created')
        return Em.RSVP.all(this.get('created').map(function(attachment) {
            return attachment.save()
                .then(function() {
                    created.removeObject(attachment)
                })
        }))
    },

    saveDeleted: function() {
        var deleted = this.get('deleted')
        return Em.RSVP.all(this.get('deleted').map(function(attachment) {
            return attachment.save()
                .then(function() {
                    deleted.removeObject(attachment)
                })
        }))
    },

    isDirty: function() {
        return this.get('created.length') > 0 || this.get('deleted.length') > 0
    }.property('created.length', 'deleted.length'),

    rollback: function() {
        this.get('created').each(function(attachment) {
            attachment.rollback()
        })
        this.set('created', [])

        this.get('deleted').each(function(attachment) {
            attachment.rollback()
        })
        this.set('deleted', [])
    }
})

var functionProxy = require('function-proxy')

module.exports = Em.View.extend({
    didInsertElement: function() {
        this.$().delegate('.sales-tax-return-audit-trail a', 'click', functionProxy(this.didClickAccount, this))
    },

    didClickAccount: function(e) {
        var td = $(e.target.parentNode)
        var transactionId = td.attr('data-transaction-id')
        var originatorType = td.attr('data-originator-type')
        var originatorId = td.attr('data-originator-id')

        var route
        var model

        if (transactionId) {
            route = 'transaction'
            model = Billy.Transaction.find(transactionId)
        } else if (originatorType) {
            var type = BD.lookupTypeByName(originatorType)
            model = type.find(originatorId)
            route = model.get('route')
        }

        if (route) {
            e.preventDefault()
            var router = this.container.lookup('router:main')
            // @todo check for browser support
            window.open(router.generate(route, model), '_blank').focus()
        }
    }
})

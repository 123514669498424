var model = BD.Model.extend({
    name: BD.attr('string'),
    registrationNo: BD.attr('string'),
    logoUrl: BD.attr('string')
})

model.reopenClass({
    qProperties: ['name']
})

module.exports = model

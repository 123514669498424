var model = BD.Model.extend({
    organization: BD.belongsTo('Billy.Organization'),
    name: BD.attr('string'),
    description: BD.attr('string'),
    account: BD.belongsTo('Billy.Account'),
    inventoryAccount: BD.belongsTo('Billy.Account'),
    productNo: BD.attr('string'),
    suppliersProductNo: BD.attr('string'),
    salesTaxRuleset: BD.belongsTo('Billy.SalesTaxRuleset'),
    isArchived: BD.attr('boolean'),
    isInInventory: BD.attr('boolean'),
    prices: BD.hasMany('Billy.ProductPrice', 'product', { isEmbedded: true }),
    image: BD.belongsTo('Billy.File'),
    imageUrl: BD.attr('string', { readonly: true })
})

model.reopenClass({
    qProperties: ['name', 'description', 'productNo', 'suppliersProductNo']
})

module.exports = model

var t = require('i18n').t
var batmask = require('batmask')
var tracking = require('../utils/tracking')
var _ = require('lodash')
var NOTIFICATION_KEYS = require('../notificationKeys')
var BILLING_TYPE = require('../billingTypes')
var SubscriptionPlan = require('../constants/subscriptionPlan')

// Deprecated addons are only visible if they have already been activated by the user
var DEPRECATED_ADDONS = [
    'unlimited_postings',
    'extra_seats',
    'auto_bookkeeping'
]

module.exports = Em.Object.extend({
    api: Em.inject.service(),

    userOrganizations: Em.inject.service(),

    organization: Em.computed.oneWay('userOrganizations.activeOrganization'),

    organizationCardSubscription: Em.inject.service(),

    billingCardSubscription: Em.inject.service(),

    subscription: Em.inject.service(),

    bankConnections: Em.inject.service(),

    intercom: Ember.inject.service(),

    segment: Ember.inject.service(),

    user: function() {
        return this.container.lookup('controller:user').get('model')
    }.property(),

    includeStripeCheckout: function() {
        if (!this.includeStripeCheckoutPromise) {
            this.includeStripeCheckoutPromise = new Em.RSVP.Promise(function(resolve, reject) {
                var js = document.createElement('script')
                js.type = 'text/javascript'
                js.src = 'https://checkout.stripe.com/checkout.js'
                js.onload = resolve
                js.onerror = reject
                document.body.appendChild(js)
            })
        }
        return this.includeStripeCheckoutPromise
    },

    stripeCheckout: function(amount, description, subscriptionPlan, subscriptionPeriod) {
        var self = this
        batmask.mask()
        self.subscriptionPlan = subscriptionPlan
        self.subscriptionPeriod = subscriptionPeriod
        self.description = description
        self.amount = amount
        self.intercomMetadata = {
            plan: subscriptionPlan,
            period: subscriptionPeriod
        }
        self.includeStripeCheckout()
            .then(function() {
                var handler = window.StripeCheckout.configure({
                    key: ENV.BILLY_US_STRIPE_API_KEY,
                    token: self.onStripeToken.bind(self),
                    image: Billy.image('design/stripe-logo.png'),
                    name: 'Billy',
                    locale: 'auto',
                    zipCode: true,
                    billingAddress: true,
                    email: self.get('organization.email') || self.get('user.email'),
                    allowRememberMe: false,
                    closed: function() {
                        batmask.unmask()
                    }
                })
                if (amount) {
                    handler.open({
                        description: description,
                        amount: amount
                    })
                } else {
                    handler.open({
                        panelLabel: 'Update Card Details'
                    })
                }
            })
    },

    onStripeToken: function(token) {
        var self = this
        batmask.mask()
        self.updateOrganizationSubscription(self.subscriptionPlan, self.subscriptionPeriod)
            .then(function() {
                return Billy.__container__.lookup('service:api').request('PUT', '/v2/organizations/' + Billy.__container__.lookup('service:user-organizations').get('activeOrganization.id') + '/stripeToken', {
                    payload: {
                        data: {
                            token: token.id
                        }
                    }
                })
            })
            .then(function(payload) {
                if (payload.organizations) {
                    Billy.Organization.loadMany(payload.organizations)
                }
                var organization = self.get('organization')
                tracking.emitAnalyticsEvent('us_activation', 'activation_success')
                self.get('intercom').trackEvent(organization, 'activated_subscription', self.intercomMetadata)
                self.container.lookup('router:main').replaceWith('organization_subscription', organization)
            }, function(e) {
                if (e.type === 'API_ERROR') {
                    self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.SUBSCRIPTION_UPDATE, e.message)
                } else {
                    throw e
                }
            })
            .finally(function() {
                batmask.unmask()
            })
    },

    updateOrganizationSubscription: function(subscriptionPlan, subscriptionPeriod) {
        var self = this
        if (subscriptionPlan) {
            return self.changePlan(subscriptionPlan)
                .then(function() {
                    self.changePeriod(subscriptionPeriod)
                })
        } else {
            return new Em.RSVP.Promise(function(resolve) {
                resolve()
            })
        }
    },

    changePlan: function(subscriptionPlan) {
        var self = this
        var organization = this.get('organization')
        return this.get('api').put('/v2/organizations/' + organization.get('id') + '/subscription', {
            payload: {
                subscription: {
                    subscriptionPlan: subscriptionPlan
                }
            }
        })
            .then(function(payload) {
                if (payload.organizations) {
                    Billy.Organization.loadMany(payload.organizations)
                }
                self.load()
                self.container.lookup('util:notification').success(NOTIFICATION_KEYS.PLAN_CHANGE, t('changes_saved'))
            }, function(e) {
                if (e.type === 'API_ERROR') {
                    self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.PLAN_CHANGE, e.message)
                } else {
                    throw e
                }
            })
    },

    // TODO: Replace with call to /v2/organizations/:id/subscription
    changePeriod: function(subscriptionPeriod) {
        var self = this
        batmask.maskDelayed()

        return this.get('organization').set('subscriptionPeriod', subscriptionPeriod).save()
            .then(function() {
                self.load()
                self.container.lookup('util:notification').success(NOTIFICATION_KEYS.PLAN_PERIOD_CHANGE, t('changes_saved'))
                batmask.unmask()
            })
    },

    removeInactivateDeprecatedAddons: function(addons) {
        return _(addons)
            .filter(function(addon) {
                return ((addon.previousValue || addon.previousValue > 0) || !_.includes(DEPRECATED_ADDONS, addon.id))
            })
            .value()
    },

    /// //// new data scheme ///////

    isLoaded: false,

    data: null,

    // Subscription info

    plan: null,

    planPostingLimit: Em.computed.alias('plan.postingLimit'),
    planPostingCount: Em.computed.alias('plan.postingCount'),

    addons: function() {
        var planAddons = (this.get('plan') && this.get('plan.addons')) || []
        var nonDeprecatedPlanAddons = this.removeInactivateDeprecatedAddons(planAddons)

        return nonDeprecatedPlanAddons
    }.property('plan'),

    basicAddons: function() {
        var basicPlan = _.find(this.get('data.plans') || [], { id: SubscriptionPlan.Basic })
        var basicAddons = (basicPlan && _.get(basicPlan, 'addons')) || []
        var nonDeprecatedBasicAddons = this.removeInactivateDeprecatedAddons(basicAddons)

        return nonDeprecatedBasicAddons
    }.property('data.plans.@each'),

    premiumAddons: function() {
        var premiumPlan = _.find(this.get('data.plans') || [], { id: SubscriptionPlan.Premium })
        var premiumAddons = _.get(premiumPlan, 'addons') || []
        var nonDeprecatedPremiumAddons = this.removeInactivateDeprecatedAddons(premiumAddons)

        return nonDeprecatedPremiumAddons
    }.property('data.plans.@each'),

    postingLimitBasic: function() {
        var basicPlan = _.find(this.get('data.plans') || [], { id: SubscriptionPlan.Basic })

        return basicPlan && basicPlan.postingLimit
    }.property('basicAddons'),

    // Feature usage
    usedPostings: Em.computed.alias('organization.postingCount'),

    usedSeats: function() {
        var extraSeatsAddon = _.find(this.get('basicAddons'), { id: 'extra_seats' })
        var extraSeatsAddonCurrentValue = _.get(extraSeatsAddon, 'currentValue') || 0

        return extraSeatsAddonCurrentValue
    }.property('basicAddons.@each.currentValue'), // Maybe the observable can be more specific?

    bankConnectionCount: 0,

    loadBankConnectionsCount: function() {
        var self = this

        return this.get('bankConnections').getBankSessions(this.get('organization.id'))
            .then(function(sessions) {
                self.set('bankConnectionCount', _.size(sessions))
            })
    },

    invalidate: function() {
        var data = this.get('data')

        if (data) {
            this.set('data', null)
            // invalidate plan and addons
            this.set('plan', { addons: [] })
        }

        this.set('isLoaded', false)
            .set('data', null)
    }.observes('organization'),

    getExternalSubscriptionDataById: function(id) {
        return this.get('api').request('get', '/integrations/billing/' + id + '/subscription')
    },

    load: function() {
        var self = this

        this._loadPromise = Em.RSVP.all([
            this.get('api').request('get', '/v2/organizations/' + this.get('organization.id') + '/subscription'),
            this.loadBankConnectionsCount()
        ])
            .then(function(result) {
                var subscription = result[0]

                if (Object.keys(subscription).length) {
                    if (subscription.organization) {
                        Billy.Organization.load(subscription.organization)
                    }

                    var basicAddonsOrder = [
                        'unlimited_postings',
                        'extra_seats'
                    ]

                    var basicAddons = subscription.plans.findBy('id', SubscriptionPlan.Basic).addons

                    var sortedBasicAddons = basicAddonsOrder.map(function(key) {
                        var addon = basicAddons.findBy('id', key)
                        addon.component = _.kebabCase(addon.id) + '-add-on'

                        // Store value for later comparison
                        addon.previousValue = addon.value

                        switch (addon.id) {
                        case 'extra_seats':
                            addon.locked = addon.currentValue >= addon.value && addon.currentValue !== 0
                            break
                        case 'unlimited_postings':
                            addon.locked = addon.postingCount > addon.postingLimit
                            break
                        default:
                            addon.locked = false
                            break
                        }

                        return addon
                    })

                    var premiumAddonsOrder = [
                        'validated_scans'
                    ]

                    var premiumAddons = subscription.plans.findBy('id', SubscriptionPlan.Premium).addons

                    var sortedPremiumAddons = premiumAddonsOrder.map(function(key) {
                        var addon = premiumAddons.findBy('id', key)
                        addon.component = _.kebabCase(addon.id) + '-add-on'

                        // Store value for later comparison
                        addon.previousValue = addon.value
                        addon.locked = false

                        return addon
                    })

                    subscription.plans.findBy('id', SubscriptionPlan.Basic).addons = sortedBasicAddons
                    subscription.plans.findBy('id', SubscriptionPlan.Premium).addons = sortedPremiumAddons

                    self.set('data', subscription)

                    // Set plan = organization current plan
                    self.set('plan', Ember.copy(subscription.plans.findBy('id', subscription.organization.subscriptionPlan), true))
                }

                self.set('isLoaded', true)

                return subscription
            })
            .catch(function(error) {
                console.error(error)
                this._loadPromise = undefined
            })

        return this._loadPromise
    },

    loadSafe: function() {
        this.load()
            .catch(function(e) {
                // Organization subscription load failed, but will not throw an error
            })
    },

    ensureLoaded: function() {
        return this._loadPromise || this.load()
    },

    currentPlanName: function() {
        return this.get('organization.subscriptionPlan')
    }.property('organization.subscriptionPlan'),

    currentPlan: function() {
        return this.get('data.plans').findBy('id', this.get('currentPlanName'))
    }.property('currentPlanName'),

    getAllPlans: function() {
        return [
            {
                id: SubscriptionPlan.Pro,
                monthlyPrice: 179,
                yearlyPrice: 1788,
                postingLimit: 500,
                addons: []
            },
            {
                id: SubscriptionPlan.Premium,
                monthlyPrice: 295,
                yearlyPrice: 2940,
                postingLimit: null,
                postingCount: 0,
                addons: [
                    {
                        id: 'validated_scans',
                        type: 'integer',
                        period: null,
                        price: 2.5,
                        currencyId: 'DKK',
                        value: 0,
                        currentValue: 0,
                        expirationDate: null,
                        lastRenewalDate: null,
                        nextState: 0,
                        addonDisabled: false
                    }
                ]
            },
            {
                id: SubscriptionPlan.Basic,
                monthlyPrice: 0,
                yearlyPrice: 0,
                postingLimit: 300,
                postingCount: 0,
                addons: [
                    {
                        id: 'extra_seats',
                        type: 'integer',
                        period: 'monthly',
                        price: 65,
                        currencyId: 'DKK',
                        value: 0,
                        currentValue: 0,
                        expirationDate: null,
                        lastRenewalDate: null,
                        nextState: 0,
                        addonDisabled: false
                    },
                    {
                        postingCount: 0,
                        postingLimit: 300,
                        id: 'unlimited_postings',
                        type: 'boolean',
                        period: 'yearly',
                        price: 95,
                        currencyId: 'DKK',
                        value: false,
                        currentValue: 0,
                        expirationDate: null,
                        lastRenewalDate: null,
                        nextState: false,
                        addonDisabled: true
                    }
                ]
            },
            {
                id: SubscriptionPlan.Free,
                monthlyPrice: 0,
                yearlyPrice: 0,
                postingLimit: 300,
                addons: [
                    {
                        id: 'extra_seats',
                        type: 'integer',
                        period: 'monthly',
                        price: 65,
                        currencyId: 'DKK',
                        value: 0,
                        currentValue: 0,
                        expirationDate: null,
                        lastRenewalDate: null,
                        nextState: 0,
                        addonDisabled: false
                    },
                    {
                        postingCount: 0,
                        postingLimit: 300,
                        id: 'unlimited_postings',
                        type: 'boolean',
                        period: 'yearly',
                        price: 95,
                        currencyId: 'DKK',
                        value: false,
                        currentValue: 0,
                        expirationDate: null,
                        lastRenewalDate: null,
                        nextState: false,
                        addonDisabled: true
                    }
                ]
            },
            {
                id: SubscriptionPlan.BasicPaid,
                monthlyPrice: 195,
                yearlyPrice: 1800,
                postingLimit: null,
                addons: []
            },
            {
                id: SubscriptionPlan.Plus,
                monthlyPrice: 345,
                yearlyPrice: 3540,
                postingLimit: null,
                addons: []
            },
            {
                id: SubscriptionPlan.Complete,
                monthlyPrice: 595,
                yearlyPrice: 7140,
                postingLimit: null,
                addons: []
            }
        ]
    },

    getCurrentPlan: function() {
        return this.get('currentPlan')
    },

    getCurrentAddons: function() {
        var currentPlanName = this.get('currentPlanName')

        return this.get('data.plans').findBy('id', currentPlanName).addons
    },

    getBasicAddons: function() {
        var basicAddons = this.get('data.plans').findBy('id', SubscriptionPlan.Basic).addons

        return this.removeInactivateDeprecatedAddons(basicAddons)
    },

    getPremiumAddons: function() {
        return this.get('data.plans').findBy('id', SubscriptionPlan.Premium).addons
    },

    findAddonById: function(id) {
        return _.find(this.getCurrentAddons(), { id: id })
    },

    getRequiredAddons: function() {
        var basicAddons = this.getBasicAddons()

        var requiredAddons = _.filter(basicAddons, function(addon) {
            switch (addon.id) {
            case 'extra_seats':
                return addon.currentValue >= addon.value && addon.currentValue !== 0
            case 'unlimited_postings':
                return addon.postingCount > addon.postingLimit
            default:
                return false
            }
        })

        return requiredAddons
    },

    getSubscriptionPriceMonthly: function(planId, isYearlySubscription) {
        var subscriptionPlan = this.getAllPlans().findBy('id', planId)

        return isYearlySubscription ? subscriptionPlan.yearlyPrice / 12 : subscriptionPlan.monthlyPrice
    },

    getSubscriptionPriceYearly: function(planId) {
        planId = planId || SubscriptionPlan.Premium
        var subscriptionPlan = this.getAllPlans().findBy('id', planId)

        return subscriptionPlan.yearlyPrice
    },

    getYearlySavings: function() {
        var plan = this.getAllPlans().findBy('id', SubscriptionPlan.Premium)
        var maxYearlyPrice = plan.monthlyPrice * 12
        var minYearlyPrice = plan.yearlyPrice

        return maxYearlyPrice - minYearlyPrice
    },

    editOwnSubscription: function(subscriptionPlan, subscriptionPeriod, addons) {
        var self = this
        var organizationCardSubscription = this.get('organizationCardSubscription')

        return self.get('api').put('/v2/organizations/' + self.get('organization.id') + '/subscription', {
            payload: {
                subscription: {
                    subscriptionPlan: subscriptionPlan,
                    subscriptionPeriod: subscriptionPeriod,
                    addons: addons
                }
            }
        })
            .then(function() {
                return self.load() // reload subscription data
            })
            .then(function() {
                if (self.requiresCardSubscription(subscriptionPlan, addons || self.get('addons'))) {
                    return organizationCardSubscription.subscribeWithQuickPay({
                        plan: subscriptionPlan,
                        period: subscriptionPeriod
                    })
                }
            })
    },

    editExternalSubscription: function(subscriptionPlan, subscriptionPeriod, addons, couponCode, price) {
        var self = this
        var billingCardSubscription = this.get('billingCardSubscription')
        var subscription = this.get('subscription')

        var subscriptionParams = {
            plan: subscriptionPlan,
            organizationId: this.get('organization.id')
        }

        if (subscriptionPlan !== SubscriptionPlan.Basic) {
            subscriptionParams.period = subscriptionPeriod
        }

        if (couponCode) {
            subscriptionParams.couponCode = couponCode
        }

        if (typeof price === 'number') {
            subscriptionParams.price = price
        }

        // Paid subscription - goes via quickpay flow (redirect)
        if (self.requiresExternalCardSubscription(subscriptionPlan)) {
            return subscription.subscribeCard(subscriptionParams, true)
        }

        // Free subscriptions - straight to upodi
        return billingCardSubscription.startSubscription(subscriptionParams)
            .then(function() {
                self.container.lookup('util:notification').success(NOTIFICATION_KEYS.SUBSCRIPTION_UPDATE, t('changes_saved'))
                return self.load() // reload subscription data
            })
    },

    editSubscription: function(subscriptionPlan, subscriptionPeriod, addons, couponCode, price) {
        if (this.requiresExternalSubscription()) {
            return this.editExternalSubscription(subscriptionPlan, subscriptionPeriod, addons, couponCode, price)
        }
        return this.editOwnSubscription(subscriptionPlan, subscriptionPeriod, addons)
    },

    requiresExternalSubscription: function() {
        var organization = this.get('organization')

        return organization.get('billingType') === BILLING_TYPE.EXTERNAL ||
            organization.get('isAllowedForUpodiMigration')
    },

    requiresCardSubscription: function(plan, addons) {
        var organization = this.get('organization')
        var freePlans = [SubscriptionPlan.Basic, SubscriptionPlan.Free]
        var isPlanPaid = !freePlans.includes(plan)
        var hasEnabledAddons = _.find(addons || [], function(addon) {
            return !!addon.value
        })
        // TODO possibly check for unpaid invoices.

        return (isPlanPaid || hasEnabledAddons) &&
            organization.get('couponDiscountPercent') !== 100 &&
            !organization.get('consolidatedBilling') &&
            !organization.get('isSubscriptionBankPayer') &&
            !organization.get('defaultActiveCardSubscription')
    },

    requiresExternalCardSubscription: function(plan, addons) {
        var organization = this.get('organization')
        var freePlans = [SubscriptionPlan.Basic, SubscriptionPlan.Free]
        var isPlanPaid = !freePlans.includes(plan)

        // TODO: check for available Upodi payment method here, or rely on check made by react-app
        return isPlanPaid &&
            !organization.get('consolidatedBilling') &&
            !organization.get('isSubscriptionBankPayer')
    },

    hasExceededPostingLimit: function() {
        var postingLimit = this.get('planPostingLimit')
        var postingCount = this.get('planPostingCount')
        var unlimitedPostingsAddon = this.get('basicAddons').findBy('id', 'unlimited_postings')

        if (!postingLimit) {
            // The current plan allows for unlimited postings
            return false
        }

        if (unlimitedPostingsAddon && unlimitedPostingsAddon.value === true) {
            // Some basic orgs still have the deprecated addon. // TODO: Remove when addon is completely gone.
            return false
        }

        return postingCount > postingLimit
    },

    checkIfSubscriptionChangeIsPossible: function(subscriptionPlan) {
        var self = this
        var promise = Em.RSVP.resolve(true) // empty promise by default

        if (subscriptionPlan.id === SubscriptionPlan.Basic && self.get('organization.lockedCode') !== 'expiredTrial') {
            promise = Em.RSVP.hash({
                actors: Billy.OAuthActor.findByQuery({ organizationId: self.get('organization.id') }).promise
            })
                .then(function(hash) {
                    if (hash.actors.content.length > 0) {
                        self.container.lookup('component:alert-window')
                            .set('title', t('organization_subscription.downgrade.blocked_by_api_tokens.title'))
                            .set('description', t('organization_subscription.downgrade.blocked_by_api_tokens.description'))
                            .set('showSupportLink', true)
                            .show()

                        return false
                    }

                    return true
                })
        }

        return promise
    }
})

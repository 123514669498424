var Scope = require('../utils/scope')
var AuthorizedRoute = require('../mixins/authorized-route')

module.exports = Em.Route.extend(AuthorizedRoute, {
    scopes: [Scope.ExportRead],

    setupController: function() {
        this.controllerFor('exports').set('filter', null)
    }
})

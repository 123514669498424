var t = require('i18n').t
var parsePeriod = require('ember-period-selector').parsePeriod

/*
Useful when a period has to be reflected in the queryParams.

Pair it with a string property, e.g. named `periodValue`:

```js
periodValue: null,

period: periodComputedProperty('periodValue')
```
*/
module.exports = function(valueProperty) {
    return function(key, period) {
        if (arguments.length > 1) {
            this.set(valueProperty, (period && period.value) || '')
            return period
        } else {
            var v = this.get(valueProperty)
            if (!v) {
                return null
            }
            // The Balance sheet uses the `through` period type (from the beginning of time until a certain date)
            // So we need to manually handle that one here in a little hacky way. We can't add that type to the period-selector, as we don't want users to select it.
            var split = v.split(':')
            var periodType = split[0].toLowerCase()
            if (periodType === 'through') {
                return Em.O({
                    name: t('through_period', { date: moment(split[1]).format('LL') }),
                    value: v
                })
            }
            return parsePeriod(v)
        }
    }.property(valueProperty)
}

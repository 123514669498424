var _ = require('lodash')

module.exports = Em.Object.extend({
    api: Ember.inject.service(),

    context: {
        BANK_INTEGRATION: 'bankintegration',
        BANK_INTEGRATION_VERSION: 2,
        VAT_RETURN: 'vatreturn',
        VAT_RETURN_VERSION: 1,
        MENETO_AR_TERMS: 'menetoannualreportterms',
        MENETO_AR_TERMS_VERSION: 1,
        MENETO_AR_REGNSKAB: 'menetoannualreportmanagementrepresentationletter',
        MENETO_AR_REGNSKAB_VERSION: 1
    },

    hasConsent: function(organizationId, context, contextVersion) {
        var query = [
            'organizationId=' + organizationId,
            'context=' + context,
            'contextVersion=' + (contextVersion === undefined ? 1 : contextVersion),
            'sortProperty=createdTime',
            'sortDirection=DESC',
            'pageSize=1',
            'page=1'
        ].join('&')

        return this.get('api').request('GET', '/v2/organizationConsentEntries?' + query)
            .then(function(payload) {
                return _.get(payload.organizationConsentEntries, [0], { id: null, consent: false })
            })
    },

    setConsent: function(organizationId, context, contextVersion, consentText, consent) {
        return this.get('api').request('POST', '/v2/organizationConsentEntries', {
            payload: {
                organizationConsentEntry: {
                    organizationId: organizationId,
                    context: context,
                    consent: consent ? '1' : '0',
                    contextVersion: contextVersion === undefined ? 1 : contextVersion,
                    consentText: consentText || null
                }
            }
        })
    },

    giveConsent: function(organizationId, context, contextVersion, consentText) {
        return this.setConsent(organizationId, context, contextVersion, consentText, true)
    }
})

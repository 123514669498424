module.exports = BD.Model.extend({
    organization: BD.belongsTo('Billy.Organization'),
    contact: BD.belongsTo('Billy.Contact'),
    isRecurring: BD.attr('boolean'),
    recurringInterval: BD.attr('number'),

    // day, week, month, year
    recurringUnit: BD.attr('string'),

    // 1 = Monday, 17 = 17th of month, depending on recurringUnit
    recurringValue: BD.attr('number'),

    // approveAndSend, approve, draft
    createMode: BD.attr('string'),

    nextInvoiceDate: BD.attr('date'),
    endDate: BD.attr('date'),
    recipient: BD.belongsTo('Billy.ContactPerson'),
    emailSubject: BD.attr('string'),
    emailBody: BD.attr('string'),
    emailCopyToUser: BD.belongsTo('Billy.User'),
    dueMode: BD.attr('string'),
    dueValue: BD.attr('number'),
    contactMessage: BD.attr('string')
})

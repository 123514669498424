module.exports = function(line, organization, contact) {
    var salesTaxRuleset = line.get('product.salesTaxRuleset')
    if (contact && contact.get('isSalesTaxExempt')) {
        line.set('taxRate', null)
    } else if (salesTaxRuleset) {
        salesTaxRuleset.get('rules').promise.then(function() {
            // Ensures rules are loaded before searching for applicable rules
            if (contact) {
                line.set('taxRate', salesTaxRuleset.findTaxRate(contact.get('country'), contact.get('state'), contact.get('city'), contact.get('type')))
            } else {
                line.set('taxRate', salesTaxRuleset.findTaxRate(organization.get('country')))
            }
        })
    }
}

module.exports = require('./bank-sync-v2-abstract-item').extend({
    specialClasses: function() {
        var c = 'line right '
        var controller = this.get('controller')
        if (controller.get('parentController').indexOf(controller) > 0) {
            c += 'male-up'
        } else {
            c += 'female-right'
        }
        return c
    }.property('controller.parentController.@each'),

    draggableHelperClass: 'female-right'
})

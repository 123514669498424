var locales = {
    en_US: require('../locales/en_US'),
    da_DK: require('../locales/da_DK'),
    fr_FR: require('../locales/fr_FR')
}
var i18nContext = require('i18n-context')('ember_dialog', locales)
var t = i18nContext.t

module.exports = function(container) {
    container.register('component:dialog-window', require('./dialog-window'))

    function dialog(title, message, options, properties) {
        properties = properties || {}
        return new Em.RSVP.Promise(function(resolve) {
            var w = container.lookup('component:dialog-window')
            w.set('title', title || t('confirm'))
            w.set('message', message)
            w.set('options', options)
            w.setProperties(properties)
            w.on('didCancel', function(option) {
                if (!properties.ignoreFalsy) {
                    resolve(null)
                }
            })
            w.on('clickedOption', function(option) {
                if (!properties.ignoreFalsy || option.value) {
                    resolve(option.value)
                }
                w.close()
            })
            w.show()
        })
    }

    function confirm(title, message, yesText, yesPrimary, yesWarning, cancelText, cancelPrimary, cancelWarning) {
        return dialog(title || t('confirm'), message, [
            {
                value: 'yes',
                text: yesText || t('ok'),
                primary: yesPrimary,
                warning: yesWarning,
                align: 'right'
            },
            {
                value: false,
                text: cancelText || t('cancel'),
                primary: cancelPrimary,
                warning: cancelWarning,
                align: 'left'
            }
        ], {
            focusSelector: '.window-footer .right button',
            closable: true,
            ignoreFalsy: true
        })
    }

    return {
        confirm: function(title, message, yesText, cancelText) {
            return confirm(title, message, yesText, true, false, cancelText, false, false)
        },
        confirmImportant: function(title, message, yesText, cancelText) {
            return confirm(title, message, yesText, true, false, cancelText, false, true)
        },
        confirmWarning: function(title, message, yesText, cancelText) {
            return confirm(title, message, yesText, false, true, cancelText, false, false)
        },
        dialog: dialog
    }
}

module.exports.locale = i18nContext.locale

module.exports.lang = function() {
    console.warn('.lang() is deprecated. Use .locale() instead')
    return i18nContext.locale.apply(null, arguments)
}

module.exports = Em.Route.extend(require('../mixins/dirty-route'), {
    model: function() {
        return this.modelFor('recurring_invoice')
    },

    isDirty: function(invoice) {
        if (invoice.get('isDirty')) {
            return true
        }
        if (this.controllerFor('recurring_invoice.edit').get('deletedLines.length') > 0) {
            return true
        }
        return invoice.get('lines').some(function(line) {
            return line.get('isDirty')
        })
    },

    rollback: function(invoice) {
        var controller = this.controllerFor('recurring_invoice.edit')
        invoice.get('lines')
            .map(function(r) { // Map to a plain array first, so the rolled back records aren't removed, which would end up in `r` being undefined for the last records
                return r
            })
            .concat(controller.get('deletedLines'))
            .forEach(function(r) {
                r.rollback()
            })
        invoice.rollback()
        controller.set('deletedLines', [])
    }
})

var t = require('i18n').t
var moment = require('moment')
var NOTIFICATION_KEYS = require('../notificationKeys')
var annualReportData = require('../data/annual-report')
var _ = require('lodash')
var i18n = require('i18n')
var BILLING_TYPES = require('../billingTypes')
var ADDONS = require('../utils/addons')
var isCompanyEmv = require('../utils/isCompanyEmv')
var Cookies = require('js-cookie')
var cookieKey = require('../constants/cookieKey')
var getDisplayDate = require('../utils/get-display-date')
var retry = require('../utils/retry-promise')
var Scope = require('../utils/scope')

module.exports = Em.Object.extend({
    needs: ['user'],

    COUPON_CODE: 'opstartbilly',
    COUPON_DISCOUNT_PERCENT: 1,
    NON_PREMIUM_ACCOUNT_SURCHARGE: 0,
    ACCOUNTING_PACKAGE_SOLD_UNTIL_YEAR: 2022,

    MENETO_INVENTORY_ACCOUNT_NUMBER: 5830,

    api: Em.inject.service(),

    auth: Em.inject.service(),

    cvr: Em.inject.service(),

    annualReport: Em.inject.service(),

    annualReportCredits: Em.inject.service(),

    userOrganizations: Em.inject.service(),

    billingCardSubscription: Em.inject.service(),

    organization: Em.computed.alias('userOrganizations.activeOrganization'),

    organizationSubscription: Em.inject.service(),

    activeStep: null,

    userUmbrellas: Em.inject.service(),

    user: function() {
        return this.container.lookup('controller:user')
    }.property(),

    labs: function() {
        return this.container.lookup('controller:labs')
    }.property(),

    getDiscountValidOneOffAddons: function() {
        return [ADDONS.ACCOUNTING_PACKAGE, ADDONS.AR_EMV, ADDONS.AR_LTD]
    },

    getDiscountValidSubscriptionAddons: function() {
        return [ADDONS.AR_SUB_EMV, ADDONS.AR_SUB_LTD]
    },

    hasARTokenScope: function() {
        return this.get('auth').isAuthorized(Scope.AnnualReportToken)
    }.property(),

    maybeAdjustPriceWithCoupon: function(price) {
        var self = this
        var plan = this.get('organizationSubscription.currentPlanName')
        var subscriptionPeriod = this.get('organization.subscriptionPeriod')

        if (plan !== 'premium') {
            return price + self.NON_PREMIUM_ACCOUNT_SURCHARGE
        }

        return subscriptionPeriod === 'yearly' ? self.adjustPriceWithCoupon(price) : price
    },

    hasStarterPackageCoupon: function() {
        var couponCode = this.get('organization.couponCode')
        var couponExpires = this.get('organization.couponExpires')

        return couponCode === this.get('COUPON_CODE') && moment(couponExpires).isAfter(new Date())
    }.property('organization.{couponCode,couponExpires}'),

    getPrices: function(annualPrice, oneoffPrice) {
        var self = this

        return {
            annual: annualPrice || 0,
            oneoff: self.maybeAdjustPriceWithCoupon(oneoffPrice || 0)
        }
    },

    getAnnualReportSubscription: function() {
        return this.get('organizationSubscription').getExternalSubscriptionDataById(this.get('organization.id'))
            .then(function(result) {
                var addons = result.AddonSubscriptions

                if (!addons) {
                    return null
                }

                return _.find(addons, function(addonSubscription) {
                    return addonSubscription.ProductPlan.RefKey.indexOf('annual_report_subscription') >= 0
                })
            })
    },

    getSubscriptionRenewalDate: function(subscription) {
        if (!subscription || !subscription.NextCharge) {
            return ''
        }

        return getDisplayDate(subscription.NextCharge.NextChargeDate)
    },

    hasSubscriptionRenewed: function(subscription, year) {
        return year >= 2022 && !!(subscription && subscription.ID)
    },

    isPackageSoldOut: function(year) {
        return year <= this.ACCOUNTING_PACKAGE_SOLD_UNTIL_YEAR
    },

    hasSubscriptionRenewedForCommitment: function(subscription) {
        if (!subscription || !subscription.NextCharge) {
            return false
        }

        var renewal = moment(subscription.NextCharge.NextChargeDate)
        var nowPlus11Months = moment(new Date()).add('months', 11)

        return moment(renewal).isAfter(nowPlus11Months)
    },

    getCouponOrDiscount: function() {
        var coupon = Cookies.get(cookieKey.couponARDiscount)

        if (coupon) {
            return JSON.parse(coupon)[0]
        }

        if (this.get('annualReportCredits.hasUnusedCredits')) {
            return {
                discount: 100,
                year: 2022,
                product: 'diy'
            }
        }

        return null
    }.property(),

    getPriceAccountedForCoupons: function(price) {
        var coupon = this.get('getCouponOrDiscount')
        if (!coupon) return price
        return price - ((price / 100) * coupon.discount)
    },

    getDisplayPriceAccountedForCoupons: function(price, product) {
        var coupon = this.get('getCouponOrDiscount')

        if (!coupon) {
            return price
        }

        var adjustedPrice = price - ((price / 100) * coupon.discount)
        var roundedAdjustedPrice = Math.round(adjustedPrice * 100) / 100

        if (!coupon.product) {
            return roundedAdjustedPrice
        }

        return coupon.product === product ? roundedAdjustedPrice : price
    },

    applyCouponPassedFromUrl: function(addons) {
        var discountValidOneOffAddons = this.getDiscountValidOneOffAddons()
        var discountValidSubscriptionAddons = this.getDiscountValidSubscriptionAddons()

        var coupon = this.get('getCouponOrDiscount')

        if (!coupon) return addons

        Cookies.remove(cookieKey.couponARDiscount)

        return addons.map(function(addon) {
            // this covers the case of manually added coupons / discounts that come from free AR credits
            if (coupon.product && coupon.product === 'diy' && addon.id === ADDONS.ACCOUNTING_PACKAGE.id) {
                return addon
            }

            if (discountValidOneOffAddons.some(function(discountValidOneOffAddon) { return discountValidOneOffAddon.id.includes(addon.id) })) {
                addon.discount = coupon.discount
            }

            if (discountValidSubscriptionAddons.some(function(discountValidSubscriptionAddon) { return discountValidSubscriptionAddon.id.includes(addon.id) })) {
                addon.discountCode = coupon.code
            }

            return addon
        })
    },

    calculatePrice: function() {
        var self = this

        if (this.isInvalidRegistrationNo()) {
            return Em.RSVP.Promise.resolve(self.getPrices(ADDONS.AR_SUB_EMV.unitPrice, ADDONS.AR_EMV.unitPrice))
        }

        return this.get('cvr')
            .loadSafe()
            .then(function(cvrData) {
                var price = {
                    annual: null,
                    oneoff: null
                }
                if (!cvrData || !['ENK', 'PMV'].includes(cvrData.companyType)) {
                    price.annual = ADDONS.AR_SUB_LTD.unitPrice
                    price.oneoff = ADDONS.AR_LTD.unitPrice
                } else {
                    price.annual = ADDONS.AR_SUB_EMV.unitPrice
                    price.oneoff = ADDONS.AR_EMV.unitPrice
                }

                return self.getPrices(price.annual, price.oneoff)
            })
            .catch(function() {
                // Default to the lower price
                var onePersonCompanyPrice = self.getPriceAccountedForCoupons(ADDONS.AR_SUB_EMV.unitPrice)
                var onePersonCompanyPriceOneOff = self.getPriceAccountedForCoupons(ADDONS.AR_EMV.unitPrice)
                return self.getPrices(onePersonCompanyPrice, onePersonCompanyPriceOneOff)
            })
    },

    calculateAccountingPackagePrice: function() {
        return ADDONS.ACCOUNTING_PACKAGE.unitPrice
    },

    adjustPriceWithCoupon: function(price) {
        return this.hasDiscountCoupon()
            ? Math.floor(price * (1 - this.COUPON_DISCOUNT_PERCENT))
            : price
    },

    hasDiscountCoupon: function() {
        if (this.get('organization.couponCode') !== this.COUPON_CODE || !this.get('organization.couponRedeemed')) {
            return false
        }

        var couponExpires = this.get('organization.couponExpires')
        if (couponExpires) {
            return moment(couponExpires).isAfter(new Date())
        }

        return true
    }.observes('organization.couponCode'),

    isInvalidRegistrationNo: function() {
        var str = this.get('cvr').cleanRegistrationNo()

        return !/^((?:DK)?\d{8})$/.test(str) || !this.get('organization.companyType')
    },

    getAnnualReportAddonData: function(isSubscription, year) {
        var companyType = this.get('cvr.data.companyType')

        var collectedAddon

        if (isSubscription) {
            if (isCompanyEmv(companyType)) {
                collectedAddon = ADDONS.AR_SUB_EMV
            } else {
                collectedAddon = ADDONS.AR_SUB_LTD
            }
        } else {
            if (isCompanyEmv(companyType)) {
                collectedAddon = ADDONS.AR_EMV
            } else {
                collectedAddon = ADDONS.AR_LTD
            }
        }

        collectedAddon.year = year
        collectedAddon.userId = this.get('user.id')

        return collectedAddon
    },

    getNextFiscalYear: function(startDate, endDate) {
        var nextEndDate = endDate.clone().add(1, 'year')
        var nextStartDate = nextEndDate.clone().add(-1, 'year').add(1, 'day')

        return {
            year: nextEndDate.year(),
            startDate: nextStartDate,
            endDate: nextEndDate
        }
    },

    getFiscalYearForYear: function(year, firstStartDate, firstEndDate) {
        var fiscalYear = {
            year: moment.utc(firstEndDate).year(),
            startDate: moment.utc(firstStartDate),
            endDate: moment.utc(firstEndDate)
        }

        while (fiscalYear.endDate.year() < year) {
            fiscalYear = this.getNextFiscalYear(fiscalYear.startDate, fiscalYear.endDate)
        }

        return fiscalYear
    },

    /**
     * Get full report.
     * Does not include either "questions" nor "completion" if no commit has happened.
     *
     * Includes
     * ```
     * {
     *     "questions": [{...},...,{...}],
     *     "completion": {"checklist": false, ..., ...}
     * }
     * ```
     *
     * Questions are logically "grouped" by a property called group.
     *
     * Example to filter to only include checklist questions:
     *
     * ```
     * this.getReport('2017').then(function(response) {
     *     _.filter(response.questions, function(question) {
     *         return question.group === 'checklist'
     *     })
     * })
     * ```
     *
     * Example to group them nicely
     *
     * ```
     * this.getReport('2017').then(function(response) {
     *     _.group(response.questions, 'group')
     * })
     * ```
     *
     * The completion property is meant for the sidebar with checkmarks.
     * true if everything is answered
     * false if not
     *
     * Backend can save wrong answers and 'complete' for now.
     *
     * All groups have a 'completed' question. This will always be boolean.
     * Nothing than a 'completed' question in master_data intentionally.
     *
     * @param {string} year
     * @returns {Promise<AnnualReport>}
     */
    getReport: function(year) {
        var organizationId = this.get('userOrganizations').get('activeOrganization.id')

        return this.get('api').getData('/organizations/' + organizationId + '/annualReport/' + year)
    },

    calculateTotalAmountForAddons: function(addons) {
        var discountedAddons = this.applyCouponPassedFromUrl(addons)

        return discountedAddons.reduce(function(acc, obj) {
            var price = obj.discount ? obj.unitPrice - ((obj.unitPrice / 100) * obj.discount) : obj.unitPrice
            return acc + price
        }, 0)
    },

    shouldPurchaseAddonsViaUpodi: function() {
        var organization = this.get('organization')
        var isExternal = organization.get('billingType') === BILLING_TYPES.EXTERNAL
        var isAllowedForMigration = organization.get('isAllowedForUpodiMigration')

        return isExternal || isAllowedForMigration
    },

    purchaseAddonsViaUpodi: function(addons) {
        var organization = this.get('organization')
        var url = '/integrations/billing/' + organization.get('id') + '/addons/buy'
        var providerTheme = ENV.theme

        var payload = {
            currency: organization.get('subscriptionCurrency').get('id'),
            organizationId: organization.get('id'),
            amount: this.calculateTotalAmountForAddons(addons),
            language: this.get('user.quickpayLanguage'),
            variables: JSON.stringify({ cardOnly: true, providerTheme: providerTheme }),
            continueUrl: this.get('billingCardSubscription').getPaymentContinueUrl({}),
            cancelUrl: this.get('billingCardSubscription').getPaymentContinueUrl({}),
            addons: this.applyCouponPassedFromUrl(addons)
        }

        // do not send invoice when credit is already present
        if (payload.amount === 0) {
            return Promise.resolve()
        }

        return this.get('api').request('POST', url, {
            payload: payload
        })
            .then(function(purchaseResult) {
                if (purchaseResult.paymentRequired) {
                    window.location = purchaseResult.url
                }

                // if not required, just proceed
            })
    },

    waitForCredit: function() {
        var self = this
        var NUMBER_OF_RETRIES = 30
        var RETRY_DELAY = 2000
        var creditCheckFn = function() {
            return new Promise(function(resolve, reject) {
                return self.get('annualReportCredits').fetchUnusedCredits()
                    .then(function(credits) {
                        if (credits.length > 0) {
                            resolve(true)
                        } else {
                            reject(new Error('No free credits'))
                        }
                    })
            })
        }

        return retry(creditCheckFn, NUMBER_OF_RETRIES, RETRY_DELAY)
            .then(Promise.resolve())
    },

    /**
     * @param {string} year
     * @param {boolean} isSubscription
     * @returns {Promise<AnnualReport>}
     */
    completeChecklist: function(year, isSubscription, addons) {
        var self = this
        var organization = this.get('userOrganizations').get('activeOrganization')
        var purchasePromise = Promise.resolve()
        var creditAvailable = Promise.resolve()

        if (this.shouldPurchaseAddonsViaUpodi() && !this.get('hasARTokenScope')) {
            purchasePromise = this.purchaseAddonsViaUpodi(addons)
            creditAvailable = this.waitForCredit.bind(this)
        }

        return purchasePromise
            .then(creditAvailable)
            .then(this.completeChecklistRequest.bind(this, organization.get('id'), year, isSubscription))
            .catch(function(error) {
                if (error.status === 409) {
                    self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.AR_STEPS, t('annual_report.request.unpaid_invoices_error'))
                } else {
                    self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.AR_STEPS, t('annual_report.request.credits_error'))
                }
            })
    },

    /**
     * @param {string} year
     * @param {boolean} isSubscription
     * @returns {Promise<AnnualReport>}
     */
    completeChecklistAfterPayment: function(year, isSubscription) {
        var organization = this.get('userOrganizations').get('activeOrganization')

        return this.waitForCredit()
            .then(this.completeChecklistRequest.bind(this, organization.get('id'), year, isSubscription))
            .catch(function() {
                throw new Error(t('annual_report.request.credits_error'))
            })
    },

    completeChecklistRequest: function(organizationId, year, isSubscription) {
        return this.get('api')
            .put('/organizations/' + organizationId + '/annualReport/' + year, {
                payload: {
                    data: {
                        completion: {
                            checklist: true
                        },
                        isSubscription: isSubscription
                    }
                }
            })
            .catch(function(e) {
                switch (e.code) {
                case 'E_VALIDATION':
                    self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.AR_STEPS, t('annual_report.request.validation_error'))
                    break
                default:
                    throw e
                }
            })
    },

    putSteps: function(year, steps) {
        var self = this
        if (!year) {
            throw new Error('No year specified')
        }

        var organizationId = this.get('userOrganizations.activeOrganization.id')

        return this.get('api')
            .put('/organizations/' + organizationId + '/annualReport/' + year, {
                payload: {
                    data: {
                        completion: steps
                    }
                }
            })
            .catch(function(e) {
                switch (e.code) {
                case 'E_VALIDATION':
                    self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.AR_STEPS, t('annual_report.request.validation_error'))
                    break
                default:
                    throw e
                }
            })
    },

    /**
     * @param {AnnualReportCommitment} commitment
     * @returns {AnnualReportStepEnum}
     */
    getCurrentStep: function(commitment) {
        var currentStep
        var self = this
        var steps = this.getStepOrder()

        // Hack before we run migration on all annual reports with .completed on steps
        if (commitment.state === 'reported' || commitment.state === 'received') {
            return steps[steps.length - 1]
        }

        steps.some(function(step) {
            currentStep = step
            return !self.isStepCompleted(step, commitment)
        })

        return currentStep || steps[0]
    },

    /**
     * @param {AnnualReportCommitment} commitment
     * @returns {AnnualReportStep[]}
     */
    getSteps: function(commitment) {
        if (!commitment) {
            return []
        }

        var self = this
        return this.getStepOrder().map(function(step) {
            return {
                route: step,
                completeRoute: 'annual_report.' + step,
                completed: self.isStepCompleted(step, commitment),
                settings: self.getStepSettings(step)
            }
        })
    },

    getCompleteBookkeepingItems: function(commitment) {
        return _.chain(commitment.questions)
            .filter({ group: 'complete_bookkeeping' })
            .map(function(item) {
                return Em.Object.create({
                    year: commitment.year,
                    translationKey: item.name,
                    isChecked: item.value || false
                })
            })
            .value()
    },

    getAboutYourTaxesItems: function(commitment) {
        return _.chain(commitment.questions)
            .filter({ group: 'about_your_taxes' })
            .groupBy('section')
            .toArray()
            .map(function(section) {
                var sectionName = section.get('firstObject.section')
                section[sectionName] = true
                var selection = section.get('firstObject.value')
                section.dropdownIsVisible = selection === true
                section.translationKey = sectionName
                section.year = commitment.year
                section.choices = [
                    Em.Object.create({
                        label: t('annual_report.additional_info.multiple_choice.choice.1'),
                        value: true,
                        isSelected: selection === true
                    }),
                    Em.Object.create({
                        label: t('annual_report.additional_info.multiple_choice.choice.2'),
                        value: false,
                        isSelected: selection === false
                    })
                ]
                section.isValid = selection !== null

                return section
            })
            .value()
    },

    getAdditionalInfoItems: function(commitment) {
        var sectionsToSkip = [
            'nominal_capital_changes',
            'joint_taxation',
            'the_owner_provides_a_private_car'
        ]

        return _.chain(commitment.questions)
            .filter({ group: 'additional_info' })
            .groupBy('section')
            .toArray()
            .map(function(section) {
                var sectionName = section.get('firstObject.section')
                section[sectionName] = true
                var selection = section.get('firstObject.value')
                section.dropdownIsVisible = selection === true
                section.translationKey = sectionName
                section.year = commitment.year
                if (sectionsToSkip.contains(sectionName)) {
                    section.isValid = true
                    section.hidden = true
                } else if (!['company_activity', 'general_assembly', 'average_employees', 'years_result'].contains(sectionName)) {
                    section.choices = [
                        Em.Object.create({
                            label: t('annual_report.additional_info.multiple_choice.choice.1'),
                            value: true,
                            isSelected: selection === true
                        }),
                        Em.Object.create({
                            label: t('annual_report.additional_info.multiple_choice.choice.2'),
                            value: false,
                            isSelected: selection === false
                        })
                    ]
                    section.isValid = selection !== null
                } else {
                    switch (sectionName) {
                    case 'general_assembly':
                        var assemblyDate = _.find(section, { name: 'additional_info.general_assembly.date' })
                        assemblyDate.error = Em.O()
                        var address = _.find(section, { name: 'additional_info.general_assembly.address' })
                        address.error = Em.O()
                        var conductor = _.find(section, { name: 'additional_info.general_assembly.conductor' })
                        conductor.error = Em.O()

                        section.validate = function() {
                            var isValid = assemblyDate.error.get('code') !== 'ValidationError'
                            isValid && assemblyDate.error.set('text', Em.isEmpty(assemblyDate.value) ? t('required_field') : null)
                            address.error.set('text', Em.isEmpty(address.value) ? t('required_field') : null)
                            conductor.error.set('text', Em.isEmpty(conductor.value) ? t('required_field') : null)

                            section.isValid = isValid && _.every(section, function(item) { return !Em.isEmpty(item.value) })
                        }
                        break
                    case 'company_activity':
                        var companyActivity = _.find(section, { name: 'additional_info.company_activity_id' })
                        companyActivity.error = Em.O()
                        section.validate = function() {
                            section.isValid = !Em.isEmpty(companyActivity.value)
                            companyActivity.error.set('text', Em.isEmpty(companyActivity.value) ? t('required_field') : null)
                        }
                        break
                    default:
                        section.isValid = true
                    }
                }

                return section
            })
            .value()
    },

    /**
     * @param {AnnualReportCommitment} commitment
     */
    getApproveBookkeepingItems: function(commitment) {
        var self = this
        return commitment.questions
            .filter(function(question) {
                return question.group === 'approve_bookkeeping'
            })
            .map(function(question) {
                var updatedQuestion = Em.Object.create(Object.assign({}, question, {
                    year: commitment.year,
                    choices: [
                        Em.Object.create({
                            label: t('annual_report.' + question.name + '.choice.1'),
                            isSelected: question.value === true,
                            value: true
                        }),
                        Em.Object.create({
                            label: t('annual_report.' + question.name + '.choice.2'),
                            isSelected: question.value === false,
                            value: false
                        })
                    ]
                }))

                var questionName = question.name.split('.').pop()
                updatedQuestion[questionName] = true

                // The link-to helper doesn't allow for adding query parameters, so we have to do it ourselves
                var router = self.container.lookup('router:main')

                switch (questionName) {
                case 'approve_trial_balance':
                    var query = $.param({
                        hide_zeroes: true,
                        period: 'fiscalyear:' + self.get('organization.id') + ',' + commitment.year
                    })

                    updatedQuestion.link = router.generate('trial_balance', self.get('organization')) + '?' + query
                    break

                case 'approve_debitor_list':
                    updatedQuestion.link = self._getAccountListPdfUrl('debitor', commitment.year)
                    break

                case 'approve_creditor_list':
                    updatedQuestion.link = self._getAccountListPdfUrl('creditor', commitment.year)
                    break
                }

                return updatedQuestion
            })
    },

    getIntangibleFixedAssets: function(commitment) {
        return _.chain(commitment.questions)
            .filter({ group: 'intangible_fixed_assets' })
            .groupBy('section')
            .toArray()
            .map(function(section) {
                return _.transform(section, function(res, i) {
                    var name = i.name.split('.').splice(2, 2).join('_')
                    var translationKey = i.group + '.' + i.section
                    res[name] = i
                    _.assign(res, {
                        year: commitment.year,
                        key: translationKey
                    })
                    return Em.Object.create(res)
                }, {})
            })
            .value()
    },

    getTangibleFixedAssets: function(commitment) {
        return _.chain(commitment.questions)
            .filter({ group: 'tangible_fixed_assets' })
            .groupBy('section')
            .toArray()
            .map(function(section) {
                return _.transform(section, function(res, i) {
                    var name = i.name.split('.').splice(2, 2).join('_')
                    var translationKey = i.group + '.' + i.section
                    res[name] = i
                    _.assign(res, {
                        year: commitment.year,
                        key: translationKey
                    })
                    return Em.Object.create(res)
                }, {})
            })
            .value()
    },

    getMissingAccounts: function(commitment, sectionFilter) {
        sectionFilter = sectionFilter || false

        if (_.isEmpty(commitment.validationErrors)) {
            return []
        }

        return commitment.validationErrors
            .filter(function(error) {
                var codeValidation = ['E_ACCOUNT_GROUP_NOT_FOUND', 'E_ACCOUNT_NOT_FOUND'].includes(error.code)
                var sectionValidation = (sectionFilter) ? (sectionFilter === error.data.section) : true
                return codeValidation && sectionValidation
            })
            .map(function(error) {
                var result = error.data.number + ' - ' + error.data.name
                if (error.code === 'E_ACCOUNT_GROUP_NOT_FOUND') {
                    result = result + ' (' + t('annual_report.accounts_group') + ')'
                }
                return result
            })
    },

    getDownloadItems: function(commitment) {
        var organizationId = this.get('userOrganizations').get('activeOrganization.id')
        var accessToken = this.container.lookup('api:billy').storageAdapter.getValue('accessToken')

        return _.chain(commitment.files)
            .map(function(file) {
                return {
                    year: commitment.year,
                    name: file.name,
                    download_path: ENV.newApiUrl + '/organizations/' + organizationId + '/annualreports/files/' + file.id + '?access_token=' + accessToken
                }
            })
            .value()
    },

    commit: function(year, registrationNo) {
        var self = this
        var organization = this.get('userOrganizations').get('activeOrganization')

        return organization.set('registrationNo', registrationNo).save({
            properties: {
                registrationNo: registrationNo
            }
        }).then(function() {
            var organizationId = self.get('userOrganizations').get('activeOrganization.id')
            var url = ('/organizations/' + organizationId + '/annualReport/' + year + '/commit')

            return self.get('api').post(url, {
                payload: {
                    data: {
                        companyType: organization.get('companyType'),
                        context: 'billy'
                    }
                }
            })
        })
            .catch(function(e) {
                switch (e.code) {
                case 'E_DUPLICATE':
                    self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.AR_COMMIT, t('annual_report.request.duplicate_error', { year: year }))
                    break
                default:
                    throw e
                }
            })
    },

    /**
     * Put a collection of answers.
     *
     * Not much validation happens at the moment. Posting a wrong "name" will return in an error.
     *
     * You can literally get the report, and just POST what you get in
     * questions (but please change the values, since we'll do proper validation later)
     *
     * Example
     * ```
     * this.putAnswer('2017', [
     *     {
     *         name: 'checklist.has_mandatory_audit',
     *         value: false
     *     },
     *     {
     *         name: 'checklist.has_suspected_termination',
     *         value: false
     *     }
     * ]).then(function(response) {
     *     // Holy cow, the response is the full report in its new state!
     *  })
     * ```
     */
    putAnswer: function(year, answers) {
        var self = this

        if (!year) {
            throw new Error('no year')
        }

        var organizationId = this.get('userOrganizations').get('activeOrganization.id')

        return this.get('api').put('/organizations/' + organizationId + '/annualReport/' + year, {
            payload: {
                data: {
                    questions: answers
                }
            }
        })
            .catch(function(e) {
                switch (e.code) {
                case 'E_VALIDATION':
                    throwForSchema(e, 'additional_info.general_assembly.date')
                    self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.AR_ANSWER, t('annual_report.request.validation_error'))
                    break
                default:
                    throw e
                }
            })
    },

    /**
     * @returns {Promise<{years: AnnualReportYear[]}>}
     */
    getYears: function() {
        var organizationId = this.get('userOrganizations').get('activeOrganization.id')

        return this.get('api').getData('/organizations/' + organizationId + '/annualReport/years')
            .then(function(data) {
                var years = data.years.map(function(year) {
                    return Object.assign({ isAvailable: true }, year)
                })
                var lastYear = years.sortBy('year').last() || { year: moment.utc().year() - 1 }
                var nextYear = {
                    year: lastYear.year + 1,
                    state: 'inactive',
                    isAvailable: false,
                    isFillable: false,
                    companyType: lastYear.companyType
                }

                // Add upcoming year upsell
                return {
                    years: years.concat([nextYear])
                }
            })
    },

    getDebtItems: function(commitment) {
        return _.chain(commitment.questions)
            .filter({ group: 'long_term_debt' })
            .transform(function(res, item) {
                var period = item.name.split('.').pop()
                res[item.section] = res[item.section] || {}
                res[item.section][period] = item
                return res
            }, {})
            .value()
    },

    getCommitment: function(id) {
        var self = this
        var organizationId = this.get('userOrganizations').get('activeOrganization.id')
        var url = '/organizations/' + organizationId + '/annualreports/' + id

        return self.get('api').getData(url)
    },

    putState: function(id, state) {
        var self = this
        var organizationId = this.get('userOrganizations').get('activeOrganization.id')

        return this.get('api').put('/organizations/' + organizationId + '/annualreports/' + id, {
            payload: {
                data: {
                    state: state
                }
            }
        })
            .catch(function(e) {
                switch (e.code) {
                case 'E_VALIDATION':
                    self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.AR_STATE, t('annual_report.request.validation_error'))
                    break
                default:
                    throw e
                }
            })
    },

    isAvailable: function() {
        return true
    },

    /**
     * @returns {string[]}
     */
    getStepOrder: function() {
        var path
        var organizationCompanyType = this.get('organization.companyType')
        var companyType = organizationCompanyType === 'other' ? 'company' : organizationCompanyType

        path = annualReportData.paths.billy[companyType]

        return path || ['start']
    },

    /**
     * Gets the overall settings, calculated by merging more and more specific settings on top of each other:
     * - Global default settings (default)
     * - Context default settings (billy.default / meneto.default)
     * - Global step settings (<step>)
     * - Context step settings (billy.<step> / meneto.<step>)
     *
     * @param {string} step
     * @returns {AnnualReportStepSettings}
     */
    getStepSettings: function(step) {
        var contextSettings = annualReportData.settings.billy || {}
        var globalDefaultSettings = annualReportData.settings.default
        var contextDefaultSettings = contextSettings.default || {}
        var globalStepSettings = annualReportData.settings[step] || {}
        var contextStepSettings = contextSettings[step] || {}

        return Object.assign(
            {},
            globalDefaultSettings,
            contextDefaultSettings,
            globalStepSettings,
            contextStepSettings
        )
    },

    /**
     * @param {string} year
     * @returns {Promise<AnnualReportModel>}
     */
    bootstrap: function(year) {
        var self = this
        return this
            .getYears()
            .then(function(model) {
                var commitment = _.find(model.years, { year: _.toInteger(year) })
                return Em.RSVP.hash({
                    years: model.years,
                    commitment: commitment.state === 'inactive'
                        ? null
                        : self.getCommitment(commitment.id)
                })
            })
            .then(function(model) {
                var data = model.commitment
                    ? model.commitment.data
                    : {}
                var steps = self.getSteps(data)
                var currentStep = self.getCurrentStep(data)
                var commitment = model.years.find(function(yearInformation) {
                    return yearInformation.year === parseInt(year)
                })

                return {
                    years: model.years,
                    steps: steps,
                    currentStep: currentStep,
                    settings: self.get('organization'),
                    commitment: Object.assign(
                        data,
                        commitment,
                        model.commitment
                    )
                }
            })
    },

    /**
     * @param {string} step
     * @returns {string | null}
     */
    getPreviousStep: function(step) {
        var steps = this.getStepOrder()
        var currentIndex = steps.indexOf(step)
        if (currentIndex <= 0) {
            return null
        }

        return steps[currentIndex - 1]
    },

    /**
     * @param {string} step
     * @returns {string | null}
     */
    getNextStep: function(step) {
        var steps = this.getStepOrder()
        var currentIndex = steps.indexOf(step)
        if (currentIndex < 0 || currentIndex + 1 >= steps.length) {
            return null
        }

        return steps[currentIndex + 1]
    },

    /**
     * @param {AnnualReportStateEnum} state
     * @returns {boolean}
     */
    isSubmittedState: function(state) {
        return ['completed', 'delivered'].indexOf(state) >= 0 || this.isProcessedState(state)
    },

    /**
     * @param {AnnualReportStateEnum} state
     * @returns {boolean}
     */
    isProcessedState: function(state) {
        return ['received', 'reported'].includes(state)
    },

    /**
     * @param {AnnualReportStateEnum} state
     * @returns {boolean}
     */
    isInactiveState: function(state) {
        return ['inactive'].includes(state)
    },

    /**
     * @param {AnnualReportStepEnum} step
     * @param {AnnualReportCommitment} commitment
     * @returns {boolean}
     */
    isStepCompleted: function(step, commitment) {
        if (!commitment.completion || this.isInactiveState(commitment.state)) {
            return false
        }

        switch (step) {
        case 'not_started':
        case 'start':
        case 'start_client_flow':
            return true

        // "Waiting for bookkeeper" is considered complete when the bookkeeper has marked it as submitted for processing
        case 'waiting_for_bookkeeper':
            return !!commitment.completion.bookkeeper_submit_for_processing

        case 'submit_for_processing':
            return !!commitment.completion[step] && this.isSubmittedState(commitment.state)

        case 'report_processing':
            return this.isProcessedState(commitment.state)

        default:
            return !!commitment.completion[step]
        }
    },

    /**
     * @param {AnnualReportStepEnum} step
     * @returns {boolean}
     */
    canRevisitStep: function(step) {
        return this.getStepOrder().includes(step) && this.getStepSettings(step).canBeRevisited
    },

    /**
     * @private
     * @param {'creditor' | 'debitor'} listType
     * @param {number} year
     * @returns {string}
     */
    _getAccountListPdfUrl: function(listType, year) {
        var fiscalYear = this.getFiscalYearForYear(
            year,
            this.get('organization.firstFiscalYearStart'),
            this.get('organization.firstFiscalYearEnd')
        )

        var api = this.container.lookup('api:billy')
        var query = $.param({
            accessToken: api.storageAdapter.getValue('accessToken'),
            acceptLanguage: i18n.locale(),
            date: fiscalYear.endDate.format('YYYY-MM-DD')
        })

        var correctListType = (listType === 'debitor' ? 'debtor' : listType)
        return api.options.apiUrl + '/organizations/' + this.get('organization.id') + '/' + correctListType + 'List.pdf?' + query
    },

    /**
     * @param {number} year
     * @returns {Promise<number>}
     */
    getInventoryAccountValue: function(year) {
        var organizationId = this.get('organization.id')
        var query = {
            period: 'fiscalyear:' + organizationId + ',' + year,
            compareWith: 'period',
            compareCount: 1
        }

        var accountNumber = this.MENETO_INVENTORY_ACCOUNT_NUMBER

        return this.get('api')
            .getData('/v2/organizations/' + organizationId + '/trialBalance.json', query, { raw: true })
            .then(function(payload) {
                // This is very annoying because we do not receive the data as JSON from the server, we receive the full report
                // as HTML. We need to parse the HTML in order for it to work. Instead of a fancy regular expression, we can simply
                // split text a few times to get it, which is safer because then we don't depend on the placement of attributes
                // and it's less error-prone

                // ...<tr...><td...>{accountNumber}</td><td...>.....</td><td...>{value}</td><td...>.....</td></tr>...
                var html = payload.report.html

                //                                      <td...>.....</td><td...>{value}</td><td...>.....</td></tr>...
                var matchingHalf = html.split('>' + accountNumber + '</td>').pop()
                if (!matchingHalf) {
                    throw new Error('Could not find inventory account in trial balance')
                }

                //                                                       <td...>{value}
                var matchingCell = matchingHalf.split('</td>', 3)[1]
                if (!matchingCell) {
                    throw new Error('Could not find inventory account in trial balance')
                }

                var accountValue = matchingCell.split('>').pop()
                if (!accountValue) {
                    throw new Error('Could not find inventory account in trial balance')
                }

                var regex = /[.,]/g
                return parseFloat(accountValue.replace(regex, ''))
            })
    }
})

function throwForSchema(res, name) {
    var rawData = _.map(_.get(res, 'payload.errors'), 'raw_data')
    var schemaNameFound = _.flatten(
        _.filter(rawData, function(rawDataItem) {
            return /^Invalid answers:/.test(rawDataItem)
        }))
        .join()
        .includes(name)

    if (schemaNameFound) {
        throw name
    }
}

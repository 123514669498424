var billyApi = require('./billy-api')

var endpointDataProperties = {
    'bills/stackedBarChart': 'items',
    'bills/summary': 'summary',
    'invoices/stackedBarChart': 'items',
    'invoices/summary': 'summary'
}

module.exports = function(endpoint, query) {
    return new Em.RSVP.Promise(function(resolve, reject) {
        query = Ember.copy(query)
        if (query.organization) {
            query.organizationId = query.organization.get('id')
            delete query.organization
        }
        if (query.contact) {
            query.contactId = query.contact.get('id')
            delete query.contact
        }
        billyApi.get('/' + endpoint + '?' + $.param(query), {
            success: function(payload) {
                resolve(payload[endpointDataProperties[endpoint]])
            },
            error: reject
        })
    })
}

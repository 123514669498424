module.exports = require('./bank-sync-v2-abstract-item').extend({
    didInsertElement: function() {
        var controller = this.get('controller')
        controller.set('canBeRemoved', false)
    },

    specialClasses: function() {
        var c = 'subject '
        var controller = this.get('controller')
        if (controller.get('parentController').indexOf(controller) > 0) {
            c += 'male-up'
        } else {
            c += 'male-left'
        }
        return c
    }.property('controller.parentController.@each'),

    draggableHelperClass: 'male-left'
})

var t = require('i18n').t

module.exports = require('ember-super-field').types.Model.extend({
    modelClass: Billy.State,
    sortProperty: 'name',

    noOptionsFoundText: t('ui.fields.superfield.no_states_found'),

    optionViewClass: require('ember-super-field').OptionView.extend({
        template: Ember.Handlebars.compile(
            '<div class="name">{{name}} ({{stateCode}})</div>'
        )
    }),

    query: function() {
        return {
            country: this.get('field.country')
        }
    }.property('field.country')
})

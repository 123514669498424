// var dnd = require('../utils/dnd')

module.exports = Em.View.extend({
    templateName: 'bank-approved-line',

    classNames: ['match', 'fulfilled'],

    actions: {
        doUnapproveMatch: function() {
            this.controller.send('doUnapproveMatch')
        }
    }
})

module.exports = Em.Route.extend({
    model: function(params) {
        var quote = this.store.getById('quote', params.quote_id)
        if (quote) {
            return quote
        }
        return this.store.find('quote', { organization: this.modelFor('organization').id, id: params.quote_id })
            .then(function(quotes) {
                return quotes.objectAt(0)
            })
    }
})

var AuthorizedRoute = require('../mixins/authorized-route')
var Scope = require('../utils/scope')

module.exports = Em.Route.extend(AuthorizedRoute, {
    scopes: [Scope.OfferRead],

    model: function() {
        return this.modelFor('quote')
    },

    setupController: function(controller, model) {
        controller.set('model', model)
        controller.loadHtml()
        controller.activate()
    }
})

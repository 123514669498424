module.exports = Em.Route.extend({
    afterModel: function(daybookTransaction) {
        var self = this
        return Billy.Transaction.find({
            originatorReference: daybookTransaction.get('reference'),
            isVoided: false,
            isVoid: false
        }).promise
            .then(function(transactions) {
                if (transactions.get('length') === 0) {
                    self.replaceWith('transactions')
                } else {
                    self.replaceWith('transaction', transactions.objectAt(0))
                }
            })
    }
})

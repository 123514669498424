var t = require('i18n').t

module.exports = require('ember-super-field').types.Model.extend({
    modelClass: Billy.IndustryGroup,

    inputReadonly: true,

    allowKeyInput: false,

    inputValuePath: 'translatedName',

    sortProperty: 'translatedName',

    noOptionsFoundText: t('ui.fields.superfield.no_industries_found'),

    optionViewClass: require('ember-super-field').GroupedOptionView.extend({
        template: Ember.Handlebars.compile(
            '<div>{{view.content.translatedName}}</div>'
        )
    })
})

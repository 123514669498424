var Controller = require('./controller')

module.exports = Controller.extend({
    type: null,

    isReady: true,

    query: null,

    remoteQuery: null,

    isFiltered: function() {
        return !Em.isEmpty(this.get('records.q'))
    }.property('records.q'),

    records: function(property, records) {
        if (arguments.length === 2) {
            this.set('_records', records)
        } else {
            records = this.get('_records')
            if (!records) {
                records = this.loadRecords()
            }
        }
        return records
    }.property(),

    loadRecords: function() {
        if (!this.get('isReady')) {
            return null
        }
        var self = this
        var records = this.get('type').filter({
            query: this.get('query'),
            remoteQuery: this.get('remoteQuery'),
            q: this.get('q'),
            pageSize: 100,
            sortProperty: this.get('sortProperty'),
            sortDirection: this.get('sortDirection')
        })
        records.one('didLoad', function(payload) {
            self.didLoadRecords(payload)
            self._replaceRecords(records)
        })
        return records
    }.observes('isReady', 'query', 'sortProperty', 'sortDirection'),

    clearAndLoadRecords: function() {
        this._replaceRecords(null)
        this.loadRecords()
    },

    _replaceRecords: function(records) {
        var oldRecords = this.get('records')
        if (records !== oldRecords) {
            this.set('records', records)
            if (oldRecords) {
                oldRecords.destroy()
            }
        }
    },

    didLoadRecords: Em.K,

    qDidChange: function() {
        var self = this
        if (BD.store.allOfTypeIsLoaded(this.get('type'))) {
            this.loadRecords()
        } else {
            clearTimeout(this.loadRecordsTimeout)
            this.loadRecordsTimeout = setTimeout(function() {
                self.loadRecords()
            }, 200)
        }
    }.observes('q'),

    toString: function() {
        return '<list-model-controller>'
    }
})

var model = BD.Model.extend({
    chartOfAccount: BD.belongsTo('Billy.ChartOfAccount', { readonly: true }),
    name: BD.attr('string', { readonly: true }),
    accountNo: BD.attr('number', { readonly: true }),
    description: BD.attr('string', { readonly: true }),
    group: BD.belongsTo('Billy.AccountGroup', { readonly: true }),
    nature: BD.belongsTo('Billy.AccountNature', { readonly: true }),
    systemRole: BD.attr('string', { readonly: true }),
    taxRate: BD.belongsTo('Billy.TaxRate', { readonly: true }),
    isPaymentEnabled: BD.attr('boolean', { readonly: true }),
    isBankAccount: BD.attr('boolean', { readonly: true }),
    isEditable: BD.attr('boolean', { readonly: true })
})

module.exports = model

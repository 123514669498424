var AuthorizedRoute = require('../mixins/authorized-route')
var Scope = require('../utils/scope')

module.exports = Em.Route.extend(require('../mixins/dirty-route'), AuthorizedRoute, {
    scopes: [Scope.OfferWrite],

    model: function() {
        return this.modelFor('quote')
    },

    afterModel: function(quote) {
        if (!quote.get('isEditable')) {
            this.replaceWith('quote', quote)
        }
    },

    isDirty: function(quote, controller) {
        if (quote.get('isDirty')) {
            return true
        }
        if (controller.get('attachmentsChangeset.isDirty')) {
            return true
        }
        if (this.controllerFor('quote.edit').get('deletedLines.length') > 0) {
            return true
        }
        return quote.get('lines').some(function(line) {
            return line.get('isDirty')
        })
    },

    rollback: function(quote, controller) {
        quote.get('lines')
            .map(function(r) { // Map to a plain array first, so the rolled back records aren't removed, which would end up in `r` being undefined for the last records
                return r
            })
            .concat(controller.get('deletedLines'))
            .forEach(function(r) {
                r.rollback()
            })
        controller.get('attachmentsChangeset').rollback()
        quote.rollback()
        controller.set('deletedLines', [])
    }
})

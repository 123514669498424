module.exports = require('ember-super-field').types.Model.extend({
    inputValuePath: 'name',

    iconPath: 'iconUrl',

    modelClass: Billy.Locale,

    sortProperty: 'name',

    optionViewClass: require('ember-super-field').OptionView.extend({
        template: Ember.Handlebars.compile(
            '<div class="image"><img {{bind-attr src="iconUrl"}}/></div>' +
                '<div class="image-content">' +
                '<div class="name">{{name}}</div>' +
            '</div>'
        )
    })
})

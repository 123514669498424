var t = require('i18n').t

module.exports = require('ember-super-field').types.Model.extend({

    modelClass: Billy.Product,
    sortProperty: 'name',

    inputValuePath: 'nameWithProductNo',

    noOptionsFoundText: t('ui.fields.superfield.no_products_found'),

    optionHeight: 64,
    optionViewClass: require('ember-super-field').OptionView.extend({
        classNames: ['display-flex'],
        template: Ember.Handlebars.compile(
            '<div class="flex-1">' +
                '<div class="name" data-cy="product-list-option">{{nameWithProductNo}}</div>' +
                '<div class="meta">{{description}}</div>' +
            '</div>' +
            '{{#if imageUrl}}<img class="super-field-option-image" {{bind-attr src=imageUrl}}>{{/if}}'
        )
    }),

    create: function(name, callback) {
        var organization = this.get('userController.activeOrganization')
        var product = Billy.Product.createRecord({
            organization: organization,
            name: name,
            account: organization.get('defaultSalesAccount'),
            salesTaxRuleset: organization.get('defaultSalesTaxRuleset')
        })

        this.container.lookup('component:product-editor')
            .setProperties({
                content: product,
                callback: callback
            })
            .show()
    },

    query: function() {
        var q = {
            organization: this.get('userController.activeOrganization'),
            isArchived: false
        }
        var isInInventory = this.get('field.isInInventory')
        if (isInInventory !== undefined) {
            q.isInInventory = isInInventory
        }
        return q
    }.property('userController.activeOrganization')
})

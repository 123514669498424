var omit = require('lodash').omit

var BLACKLISTED_ORGANIZATION_PROPERTIES = [
    'activationDate',
    'analytics',
    'appUrl',
    'bankSyncStartDate',
    'billEmailAddress',
    'billingType',
    'billyProviderId',
    'brand',
    'companyType',
    'consolidatedBilling',
    'couponCode',
    'couponDiscountPercent',
    'couponExpires',
    'couponRedeemed',
    'createdTime',
    'defaultDaybook',
    'externalId',
    'extraSeatsAddonLimit',
    'hasBillVoucherNo',
    'hasUnlimitedPostingsAddon',
    'id',
    'industryGroup',
    'isLocked',
    'isSubscriptionBankPayer',
    'isTerminated',
    'isTrial',
    'isUnmigrated',
    'lockedCode',
    'lockedReason',
    'ownerUmbrellaId',
    'ownerUser',
    'ownerUserId',
    'postingCount',
    'postingLimit',
    'randomBucket',
    'source',
    'subscriptionCurrency',
    'subscriptionDiscount',
    'subscriptionExpires',
    'subscriptionPeriod',
    'subscriptionPlan',
    'subscriptionPrice',
    'subscriptionProductPlan',
    'subscriptionTransaction',
    'terminationTime',
    'trialExpiryDate'
]

module.exports = function(organizationData) {
    return omit(organizationData, BLACKLISTED_ORGANIZATION_PROPERTIES)
}

var svgs = require.context('./', true, /\.(svg)$/)

var result = svgs.keys().reduce(function(result, name) {
    var match = name.match(/^\.\/([^\.]+)\.svg$/)

    if (match && Array.isArray(match) && match.length > 0) {
        var iconName = match[1]
        result[iconName] = svgs(name)
    } else {
        console.error('Can not extract icon name from file path', match)
    }

    return result
}, {})

module.exports = result

var t = require('i18n').t

module.exports = require('ember-super-field').types.Model.extend({
    modelClass: Billy.TaxRate,

    sortProperty: 'priority',

    inputValuePath: 'nameWithRate',

    noOptionsFoundText: t('ui.fields.superfield.no_tax_rates_found'),

    selectorMinWidth: 320,

    optionViewClass: require('ember-super-field').GroupedOptionView.extend({
        template: Ember.Handlebars.compile(
            '<div class="name" data-cy="tax-rate-list-option">{{nameWithRate}}</div>' +
            '<div class="small-font downtoned-text">{{description}}</div>'
        )
    }),

    groupBy: 'taxRateGroup',

    groupName: function(taxRate) {
        var group = taxRate.get('taxRateGroup') || 'other'
        return t('tax_rates.groups.' + group)
    },

    query: function() {
        var query = {
            organization: this.get('userController.activeOrganization'),
            isActive: true
        }
        var appliesToSales = this.get('field.appliesToSales')
        var appliesToPurchases = this.get('field.appliesToPurchases')
        if (appliesToSales) {
            query.appliesToSales = true
        }
        if (appliesToPurchases) {
            query.appliesToPurchases = true
        }
        return query
    }.property('userController.activeOrganization', 'field.appliesToSales', 'field.appliesToPurchases')
})

module.exports = Em.View.extend({
    templateName: 'templates/template-thumb',

    classNames: ['template'],

    click: function() {
        this.get('controller').send('editTemplate', this.get('content').data)
    },

    actions: {
        didClickDuplicate: function() {
            this.get('controller').send('duplicateTemplate', this.get('content').data)
        },
        didClickEdit: function() {
            this.get('controller').send('editTemplate', this.get('content').data)
        },
        didClickSetAsDefault: function() {
            this.get('controller').send('setTemplateAsDefault', this.get('content').data)
        },
        didClickDelete: function() {
            this.get('controller').send('deleteTemplate', this.get('content').data)
        }
    }
})

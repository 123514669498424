module.exports = BD.Model.extend({
    createdTime: BD.attr('datetime'),
    state: BD.attr('string'),
    ordernumber: BD.attr('string'),
    amount: BD.attr('number'),
    balance: BD.attr('number'),
    currency: BD.belongsTo('Billy.Currency'),
    transaction: BD.attr('string'),
    cardtype: BD.attr('string'),
    cardnumber: BD.attr('string'),
    cardexpire: BD.attr('date'),
    callbackError: BD.attr('string')
})

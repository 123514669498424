module.exports = Em.View.extend({
    resizeHandler: null,

    queryParams: { prismic: 'prismic' },

    prismic: null,

    didInsertElement: function() {
        var resizeHandler = this.setAnnualReportBannerStyle.bind(this)

        this.set('resizeHandler', resizeHandler)
        $(window).on('resize', resizeHandler)
    },

    willDestroyElement: function() {
        $(window).off('resize', this.get('resizeHandler'))
        this.set('resizeHandler', null)
    },

    setAnnualReportBannerStyle: function() {
        if (this.get('controller.annualReportShowProgress')) {
            this.set('controller.annualReportSimple', false)
        } else {
            this.set('controller.annualReportSimple', $(window).width() < 1514)
        }
    }.observes('controller.annualReportShowProgress')
})

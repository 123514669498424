module.exports = Em.Object.extend({
    needs: ['organization'],

    api: Ember.inject.service(),

    cvr: Em.inject.service(),

    userOrganizations: Em.inject.service(),

    organization: Em.computed.alias('userOrganizations.activeOrganization'),

    createContactFromRegistrationNo: function(registrationNo) {
        var self = this

        return this.get('api')
            .request('GET', this.get('cvr').getCvrCompaniesUrl(registrationNo), {
                mask: true
            })
            .then(function(cvr) {
                if (!cvr || !cvr.data) {
                    return null
                }

                var organization = self.get('organization')
                var contact = Billy.Contact.createRecord({
                    organization: organization,
                    name: cvr.data.name || '',
                    country: Billy.Country.find('DK'),
                    type: 'company',
                    registrationNo: registrationNo
                })

                self.fillContactWithCvrData(contact, cvr.data)
                return contact
            })
    },

    fillContactWithCvrData: function(contact, cvrInfo) {
        var streetText = _.chain(['street', 'number', 'numberTo'])
            .map(_.partial(_.get, cvrInfo))
            .reject(_.isNull)
            .join(' ')
            .value()

        contact.set('name', cvrInfo.name)
        contact.set('cityText', cvrInfo.city)
        contact.set('zipcodeText', cvrInfo.zipcode)
        contact.set('street', streetText)
        contact.set('stateText', cvrInfo.state)
        contact.set('phone', cvrInfo.phone)
    }
})

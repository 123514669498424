var Scope = {
    AccountRead: 'account_read',
    AccountWrite: 'account_write',
    Admin: 'admin',
    AnnualReportRead: 'annual_report_read',
    AnnualReportToken: 'annual_report_token',
    AnnualReportWrite: 'annual_report_write',
    AssertWrite: 'asset_write', // Legacy
    BankReconciliationAutoMatch: 'bank_reconciliation_automatch',
    BankReconciliationRead: 'bank_reconciliation_read',
    BankReconciliationWrite: 'bank_reconciliation_write',
    BankStatementImport: 'bank_statement_import',
    BankSyncRead: 'bank_sync_read',
    BankSyncWrite: 'bank_sync_write',
    BetaRead: 'beta_read',
    BetaWrite: 'beta_write',
    BillAttachmentRead: 'bill_attachment_read',
    BillAttachmentWrite: 'bill_attachment_write',
    BillRead: 'bill_read',
    BillVoid: 'bill_void',
    BillWrite: 'bill_write',
    BulkWorkflows: 'bulk_workflows',
    ChartOfAccountsImportWrite: 'coa_import_write',
    CollaborationRead: 'collaboration_read',
    CollaborationWrite: 'collaboration_write',
    CollectaiRead: 'collectai_read', // Legacy
    CollectaiWrite: 'collectai_write', // Legacy
    ContactBookkeepingDefaults: 'contact_bookkeeping_defaults',
    ContactRead: 'contact_read',
    ContactRegisterLookup: 'contact_register_lookup',
    ContactStatementRead: 'contact_statement_read',
    ContactWrite: 'contact_write',
    DashboardWrite: 'dashboard_write',
    DaybookWrite: 'daybook_write',
    EDocumentRead: 'e_document_read',
    ExportRead: 'export_read',
    FileRead: 'file_read',
    FileWrite: 'file_write',
    FindAccountant: 'find_accountant',
    HasRole: 'has_role', // fake scope; use to check if new scopes are loded
    InventoryRead: 'inventory_read', // Legacy
    InventoryWrite: 'inventory_write', // Legacy
    InvoiceAttachmentRead: 'invoice_attachment_read',
    InvoiceAttachmentWrite: 'invoice_attachment_write',
    InvoiceDesignerRead: 'invoice_designer_read',
    InvoiceDesignerWrite: 'invoice_designer_write',
    InvoiceEan: 'invoice_ean',
    InvoicePackingListPrint: 'invoice_packinglist_print',
    InvoiceRead: 'invoice_read',
    InvoiceReminderWrite: 'invoice_reminder_write',
    InvoiceVoid: 'invoice_void',
    InvoiceWrite: 'invoice_write',
    LoansWrite: 'loans_write',
    MobilepayPay: 'mobilepay_pay',
    MobilepayRead: 'mobilepay_read',
    MobilepayWrite: 'mobilepay_write',
    OfferRead: 'offer_read',
    OfferSign: 'offer_sign',
    OfferWrite: 'offer_write',
    OrderRead: 'order_read', // Legacy
    OrderWrite: 'order_write', // Legacy
    OrganizationFeatureRead: 'organization_feature_read', // Legacy
    OrganizationFeatureWrite: 'organization_feature_write', // Legacy
    OrganizationPaymentWrite: 'organization_payment_write',
    OrganizationRead: 'organization_read',
    OrganizationSubscriptionWrite: 'organization_subscription_write',
    OrganizationWrite: 'organization_write',
    PaylikePay: 'paylike_pay',
    PaylikeRead: 'paylike_read',
    PaylikeWrite: 'paylike_write',
    PaymentMethodRead: 'payment_method_read',
    PaymentMethodWrite: 'payment_method_write',
    PbsRead: 'pbs_read', // Legacy
    PbsWrite: 'pbs_write', // Legacy
    ProductRead: 'product_read',
    ProductWrite: 'product_write',
    RecurringInvoiceRead: 'recurring_invoice_read',
    RecurringInvoiceWrite: 'recurring_invoice_write',
    ReportsRead: 'reports_read',
    ResetBookkeeping: 'reset_bookkeeping',
    RoleRead: 'role_read', // Legacy
    RoleWrite: 'role_write', // Legacy:   SalaryRead: 'salary_read',
    SalaryRead: 'salary_read',
    SalaryWrite: 'salary_write',
    ServicesRead: 'services_read',
    SettingTaxWrite: 'setting_tax_write',
    SettingVatWrite: 'setting_vat_write',
    SettingVoucherDataExtraction: 'setting_voucher_data_extraction', // Legacy
    StripePay: 'stripe_pay',
    StripeRead: 'stripe_read',
    StripeWrite: 'stripe_write',
    Supporter: 'supporter',
    TaskWrite: 'task_write',
    TokensRead: 'tokens_read',
    TokensWrite: 'tokens_write',
    TransactionRead: 'transaction_read',
    TransactionTemplate: 'transaction_template',
    TransactionWrite: 'transaction_write',
    UploadsRead: 'uploads_read',
    UploadsWrite: 'uploads_write',
    User: 'user',
    UserRead: 'user_read',
    UserWrite: 'user_write',
    UserWriteAccountant: 'user_write_accountant',
    VatReturnRead: 'vat_return_read',
    VatReturnWrite: 'vat_return_write',
    VoucherScanRead: 'voucher_scan_read',
    VoucherScanWrite: 'voucher_scan_write', // Legacy
    VoucherWrite: 'voucher_write', // Legacy
    YourpayPay: 'yourpay_pay', // Legacy
    YourpayRead: 'yourpay_read', // Legacy
    YourpayWrite: 'yourpay_write' // Legacy
}

module.exports = Scope

var simplifyFileame = require('./simplify-filename')
var resolveMimeType = require('./resolve-mime-type')

window.FileUploadRequest = function(method, url, file, sendAsFormData, formDataParams) {
    this.method = method
    this.url = url
    this.file = file
    this.requestHeaders = {}
    this.listeners = {
        readystatechange: [],
        progress: [],
        load: [],
        error: []
    }
    this.sendAsFormData = sendAsFormData || false
    this.formDataParams = formDataParams || {}

    if (!this.sendAsFormData) {
        var type = resolveMimeType(file.name, file.type)
        this.setRequestHeader('Content-Type', type)
    }
}

window.FileUploadRequest.prototype = {
    on: function(eventType, callback) {
        this.listeners[eventType].push(callback)
    },

    _trigger: function(eventType) {
        var args = Array.prototype.slice.call(arguments, 1)
        this.listeners[eventType].forEach(function(callback) {
            callback.apply(null, args)
        })
    },

    setRequestHeader: function(name, value) {
        this.requestHeaders[name] = value
    },

    send: function() {
        var xhr = new XMLHttpRequest()
        var upload = xhr.upload
        var name
        this.xhr = xhr
        xhr.addEventListener('readystatechange', this.onReadyStateChange.bind(this), false)
        upload.addEventListener('progress', this.onUploadProgress.bind(this), false)
        upload.addEventListener('load', this.onUploadLoad.bind(this), false)
        upload.addEventListener('error', this.onUploadError.bind(this), false)
        xhr.open(this.method, this.url, true)
        for (name in this.requestHeaders) {
            if (!Object.prototype.hasOwnProperty.call(this.requestHeaders, name)) continue
            var data = this.requestHeaders[name]
            if (name.toLowerCase() === 'x-filename') {
                data = simplifyFileame(data)
            }
            xhr.setRequestHeader(name, data)
        }

        if (this.sendAsFormData) {
            var formData = new FormData()
            formData.append('files', this.file)

            for (var param in this.formDataParams) {
                formData.append(param, this.formDataParams[param])
            }

            xhr.send(formData)
        } else {
            xhr.send(this.file)
        }

        delete this.file
    },

    onReadyStateChange: function() {
        this.readyState = this.xhr.readyState
        if (this.readyState === 4) {
            this.status = this.xhr.status
            this.responseText = this.xhr.responseText
        }
        this._trigger('readystatechange')
    },

    onUploadProgress: function(e) {
        this._trigger('progress', e)
    },

    onUploadLoad: function() {
        this._trigger('load')
    },

    onUploadError: function() {
        this._trigger('error')
    },

    abort: function() {
        if (this.xhr) {
            this.xhr.abort()
        }
    }
}

var SubscriptionPlan = require('../constants/subscriptionPlan')

module.exports = Em.Object.extend({
    api: Em.inject.service(),

    data: null,

    constraints: Ember.inject.service(),

    userOrganizations: Em.inject.service(),

    organization: Em.computed.alias('userOrganizations.activeOrganization'),

    organizationSubscriptionProductPlan: Em.computed.alias('organization.subscriptionProductPlan'),

    organizationSubscription: Em.inject.service(),

    invalidate: function() {
        this.set('data', null)
        this._loadPromise = null
    }.observes('organization'),

    isOrganizationOnLimitedPlan: function() {
        return this.get('organizationSubscriptionProductPlan') === SubscriptionPlan.Free
    }.property('organizationSubscriptionProductPlan'),

    load: function() {
        var self = this

        this._loadPromise = this.get('api').request('GET', '/v2/organizations/' + this.get('organization').get('id') + '/subscriptionMetrics')
            .then(function(payload) {
                self.set('data', payload)
            }, function() {
                self.set('data', null)
            })

        return this._loadPromise
    },

    ensureLoaded: function() {
        return this._loadPromise || this.load()
    },

    postings: function() {
        return this.get('data.postings')
    }.property('data.postings'),

    expenses: function() {
        return this.get('data.expenses')
    }.property('data.expenses'),

    invoices: function() {
        return this.get('data.invoices')
    }.property('data.invoices'),

    planLimits: function() {
        return this.get('data.plans')[this.get('organizationSubscriptionProductPlan')]
    }.property('data'),

    isInvoiceLimitExceeded: function() {
        if (!this.get('isOrganizationOnLimitedPlan')) {
            return false
        }

        var invoicesLimit = this.get('planLimits').invoicesLimit
        var invoices = this.get('invoices')

        return invoicesLimit && invoices >= invoicesLimit
    }.property('data.{plans,invoices}'),

    isExpenseLimitExceeded: function() {
        if (!this.get('isOrganizationOnLimitedPlan')) {
            return false
        }

        var expensesLimit = this.get('planLimits').expensesLimit
        var expenses = this.get('expenses')

        return expensesLimit && expenses >= expensesLimit
    }.property('data.{plans,expenses}'),

    isExpenseLimitExceededWithMultiple: function(newExpensesCount) {
        if (!this.get('isOrganizationOnLimitedPlan')) {
            return false
        }

        var expensesLimit = this.get('planLimits').expensesLimit
        var expenses = this.get('expenses')

        return expensesLimit && expenses + newExpensesCount > expensesLimit
    },

    checkForExpenseLimitAndExecute: function(callback) {
        if (!this.get('isOrganizationOnLimitedPlan')) {
            callback()
            return
        }

        this.checkForExpenseLimitWithMultipleAndExecute(1, callback)
    },

    checkForExpenseLimitWithMultipleAndExecute: function(newExpensesCount, callback) {
        if (!this.get('isOrganizationOnLimitedPlan')) {
            callback()
            return
        }

        var self = this
        this.ensureLoaded().then(function() {
            var isExpenseLimitExceeded = self.isExpenseLimitExceededWithMultiple(newExpensesCount)

            if (isExpenseLimitExceeded) {
                self.get('constraints').showUpgradeOverlay('posting_limit', 'free_expenses_limit_exceeded')
                return
            }

            callback()
            self.invalidate()
        })
    },
    isInvoiceLimitExceededWithMultiple: function(newInvoicesCount) {
        if (!this.get('isOrganizationOnLimitedPlan')) {
            return false
        }

        var invoicesLimit = this.get('planLimits').invoicesLimit
        var invoices = this.get('invoices')

        return invoicesLimit && invoices + newInvoicesCount > invoicesLimit
    },

    checkForInvoiceLimitAndExecute: function(callback) {
        if (!this.get('isOrganizationOnLimitedPlan')) {
            callback()
            return
        }

        this.checkForInvoiceLimitWithMultipleAndExecute(1, callback)
    },

    checkForInvoiceLimitWithMultipleAndExecute: function(newInvoicesCount, callback) {
        if (!this.get('isOrganizationOnLimitedPlan')) {
            callback()
            return
        }

        var self = this
        this.ensureLoaded().then(function() {
            var isInvoiceLimitExceeded = self.isInvoiceLimitExceededWithMultiple(newInvoicesCount)

            if (isInvoiceLimitExceeded) {
                self.get('constraints').showUpgradeOverlay('free_invoices_limit_exceeded', 'free_invoices_limit_exceeded')
                return
            }

            callback()
            self.invalidate()
        })
    }
})

var RowView = require('./row-view')

module.exports = Em.Mixin.create({
    attributeBindings: ['cy:data-cy'],

    cy: 'table-item',

    templateName: 'ui/list/table/item',

    classNames: ['table-item'],

    classNameBindings: ['controller.checked', 'controller.itemCssClass', 'controller.rowClass'],

    click: function(e) {
        if (e.within('.list-checkbox') || e.within('.tool')) {
            return
        }
        this.get('controller').click()
    },

    rowViewClass: RowView
})

module.exports = Em.Object.extend({
    api: Ember.inject.service(),

    isLoaded: false,

    data: null,

    userOrganizations: Em.inject.service(),

    organization: Em.computed.oneWay('userOrganizations.activeOrganization'),

    invalidate: function() {
        this.set('isLoaded', false)
            .set('data', null)
    }.observes('organization'),

    load: function() {
        var self = this
        var organization = this.get('organization')
        this._loadPromise = this.get('api').request('GET', '/organizations/' + organization.get('id') + '/templates')
            .then(function(payload) {
                self.set('data', payload.data)
                    .set('isLoaded', true)
            })
        return this._loadPromise
    },

    loadSafe: function() {
        this.load()
            .catch(function(e) {
                // Templates load failed, but will not throw an error
            })
    },

    ensureLoaded: function() {
        return this._loadPromise || this.load()
    },

    all: function() {
        if (!this.get('isLoaded')) {
            throw new Error('Templates have not been loaded yet.')
        }
        return Ember.copy(this.get('data'), true)
    },

    find: function(id) {
        if (!this.get('isLoaded')) {
            throw new Error('Templates have not been loaded yet.')
        }
        if (!id) {
            id = this.get('organization.defaultTemplateId')
        }
        var tpl = this.get('data').findBy('id', id)
        if (!tpl) {
            throw new Error('Template with ID ' + id + ' was not found.')
        }
        return Ember.copy(tpl, true)
    },

    variables: function(id, locale) {
        var tpl = this.find(id)
        if (!locale || !tpl.variables[locale]) {
            locale = tpl.fallbackLocale
        }
        return _.merge({}, tpl.variables.default, tpl.variables[locale])
    },

    variablesForInvoice: function(invoice) {
        var locale = invoice.get('contact.locale.id') || invoice.get('organization.locale.id')
        return this.variables(invoice.get('templateId'), locale)
    }
})

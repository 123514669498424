var t = require('i18n').t

module.exports = require('ember-super-field').types.Model.extend({

    inputValuePath: 'shortInvoiceNoWithCustomer',
    modelClass: Billy.Invoice,
    sortProperty: 'invoiceNo',
    sortDirection: 'ASC',

    noOptionsFoundText: t('ui.fields.superfield.no_invoices_found'),

    optionHeight: 64,
    optionViewClass: require('ember-super-field').OptionView.extend({
        template: Ember.Handlebars.compile(
            '<div class="name">{{shortInvoiceNoWithCustomer}}</div>' +
            '<div class="meta">{{money balance currencyBinding="currency"}} - {{lineDescription}}</div>'
        )
    }),

    query: function() {
        var query = {
            organization: this.get('userController.activeOrganization'),
            state: 'approved'
        }
        var isPaid = this.get('field.isPaid')
        if (typeof isPaid !== 'undefined') {
            query.isPaid = isPaid
        }
        return query
    }.property('userController.activeOrganization', 'field.isPaid')
})

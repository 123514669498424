var batmask = require('batmask')
var tracking = require('../utils/tracking')
var moment = require('momentjs')
var capitalize = require('../utils/capitalize')

module.exports = Em.Object.extend({
    api: Em.inject.service(),

    isLoaded: function() {
        return this.get('data') !== null
    },

    data: null,

    user: function() {
        return this.container.lookup('controller:user')
    }.property(),

    userOrganizations: Em.inject.service(),

    organization: Em.computed.alias('userOrganizations.activeOrganization'),

    organizationSubscription: Em.inject.service(),

    invalidate: function() {
        this.set('data', null)
    }.observes('organization'),

    load: function() {
        var self = this

        this._loadPromise = this.get('api').getData('/quickpay/organizations/' + this.get('organization').get('id') + '/subscriptions')
            .then(function(payload) {
                self.set('data', payload)
            }, function() {
                self.set('data', null)
            })

        return this._loadPromise
    },

    loadSafe: function() {
        return this.load()
            .catch(function(e) {
                // Card subscriptions load failed, but will not throw an error
            })
    },

    ensureLoaded: function() {
        return this._loadPromise || this.load()
    },

    getContent: function() {
        if (!this.get('isLoaded')) {
            throw new Error('Card subscriptions has not been loaded yet.')
        }

        return Ember.copy(this.get('data'), true)
    },

    isApplicable: function() {
        return Ember.keys(this.get('data') || {}).length > 0
    },

    getQuickPayVariables: function(plan, period, cardOnly, billingStart) {
        var organization = this.get('organization')
        var isEnglish = this.get('user.quickpayLanguage') === 'en'
        var billingStartDate = moment(billingStart).format('D MMM YYYY') || moment(new Date()).format('D MMM YYYY')
        var subscriptionDiscount = organization.get('subscriptionDiscount')
        var couponDiscountPercent = organization.get('couponDiscountPercent')
        var couponPeriod = organization.get('couponPeriod')
        var subscriptionPeriodEn = period || organization.get('subscriptionPeriod')
        var subscriptionPeriodDa = subscriptionPeriodEn === 'yearly' ? 'årligt' : 'månedligt'
        var subscriptionPlan = plan || organization.get('subscriptionPlan')
        var monthlyPrice = this.get('organizationSubscription').getSubscriptionPriceMonthly(subscriptionPlan)
        var yearlyPrice = this.get('organizationSubscription').getSubscriptionPriceYearly()
        var subscriptionPrice = subscriptionPeriodEn === 'yearly' ? yearlyPrice : monthlyPrice
        var isYearly = subscriptionPeriodEn === 'yearly'
        var couponBasePrice = couponPeriod === 'yearly' ? monthlyPrice * 12 : monthlyPrice
        var amountWithCouponApplied = couponDiscountPercent && couponPeriod === subscriptionPeriodEn ? couponBasePrice - couponBasePrice * couponDiscountPercent / 100 : subscriptionPrice
        var amountExVat = amountWithCouponApplied
        var amountExVatFormatted = Billy.money(amountExVat, organization.get('subscriptionCurrency'))
        var vat = amountExVat * 0.25
        var vatFormatted = Billy.money(vat, organization.get('subscriptionCurrency'))
        var totalAmount = amountExVat + vat
        var totalAmountFormatted = Billy.money(totalAmount, organization.get('subscriptionCurrency'))
        var amountPerPeriodEn = isYearly ? '1 year' : '1 month'
        var amountPerPeriodDa = isYearly ? '1 år' : '1 måned'
        var subscriptionPeriod = isEnglish ? subscriptionPeriodEn : subscriptionPeriodDa
        var amountPerPeriod = isEnglish ? amountPerPeriodEn : amountPerPeriodDa
        var providerTheme = ENV.theme

        var quickpayVariables = {
            amount: amountWithCouponApplied,
            billingStart: billingStartDate,
            period: subscriptionPeriodEn || '',
            subscriptionDiscount: subscriptionDiscount,
            subscriptionPeriod: capitalize(subscriptionPeriod),
            subscriptionPlan: capitalize(subscriptionPlan),
            subscriptionPrice: subscriptionPrice,
            amountExVat: amountExVat,
            amountExVatFormatted: amountExVatFormatted,
            vat: vat,
            vatFormatted: vatFormatted,
            totalAmount: totalAmount,
            totalAmountFormatted: totalAmountFormatted,
            amountPerPeriod: amountPerPeriod,
            cardOnly: cardOnly,
            providerTheme: providerTheme
        }

        return quickpayVariables
    },

    // QUICKPAY
    quickpayCreate: function(organizationId) {
        var url = '/quickpay/organizations/' + organizationId + '/subscriptions'
        var variables = this.getQuickPayVariables()

        return this.get('api')
            .postData(url, {
                amount: Math.round(variables.totalAmount * 100),
                variables: variables
            })
    },

    quickpayUpdate: function(organizationId, subscriptionId, payload) {
        var url = '/quickpay/organizations/' + organizationId + '/subscriptions/' + subscriptionId

        return this.get('api')
            .putData(url, payload)
    },

    quickpayAuthorize: function(organizationId, subscriptionId, payload, saveHistory) {
        var url = '/quickpay/organizations/' + organizationId + '/subscriptions/' + subscriptionId + '/authorizationLink'

        batmask.maskDelayed() // mask UI before redirect

        return this.get('api')
            .getData(url, payload)
            .then(function(authorizeResponse) {
                // Redirect to quickpay
                if (saveHistory) {
                    window.location = authorizeResponse.url
                } else {
                    window.location.replace(authorizeResponse.url)
                }
            }, function(e) {
                batmask.unmask() // unmask in case of error
                throw e
            })
    },

    quickpayGet: function(organizationId, subscriptionId) {
        var url = '/quickpay/organizations/' + organizationId + '/subscriptions/' + subscriptionId

        return this.get('api')
            .getData(url, {})
    },

    quickpayCancel: function(organizationId, subscriptionId) {
        var url = '/quickpay/organizations/' + organizationId + '/subscriptions/' + subscriptionId + '/cancel'

        return this.get('api')
            .postData(url, {})
    },

    // GENERAL
    subscribeWithQuickPay: function(quickpayOptions) {
        var options = quickpayOptions || {}
        var subscription = this.get('organizationSubscription')
        var subscriptionExpires = subscription && subscription.get('data.organization.subscriptionExpires')
        var continueUrl = options.continueUrl
        var cancelUrl = options.cancelUrl
        var saveHistory = options.saveHistory
        var plan = options.plan
        var period = options.period
        var cardOnly = options.cardOnly
        var billingStart = subscriptionExpires && moment(subscriptionExpires).isAfter(new Date()) ? subscriptionExpires : new Date()
        var self = this
        var organization = this.get('organization')
        var organizationId = organization.get('id')
        var pendingSubscription = _.find(
            organization.get('cardSubscriptions'),
            { state: 'pending', amount: 0, currencyCode: organization.get('subscriptionCurrency').get('id') }
        )
        var cardSubscription = null

        tracking.emitAnalyticsEvent('credit_card', 'credit_card_submission_started')

        return (
            // use a pending subscription if available, otherwise create a new one
            pendingSubscription
                ? Em.RSVP.resolve(pendingSubscription)
                : self.quickpayCreate(organizationId)
        )
            .then(function(subscription) {
                cardSubscription = subscription
                return self.quickpayUpdate(organizationId, cardSubscription.id, {
                    variables: self.getQuickPayVariables(plan, period, cardOnly, billingStart)
                })
            })
            .then(function() {
                return self.initiateAuthorization(cardSubscription.id, continueUrl, cancelUrl, saveHistory)
            })
    },

    initiateAuthorization: function(subscriptionId, continueUrl, cancelUrl, saveHistory) {
        var self = this
        var organization = this.get('organization')
        var organizationId = organization.get('id')
        var url = window.location.href

        // TODO: Clean up `continueUrl` and `cancelUrl` from func sig
        // `window.location.href` should be enough
        if (!continueUrl) {
            continueUrl = url + (url.indexOf('?') > 0 ? '&' : '?') + 'payment_successful=1'
        } else {
            continueUrl = continueUrl + '?payment_successful=1'
        }

        if (!cancelUrl) {
            cancelUrl = url + (url.indexOf('?') > 0 ? '&' : '?') + 'payment_cancelled=1'
        } else {
            cancelUrl = cancelUrl + '?payment_cancelled=1'
        }

        return self.quickpayAuthorize(organizationId, subscriptionId, {
            continueUrl: continueUrl,
            cancelUrl: cancelUrl,
            language: self.get('user.quickpayLanguage')
        }, saveHistory) // TODO: Find out if we can use `location =` all the time to avoid passing this arg all the way down.
    },

    makeDefault: function(subscriptionId) {
        var self = this
        var organization = this.get('organization')
        var organizationId = organization.get('id')

        return self.quickpayUpdate(organizationId, subscriptionId, { isDefault: true })
    },

    cancel: function(subscriptionId) {
        var self = this
        var organization = this.get('organization')
        var organizationId = organization.get('id')

        return self.quickpayCancel(organizationId, subscriptionId)
    }
})

// This service was created in order to be able to react to location changes when transitioning, as if they were
// property changes. In the annual report, it is used to calculate the active step (as opposed to the current step)
// when clicking around
module.exports = Em.Object.extend({
    // We make href observable, and then the other properties are also observable as long as they observe href
    href: location.href,

    _mounted: false,

    init: function() {
        var self = this
        var refresh = function() {
            if (!self.get('_mounted')) {
                return
            }

            if (self.get('href') !== location.href) {
                self.set('href', location.href)
            }

            requestAnimationFrame(refresh)
        }

        this.set('_mounted', true)
        refresh()
    }.on('init'),

    pathname: function() {
        return location.pathname
    }.property('href'),

    willDestroy: function() {
        this._super()
        this.set('_mounted', false)
    }
})

var storage = require('storage')
var storageToken = 'hideIntercomMessenger'

module.exports = Em.Object.extend({
    isHidden: storage(storageToken) === 'true',

    trackEvent: function(organization, eventType, eventData) {
        if (!organization.get('isBillyProviderDk') && window.Intercom != null) {
            eventData = eventData || {}
            eventData.company_id = organization.get('id')
            window.Intercom('trackEvent', eventType, eventData)
        }
    },

    showNewMessage: function() {
        if (window.Intercom) {
            window.Intercom('showNewMessage')
        }
    },

    setMessengerIsHidden: function(value) {
        value = value !== undefined ? !!value : this.get('isHidden')

        // update local state
        this.set('isHidden', value)
        storage(storageToken, value)

        // set state in vendor
        if (window.Intercom) {
            window.Intercom('update', { hide_default_launcher: value })
        }
    }
})

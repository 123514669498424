var i18n = require('i18n')
var CustomMetaMixin = require('../mixins/custom-meta')
var tracking = require('../utils/tracking')

module.exports = Em.Route.extend(CustomMetaMixin, {
    activate: function() {
        this._super.apply(this, arguments)

        // Use custom title and description
        this.updateMeta('title', i18n.t('login.meta.title'))
        this.updateMeta('description', i18n.t('login.meta.description'))
    },

    beforeModel: function() {
        if (this.controllerFor('user').get('isAuthenticated') !== false) {
            this.replaceWith('user')
        } else {
            tracking.trackVisit('/login')
        }
    }
})

var postingSides = require('posting-sides')
var f = require('float')

Billy.SideAmount = function(amount, side) {
    if (f.equals(amount, 0)) {
        side = null
    } else {
        side = postingSides.resolveSide(side)
        if (amount < 0) {
            amount *= -1
            side = side.opposite
        }
    }
    this.amount = f.round(amount)
    this.side = side
}

Billy.SideAmount.prototype = {
    add: function(amount, side) {
        var newAmount
        if (amount instanceof Billy.SideAmount) {
            side = amount.side
            amount = amount.amount
        } else {
            side = postingSides.resolveSide(side)
        }
        if (f.equals(amount, 0)) {
            return this.copy()
        }
        if (this.isZero()) {
            return new Billy.SideAmount(amount, side)
        }
        if (side === this.side) {
            newAmount = this.amount + amount
        } else {
            newAmount = this.amount - amount
        }
        return new Billy.SideAmount(newAmount, this.side)
    },

    subtract: function(amount, side) {
        var newAmount
        if (amount instanceof Billy.SideAmount) {
            side = amount.side
            amount = amount.amount
        } else {
            side = postingSides.resolveSide(side)
        }
        if (f.equals(amount, 0)) {
            return this.copy()
        }
        if (this.isZero()) {
            return new Billy.SideAmount(amount, side.opposite)
        }
        if (side === this.side) {
            newAmount = this.amount - amount
        } else {
            newAmount = this.amount + amount
        }
        return new Billy.SideAmount(newAmount, this.side)
    },

    multiply: function(multiplier) {
        return new Billy.SideAmount(this.amount * multiplier, this.side)
    },

    divide: function(divisor) {
        if (divisor === 0) {
            throw new Error('SideAmount cannot be divided by zero.')
        }
        return new Billy.SideAmount(this.amount / divisor, this.side)
    },

    copy: function() {
        return new Billy.SideAmount(this.amount, this.side)
    },

    equals: function(amount, side) {
        if (amount instanceof Billy.SideAmount) {
            side = amount.side
            amount = amount.amount
        } else {
            amount = f.round(amount)
        }
        if (f.equals(this.amount, 0) && f.equals(amount, 0)) {
            return true
        }
        if (!f.equals(this.amount, amount)) {
            return false
        }
        side = postingSides.resolveSide(side)
        return this.side === side
    },

    isZero: function() {
        return f.equals(this.amount, 0)
    },

    toString: function() {
        if (this.isZero()) {
            return '0'
        }
        return this.amount + ' (' + this.side.value + ')'
    }

}

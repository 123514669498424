var t = require('i18n').t

module.exports = function(mode, days, dueDate, entryDate) {
    if (mode === 'date' && !dueDate) {
        mode = null
    }
    switch (mode) {
    case 'net':
        if (days === 0) {
            return t('payment_terms.net0')
        } else if (entryDate && moment().isSame(entryDate, 'day')) {
            return t('payment_terms.net_today', { count: +days })
        } else {
            return t('payment_terms.net', { count: +days })
        }
    case 'netEndOfMonth':
        return t('payment_terms.net_end_of_month', { count: +days })
    case 'date':
        return t('payment_terms.date', { date: dueDate.format('L') })
    }
    return t('payment_terms.none')
}

var t = require('i18n').t
var download = require('downloadjs')

module.exports = function(container, exportUrl) {
    DownloadExportTask.create({
        container: container,
        title: t('download_export_task.processing.title'),
        text: t('download_export_task.processing.description'),
        exportUrl: exportUrl
    })
}

var DownloadExportTask = Billy.BackgroundTask.extend({
    progress: 0,

    closeOnClick: false,

    exportUrl: null,

    timeout: 45000,

    progressTimer: null,

    progressTimerInterval: 1000,

    init: function() {
        this._super()
        var self = this
        var api = self.container.lookup('api:billy')

        var xhr = download(api.options.apiUrl + self.get('exportUrl'))

        if (xhr instanceof XMLHttpRequest) {
            self.progressTimer = setInterval(function() {
                var newProgress = self.get('progress') + self.progressTimerInterval / self.get('timeout')

                if (newProgress >= 1) {
                    clearInterval(self.progressTimer)
                    self.set('progress', 1)
                } else {
                    self.set('progress', newProgress)
                }
            }, self.progressTimerInterval)

            xhr.timeout = self.get('timeout')

            xhr.addEventListener('load', function() {
                clearInterval(self.progressTimer)

                self.setProperties({
                    progress: 1,
                    icon: 'icons/tick',
                    title: t('download_export_task.success.title'),
                    text: t('download_export_task.success.description')
                })
            })

            xhr.addEventListener('timeout', function() {
                clearInterval(self.progressTimer)
                self.set('progress', 1)

                api.get(self.get('exportUrl') + '&sendEmail=true')
                    .then(function() {
                        self.setProperties({
                            icon: 'icons/tick',
                            title: t('download_export_task.timeout.title'),
                            text: t('download_export_task.timeout.description')
                        })
                    })
                    .catch(function() {
                        self.setProperties({
                            icon: 'icons/cross-fat',
                            title: t('download_export_task.error.title'),
                            text: t('download_export_task.error.description')
                        })
                    })
            })

            xhr.addEventListener('error', function() {
                clearInterval(self.progressTimer)

                self.setProperties({
                    progress: 1,
                    icon: 'icons/cross-fat',
                    title: t('download_export_task.error.title'),
                    text: t('download_export_task.error.description')
                })
            })
        }
    }
})

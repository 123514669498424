var t = require('i18n').t
var ItemController = require('./item-controller')
var TableLazyBodyView = require('./table/lazy-body-view')
var TableBodyView = require('./table/body-view')
var ActionColumn = require('./columns/action')
var CheckboxColumn = require('./columns/checkbox')
var NestedColumn = require('./columns/nested')

var Controller = Em.Controller.extend({
    name: null,

    init: function() {
        this._super()
        var selection = this.get('selection')
        if (selection) {
            // Make sure we don't alter some other dude's array
            this.set('selection', selection.toArray())
        } else {
            this.set('selection', Em.A())
        }
        this._recordsDidChange()
    },

    isLazy: true,

    tableRowHeight: 56,

    allowOverflow: false,

    itemControllerClass: ItemController,

    tableBodyViewClass: function() {
        return this.get('isLazy') ? TableLazyBodyView : TableBodyView
    }.property('isLazy'),

    storageKey: null,

    columnsTogglable: false,

    columns: [],

    setupColumns: function() {
        var self = this
        this.get('columns').forEach(function(column) {
            column.set('controller', self)
        })
    }.observes('columns.@each').on('init'),

    setupVisibleColumns: function() {
        var columns = this.get('visibleColumns')
        var columnsLength = columns.get('length')
        var i
        var column
        var flex
        var totalFlex = 0
        var width
        var totalWidth = 0
        var cellPadding
        if (!columns) {
            return
        }
        for (i = 0; i < columnsLength; i++) {
            column = columns.objectAt(i)
            flex = column.get('flex')
            width = column.get('width')
            if (flex) {
                totalFlex += flex
            }
            if (width) {
                totalWidth += width
            }
        }
        for (i = 0; i < columnsLength; i++) {
            column = columns.objectAt(i)
            flex = column.get('flex')
            if (flex) {
                width = column.get('width')
                column.set('flexWidth', Math.floor(flex / totalFlex * 100))
                cellPadding = column instanceof NestedColumn ? 0 : Billy.constants.cellPadding
                column.set('paddingRight', cellPadding + Math.floor(flex / totalFlex * totalWidth) - width)
                column.set('marginRight', -(Math.ceil(flex / totalFlex * totalWidth) - width))
            }
        }
    }.observes('visibleColumns.@each').on('init'),

    visibleColumns: function() {
        var actionCount = this.get('listActions.length')
        var columns = this.get('columns').filterBy('visible', true)
        if (this.get('isSelectable')) {
            columns.insertAt(0, CheckboxColumn.create({}))
        }
        if (actionCount) {
            columns.pushObject(ActionColumn.create({
                name: 'actions',
                width: actionCount * Billy.constants.toolSize + 5 + 10
            }))
        }
        return columns
    }.property('columns.@each.visible', 'listActions.length'),

    findColumn: function(name) {
        var result = this._findColumnHelper(this.get('columns'), name)
        Ember.assert('No column by the name `' + name + '` exists in this list controller.', result)
        return result
    },

    _findColumnHelper: function(columns, name) {
        var result = null
        columns.find(function(column) {
            if (column.get('name') === name) {
                result = column
            } else if (column instanceof NestedColumn) {
                result = this._findColumnHelper(column.get('columns'), name)
            }
            return !!result
        }, this)
        return result
    },

    width: function() {
        return this.get('visibleColumns').reduce(function(result, c) {
            return result + c.get('width')
        }, 0)
    }.property('visibleColumns.@each.width'),

    hasHeader: true,

    headerIsVisible: true,

    totals: null,

    listActions: null,

    isSelectable: false,

    q: null,

    records: null,
    _recordsWillChange: function() {
        var records = this.get('records')
        if (records) {
            records.removeArrayObserver(this)
        }
    }.observesBefore('records'),

    _recordsDidChange: function() {
        var records = this.get('records')
        this.get('selection').forEach(function(r) {
            if (!records.contains(r)) {
                this.removeSelected(r)
            }
        }, this)
        if (records) {
            records.addArrayObserver(this)
        }
    }.observes('records'),

    arrayWillChange: function(records, start, removedCount) {
        var i
        var r
        for (i = 0; i < removedCount; i++) {
            r = records.objectAt(start + i)
            if (r !== BD.SPARSE_PLACEHOLDER) {
                this.removeSelected(r)
            }
        }
    },
    arrayDidChange: Em.K,

    sortProperty: null,

    sortDirection: 'ASC',

    sortPropertyOptions: function() {
        var options = []
        this.get('columns').forEach(function(column) {
            if (!column.get('sortable')) {
                return
            }
            options.push({
                value: column.get('name'),
                label: column.get('header')
            })
        })
        return options
    }.property('columns'),

    sortDirectionOptions: function() {
        return [
            {
                value: 'ASC',
                label: 'A-Z'
            },
            {
                value: 'DESC',
                label: 'Z-A'
            }
        ]
    }.property(),

    click: null,

    selection: null,

    addSelected: function(r) {
        var selection = this.get('selection')
        if (!selection.contains(r)) {
            selection.pushObject(r)
            this.didAddSelected(r)
        }
    },

    didAddSelected: Em.K,

    removeSelected: function(r) {
        var selection = this.get('selection')
        if (selection.contains(r)) {
            selection.removeObject(r)
            this.didRemoveSelected(r)
        }
    },

    didRemoveSelected: Em.K,

    hasEmpty: false,

    emptyTitle: t('ui.list.nothing_here_yet'),

    emptySubtitle: '',

    emptyText: '',

    emptyVideo: null,

    isFiltered: false,

    noRecordsFoundText: function() {
        if (this.get('records.q')) {
            return t('ui.list.no_records_matching_query', { query: this.get('records.q') })
        } else {
            return t('ui.list.no_records_found')
        }
    }.property('records.q'),

    isEmpty: function() {
        return this.get('records.isLoaded') && !this.get('records.length') && !this.get('isFiltered')
    }.property('records.length', 'records.isLoaded', 'isFiltered'),

    toString: function() {
        return '<list-controller>'
    }
})

Controller.reopenClass({
    tableRowHeightAdditionalSmallLine: 56
})

module.exports = Controller

var HeaderCollectionView = require('./header-collection-view')

module.exports = Em.View.extend({
    attributeBindings: 'cy:data-cy',

    cy: function() {
        return _.kebabCase(_.toLower('header-' + this.get('column.name')))
    }.property('column.name'),

    templateName: 'ui/list/table/header',

    classNames: ['table-header'],

    classNameBindings: ['controller.headerIsVisible::table-header-hidden', 'columnToggler:has-column-toggler'],

    actions: {
        toggleColumnToggler: function() {
            var self = this
            var toggler = this.get('columnToggler')
            if (toggler) {
                toggler.destroy()
            } else {
                toggler = this.container.lookup('component:table-column-toggler')
                this.set('columnToggler', toggler)
                toggler.set('field', this)
                    .set('listController', this.get('controller'))
                toggler.one('willDestroyElement', function() {
                    self.set('columnToggler', null)
                })
                toggler.show(this, this.$('.column-toggle-tool'))
            }
        }
    },

    headerCollectionViewClass: HeaderCollectionView
})

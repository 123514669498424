var billyApi = require('./billy-api')
var CustomEvent = require('../constants/customEvent')

module.exports = Ember.Object.extend({
    organization: null,

    data: null,

    customEvent: null,

    unknownProperty: function(key) {
        return this.data[key]
    },

    setUnknownProperty: function(key, value) {
        var self = this
        var url = '/organizations/' + this.get('organization.id') + '/settings/billy/' + key
        var data
        data = {
            value: value
        }
        this.setRaw(key, value)
        billyApi.put(url, data, {
            success: function() {
                self.get('customEvent').dispatchEvent(CustomEvent.OrganizationSettingsUpdated)
            },
            error: function() {
                // Revert if save failed
                self.setRaw(key, value)
            }
        })
    },

    setRaw: function(key, value) {
        this.data[key] = value
        this.notifyPropertyChange(key)
    }
})

var _ = require('lodash')
var t = require('i18n').t
var moment = require('momentjs')

module.exports = Em.Mixin.create({
    auth: Ember.inject.service(),

    userOrganizations: Ember.inject.service(),

    constraints: Ember.inject.service(),

    sideMenu: Ember.inject.service(),

    organizationSubscription: Em.inject.service(),

    user: function() {
        return this.container.lookup('controller:user').get('model')
    }.property(),

    redirectToInnerRoute: function(params, transition) {
        var queryParams = transition.queryParams
        var self = this
        var userOrganizations = this.get('userOrganizations')
        var organizationToActivate = params.url === 'in' ? userOrganizations.get('lastOrganizationUrl') : params.url

        // If no orgs found - redirect to index page
        if (!organizationToActivate) {
            userOrganizations.all().then(function(organizations) {
                self.replaceWith(params.route, organizations[0].url, {
                    queryParams: queryParams
                })
            })
            return
        }

        // Treating annual reports route separately, as we need to support two route params (orgId and year).
        // Checking for presence of "annual_report" instead of comparing only "annual_report" to targetName
        // as we want to support routes like /in/annual_reports/2023/start (which has targetName === "annual_report.start")
        if (transition.targetName.indexOf('annual_report') >= 0) {
            self.replaceWith(transition.targetName, organizationToActivate, _.get(transition, 'params.annual_report.year', moment().year() - 1))
            return
        }

        if (transition.targetName === 'organization_subscription.plans_selection') {
            // Show plans screens if organization is in trial or on a free plan
            self.replaceWith('organization_subscription.plans_selection', organizationToActivate, {
                queryParams: queryParams
            })
        }

        // No other conditions met - just redirect to proper route while replacing 'in' with 'org id'
        self.replaceWith(params.route, organizationToActivate, {
            queryParams: queryParams
        })
    },

    model: function(params, transition) {
        var self = this
        this.get('sideMenu').deactivate()

        // This is for nested /in/ route, which should replace /in/ with the first available org in the url
        // Done here since ember router does not pick nested routes in `path` property
        if (params.url === 'in' || transition.targetName === 'organization_subscription.plans_selection') {
            self.redirectToInnerRoute(params, transition)
        }

        return new Em.RSVP.Promise(function(resolve, reject) {
            self._getUserOrganization(params)
                .then(function(organization) {
                    var organizationId = organization.get('id')
                    // check if new scopes are fully prepared on BE side
                    if (self._shouldWaitForAuthData(organizationId)) {
                        self._waitForAuthData(organizationId)
                            .then(function() {
                                resolve(organization)
                            })
                            .catch(function(error) {
                                reject(error)
                            })
                        return
                    }

                    resolve(organization)
                })
                .catch(function(error) {
                    reject(error)
                })
        })
    },

    afterModel: function(organization, transition) {
        var queryParams = transition.queryParams
        this.get('sideMenu').activateOrganization(organization)

        if (organization.get('isLocked')) {
            this.replaceWith('organization.locked', organization, {
                queryParams: queryParams
            })
            return
        }

        // If the route is navigated to with the full model, the model hook won't be called
        // We check if this happens, and if it does, then do the bootstrap here in afterModel instead
        var userOrganizations = this.get('userOrganizations')
        if (userOrganizations.get('activeOrganization') !== organization) {
            return userOrganizations.bootstrapByUrl(organization.get('url'))
        }
    },

    setupController: function(controller, organization) {
        controller.set('model', organization)
        if (!Ember.testing) {
            document.title = organization.get('name') + ' - ' + ENV.title
        }
    },

    deactivate: function() {
        if (!Ember.testing) {
            document.title = ENV.title
        }
    },

    serialize: function(organization) {
        return {
            url: organization ? organization.get('url') : ''
        }
    },

    _shouldWaitForAuthData: function(organizationId) {
        var auth = this.get('auth')
        var isNewScopesVersion = auth.getIsNewScopesVersion()
        var scopesRaw = auth.getScopesRaw()
        return !auth.isAllAuthDataLoaded(isNewScopesVersion, scopesRaw, organizationId)
    },

    _waitForAuthData: function(organizationId) {
        var loadingController = this.controllerFor('user-loading')
        loadingController.set('text', t('scopes_loading.text'))
        return this.get('auth').waitForCompleteAuthData(organizationId)
    },

    _getUserOrganization: function(params) {
        var self = this
        var userId = this.get('user.id')
        var userOrganizations = this.get('userOrganizations')
        var lastOrganizationUrl = userOrganizations.get('lastOrganizationUrl')

        userOrganizations.all().then(function(organizations) {
            if (!lastOrganizationUrl) {
                return
            }
            var lastOrganizationFound = _.find(organizations, {
                url: lastOrganizationUrl
            })
            if (!lastOrganizationFound) {
                userOrganizations.set('lastOrganizationUrl', '')
                return self.replaceWith('user.index')
            }
        })

        return userOrganizations.all().then(function(organizations) {
            var userOwnOrganization = _.find(organizations, function(organization) { return organization.organizationOwnerUserId === userId })
            var userOwnOrganizationUrl = userOwnOrganization && userOwnOrganization.url
            var lastOrDefaultOrgUrl = userOrganizations.get('lastOrganizationUrl') || userOwnOrganizationUrl || organizations[0].url
            var urlToBootstrap = params.url === 'in' ? lastOrDefaultOrgUrl : params.url

            return userOrganizations.bootstrapByUrl(urlToBootstrap)
        })
            .catch(function(e) {
                if (
                    lastOrganizationUrl === params.url &&
                e.type === 'API_ERROR' &&
                e.status === 404
                ) {
                /*
                    In case of trying to bootstrap an organization using the
                    `lastOrganizationUrl` localStorage variable:
                    If we got a 404 from the bootstrap, that means that the user
                    does not have access to the organization or it doesn't exist
                    at all. In this case we go back to `user.index`, which will
                    redirect to a valid organization or go to My organizations.
                    */
                    self.replaceWith('user.index')
                } else {
                    throw e
                }
            })
    },

    actions: {
        upgradeToPremium: function() {
            this.get('constraints').showUpgradeOverlay()
        },

        showSubscriptionOverview: function() {
            this.get('constraints').showUpgradeOverlay('', 'organization_route')
        }
    }
})

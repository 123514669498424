var _ = require('lodash')
var emberFlashError = require('ember-flash-error')

module.exports = function registerContainerComponents(container) {
    // Bower components
    _.each({
        'date-field': require('ember-date-field'),
        'date-selector': require('ember-date-selector'),
        'file-uploader': require('ember-file-uploader'),
        'flash-error': require('ember-flash-error'),
        'modal-mask': require('ember-modal-mask'),
        'money-field': require('ember-money-field'),
        'number-field': require('ember-number-field'),
        'period-field': require('ember-period-field'),
        'period-selector': require('ember-period-selector'),
        'period-tool': require('ember-period-tool'),
        'select-field': require('ember-select-field'),
        'spinner-field': require('ember-spinner-field'),
        'super-field': require('ember-super-field'),
        'text-area': require('ember-text-area'),
        'text-field': require('ember-text-field'),
        'toggle-group': require('ember-toggle-group'),
        'bb-tool': require('ember-tool'),
        'window-component': require('ember-window')
    }, function(Component, name) {
        container.register('component:' + name, Component)
        Ember.Handlebars.helper(name, Component)
    })

    // Configure ember-flash-error
    emberFlashError.reopen({
        containerSelector: '.section-body, .window-body, .flash-error-target'
    });

    // Local components
    [
        'access-token-editor',
        'access-token-viewer',
        'account-editor',
        'address-editor',
        'alert-box',
        'alert-window',
        'attachment-viewer',
        'background-task',
        'bank-sync-bank-fee-account-window',
        'bookkeeping-hero-popover',
        'bank-sync-difference-popover',
        'circle-filter',
        'circle-filter-category',
        'contact-editor',
        'contact-editor-add-field-popover',
        'contact-person-editor',
        'file-area',
        'formatted-address',
        'horizontal-bar-chart',
        'inline-error',
        'invoice-email-sender',
        'invoices-circle-filter',
        'login-form',
        'loading-spinner',
        'organization-fiscal-settings',
        'organization-overdue-invoice-locked-box',
        'organization-trial-locked-box',
        'payment-details',
        'payment-terms-popover-item-date',
        'payment-terms-popover-item-net',
        'payment-terms-popover-item-net-end-of-month',
        'progress-circle',
        'skeleton-box',
        'sales-tax-pay-window',
        'stacked-bar-chart',
        'stacked-bar-chart-popover',
        'subscription-plan-window',
        'table-cell-comment',
        'tooltip-wrapper',
        'transaction-manual-line',
        'unsupported-browser-bar'
    ].forEach(function registerComponent(name) {
        var fullName = 'component:' + name
        var Component = container.lookupFactory(fullName)
        Ember.Handlebars.helper(name, Component)
    })

    // Utils
    _.each({
        dialog: require('ember-dialog'),
        notification: require('ember-notification'),
        tooltip: require('ember-tooltip')
    }, function(dependency, name) {
        container.register('util:' + name, dependency(container), { instantiate: false })
    })
}

/**
This module can be used to stub what "now" is in tests.
*/

var stubbedNow = null

module.exports.now = function() {
    if (stubbedNow) {
        return stubbedNow
    } else {
        return moment()
    }
}

module.exports.stub = function(now) {
    stubbedNow = now
}

module.exports.restore = function() {
    stubbedNow = null
}

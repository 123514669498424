module.exports = {
  delimiters: {
      thousands: ' ',
      decimal: ','
  },
  abbreviations: {
      thousand: 'k',
      million: 'M',
      billion: 'G',
      trillion: 'T'
  },
  ordinal: function (number) {
      return number === 1 ? 'er' : 'e';
  },
  currency: {
      symbol: 'EUR'
  }
};
var _ = require('lodash')
var cookieKey = require('../constants/cookieKey')
var externalLoginViewMap = require('../constants/externalLoginViewMap')
var tracking = require('../utils/tracking')

module.exports = Em.Route.extend({
    segment: Em.inject.service(),

    beforeModel: function(transition) {
        this.set('orgUrl', transition.queryParams.orgUrl || null)
        this.set('externalToken', transition.queryParams.externalToken || null)
        this.set('view', transition.queryParams.view || null)
    },

    model: function(params) {
        var self = this
        var token = params.token
        var apiClient = this.container.lookup('api:billy')
        var orgUrl = this.get('orgUrl')
        var view = this.get('view')
        var externalToken = this.get('externalToken')
        apiClient.authorize(token, false)
        apiClient.storageAdapter.setValue(cookieKey.externalToken, externalToken)

        return Ember.RSVP.hash({
            organization: this.findOrganization(orgUrl),
            user: this.findUser()
        })
            .then(function(props) {
                var organization = props.organization
                var user = props.user
                var root = self.container.lookup('application:main').get('rootElement')
                var orgUrl = organization && organization.get('url')

                tracking.trackPartnerAddsSignup(user, root)

                if (!orgUrl) {
                    console.error('No organization found for user: ' + user.get('email'))
                    self.replaceWith('logout')
                    return
                }

                self.replaceWith(externalLoginViewMap[view] || 'dashboard', orgUrl)
            }, function(e) {
                if (e.type === 'API_ERROR' && e.status === 401) {
                    self.replaceWith('logout')
                } else {
                    throw e
                }
            })
    },

    findOrganization: function(orgUrl) {
        return this.get('api').getData('/user/organizations')
            .then(function(organizations) {
                var organization = orgUrl ? _.find(organizations, function(organization) {
                    return organization.url === orgUrl
                }) : organizations[0]

                if (Ember.none(organization)) {
                    return Ember.RSVP.resolve(null)
                } else {
                    return Billy.Organization.find(organization.organizationId).promise
                }
            })
    },

    findUser: function() {
        return this.get('api').request('GET', '/v2/user')
            .then(function(payload) {
                return Billy.User.find(payload.user.id).promise
            })
    }
})

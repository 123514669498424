var model = BD.Model.extend({
    organization: BD.belongsTo('Billy.Organization'),
    type: BD.attr('string', { defaultValue: 'bill' }),
    createdTime: BD.attr('datetime', { readonly: true }),
    approvedTime: BD.attr('datetime', { readonly: true }),
    contact: BD.belongsTo('Billy.Contact'),
    contactName: BD.attr('string'),
    entryDate: BD.attr('date'),
    paymentAccount: BD.belongsTo('Billy.Account'),
    paymentDate: BD.attr('date'),
    dueDate: BD.attr('date'),
    isBare: BD.attr('boolean'),
    state: BD.attr('string', { defaultValue: 'draft' }),
    suppliersInvoiceNo: BD.attr('string'),
    taxMode: BD.attr('string', { defaultValue: 'excl' }),
    voucherNo: BD.attr('string'),
    lineDescription: BD.attr('string', { readonly: true }),
    amount: BD.attr('number', { readonly: true }),
    grossAmount: BD.attr('number', { readonly: true }),
    tax: BD.attr('number', { readonly: true }),
    currency: BD.belongsTo('Billy.Currency'),
    exchangeRate: BD.attr('number'),
    balance: BD.attr('number', { readonly: true }),
    isPaid: BD.attr('boolean', { readonly: true }),
    creditedBill: BD.belongsTo('Billy.Bill'),
    creditedAmount: BD.attr('number', { readonly: true }),
    source: BD.attr('string', { readonly: true }),
    subject: BD.attr('string', { readonly: true }),
    isPendingCloudScan: BD.attr('boolean', { readonly: true }),
    creditNotes: BD.hasMany('Billy.Bill', 'creditedBill'),
    lines: BD.hasMany('Billy.BillLine', 'bill', { isEmbedded: true }),
    attachments: BD.hasMany('Billy.Attachment', 'owner', { isEmbedded: true }),
    balanceModifiers: BD.hasMany('Billy.BalanceModifier', 'subject'),
    commentAssociations: BD.hasMany('Billy.CommentAssociation', 'owner'),
    scannedState: BD.attr('string', { readonly: true }),
    scannedCode: BD.attr('string', { readonly: true }),
    scannedValidation: BD.attr('boolean', { readonly: true }),
    accountingBasis: BD.attr('string', { readonly: true })
})

model.reopenClass({
    supportsBulkSave: true,
    qProperties: ['contact.name', 'lineDescription', 'voucherNo']
})

module.exports = model

var tracking = require('../utils/tracking')
var eventBus = require('../utils/event-bus')

module.exports = Em.Route.extend({
    auth: Em.inject.service(),

    segment: Em.inject.service(),

    model: function() {
        this.controllerFor('user').set('isAuthenticated', false)

        tracking.stopIntercomTracking()
        tracking.stopUserflow()

        var api = this.container.lookup('api:billy')
        var auth = this.get('auth')

        try {
            api.logout().then(function() {
                auth.setScopes([])
            })

            this.get('segment').track('Signed Out')

            window.analytics.reset()
        } catch(e) {
            // Ignore access token error
        }

        eventBus.trigger('logout')

        this.replaceWith('login').then(function() {
            BD.store.reset()
        })
    }
})

var _ = require('lodash')
var t = require('i18n').t

module.exports = Em.Object.extend({
    userOrganizations: Em.inject.service(),

    organization: Em.computed.oneWay('userOrganizations.activeOrganization'),

    organizationSubscription: Em.inject.service(),

    organizationSettings: Em.computed.alias('organization.settings'),

    addons: Em.Object.create({
        basic: [],

        premium: []
    }),

    getAddons: function() {
        return this.get('addons')
    },

    setAddonPrices: function() {
        if (!this.get('organizationSubscription.isLoaded')) {
            return
        }

        var self = this
        var basicAddons = this.get('organizationSubscription').getBasicAddons()
        var premiumAddons = this.get('organizationSubscription').getPremiumAddons()

        // Set basic addon prices
        basicAddons.forEach(function(basicAddon) {
            self.get('addons.basic').forEach(function(addon) {
                if (basicAddon.id === addon.get('id')) {
                    addon.set('price', basicAddon.monthlyPrice)
                }
            })
        })

        // Set premium addon prices
        premiumAddons.forEach(function(premiumAddon) {
            self.get('addons.premium').forEach(function(addon) {
                if (premiumAddon.id === addon.get('id')) {
                    addon.set('price', premiumAddon.monthlyPrice)
                }
            })
        })
    }.observes('organizationSubscription.isLoaded').on('init'),

    features: function(subscriptionData) {
        // Config factory for feature lists.
        // [0] = translation label, [1] = determines icon shape/color

        var basicPreviewListItems = [
            ['feature.invoices', {}, 'isIncluded'],
            ['feature.vat', {}, 'isIncluded'],
            ['feature.bills', {}, 'isIncluded'],
            ['feature.posting_limit', subscriptionData, 'isIncluded'],
            ['feature.cloud_storage', {}, 'isNotIncluded'],
            ['feature.unlimited_users', {}, 'isNotIncluded'],
            ['feature.bank_reconciliation', {}, 'isNotIncluded'],
            ['feature.unlimited_postings', {}, 'isNotIncluded'],
            ['feature.api_integrations', {}, 'isNotIncluded'],
            ['feature.quotes', {}, 'isNotIncluded'],
            ['feature.invoice_designer', {}, 'isNotIncluded']
        ]

        var basicFullListItems = [
            ['feature.invoices', {}, 'isIncluded'],
            ['feature.bills', {}, 'isIncluded'],
            ['feature.vat', {}, 'isIncluded'],
            ['feature.full_support', {}, 'isIncluded'],
            ['feature.posting_limit', subscriptionData, 'isIncludedButLimited'],
            ['feature.mobile_app_only_invoicing', {}, 'isIncludedButLimited'],
            ['feature.cloud_storage', {}, 'isNotIncluded'],
            ['feature.bank_integration', {}, 'isNotIncluded'],
            ['feature.bank_reconciliation', {}, 'isNotIncluded'],
            ['feature.ean_invoicing', {}, 'isNotIncluded'],
            ['feature.invoice_designer', {}, 'isNotIncluded'],
            ['feature.unlimited_users', {}, 'isNotIncluded'],
            ['feature.scanning_prefilling_bills', {}, 'isNotIncluded'],
            ['feature.quotes', {}, 'isNotIncluded'],
            ['feature.recurring_invoices', {}, 'isNotIncluded'],
            ['feature.api_integrations', {}, 'isNotIncluded']
        ]

        // Per list, items will have all but two identical properties, so curry the repeated properties.
        var createList = function(subscription, listType, listItems) {
            return listItems.map(function(item) {
                return Em.Object.create({
                    name: t(item[0], item[1]),
                    iconState: item[2],
                    subscriptions: [subscription],
                    previewList: listType === 'preview',
                    fullList: listType === 'full'
                })
            })
        }

        // Only a few premium items are different, so don't re-write all the duplicated items, just modify the different items.
        var premiumList = function(listItems) {
            return listItems.map(function(basicItem) {
                var premiumItem = _.clone(basicItem)
                premiumItem[2] = 'isIncluded'

                if (premiumItem[0] === 'feature.posting_limit') {
                    premiumItem[0] = 'feature.unlimited_postings'
                }

                if (premiumItem[0] === 'feature.mobile_app_only_invoicing') {
                    premiumItem[0] = 'feature.mobile_app'
                }

                return premiumItem
            })
        }

        var basicPreviewList = createList('basic', 'preview', basicPreviewListItems)
        var basicFullList = createList('basic', 'full', basicFullListItems)
        var premiumPreviewList = createList('premium', 'preview', premiumList(basicPreviewListItems))
        var premiumFullList = createList('premium', 'full', premiumList(basicFullListItems))

        var featureListsConfig = basicPreviewList
            .concat(basicFullList)
            .concat(premiumPreviewList)
            .concat(premiumFullList)

        return featureListsConfig
    },

    getFeatures: function(isPreview, lastItemState, subscription) {
        var features = null
        var subscriptionSvc = this.get('organizationSubscription')
        var subscriptionData = {
            plan: this.get('organization.subscriptionPlan'),
            postingCount: this.get('organizationSubscription.planPostingCount'),
            postingLimit: subscriptionSvc.get('postingLimitBasic')
            // More could be added
        }

        if (isPreview) {
            var subscriptionPlan = this.get('organization.subscriptionPlan')

            switch (subscriptionPlan) {
            case 'basic':
                features = this.get('features')(subscriptionData)
                    .filterBy('previewList', true)
                    .filter(function(item) {
                        return _.includes(item.subscriptions, 'basic')
                    })

                this._addLastItemToFeatures(features, 'isNotIncluded')
                return features

            case 'premium':
                features = this.get('features')(subscriptionData)
                    .filterBy('previewList', true)
                    .filter(function(item) {
                        return _.includes(item.subscriptions, 'premium')
                    })

                this._addLastItemToFeatures(features, 'isOptIn')
                return features

            default:
                return false
            }
        } else {
            features = this.get('features')(subscriptionData)
                .filterBy('fullList', true)
                .filter(function(item) {
                    return _.includes(item.subscriptions, subscription)
                })
        }

        this._addLastItemToFeatures(features, lastItemState)
        return features
    },

    _addLastItemToFeatures: function(features, iconState) {
        if (this.get('organization.settings.featureAnnualReport')) {
            features.push({
                name: '<a target="_blank" href="https://www.billy.dk/priser" class="link">' + t('feature.more') + '</a>',
                iconState: iconState
            })
        } else {
            features.push({
                name: t('feature.more'),
                iconState: iconState
            })
        }

        return features
    }
})

module.exports = Em.View.extend({
    classNames: ['simple-view'],

    /*
    Because of slow performance on mobile devices we add this class to the
    application's root element. This is to minimize the number of nested elements
    with `position:absolute; overflow:...`.
    */
    addClassToRoot: function() {
        $(this.container.lookup('application:main').rootElement).addClass('simple-view-root')
    }.on('didInsertElement'),

    removeClassFromRoot: function() {
        $(this.container.lookup('application:main').rootElement).removeClass('simple-view-root')
    }.on('willDestroyElement')
})

var _ = require('lodash')

var EU_COUNTRIES = {
    AT: 'Austria',
    BE: 'Belgium',
    BG: 'Bulgaria',
    HR: 'Croatia',
    CY: 'Cyprus',
    CZ: 'Czech Republic',
    DK: 'Denmark',
    EE: 'Estonia',
    FI: 'Finland',
    FR: 'France',
    DE: 'Germany',
    GR: 'Greece',
    HU: 'Hungary',
    IE: 'Ireland',
    IT: 'Italy',
    LV: 'Latvia',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    MT: 'Malta',
    NL: 'Netherlands',
    PL: 'Poland',
    PT: 'Portugal',
    RO: 'Romania',
    SK: 'Slovakia',
    SI: 'Slovenia',
    ES: 'Spain',
    SE: 'Sweden'
}

module.exports.isCountryEU = function(countryCode) {
    var euCountriesCodes = _.keys(EU_COUNTRIES)
    return euCountriesCodes.includes(countryCode)
}

module.exports.getRegion = function(countryCode) {
    if (countryCode === 'DK') {
        return 'DK'
    }

    if (this.isCountryEU(countryCode)) {
        return 'EU'
    }

    return 'Other'
}


module.exports = function(container) {
    container.register('component:notification', require('./notification-component'))

    var notifications = []

    function addKeyPrefixToAvoidReactDublicate(key) {
        return 'EMBER_' + key
    }

    function notify(notificationKey, message, type = 'success') {
        if (!notificationKey) {
            console.error('You have to pass "notificationKey" to make the notification work')
            return
        }

        if (!message) {
            console.error('You have to pass "message" to make the notification work')
            return
        }

        var notification = container.lookup('component:notification')
        notification.set('notificationKey', addKeyPrefixToAvoidReactDublicate(notificationKey))
        notification.set('message', message)
        notification.set('type', type)
        notification.show()

        notifications.push(notification)
    }

    function clear() {
        notifications.forEach(function(notification) {
            notification.hideImmediately()
        })

        notifications = []
    }

    return {
        notify: notify,
        clear: clear,
        success: function(notificationKey, text) {
            notify(notificationKey, text, 'success')
        },
        warn: function(notificationKey, text) {
            notify(notificationKey, text, 'warning')
        }
    }
}

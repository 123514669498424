var mimeMap = {
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    gif: 'image/gif',
    pdf: 'application/pdf',
    txt: 'text/plain',
    csv: 'text/csv'
}

module.exports = function resolveMimeType(name, type) {
    if (!type) {
        var match = (name || '').match(/\.([^\.]+$)/)
        if (match) {
            type = mimeMap[match[1]]
        }
    }
    if (!type) {
        // eslint-disable-next-line no-console
        console.warn('Could not resolve mime type for name=' + name + ' and type=' + type + '. Falling back to text/plain.')
        type = 'text/plain'
    }
    return type
}

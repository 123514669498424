Billy.BackgroundTask = Em.Object.extend(Em.Evented, {
    addToController: function() {
        Em.assert('You need to set the `container` property of a background task.', this.container)
        this.container.lookup('controller:backgroundTasks').pushObject(this)
    }.on('init'),

    title: '',

    text: '',

    progress: null,

    icon: null,

    closable: true,

    closeOnClick: true,

    willClose: null,

    click: null,

    clickRoute: null
})

var moment = require('moment')

/**
 * @param {Moment} startDate
 * @param {Moment} endDate
 * @returns {{year: number, startDate: Moment, endDate: Moment}}
 */
function getNextFiscalYear(startDate, endDate) {
    var nextEndDate = endDate.clone().add(1, 'year')
    var nextStartDate = nextEndDate.clone().add(-1, 'year').add(1, 'day')

    return {
        year: nextEndDate.year(),
        startDate: nextStartDate,
        endDate: nextEndDate
    }
}

/**
 * @param {number} year
 * @param {number|string|Moment|Date} firstStartDate
 * @param {number|string|Moment|Date} firstEndDate
 * @returns {{startDate: Date, endDate: Date}}
 */
function getFiscalYearForYear(year, firstStartDate, firstEndDate) {
    var fiscalYear = {
        year: moment.utc(firstEndDate).year(),
        startDate: moment.utc(firstStartDate),
        endDate: moment.utc(firstEndDate)
    }

    while (fiscalYear.endDate.year() < year) {
        fiscalYear = getNextFiscalYear(fiscalYear.startDate, fiscalYear.endDate)
    }

    return {
        startDate: fiscalYear.startDate.toDate(),
        endDate: fiscalYear.endDate.toDate()
    }
}

module.exports = getFiscalYearForYear

var eventBus = require('./event-bus')

module.exports = Em.Object.extend({
    invalidateOn: null,

    resolver: null,

    _isLoading: false,

    _isLoaded: false,

    isLoaded: function() {
        this._ensureLoaded()
        return this.get('_isLoaded')
    }.property('_isLoaded'),

    _value: null,

    value: function() {
        this._ensureLoaded()
        return this.get('_value')
    }.property('_value'),

    _ensureLoaded: function() {
        if (this.get('_isLoaded') || this.get('_isLoading')) {
            return
        }
        this._load()
    },

    _load: function() {
        var self = this
        this.set('_isLoading', true)
        this._promise = Em.RSVP.resolve(this.get('resolver')())
            .then(function(value) {
                self.set('_value', value)
                    .set('_isLoaded', true)
                    .set('_isLoading', false)
                return value
            }, function(e) {
                self.set('_isLoaded', false)
                    .set('_isLoading', false)
                throw e
            })
        this._promise.catch(function() {
            // TODO: What to do when the promise is rejected?
        })
        return this._promise
    },

    reload: function() {
        this._load()
        return this
    },

    then: function(resolved, rejected) {
        this._ensureLoaded()
        return this._promise.then(resolved, rejected)
    },

    invalidate: function() {
        this.set('_value', null)
        this.set('_isLoaded', false)
    },

    eachInvalidateEvent: function(callback) {
        var events = this.get('invalidateOn')
        if (events) {
            if (Em.typeOf(events) === 'array') {
                events.forEach(function(event) {
                    callback(event)
                })
            } else {
                callback(events)
            }
        }
    },

    setupInvalidateListeners: function() {
        var self = this
        this.eachInvalidateEvent(function(event) {
            eventBus.on(event, self, self.invalidate)
        })
    }.on('init'),

    willDestroy: function() {
        var self = this
        this.eachInvalidateEvent(function(event) {
            eventBus.off(event, self, self.invalidate)
        })
    }
})

module.exports = function getUserType(activeMembership) {
    if (!activeMembership) {
        return 'Unknown'
    }
    var isViaUmbrella = activeMembership.via === 'umbrella'

    if (isViaUmbrella) {
        return 'Accountant'
    }

    var isOwner = activeMembership.isOwner

    if (isOwner) {
        return 'Business Owner'
    }

    var isColleague = activeMembership.via === 'direct'

    if (isColleague) {
        return 'Business Colleague'
    }

    return 'Unknown'
}

var invoiceTotal = require('invoice-total')

module.exports = function(invoice, lines) {
    return invoiceTotal(serializeInvoice(invoice), lines.map(serializeLine))
}

module.exports.line = function(invoice, line) {
    return invoiceTotal.line(serializeInvoice(invoice), serializeLine(line))
}

function serializeInvoice(invoice) {
    return {
        taxMode: invoice.get('taxMode')
    }
}

function serializeLine(line) {
    var r = {
        quantity: line.get('quantity') || 0,
        unitPrice: line.get('unitPrice') || 0,
        discountMode: line.get('discountMode'),
        discountValue: line.get('discountValue') || 0,
        currentTaxRate: line.get('currentTaxRate') || 0,
        taxRateName: line.get('taxRate.name')
    }
    // We need this since billy-api still serves discountValue for invoice-lines with 100-based numbers
    if (line instanceof Billy.InvoiceLine && r.discountMode === 'percent') {
        r.discountValue /= 100
    }
    return r
}

var _ = require('lodash')
var t = require('i18n').t
var Scope = require('../utils/scope')

var ORGANIZATION_SCOPE_PREFIX = 'organization:'

module.exports = Em.Object.extend({
    api: Em.inject.service(),

    userOrganizations: Em.inject.service(),

    organization: Em.computed.oneWay('userOrganizations.activeOrganization'),

    organizationsAuthData: null,

    getScopes: function() {
        return window.Billy.scopes || []
    },

    getScopesRaw: function() {
        return window.Billy.scopesRaw || []
    },

    setScopes: function(scopes) {
        window.Billy.scopes = scopes
    },

    setScopesRaw: function(scopesRaw) {
        window.Billy.scopesRaw = scopesRaw
    },

    getIsNewScopesVersion: function() {
        return !!window.Billy.isNewScopesVersion
    },

    setIsNewScopesVersion: function(userNewScopesFlag) {
        window.Billy.isNewScopesVersion = userNewScopesFlag === 1
    },

    getOrganizationScopePrefix: function(organizationId) {
        return ORGANIZATION_SCOPE_PREFIX + organizationId + ':'
    },

    getOrganizationAuthData: function() {
        var organizationId = this.get('organization.id')
        var organizationsAuthData = this.get('organizationsAuthData')

        if (organizationsAuthData && organizationId) {
            var organizationAuthData = organizationsAuthData.find(function(authData) {
                return authData.id === organizationId
            })

            return organizationAuthData
        }

        return undefined
    },

    getIsSourceZervant: function() {
        var organization = this.get('organization')
        return organization.get('isSourceZervant')
    },

    setCurrentOrganizationScopes: function(scopes) {
        this.setScopesRaw(scopes)
        var organization = this.get('organization')
        var organizationId = organization && organization.get('id')

        if (!organizationId) {
            this.setScopes(scopes)
            return
        }

        var organizationScopes = []
        var currentOrganizationScopePrefix = this.getOrganizationScopePrefix(organizationId)

        // Filter scopes for current organization
        scopes.forEach(function(scope) {
            var isOrganizationScope = scope.startsWith(ORGANIZATION_SCOPE_PREFIX)
            var isCurrentOrganizationScope = scope.startsWith(currentOrganizationScopePrefix)

            if (!isOrganizationScope) {
                // Keep none-organization/umbrella scope as it is
                organizationScopes.push(scope)
            } else if (isCurrentOrganizationScope) {
                // Remove current organization's specific scope prefix
                organizationScopes.push(scope.replace(currentOrganizationScopePrefix, ''))
            }
        })

        this.setScopes(organizationScopes)
    },

    onOrganizationChanged: function() {
        // Filter & set current organization scopes once the organization is loaded
        var organization = this.get('organization')

        if (organization) {
            var scopes = this.getScopes()
            this.setCurrentOrganizationScopes(scopes)
        }
    }.observes('organization').on('init'),

    setAuthData: function(tokenInfoResponse) {
        var response = tokenInfoResponse
        var isNewScopesVersion = response.data.user && response.data.user.newScopes
        var scopes = response.data.scope.split(/\s/)

        this.setCurrentOrganizationScopes(scopes)
        this.setIsNewScopesVersion(isNewScopesVersion)
    },

    setAuthDetails: function() {
        var self = this
        var accessToken = this.container.lookup('api:billy').storageAdapter.getValue('accessToken')

        return new Em.RSVP.Promise(function(resolve, reject) {
            self.get('api').request('GET', '/v2/auth/details?accessToken=' + accessToken)
                .then(function(authDetails) {
                    var organizationsAuthData = _.get(authDetails, 'data.organizations')

                    if (organizationsAuthData && Array.isArray(organizationsAuthData)) {
                        self.set('organizationsAuthData', organizationsAuthData)
                    }

                    resolve()
                })
                .catch(function() {
                    console.error('Couldn\'t get auth details for token: ' + accessToken)
                    resolve() // do not stop process because of that
                })
        })
    },

    fetchTokenInfo: function() {
        var accessToken = this.container.lookup('api:billy').storageAdapter.getValue('accessToken')
        var options = {
            headers: {
                Authorization: 'Bearer ' + btoa('access:' + accessToken)
            }
        }

        return this.get('api').request('GET', '/oauth2/tokeninfo', options)
    },

    isAllAuthDataLoaded: function(isNewScopesVersion, scopesRaw, organizationId) {
        var organizationScopePrefix = this.getOrganizationScopePrefix(organizationId)
        var hasRoleScope = organizationScopePrefix + Scope.HasRole
        var hasNewScopesLoaded = _.some(scopesRaw, function(scope) {
            return scope.indexOf(hasRoleScope) !== -1
        })

        return !isNewScopesVersion || (isNewScopesVersion && hasNewScopesLoaded)
    },

    waitForCompleteAuthData: function(organizationId) {
        var INITIAL_TIMEOUT = 2000
        var MAX_ATTEMPT_COUNT = 5
        var self = this

        return new Em.RSVP.Promise(function(resolve, reject) {
            var count = 1

            var check = function() {
                self.fetchTokenInfo().then(function(response) {
                    var isNewScopesVersion = response.data.user && response.data.user.newScopes
                    var scopesRaw = response.data.scope.split(/\s/)
                    var isAllAuthDataLoaded = self.isAllAuthDataLoaded(isNewScopesVersion, scopesRaw, organizationId)

                    // auth data has to be set first, before invoking 'isAllAuthDataLoaded' as it checks saved state
                    if (isAllAuthDataLoaded) {
                        self.setAuthData(response)
                        resolve()
                        return
                    }

                    if (count >= MAX_ATTEMPT_COUNT) {
                        reject(new Error(t('scopes_loading.error') + ' (Code: NO_SCOPES_AUTH_DATA)'))
                        return
                    }

                    setTimeout(check, INITIAL_TIMEOUT * count + 1)
                    count++
                })
            }

            setTimeout(check(), INITIAL_TIMEOUT)
        })
    },

    load: function() {
        var self = this

        return new Em.RSVP.Promise(function(resolve, reject) {
            self.fetchTokenInfo()
                .then(function(response) {
                    self.setAuthData(response)
                    resolve()
                })
                .catch(function(error) {
                    reject(error)
                })
        })
    },

    isAuthorized: function(
        requiredScopes,
        shouldCheckLegacyScopes
    ) {
        if (typeof shouldCheckLegacyScopes !== 'boolean') {
            shouldCheckLegacyScopes = false
        }

        // Ignore nullable or empty array scopes check
        if (!requiredScopes || !requiredScopes.length) {
            return true
        }

        var requiredScopesArray = Array.isArray(requiredScopes) ? requiredScopes : [requiredScopes]
        var userScopes = this.getScopes() || []

        // Check that there's no scope which is not authorized
        return !_.difference(requiredScopesArray, userScopes).length
    },

    showUpgradeOverlay: function(context) {
        this.container.lookup('component:upgrade-plan-overlay')
            .set('trackingContext', context)
            .show()
    },

    withAuthorization: function(onAuthorized, scopes, context) {
        if (!this.isAuthorized(scopes)) {
            this.showUpgradeOverlay(context)
            return
        }

        return onAuthorized()
    }
})

var i18n = require('i18n')
var userBootstrap = require('../utils/user-bootstrap')
var tracking = require('../utils/tracking')
var CustomEvent = require('../constants/customEvent')

module.exports = Em.Route.extend({
    auth: Ember.inject.service(),

    intercom: Ember.inject.service(),

    setUserThemeMode: function(themeLayoutMode) {
        window.Billy.themeLayoutMode = themeLayoutMode
    },

    model: function(params, transition) {
        var self = this
        var userController = this.controllerFor('user')

        // If model is already set, return it
        if (userController.get('isAuthenticated') && this.currentModel) {
            return this.currentModel
        }

        // If we don't have an access token, we just redirect to login
        var accessToken = this.container.lookup('api:billy').storageAdapter.getValue('accessToken')
        if (Em.isEmpty(accessToken)) {
            self.showLogin(transition)
            return
        }

        // Check/update access token and resolve user model/bootstrap
        return self.get('auth').setAuthDetails()
            .then(userBootstrap)
            .then(function(user) {
                self.setUserThemeMode(user.get('themeMode'))
                // When the user logs in or is logged in automatically (accessToken is set), we emit an event to google analytics
                tracking.emitAnalyticsEvent('log_in', 'authenticated')

                // TODO: Does RSVP have a .tap method?
                userController.set('isAuthenticated', true)

                // Fetch auth scopes
                return self.get('auth').load()
                    .then(function() {
                        return user
                    })
            })
            .catch(function(e) {
                if (e.xhr && e.xhr.status === 401) {
                    // If we got a 401 we have to login again
                    self.showLogin(transition)
                } else {
                    // Otherwise we will show the error state to the user.
                    userController.set('isAuthenticated', false)
                    throw e
                }
            })
    },

    afterModel: function(user) {
        var locale = user.get('locale')

        if (locale) {
            i18n.locale(locale)
        }
    },

    showLogin: function(transition) {
        // when we try to route to the React view (e.g. Settings Page)
        // we have to save the whole href, to correctly handle redirection
        // (we know it's a React path because it uses HashRouter)
        if (window.location.hash) {
            this.controllerFor('login').set('afterLoginHref', window.location.href)
        }

        this.controllerFor('user').set('isAuthenticated', false)
        this.controllerFor('login').set('afterLoginTransition', transition)
        this.replaceWith('login')
    },

    listenLayoutThemeChange: function() {
        var self = this

        window.addEventListener(CustomEvent.ThemeLayoutModeChanged, function(event) {
            var newThemeMode = event.detail
            self.setUserThemeMode(newThemeMode)
        })
    }.on('init')
})

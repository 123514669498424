BD.RecordArray = Em.Mixin.create(Em.Evented, {

    isLoaded: false,

    isNotLoaded: Em.computed.not('isLoaded'),

    init: function() {
        this._super()
        var self = this
        this.promise = new Em.RSVP.Promise(function(resolve, reject) {
            self.one('didLoad', function() {
                self.set('isLoaded', true)
                resolve(self)
            })
            self.one('didError', reject)
        })
    },

    arrayContentWillChange: function(index, removed, added) {
        var changedContent = this._super.apply(this, arguments)
        var removedItem
        var loopIndex
        if (removed) {
            for (loopIndex = 0; loopIndex < removed; loopIndex++) {
                removedItem = this.objectAt(index + loopIndex)
                if (removedItem && removedItem !== BD.SPARSE_PLACEHOLDER) {
                    removedItem.didRemoveFromRecordArray(this)
                }
            }
        }
        return changedContent
    },
    arrayContentDidChange: function(index, removed, added) {
        var changedArray = this._super.apply(this, arguments)
        var objectToRemove
        var loopIndex
        if (added) {
            for (loopIndex = 0; loopIndex < added; loopIndex++) {
                objectToRemove = this.objectAt(index + loopIndex)
                if (objectToRemove && objectToRemove !== BD.SPARSE_PLACEHOLDER) {
                    objectToRemove.didAddToRecordArray(this)
                }
            }
        }
        return changedArray
    },

    willDestroy: function() {
        this.forEach(function(r) {
            r.didRemoveFromRecordArray(this)
        }, this)
        this._super()
    }

})

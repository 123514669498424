module.exports = Em.Object.extend({
    organizationSubscription: Ember.inject.service(),

    scopes: {
        basic: [
            'payment_method:bank',
            'payment_method:cash',
            'payment_method:check',
            'payment_method:fi',
            'payment_method:other'
        ],
        premium: [
            'payment_method:bank',
            'payment_method:cash',
            'payment_method:check',
            'payment_method:fi',
            'payment_method:other',
            'payment_method:mobilepay',
            'payment_method:stripe',
            'payment_method:pbs',
            'bank_sync',
            'ean_invoice',
            'voucher',
            'api_key',
            'integrations',
            'invoice_template',
            'inventory',
            'recurring_invoices',
            'quotes'
        ]
    },

    hasScope: function(scope) {
        var plan = this.get('organizationSubscription.currentPlanName')
        var scopes = this.get('scopes.' + plan)

        return scopes.indexOf(scope) >= 0
    },

    isUserInvitationConstrained: function() {
        var plan = this.get('organizationSubscription.currentPlanName')
        if (plan === 'premium') {
            return false
        }

        var extraSeatsAddon = this.get('organizationSubscription').findAddonById('extra_seats')
        if (!extraSeatsAddon) {
            return true
        }

        return extraSeatsAddon.currentValue >= extraSeatsAddon.value
    }.property('organizationSubscription.data'),

    showUpgradeOverlay: function(reason, context) {
        this.container.lookup('component:upgrade-plan-overlay')
            .set('reason', reason)
            .set('trackingContext', context)
            .show()
    }
})

var t = require('i18n').t

module.exports = Em.Object.extend({
    api: Em.inject.service(),

    userUmbrellas: Em.inject.service(),

    data: null, // what's returned from `GET /users/organizations`. Contains `organizationId`, `name` etc.

    loaded: false,

    organization: null,

    loadData: function() {
        if (this._loadPromise) {
            return this._loadPromise
        }

        var self = this
        var id = this.get('data.organizationId')
        var api = this.get('api')

        this._loadPromise = Em.RSVP.hash({
            organization: Billy.Organization.find(id).promise,
            salesTaxReturns: Billy.SalesTaxReturn.find({ organizationId: id, isPaid: false, sortProperty: 'startDate' }).promise,
            draftBills: api.request('GET', '/v2/bills?organizationId=' + id + '&state=draft&pageSize=50'),
            draftDaybookTransactions: api.request('GET', '/v2/daybookTransactions?organizationId=' + id + '&state=draft&include=daybookTransaction.lines:embed&pageSize=50'),
            tasks: api.getData('/organizations/' + id + '/tasks'),
            umbrellas: this.get('userUmbrellas').all()
        })
            .then(function(hash) {
                self.set('organization', hash.organization)
                self.set('draftBillsCount', hash.draftBills.bills.length)
                self.set('isUmbrella', hash.umbrellas.length > 0)

                var daybookTransactions = hash.draftDaybookTransactions.daybookTransactions.filter(function(dt) {
                    return dt.lines.some(function(line) {
                        return !!line.text
                    })
                })
                self.set('draftDaybookTransactionsCount', daybookTransactions.length)

                var salesTaxReturns = hash.salesTaxReturns.filter(function(salesTaxReturn) {
                    return !salesTaxReturn.get('isCurrentPeriod')
                })
                self.set('salesTaxReturns', salesTaxReturns)

                var tasks = hash.tasks.map(function(task) {
                    return Em.Object.create(task)
                })
                self.set('tasks', tasks)

                self.set('loaded', true)
            })
        return this._loadPromise
    },

    hasDraftItems: function() {
        return this.get('draftBillsCount') > 0 || this.get('draftDaybookTransactionsCount') > 0
    }.property('draftBillsCount', 'draftDaybookTransactionsCount'),

    humanSalesTaxPeriod: function() {
        var period = this.get('organization.salesTaxPeriod')
        return period && t('organization.sales_tax_period.' + Em.String.underscore(period))
    }.property('organization.salesTaxPeriod')
})

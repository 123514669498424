/**
 * Service to handle Annual Report Credits.
 * It loads unused credits on init, also has a computed property `hasUnusedCredits` which does what it says.
 * To reload credits in service state, call `loadUnusedCredits`.
 * If you want to just fetch credits without altering service state, call `fetchUnusedCredits`.
 */
module.exports = Em.Object.extend({

    // dependencies

    api: Em.inject.service(),
    userOrganizations: Em.inject.service(),
    organization: Em.computed.alias('userOrganizations.activeOrganization'),
    organizationId: Em.computed.alias('organization.id'),

    // properties

    isLoaded: false,

    unusedCredits: [],

    hasUnusedCredits: function() {
        return this.get('unusedCredits') && this.get('unusedCredits').length > 0
    }.property('unusedCredits'),

    // init

    init: function() {
        this.loadUnusedCredits()
    }.observes('organizationId'),

    // methods

    loadUnusedCredits: function() {
        return this.fetchUnusedCreditsAndSave()
    },

    fetchUnusedCreditsAndSave: function() {
        if (!this.get('organizationId')) {
            return Promise.resolve()
        }

        if (this._fetchPromise) {
            return this._fetchPromise
        }

        this._fetchPromise = this.fetchUnusedCredits()
            .then(this.storeUnusedCreditsFromResponse.bind(this))
            .then(this.markAsFetched.bind(this))

        return this._fetchPromise
    },

    fetchUnusedCredits: function() {
        var organizationId = this.get('organizationId')

        return this.get('api').getData('/organizations/' + organizationId + '/annualReport/credits?unused=1')
    },

    storeUnusedCreditsFromResponse: function(creditsResponse) {
        this.set('unusedCredits', creditsResponse)
    },

    markAsFetched: function() {
        this._fetchPromise = null
        this.set('isLoaded', true)
    }
})

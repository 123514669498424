var tracking = require('../utils/tracking')

module.exports = Em.Route.extend({
    segment: Em.inject.service(),

    beforeModel: function(transition) {
        var signupCouponParams = {
            signupCouponId: transition.queryParams.signupCouponId,
            signupPeriod: transition.queryParams.signupPeriod,
            signupPlan: transition.queryParams.signupPlan,
            signupAvailableDays: transition.queryParams.signupAvailableDays
        }

        this.set('couponCodeId', transition.queryParams.couponCodeId || null)
        this.set('newSignupFlow', transition.queryParams.newSignupFlow || null)
        this.set('anonymousId', transition.queryParams.anonymousId || null)
        this.set('signupUmbrellaId', transition.queryParams.signupUmbrellaId || null)

        if (signupCouponParams.signupCouponId) {
            this.set('signupCouponParams', signupCouponParams)
        }
    },

    model: function(params) {
        var self = this
        var token = params.token
        var apiClient = this.container.lookup('api:billy')
        apiClient.authorize(token, false)

        return Ember.RSVP.hash({
            organization: this.findOrganization(),
            user: this.findUser()
        })
            .then(function(props) {
                var organization = props.organization
                var user = props.user
                var root = self.container.lookup('application:main').get('rootElement')
                var couponCodeId = self.get('couponCodeId')
                var newSignupFlow = self.get('newSignupFlow')
                var anonymousId = self.get('anonymousId')
                var signupUmbrellaId = self.get('signupUmbrellaId')
                var signupCouponParams = self.get('signupCouponParams')

                if (anonymousId) {
                    self.get('segment').setAnonymousId(anonymousId)
                }

                tracking.trackPartnerAddsSignup(user, root)

                if (!organization) {
                    if (anonymousId) {
                        window.analytics.identify(anonymousId)
                    }

                    if (newSignupFlow === 'true') {
                        tracking.emitAnalyticsEvent('log_in', 'authenticated')
                        tracking.emitAnalyticsEvent('conversion', 'user_signup_webapp')
                        self.container.lookup('controller:signup-guide').set('user', user)
                        self.container.lookup('controller:signup-guide').set('signupUmbrellaId', signupUmbrellaId)
                        self.container.lookup('controller:signup-guide').set('signupCouponParams', JSON.stringify(signupCouponParams))
                        self.replaceWith('signup-guide')
                    } else {
                        self.replaceWith('startguide.welcome')
                    }

                    return
                }

                self.get('segment').identify(organization)

                if (couponCodeId) {
                    // Send to coupon offer page
                    self.replaceWith('coupon_offer', organization)
                    return
                }

                self.container.lookup('controller:signup-guide').set('user', user)
                self.container.lookup('controller:signup-guide').set('signupUmbrellaId', signupUmbrellaId)
                self.replaceWith('signup-guide')
            }, function(e) {
                if (e.type === 'API_ERROR' && e.status === 401) {
                    self.replaceWith('login')
                } else {
                    throw e
                }
            })
    },

    findOrganization: function() {
        return this.get('api').getData('/user/organizations')
            .then(function(organizations) {
                if (Ember.none(organizations[0])) {
                    return Ember.RSVP.resolve(null)
                } else {
                    return Billy.Organization.find(organizations[0].organizationId).promise
                }
            })
    },

    findUser: function() {
        return this.get('api').request('GET', '/v2/user')
            .then(function(payload) {
                return Billy.User.find(payload.user.id).promise
            })
    }
})

var _ = require('lodash')
var CellView = require('./cell-view')

module.exports = CellView.extend({
    templateName: 'ui/list/table/icon-cell',
    classNames: ['table-icon-cell'],
    value: Em.computed.alias('rawValue'),
    'icon-status': function() {
        var value = this.get('value')
        try {
            // value is composite of icon path, class and due date like:
            // icons/dot.svg-red@Overdue, 23d past due
            // so we're extracting only status after @
            return 'icon-status-' + _.kebabCase(_.toLower(value.split(/[@,]/)[1]))
        } catch(error) {
            return ''
        }
    }.property()
})

var postingSides = require('posting-sides')

module.exports = Em.Object.extend({
    subjectWrapper: function() {
        var cls
        var subject = this.get('subject')

        if (subject instanceof Billy.Invoice) {
            cls = InvoiceWrapper
        } else if (subject instanceof Billy.Bill) {
            cls = BillWrapper
        } else if (subject instanceof Billy.SalesTaxReturn) {
            cls = SalesTaxReturnWrapper
        } else if (subject instanceof Billy.Posting) {
            cls = PostingWrapper
        } else {
            throw new Error('Unknown subject type of class ' + subject.constructor.toString())
        }

        return cls.create({
            subject: subject
        })
    }.property('subject'),

    // Alias the wrapped subject's properties
    description: Em.computed.alias('subjectWrapper.description'),
    refText: Em.computed.alias('subjectWrapper.refText'),
    amount: Em.computed.alias('subjectWrapper.amount'),
    side: Em.computed.alias('subjectWrapper.side'),
    sideAmount: Em.computed.alias('subjectWrapper.sideAmount'),
    entryDate: Em.computed.alias('subjectWrapper.entryDate'),
    paymentDate: Em.computed.alias('subjectWrapper.paymentDate'),
    orderNo: Em.computed.alias('subjectWrapper.orderNo'),
    formattedAmount: Em.computed.alias('subjectWrapper.formattedAmount'),
    stateIconPath: Em.computed.alias('subjectWrapper.stateIconPath')
})

var BaseWrapper = Em.Object.extend({
    subject: null,

    sideAmount: function() {
        return (this.get('side.isCredit') ? -1 : 1) * this.get('amount')
    }.property('amount', 'side'),

    formattedAmount: function() {
        return (this.get('side.isDebit') ? '+' : '-') + Billy.money(this.get('amount'))
    }.property('amount', 'side'),

    stateIconPath: function() {
        if (!this.get('subject.state')) {
            return null
        }

        var state = this.get('subject.state') === 'draft'
            ? 'draft'
            : this.get('subject.isPaid') ? 'paid' : 'unpaid'

        switch (state) {
        case 'draft':
            return 'icons/dot.svg-blue'
        case 'paid':
            return 'icons/dot.svg-green'
        case 'unpaid':
            return 'icons/dot.svg-yellow'
        }
    }.property('subject.state', 'subject.isPaid')
})

var InvoiceWrapper = BaseWrapper.extend({
    description: Em.computed.alias('subject.contact.name'),

    refText: function() {
        var text = []
        text.push('Faktura nr. ' + this.get('subject.invoiceNo'))

        if (this.get('subject.orderNo')) {
            text.push('Ordre nr. ' + this.get('subject.orderNo'))
        }

        return text.join(' / ')
    }.property('subject.invoiceNo', 'subject.orderNo'),

    amount: Em.computed.alias('subject.balance'),

    side: function() {
        return this.get('subject.isCreditNote') ? postingSides.credit : postingSides.debit
    }.property('subject.isCreditNote'),

    entryDate: Em.computed.alias('subject.entryDate'),

    orderNo: Em.computed.alias('subject.orderNo')
})

var BillWrapper = BaseWrapper.extend({
    description: Em.computed.alias('subject.contact.name'),

    refText: function() {
        var text = []
        text.push('Regning nr. ' + this.get('subject.voucherNo'))

        if (this.get('subject.suppliersInvoiceNo')) {
            text.push('Ref. nr. ' + this.get('subject.suppliersInvoiceNo'))
        }

        return text.join(' / ')
    }.property('subject.voucherNo', 'subject.suppliersInvoiceNo'),

    amount: Em.computed.alias('subject.balance'),

    side: function() {
        return this.get('subject.isCreditNote') ? postingSides.debit : postingSides.credit
    }.property('subject.isCreditNote'),

    entryDate: Em.computed.alias('subject.entryDate'),

    paymentDate: function() {
        return this.get('subject.paymentDate') || this.get('subject.dueDate')
    }.property('subject.paymentDate', 'subject.dueDate')
})

var SalesTaxReturnWrapper = BaseWrapper.extend({
    description: function() {
        return 'Momsbetaling'
    }.property(),

    refText: Em.computed.alias('subject.periodText'),

    amount: function() {
        return Math.abs(this.get('subject.report.result'))
    }.property('subject.report.result'),

    side: function() {
        return this.get('subject.report.result') > 0 ? postingSides.credit : postingSides.debit
    }.property('subject.report.result'),

    entryDate: Em.computed.alias('subject.reportDeadline'),

    stateIconPath: function() {
        return this.get('subject.isPaid') ? 'icons/dot.svg-green' : 'icons/dot.svg-yellow'
    }.property('subject.isPaid')
})

var PostingWrapper = BaseWrapper.extend({
    description: Em.computed.alias('subject.transaction.originatorName'),

    refText: function() {
        var transaction = this.get('subject.transaction')
        var originator = transaction.get('originator')
        var originatorType

        if (originator instanceof Billy.BankPayment) {
            if (originator.get('cashSide.isDebit')) {
                originatorType = 'Kundeindbetaling'
            } else {
                originatorType = 'Leverandørindbetaling'
            }
        } else {
            originatorType = 'Finansbilag'
        }

        return originatorType + ' / Transak. ' + transaction.get('transactionNo')
    }.property('subject.transaction.{transactionNo,originator.cashSide.isDebit}'),

    amount: Em.computed.alias('subject.amount'),

    side: Em.computed.alias('subject.side'),

    entryDate: Em.computed.alias('subject.entryDate')
})

var CellView = require('./cell-view')

module.exports = CellView.extend({
    templateName: 'ui/list/table/checkbox-cell',

    extraClass: ['list-checkbox'],

    click: function(e) {
        if (e.target.nodeName === 'INPUT') {
            return
        }
        var checkbox = this.$('input')
        checkbox.click()
    }
})

/*
    Extracted from https://github.com/thoov/ember-websockets/releases/tag/7.0.2

    Use websocket in a component:
        websockets: Ember.inject.service(),

        socketRef: null,

        didInsertElement: function() {
            this._super()
            var socket = this.get('websockets').socketFor('ws://localhost:7000/')

            socket.on('open', this.myOpenHandler, this)
            socket.on('message', this.myMessageHandler, this)
            socket.on('close', this.myCloseHandler, this)

            this.set('socketRef', socket)
        },

        willDestroyElement: function() {
            this._super()
            this.get('socketService').closeSocketFor('ws://localhost:7000/')
        }

        myOpenHandler: function(event) {
            console.log('On open event has been called: ', event)
        },

        myMessageHandler: function(event) {
            console.log('Message ', event.data)
        },

        myCloseHandler: function(event) {
            console.log('On close event has been called: ', event)
        },
        actions: {
            sendButtonPressed: function() {
                var socket = this.get('socketRef')
                socket.send('Hello from the webapp')
            }
        }
*/

var WebsocketProxy = require('../utils/websocket-proxy')
var normalizeURL = require('../utils/websocket').normalizeURL
var cleanURL = require('../utils/websocket').cleanURL

module.exports = Em.Object.extend({
    api: Em.inject.service(),

    userOrganizations: Em.inject.service(),

    /*
        A hash of open websocket connections. This
        allows multiple components to share the same connection.

        {
          'websocket-url': WebSocket Proxy object
        }
    */
    sockets: function() {
        return {}
    }.property(),

    /*
        socketFor returns a websocket proxy object. On this object there is a property `socket`
        which contains the actual websocket object. This websocket object is cached based off of the url meaning
        multiple requests for the same socket will return the same object.
    */
    socketFor: function(url, protocols) {
        protocols = protocols || []
        /*
            Websockets allows either a string or array of strings to be passed as the second argument.
            Normalize both cases into an array of strings so we can just deal with arrays.
        */
        if (typeof protocols === 'string') {
            protocols = [protocols]
        }

        /*
            Normalize the url as native websockets add a / to the end of the url:
            http://example.com:8000 becomes: http://example.com:8000/

            Since the url will be used as a key will need to make sure that it does not
            contain '.' as it will throw ember off
        */
        var normalizedUrl = normalizeURL(url)
        var cleanedUrl = cleanURL(normalizedUrl)
        var existingProxy = this.get('sockets.' + cleanedUrl)

        if (existingProxy && this.isWebSocketOpen(existingProxy.socket)) {
            return existingProxy
        }

        /*
            we can get to this place if the websocket has been closed and we are trying to reopen
            or we are creating a proxy for the first time
        */
        var newWebSocket = this.createSocket(normalizedUrl, protocols)

        if (existingProxy) {
            /*
                If there is an existing socket in place we simply update the websocket object and not
                the whole proxy as we dont want to destroy the previous listeners.
            */
            this.set(existingProxy, 'socket', newWebSocket)
            return existingProxy
        }

        var newProxy = this.createProxy(newWebSocket, protocols)

        this.set('sockets.' + cleanedUrl, newProxy)

        return newProxy
    },

    closeSocketFor: function(url) {
        var cleanedUrl = cleanURL(normalizeURL(url))
        var existingSocket = this.get('sockets.' + cleanedUrl)

        if (existingSocket) {
            existingSocket.socket.close()
        }

        delete this.get('sockets')[cleanedUrl]
    },

    isWebSocketOpen: function(websocket) {
        return websocket.readyState !== WebSocket.CLOSED
    },

    createSocket: function(url, options) {
        return new WebSocket(url, options)
    },

    createProxy: function(socket, protocols) {
        return WebsocketProxy.create({ content: this, protocols: protocols, socket: socket })
    }
})

var imageThumbnail = require('./image-thumbnail')

module.exports = function(iconUrl) {
    if (iconUrl) {
        return imageThumbnail(iconUrl, { size: '64x' })
    } else {
        // TODO: Use some kind of automatic image that uses the initials of the name, if there's no icon
        return Billy.image('misc/default-organization-icon.png')
    }
}

module.exports = Em.Route.extend({
    model: function() {
        return this.modelFor('recurring_invoice')
    },

    setupController: function(controller, model) {
        controller.set('model', model)
        controller.loadHtml()
    }
})

var i18n = require('i18n')
var tracking = require('../utils/tracking')

module.exports = Em.Route.extend({
    model: function(params) {
        tracking.trackVisit('/entrance')
        if (params.locale) {
            i18n.locale(params.locale)
        }
        if (params.target === 'signup') {
            var signupController = this.controllerFor('signup')
            // Set query params to controller (if string)
            var keys = [
                'email',
                'yourName',
                'companyName',
                'phone',
                'drip_email'
            ]
            keys.forEach(function(key) {
                var value = params[key]
                if (typeof value === 'string') {
                    if (key === 'phone') {
                        signupController.set(key, value)
                    } else if (key === 'drip_email') {
                        signupController.set('record.email', value)
                    } else {
                        signupController.set('record.' + key, value)
                    }
                }
            })
            this.replaceWith('signup')
        } else {
            this.replaceWith('login')
        }
    }
})

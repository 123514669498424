var moment = require('momentjs')

var DATE_ORGANIZATION_PROPERTIES = [
    'activationDate',
    'createdTime',
    'firstFiscalYearEnd',
    'firstFiscalYearStart',
    'fiscalLockingDate',
    'subscriptionExpires',
    'trialExpiryDate'
]

module.exports = function(organizationData) {
    Object.keys(organizationData).forEach(function(key) {
        if (DATE_ORGANIZATION_PROPERTIES.includes(key)) {
            var dateFormattedProperty = moment(organizationData[key])

            if (dateFormattedProperty.isValid()) {
                organizationData[key] = dateFormattedProperty
            }
        }
    })

    return organizationData
}

module.exports = BD.Model.extend({
    organization: BD.belongsTo('Billy.Organization'),
    transactionNo: BD.attr('string'),
    voucherNo: BD.attr('string'),
    createdTime: BD.attr('datetime'),
    entryDate: BD.attr('date'),
    originator: BD.belongsToReference(),
    originatorName: BD.attr('string'),
    originatorReference: BD.attr('string'),
    isVoided: BD.attr('boolean'),
    isVoid: BD.attr('boolean'),
    postings: BD.hasMany('Billy.Posting', 'transaction'),
    creator: BD.attr('object')
})

var functionProxy = require('function-proxy')
var InfiniteScrollView = require('../mixins/infinite-scroll-view')

module.exports = Em.View.extend(InfiniteScrollView, {
    classNames: ['daybook-view'],

    scrollSelector: '.section-body',

    itemHeight: 46,

    registerListeners: function() {
        var el = this.$()

        this.get('controller').on('newTransaction', this, this.onNewTransaction)

        el.delegate('.daybook-transactions input', 'focus', this.onInputFocusChange.bind(this, 'focus'))
        el.delegate('.daybook-transactions input', 'blur', this.onInputFocusChange.bind(this, 'blur'))

        el.delegate('.daybook-tools', 'mouseenter', this.onToolsMouseEnter.bind(this))
        el.delegate('.daybook-tools', 'mouseleave', this.onToolsMouseLeave.bind(this))
        el.delegate('.daybook-tools-popover', 'mouseleave', this.onToolsMouseLeave.bind(this))

        el.delegate('input[name="allSelected"]', 'change', this.onAllCheckboxChange.bind(this))
        el.delegate('.daybook-edit-transaction input[type="checkbox"]', 'change', this.onCheckboxChange.bind(this))

        el.delegate('.daybook-transaction-checkbox', 'click', this.onCheckboxAreaClick.bind(this))

        el.delegate('.daybook-transactions input', 'keydown', this.onInputKeyDown.bind(this))

        $(window).on('resize', functionProxy(this.positionContent, this))
        this.positionContent()

        // this.$('.toggle-balance-accounts').click(this.toggleBalanceAccounts);
    }.on('didInsertElement'),

    willDestroy: function() {
        this._super()

        this.get('controller').off('newTransaction', this, this.onNewTransaction)

        $(window).off('resize', functionProxy(this.positionContent, this))

        delete this.lastClickedCheckbox
    },

    onNewTransaction: function() {
        Em.run.schedule('afterRender', this, this.focusLastTransaction)
    },

    focusLastTransaction: function() {
        if (this.get('isDestroying')) {
            return
        }
        var name = this.focusLastTransactionName || 'entryDate'
        this.focusLastTransactionName = null
        this.$('.daybook-edit-transaction:last input[name="' + name + '"]:eq(0)').focus()
    },

    onInputFocusChange: function(eventType, e) {
        if (e.target.type.toLowerCase() === 'checkbox') {
            return
        }
        var transactionEl = this.transactionElOf(e.target)
        Em.run.join(this, function() {
            if (this.get('isDestroying')) {
                return
            }
            var transactionController = this.controllerOf(transactionEl)
            if (transactionController) { // It might have already been deleted, in which case we just stop
                this.get('controller').send(eventType, transactionController)
            }
        })
    },

    onToolsMouseEnter: function(e) {
        var popover = this.$('.daybook-tools-popover')
        popover.addClass('daybook-tools-popover-visible')
        popover.position({
            my: 'right',
            at: 'left',
            of: e.target
        })
        var lineEl = this.lineElOf(e.target)
        this.activeToolsLineController = this.controllerOf(lineEl)
    },

    onToolsMouseLeave: function(e) {
        if ($(e.relatedTarget).closest('.popover, .daybook-tools').length) {
            // Don't hide if we are moving into the popover or the daybook-tools-arrow itself
            return
        }
        this.hideToolsPopover()
    },

    hideToolsPopover: function() {
        var popover = this.$('.daybook-tools-popover')
        popover.removeClass('daybook-tools-popover-visible')
        this.activeToolsLineController = null
    },

    onAllCheckboxChange: function(e) {
        Em.run(this, function() {
            e.target.checked = !e.target.checked // Revert the user's action. The controller will set this
            this.get('controller').send('toggleSelectAll')
        })
    },

    onCheckboxChange: function(e) {
        var transactionEl = this.transactionElOf(e.target)
        Em.run.join(this, function() {
            e.target.checked = !e.target.checked // Revert the user's action. The controller will set this
            this.get('controller').send('toggleSelect', this.controllerOf(transactionEl))
        })
    },

    onCheckboxAreaClick: function(e) {
        var self = this

        if (e.shiftKey && this.lastClickedCheckbox) {
            var transactionEl = this.transactionElOf(e.target)
            var lastTransactionEl = this.transactionElOf(this.lastClickedCheckbox)
            var els = lastTransactionEl[lastTransactionEl.index() < transactionEl.index() ? 'nextUntil' : 'prevUntil'](transactionEl)
            els.each(function(idx, el) {
                self.get('controller').send('toggleSelect', self.controllerOf(el))
            })
        }

        this.lastClickedCheckbox = e.target

        if (e.target.tagName.toLowerCase() !== 'input') {
            var cb = $('input', e.target)[0]
            cb.checked = !cb.checked
            this.onCheckboxChange(e) // manually trigger this one, since JS won't fire a new `change` event when setting it manually
        }
    },

    onInputKeyDown: function(e) {
        // Make sure that a superfield options list isn't being navigated
        if ($('.popover-body.list').length) {
            return
        }

        var target = e.target

        if (e.ctrlKey) {
            switch (e.keyCode) {
            case $.keyCode.DELETE:
            case $.keyCode.BACKSPACE:
                e.preventDefault()
                this.onDeleteKey(e)
                break

            case 68: // Letter D
                e.preventDefault()
                this.focusLastTransactionName = target.name
                this.sendTransactionActionForEvent(e, 'duplicateTransaction')
                break

            case 76: // Letter L
                e.preventDefault()
                this.sendTransactionActionForEvent(e, 'addLine')
                Em.run.schedule('afterRender', this, function() {
                    var newField = this.transactionElOf(target).find('.daybook-edit-transaction-line:last-child input[name="' + target.name + '"]')
                    newField.focus()
                })
                break

            case 85: // Letter U
                e.preventDefault()
                this.sendTransactionViewActionForEvent(e, 'toggleAttachments')
                break
            }
        } else {
            switch (e.keyCode) {
            case $.keyCode.UP:
            case $.keyCode.DOWN:
                e.preventDefault()
                this.onArrowNavigation(e)
                break

            case $.keyCode.TAB:
                this.onTabKey(e)
                break
            }
        }
    },

    onArrowNavigation: function(e) {
        this.navigate(e.target, e.keyCode === $.keyCode.UP ? 'prev' : 'next')
    },

    navigate: function(target, method) {
        var name = target.name
        var inputSelector
        if (['account', 'paidInvoice', 'paidExternalInvoice', 'paidBill'].contains(name)) {
            inputSelector = 'input[name="account"], input[name="paidInvoice"], input[name="paidExternalInvoice"], input[name="paidBill"]'
        } else if (name === 'taxRate') {
            inputSelector = 'input[name="taxRate"], input[name="paidInvoice"], input[name="paidBill"]'
        } else {
            inputSelector = 'input[name="' + name + '"]'
        }

        var line = this.lineElOf(target)
        var sibling = line[method]('.daybook-edit-transaction-line')
        if (sibling.length) {
            sibling.find(inputSelector).focus()
        } else {
            var transaction = this.transactionElOf(target)
            sibling = transaction[method]('.daybook-edit-transaction')
            if (sibling.length) {
                if (line.length) {
                    // If the field is a line field, we need to enter a line element
                    sibling = sibling.find('.daybook-edit-transaction-line')[method === 'prev' ? 'last' : 'first']()
                }
                sibling.find(inputSelector).focus()
            } else {
                if (method === 'next') {
                    this.focusLastTransactionName = name
                    Em.run.join(this, function() {
                        this.get('controller').send('addTransaction')
                    })
                }
            }
        }
    },

    onDeleteKey: function(e) {
        var lineEl = this.lineElOf(e.target)
        if ((!lineEl.length || lineEl.is(':last-child')) && this.transactionElOf(e.target).is(':last-child')) {
            this.navigate(e.target, 'prev')
        } else {
            this.navigate(e.target, 'next')
        }
        if (lineEl.length) {
            this.sendLineActionForEvent(e, 'deleteLine')
        } else {
            this.sendTransactionActionForEvent(e, 'deleteTransaction')
        }
    },

    onTabKey: function(e) {
        if (e.shiftKey) {
            return
        }
        if (e.target.name !== 'contraAccount') {
            return
        }
        var transaction = this.transactionElOf(e.target)
        if (!transaction.is(':last-child')) {
            return
        }
        var line = this.lineElOf(e.target)
        if (!line.is(':last-child')) {
            return
        }
        // Prevent the tab's effect, so we can render the element before focusing it
        e.preventDefault()
        Em.run.join(this, function() {
            this.get('controller').send('addTransaction')
            Em.run.schedule('afterRender', this, function() {
                transaction.next('.daybook-edit-transaction').find('input[name="type"]').focus()
            })
        })
    },

    transactionElOf: function(el) {
        return $(el).closest('.daybook-edit-transaction')
    },

    lineElOf: function(el) {
        return $(el).closest('.daybook-edit-transaction-line')
    },

    viewOf: function(el) {
        return Em.View.views[el.id || el.attr('id')]
    },

    controllerOf: function(el) {
        var view = this.viewOf(el)
        return view && view.get('controller')
    },

    footerContentsDidChange: function() {
        Em.run.scheduleOnce('afterRender', this, this.positionContent)
    }.observes('controller.daybook.balanceAccounts.length', 'controller.controllers.daybookBalanceAccounts.isLoaded', 'controller.hasFooter'),

    positionContent: function() {
        if (this.get('isDestroying')) {
            return
        }

        var body = this.$('.section-body')
        var footer = this.$('.daybook-footer')
        body.css('bottom', footer.outerHeight() + 'px')
    },

    sendTransactionActionForEvent: function(e, action) {
        var controller = this.controllerOf(this.transactionElOf(e.target))
        controller.send(action, controller)
    },

    sendTransactionViewActionForEvent: function(e, action) {
        var view = this.viewOf(this.transactionElOf(e.target))
        view.send(action)
    },

    sendLineActionForEvent: function(e, action) {
        var controller = this.controllerOf(this.lineElOf(e.target))
        controller.send(action, controller)
    },

    actions: {
        duplicateLine: forwardLineAction('duplicateLine'),

        deleteLine: forwardLineAction('deleteLine'),

        addLine: forwardLineAction('addLine')
    }
})

function forwardLineAction(action) {
    return function() {
        var c = this.activeToolsLineController
        c.send(action, c)
        this.hideToolsPopover()
    }
}

window.apply = function(dst, src) {
    if (!_.isObject(dst)) {
        throw new Error('Dst is not an object')
    }
    if (!_.isObject(src)) {
        return false
    }
    var changed = false
    _.each(src, function(v, k) {
        if (dst[k] !== v) {
            dst[k] = v
            changed = true
        }
    })
    return changed
}

window.applyIf = function(dst, src) {
    if (!_.isObject(dst)) {
        throw new Error('Dst is not an object')
    }
    if (!_.isObject(src)) {
        return false
    }
    var changed = false
    _.each(src, function(v, k) {
        if (_.isUndefined(dst[k])) {
            dst[k] = v
            changed = true
        }
    })
    return changed
}

window.deepApply = function(dst, src) {
    if (!_.isObject(dst)) {
        throw new Error('Dst is not an object')
    }
    if (!_.isObject(src)) {
        return false
    }
    var changed = false
    _.each(src, function(v, k) {
        if (_.isObject(v)) {
            if (!_.isObject(dst[k])) {
                dst[k] = {}
            }
            var subChanged = window.deepApply(dst[k], v)
            if (subChanged) {
                changed = true
            }
        } else {
            if (dst[k] !== v) {
                dst[k] = v
                changed = true
            }
        }
    })
    return changed
}

window.cloneObject = function(o) {
    if (_.isArray(o)) {
        var a = []
        o.forEach(function(v) {
            a.push(window.cloneObject(v))
        })
        return a
    } else if (_.isObject(o)) {
        var r = {}
        _.each(o, function(v, k) {
            r[k] = window.cloneObject(v)
        })
        return r
    } else {
        return o
    }
}

window.ucfirst = function(s) {
    s += ''
    return s[0].toUpperCase() + s.substr(1)
}
/* eslint-disable no-console */
if (!window.console) {
    window.console = {}
}
if (!window.console.log) {
    window.console.log = function() {
    }
}
/* eslint-enable no-console */

window.UserError = function(message) {
    this.message = message
}
window.UserError.prototype = new Error()

var model = BD.Model.extend({
    organization: BD.belongsTo('Billy.Organization'),
    type: BD.attr('string'),
    createdTime: BD.attr('datetime', { readonly: true }),
    updatedTime: BD.attr('datetime', { readonly: true }),
    name: BD.attr('string'),
    street: BD.attr('string'),
    city: BD.belongsTo('Billy.City'),
    cityText: BD.attr('string'),
    state: BD.belongsTo('Billy.State'),
    stateText: BD.attr('string'),
    zipcode: BD.belongsTo('Billy.Zipcode'),
    zipcodeText: BD.attr('string'),
    country: BD.belongsTo('Billy.Country'),
    registrationNo: BD.attr('string'),
    nominalOwnershipPercent: BD.attr('number'),
    startDate: BD.attr('date'),
    endDate: BD.attr('date')
})

module.exports = model

var numeral = require('numeral')
var Liquid = require('liquid-js')

Liquid
    .registerFilters({
        money: function(input, currency) {
            if (isNaN(parseFloat(input))) return null

            return numeral(input).format('0,0.00') + (currency ? ' ' + currency.toUpperCase() : '')
        },
        date: function(input, format) {
            var date = moment(input)

            if (!date.isValid()) return null

            var momentFormatted = date.format(format || '%D')
            var strftimeFormatted = Date.prototype.strftime
                ? new Date(date.toISOString()).strftime(format)
                : format

            return strftimeFormatted !== format ? strftimeFormatted : momentFormatted
        }
    })

module.exports = function(localeId) {
    localeId = localeId || 'en_US'
    return {
        render: function(template, context) {
            // Set locale for liquid
            var oldDateLocale = Date.prototype.locale
            var oldNumeralLanguage = numeral.language()

            // Set new locale/language
            // eslint-disable-next-line no-extend-native
            Date.prototype.locale = localeId.replace('_', '-')
            numeral.language(localeId.split('_')[0])

            // Render the template
            var content = Liquid.parse(template).render(context)

            // Reset locale/language
            // eslint-disable-next-line no-extend-native
            Date.prototype.locale = oldDateLocale
            numeral.language(oldNumeralLanguage)

            return content
        }
    }
}

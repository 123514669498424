var Column = require('./column')
var CheckboxCellView = require('../table/checkbox-cell-view')

module.exports = Column.extend({
    name: 'check',

    width: 18,

    cellViewClass: CheckboxCellView
})

module.exports = Em.Object.extend({
    api: Em.inject.service(),

    getCoupon: function(organizationId, code) {
        return this.get('api').request('GET', '/v2/organizations/' + organizationId + '/coupon/' + code)
            .then(function(payload) {
                return payload.coupon
            })
    },

    redeemCoupon: function(organizationId, code) {
        var opts = {
            payload: {
                coupon: {}
            }
        }
        return this.get('api').request('PUT', '/v2/organizations/' + organizationId + '/coupon/' + code, opts)
            .then(function(payload) {
                if (payload.organizations) {
                    BD.store.loadAll(Billy.Organization, payload.organizations)
                }
            })
    },

    removeCoupon: function(organizationId) {
        return this.get('api').request('DELETE', '/v2/organizations/' + organizationId + '/coupon')
            .then(function(payload) {
                if (payload.organizations) {
                    BD.store.loadAll(Billy.Organization, payload.organizations)
                }
            })
    }
})

var functionProxy = require('function-proxy')

module.exports = Em.View.extend({
    didInsertElement: function() {
        this.$().delegate('.account-balance-link', 'click', functionProxy(this.didClickAccount, this))
    },

    didClickAccount: function(e) {
        e.preventDefault()
        var a = $(e.target)
        var balance = a.closest('.account-balance')
        var startDate = balance.attr('data-start-date')
        var endDate = balance.attr('data-end-date')
        var account = balance.closest('.account')
        var accountId = account.attr('data-account-id')
        this.get('controller').showAccountSpecification(accountId, startDate, endDate)
    }
})

/**
 * Based on period in form of "quarter:2020-3", get its data broken down and returned as an object.
 * Wrapped this in trycatch in case something changes in the format or there's no correct reply from API.
 *
 * @param {string} period
 * @returns {{type: string, quarter: string, year: number}}
 */
module.exports = function(period) {
    try {
        var parts = period.split(':', 2)
        var type = parts[0]
        var value = parts[1]
        var valuePair = value.split('-')

        switch (type) {
        case 'quarter':
            return {
                type: 'quarterly',
                period_settled: 'q' + valuePair[1],
                year: getYearNumber(valuePair[0])
            }
        case 'month':
            return {
                type: 'monthly',
                period_settled: 'm' + valuePair[1],
                year: getYearNumber(valuePair[0])
            }
        case 'half':
            return {
                type: 'half-yearly',
                period_settled: 'h' + valuePair[1],
                year: getYearNumber(valuePair[0])
            }
        default:
            return {
                type: 'none',
                period_settled: '-',
                year: getYearNumber(valuePair[0])
            }
        }
    } catch(error) {
        throw new Error('Cannot break period data: ' + period)
    }
}

/**
 * Just parses year string to number. If can't parse - just return null.
 *
 * @param {string} yearString
 * @returns {number}
 */
function getYearNumber(yearString) {
    try {
        return parseInt(yearString)
    } catch(error) {
        return null
    }
}

var _ = require('lodash')

module.exports = Em.Route.extend(require('../mixins/dirty-route'), {
    api: Em.inject.service(),

    beforeModel: function(transition) {
        var self = this
        var invoiceId = transition.params.invoice.invoice_id
        var documentType = transition.params.external_invoice.document_type

        this.get('api').request('GET', '/v2/externalInvoices/' + invoiceId)
            .then(function(response) {
                var externalInvoice = response.externalInvoice
                var params = {}

                if (externalInvoice && externalInvoice.externalId) {
                    params = {
                        queryParams: _.assign(
                            { initialRoute: '/' + externalInvoice.externalId + '/' + documentType }
                        )
                    }
                }

                self.transitionTo('invoices.index', params)
            })
    }
})

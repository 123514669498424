module.exports = Em.Mixin.create({
    template: require('../templates/option'),

    classNames: ['option'],

    classNameBindings: ['isHighlighted:highlighted'],

    //Default for lazy list items, is overridden when used as grouped
    selector: Em.computed.oneWay('parentView.parentView'),

    isHighlighted: function() {
        var content = this.get('controller.content') || this.get('content');
        if (this.get('hasRawResult')) {
            // TODO: Expose a field attribute that allows matching by other than "id".
            var contentId = _.get(content, 'id')
            var highlightedOptionId = _.get(this.get('selector.highlightedOption'), 'id')
            return contentId && highlightedOptionId && contentId === highlightedOptionId;
        } else {
            return (content.get('isLoaded') !== false && content === this.get('selector.highlightedOption'));
        }
    }.property('selector.highlightedOption'),

    mouseUp: function() {
        var content = this.get('controller.content') || this.get('content');
        this.get('selector').selectOption(content);
    },

    mouseEnter: function() {
        var content = this.get('controller.content') || this.get('content');
        var selector = this.get('selector');
        selector.set('highlightedOption', content);
        selector.set('createIsHighlighted', false);
    }
});

module.exports = Em.Object.extend({
    isLoaded: false,

    data: null,

    api: Em.inject.service(),

    userOrganizations: Em.inject.service(),

    organization: Em.computed.oneWay('userOrganizations.activeOrganization'),

    invalidate: function() {
        this.set('isLoaded', false)
        this.set('data', null)
        this._loadPromise = null
    }.observes('organization'),

    cleanRegistrationNo: function() {
        var registrationNo = this.get('organization.registrationNo')
        if (registrationNo) {
            return registrationNo.toString().replace(/\s/g, '').toUpperCase()
        }

        return ''
    },

    getCvrSearchUrl: function(q) {
        var apiCountryKey = this.get('organization.apiCountryKey')

        return '/cvr/' + apiCountryKey + '/search?q=' + q
    }.observes('organization.apiCountryKey'),

    getCvrCompaniesUrl: function(registrationNo) {
        var apiCountryKey = this.get('organization.apiCountryKey')

        return '/cvr/' + apiCountryKey + '/companies/' + registrationNo
    }.observes('organization.apiCountryKey'),

    load: function() {
        var self = this
        var registrationNo = this.cleanRegistrationNo()

        this._loadPromise = new Em.RSVP.Promise(function(resolve, reject) {
            if (!registrationNo) {
                resolve({ data: null })
                return
            }

            return self.get('api')
                .request('GET', self.getCvrCompaniesUrl(registrationNo))
                .then(resolve)
                .catch(function(e) {
                    if (e.code === 'E_NOT_FOUND') {
                        return resolve({ data: null })
                    }

                    return reject(e)
                })
        }).then(function(body) {
            var data = Em.Object.create(body.data)

            self.set('isLoaded', true)
            self.set('data', data)

            return data
        })

        return this._loadPromise
    },

    loadSafe: function() {
        return this.load()
            .catch(function(e) {
                // CVR load failed, but will not throw an error
            })
    },

    ensureLoaded: function() {
        return this._loadPromise || this.load()
    }
})

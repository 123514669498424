var BILLING_TYPES = require('../billingTypes')

module.exports = Em.Object.extend({

    userOrganizations: Em.inject.service(),

    organizationBillingType: Em.computed.oneWay('userOrganizations.activeOrganization.billingType'),

    organizationCardSubscription: Ember.inject.service(),

    billingCardSubscription: Ember.inject.service(),

    organizationSubscription: Ember.inject.service(),

    billingSubscription: Ember.inject.service(),

    subscribeCard: function(params, forceExternal) {
        if (this.get('organizationBillingType') === BILLING_TYPES.EXTERNAL || forceExternal) {
            return this.get('billingCardSubscription').subscribeCard(params)
        } else {
            return this.get('organizationCardSubscription').subscribeWithQuickPay(params)
        }
    }

})

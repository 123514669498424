var AuthorizedRoute = require('../mixins/authorized-route')
var Scope = require('../utils/scope')

module.exports = Em.Route.extend(require('../mixins/dirty-route'), AuthorizedRoute, {
    scopes: [Scope.InvoiceWrite],

    agerasLoan: Em.inject.service(),

    userOrganizations: Em.inject.service(),

    organization: Em.computed.alias('userOrganizations.activeOrganization'),

    model: function(params) {
        var organization = this.modelFor('organization')
        var invoice = Billy.Invoice.createRecord({
            organization: organization,
            type: 'invoice',
            state: 'draft',
            entryDate: moment(),
            currency: organization.get('baseCurrency'),
            templateId: organization.get('defaultTemplateId'),
            taxMode: organization.get('defaultTaxMode')
        })

        // Duplicate something?
        var duplicatePromise = null
        if (params.fromInvoiceId) {
            duplicatePromise = Billy.Invoice.find(params.fromInvoiceId).promise
        } else if (params.fromQuoteId) {
            var query = {
                id: params.fromQuoteId,
                organization: organization.get('id')
            }
            duplicatePromise = this.store.find('quote', query)
                .then(function(quotes) {
                    if (quotes.get('length') === 0) {
                        throw new Error('The quote was not found.')
                    }
                    return quotes.objectAt(0)
                })
        }
        if (duplicatePromise) {
            // If we need to duplicate a quote/invoice we load it first and then copy all its values
            return duplicatePromise
                .then(function(duplicate) {
                    invoice.setProperties(duplicate.getProperties('contact', 'attContactPerson', 'taxMode', 'currency', 'templateId', 'orderNo'))
                    if (duplicate instanceof Billy.Invoice) {
                        invoice.setProperties(duplicate.getProperties('type', 'contactMessage', 'paymentTermsMode', 'paymentTermsDays'))
                        if (params.fromCreditNote) {
                            invoice.setProperties({
                                type: 'invoice'
                            })
                        }
                        return duplicate.get('lines').promise
                    } else {
                        invoice.setProperties({
                            contactMessage: duplicate.get('headerMessage')
                        })
                        return duplicate.get('lines')
                    }
                })
                .then(function(foundLines) {
                    // "Map" to simple array
                    var lines = []
                    foundLines.forEach(function(line) {
                        lines.push(line)
                    })
                    lines.sortBy('priority')
                    var priority = 1
                    lines.forEach(function(line) {
                        var properties = line.getProperties('product', 'description', 'quantity', 'unitPrice', 'taxRate', 'discountText', 'discountMode', 'discountValue')
                        properties.invoice = invoice
                        properties.priority = priority++

                        if (line instanceof Billy.InvoiceLine) {
                            // No special logic for invoiceline -> invoiceline
                        } else {
                            // Convert discount properties for quoteline -> invoiceline
                            if (properties.discountMode === 'none') {
                                properties.discountMode = null
                            } else if (properties.discountMode === 'percent') {
                                properties.discountValue *= 100
                            }
                            if (properties.discountValue === 0) {
                                properties.discountValue = null
                            }
                        }

                        Billy.InvoiceLine.createRecord(properties)
                    })

                    return Em.RSVP.hash({
                        invoice: invoice
                    })
                })
        }

        if (params.newContactId) {
            var contact = Billy.Contact.find(params.newContactId)

            invoice.set('contact', contact)

            if (contact.get('currency')) {
                invoice.set('currency', contact.get('currency'))
            }
        }

        Billy.InvoiceLine.createRecord({
            invoice: invoice,
            quantity: 1,
            priority: 1
        })

        return Em.RSVP.hash({
            invoice: invoice
        })
    },

    setupController: function(controller, model) {
        controller.set('model', model.invoice)
        controller.set('financingEnabled', false)
        model.invoice.resetClean()
        controller.set('isApproveInvoiceInfoDismissed', this.get('organization.settings.isApproveInvoiceInfoDismissed') === '1')
    },

    resetController: function(controller) {
        controller.set('newContactId', null)
        controller.set('fromInvoiceId', null)
        controller.set('fromQuoteId', null)
    }
})

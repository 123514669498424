var omit = require('lodash').omit

var BLACKLISTED_ORGANIZATION_SETTINGS_PROPERTIES = [
    'featureAnnualReport',
    'featureQuotes'
]

module.exports = function(organizationSettingsData) {
    return omit(organizationSettingsData, BLACKLISTED_ORGANIZATION_SETTINGS_PROPERTIES)
}

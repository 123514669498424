module.exports = {
    start: start,
    stop: stop
}

var scrollHelper
var scrollCt
var scrollDirection
var isScrolling = false
var scrollDelta

function start(helper) {
    scrollHelper = helper
    $(document.body).on('mousemove', didMouseMove)
}

function stop() {
    $(document.body).off('mousemove', didMouseMove)
    stopScrolling()
}

function didMouseMove(e) {
    var threshold = 20
    var x = e.clientX
    var helperOffset = scrollHelper.offset()
    var helperTop = helperOffset.top
    var helperBottom = helperTop + scrollHelper.outerHeight()
    var ctSelectors = ['.bank-sync .matches', '.bank-sync .possible-subjects .subjects']
    var i
    var ct
    var offset
    var top
    var left
    var width
    var height
    var delta
    var shouldScroll = false
    for (i = 0; i < ctSelectors.length; i++) {
        ct = $(ctSelectors[i])
        offset = ct.offset()
        top = offset.top
        left = offset.left
        width = ct.outerWidth()
        height = ct.outerHeight()
        if (x >= left && x <= left + width) { // && y >= offset.top && y <= offset.top + height
            // Top
            delta = top + threshold - helperTop
            if (delta > 0) {
                scrollCt = ct
                scrollDelta = delta
                scrollDirection = 'up'
                shouldScroll = true
                break
            }
            // Bottom
            delta = helperBottom - top - height + threshold
            if (delta > 0) {
                scrollCt = ct
                scrollDelta = delta
                scrollDirection = 'down'
                shouldScroll = true
                break
            }
            break
        }
    }
    if (shouldScroll) {
        startScrolling()
    } else {
        stopScrolling()
    }
}

function startScrolling() {
    if (isScrolling) {
        return
    }
    isScrolling = true
    scroll()
}

function scroll() {
    var scrollTop = scrollCt.scrollTop() + (scrollDirection === 'down' ? 1 : -1) * Math.pow(scrollDelta, 1.5)
    scrollCt.animate({
        scrollTop: scrollTop
    }, {
        easing: 'linear',
        duration: 100,
        complete: function() {
            if (isScrolling) {
                scroll()
            }
        }
    })
}

function stopScrolling() {
    if (!isScrolling) {
        return
    }
    isScrolling = false
    scrollCt.stop()
    scrollCt = null
    scrollDelta = null
    scrollDirection = null
}

module.exports = Em.Route.extend({
    userUmbrellas: Em.inject.service(),

    sideMenu: Ember.inject.service(),

    model: function(params) {
        this.get('sideMenu').deactivate()

        var routeArray = window.location.pathname.split('/')
        var umbrellaIndex = routeArray.findIndex(function(item) {
            return item === 'umbrellas'
        })
        var umbrellaId = params.umbrella_id || routeArray[umbrellaIndex + 1]

        return this.get('api').getData('/umbrellas/' + umbrellaId)
    },

    setupController: function(controller, umbrella) {
        controller.set('model', umbrella)
        this.get('sideMenu').activateUmbrella(umbrella)
    }
})

var Scope = require('../utils/scope')

module.exports = Em.Route.extend({
    auth: Em.inject.service(),

    beforeModel: function() {
        this.isRouteAuthorized()
    },

    isRouteAuthorized: function() {
        var isAuthorized = this.get('auth').isAuthorized(Scope.ServicesRead)
        var isAuthorizedLegacy = this.get('auth').isAuthorized(Scope.OrganizationFeatureWrite, true)

        // @todo doubled scopes-check
        if (!isAuthorized && !isAuthorizedLegacy) {
            // TODO: Show relevant error message, redirect user??
            throw new Error('Unauthorized')
        }
    }
})

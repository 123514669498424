var billyApi = require('./billy-api')
var ValueResolver = require('./value-resolver')

var ContactBalance = module.exports = Em.Object.extend({
    contact: null,

    currency: null,

    sideAmountResolver: function() {
        var contact = this.get('contact')
        var currency = this.get('currency')
        if (!contact || !currency) {
            return null
        }
        return ValueResolver.create({
            resolver: function() {
                return new Em.RSVP.Promise(function(resolve, reject) {
                    billyApi.get('/contacts/' + contact.get('id') + '/balances/' + currency.get('id'), {
                        success: function(payload) {
                            var balance = payload.balance
                            resolve(new Billy.SideAmount(balance.amount, balance.side))
                        },
                        error: reject
                    })
                })
            }
        })
    }.property('contact'),

    isLoaded: Em.computed.alias('sideAmountResolver.isLoaded'),

    sideAmount: Em.computed.alias('sideAmountResolver.value'),

    amount: Em.computed.alias('sideAmount.amount'),

    side: Em.computed.alias('sideAmount.side'),

    then: function(resolve, reject) {
        var self = this
        return this.get('sideAmountResolver')
            .then(function() {
                resolve(self)
            }, reject)
    }
})

ContactBalance.reopenClass({
    load: function(contact, currency) {
        return ContactBalance.create({
            contact: contact,
            currency: currency
        })
    }
})

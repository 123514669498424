var qs = require('qs')

function getHashQueryParams() {
    var hash = window.location.hash
    var hashQueryIndex = hash.indexOf('?')
    var hashQuery = hashQueryIndex !== -1 ? hash.substring(hashQueryIndex + 1) : ''
    var hashQueryParams = {}

    if (hashQuery) {
        hashQueryParams = qs.parse(hashQuery)
    }

    return hashQueryParams
}

module.exports = getHashQueryParams

var model = BD.Model.extend({
    organization: BD.belongsTo('Billy.Organization'),
    type: BD.attr('string'),
    contactNo: BD.attr('string'),
    createdTime: BD.attr('datetime', { readonly: true }),
    name: BD.attr('string'),
    street: BD.attr('string'),
    city: BD.belongsTo('Billy.City'),
    cityText: BD.attr('string'),
    state: BD.belongsTo('Billy.State'),
    stateText: BD.attr('string'),
    zipcode: BD.belongsTo('Billy.Zipcode'),
    zipcodeText: BD.attr('string'),
    country: BD.belongsTo('Billy.Country'),
    phone: BD.attr('string'),
    fax: BD.attr('string'),
    currency: BD.belongsTo('Billy.Currency'),
    registrationNo: BD.attr('string'),
    ean: BD.attr('string'),
    isCustomer: BD.attr('boolean'),
    isSupplier: BD.attr('boolean'),
    paymentTermsMode: BD.attr('string'),
    paymentTermsDays: BD.attr('number'),
    isArchived: BD.attr('boolean'),
    locale: BD.belongsTo('Billy.Locale'),
    contactPersons: BD.hasMany('Billy.ContactPerson', 'contact', { isEmbedded: true }),
    accessCode: BD.attr('string'),
    emailAttachmentDeliveryMode: BD.attr('string'),
    commentAssociations: BD.hasMany('Billy.CommentAssociation', 'owner'),
    isSalesTaxExempt: BD.attr('boolean'),
    defaultTaxRate: BD.belongsTo('Billy.TaxRate'),
    defaultExpenseAccount: BD.belongsTo('Billy.Account'),
    defaultExpenseProductDescription: BD.attr('string'),
    invoicingLanguage: BD.attr('string')
})

model.reopenClass({
    qProperties: ['name', 'contactNo', 'street', 'city.name', 'cityText', 'zipcode.zipcode', 'zipcodeText', 'phone', 'fax', 'registrationNo', 'ean']
})

module.exports = model

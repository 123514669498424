var customEvent = require('../constants/customEvent')

module.exports = Em.Route.extend({
    model: function(params) {
        var result
        if (params.error) {
            result = { type: 'error', message: decodeURIComponent(params.error_description.replace(/\+/g, '%20')) }
        } else {
            result = { type: 'success', code: params.code }
        }
        window.addEventListener('message', function(event) {
            if (event.data.message === customEvent.StripeIntegrationRequestResult) {
                // event.source is the opener
                event.source.postMessage({ message: customEvent.StripeIntegrationDeliverResult, result: result }, '*')
            }
        })
        return new Em.RSVP.Promise(function() {
            // Never resolve
        })
    }
})

module.exports = Em.View.extend({
    templateName: 'templates/template-thumb-constrained',

    classNames: ['template'],

    constraints: Ember.inject.service(),

    click: function() {
        this.get('constraints').showUpgradeOverlay('', 'invoice_template')
    },

    actions: {
        upgradeToPremium: function() {
            this.get('constraints').showUpgradeOverlay('', 'invoice_template')
        }
    }
})

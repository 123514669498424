var Scope = require('../utils/scope')
var AuthorizedRoute = require('../mixins/authorized-route')

module.exports = Em.Route.extend(AuthorizedRoute, {
    scopes: [Scope.ContactRead],

    resetController: function(controller) {
        controller.set('importStats', null)
    }
})

var model = BD.Model.extend({
    contact: BD.belongsTo('Billy.Contact', { isParent: true }),
    isPrimary: BD.attr('boolean'),
    firstName: BD.attr('string'),
    lastName: BD.attr('string'),
    name: BD.attr('string'),
    email: BD.attr('string')
})

model.reopenClass({
    qProperties: ['firstName', 'lastName', 'name', 'email']
})

module.exports = model

var AuthorizedRoute = require('../mixins/authorized-route')
var Scope = require('../utils/scope')

module.exports = Em.Route.extend(AuthorizedRoute, {
    scopes: [Scope.InvoiceRead],

    auth: Ember.inject.service(),

    organizationFeatures: Em.inject.service(),

    queryParams: {
        category: {
            replace: true
        }
    },

    setupController: function(controller) {
        controller.set('isSourceZervant', this.get('auth').getIsSourceZervant())
    }
})

var durationIsoToMoment = {
    D: 'd',
    W: 'w',
    M: 'M',
    Y: 'y'
}

module.exports = Iso8601RepeatingInterval

function Iso8601RepeatingInterval(props) {
    this.valid = props.valid
    this.start = parseMoment(props.start)
    this.end = parseMoment(props.end)
    this.repetitions = props.repetitions
    this.durationCount = props.durationCount
    this.durationUnit = props.durationUnit

    if (this.end != null && this.repetitions == null) {
        var d = moment(this.start)
        this.repetitions = 0
        while (!d.isAfter(this.end)) {
            d.add(this.durationCount, durationIsoToMoment[this.durationUnit])
            this.repetitions++
        }
    }

    if (this.repetitions != null && this.end == null) {
        this.end = moment(this.start).add((this.repetitions - 1) * this.durationCount, durationIsoToMoment[this.durationUnit])
    }
}

Iso8601RepeatingInterval.parse = function(input) {
    var props = {
        valid: true
    }

    if (typeof input !== 'string') {
        return new Iso8601RepeatingInterval({ valid: false })
    }

    var match = input.match(/^R(\d*)\/([^\/]+)\/P(\d+)([a-z])$/i)
    if (!match) {
        return new Iso8601RepeatingInterval({ valid: false })
    }

    var repetitions = match[1]
    var start = match[2]
    var durationCount = match[3]
    var durationUnit = match[4]

    if (repetitions) {
        props.repetitions = parseInt(repetitions, 10)
    } else {
        props.repetitions = null
    }

    props.start = moment.utc(start)
    if (!props.start.isValid()) {
        return new Iso8601RepeatingInterval({ valid: false })
    }

    props.durationCount = parseInt(durationCount, 10)
    props.durationUnit = durationUnit.toUpperCase()
    if (['Y', 'M', 'W', 'D'].indexOf(props.durationUnit) === -1) {
        return new Iso8601RepeatingInterval({ valid: false })
    }

    return new Iso8601RepeatingInterval(props)
}

Iso8601RepeatingInterval.prototype.isValid = function() {
    return this.valid
}

Iso8601RepeatingInterval.prototype.serialize = function() {
    return 'R' + (this.repetitions != null ? this.repetitions : '') + '/' + this.start.format() + '/P' + this.durationCount + this.durationUnit
}

function parseMoment(input) {
    if (!input) {
        return null
    }
    if (typeof input === 'string') {
        return moment.utc(input)
    }
    return input
}

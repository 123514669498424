var tracking = require('../utils/tracking')
var moment = require('momentjs')
var capitalize = require('../utils/capitalize')

module.exports = Em.Object.extend({
    api: Em.inject.service(),

    isLoaded: function() {
        return this.get('data') !== null
    },

    data: null,

    user: function() {
        return this.container.lookup('controller:user')
    }.property(),

    userOrganizations: Em.inject.service(),

    annualReport: Em.inject.service(),

    organization: Em.computed.alias('userOrganizations.activeOrganization'),

    organizationSubscription: Em.inject.service(),

    invalidate: function() {
        this.set('data', null)
    }.observes('organization'),

    load: function() {
        var self = this

        this._loadPromise = this.get('api').getData('/quickpay/organizations/' + this.get('organization').get('id') + '/subscriptions')
            .then(function(payload) {
                self.set('data', payload)
            }, function() {
                self.set('data', null)
            })

        return this._loadPromise
    },

    loadSafe: function() {
        return this.load()
            .catch(function(e) {
                // Card subscriptions load failed, but will not throw an error
            })
    },

    ensureLoaded: function() {
        return this._loadPromise || this.load()
    },

    getContent: function() {
        if (!this.get('isLoaded')) {
            throw new Error('Card subscriptions has not been loaded yet.')
        }

        return Ember.copy(this.get('data'), true)
    },

    isApplicable: function() {
        return Ember.keys(this.get('data') || {}).length > 0
    },

    getPaymentVariables: function(plan, period, cardOnly, billingStart, price) {
        var organization = this.get('organization')
        var isEnglish = this.get('user.quickpayLanguage') === 'en'
        var billingStartDate = moment(billingStart).format('D MMM YYYY') || moment(new Date()).format('D MMM YYYY')
        var subscriptionDiscount = organization.get('subscriptionDiscount')
        var subscriptionPeriodEn = period || organization.get('subscriptionPeriod')
        var subscriptionPeriodDa = subscriptionPeriodEn === 'yearly' ? 'årligt' : 'månedligt'
        var subscriptionPlan = plan || organization.get('subscriptionPlan')
        var monthlyPrice = this.get('organizationSubscription').getSubscriptionPriceMonthly(subscriptionPlan)
        var yearlyPrice = this.get('organizationSubscription').getSubscriptionPriceYearly()
        var subscriptionPrice = subscriptionPeriodEn === 'yearly' ? yearlyPrice : monthlyPrice
        var isYearly = subscriptionPeriodEn === 'yearly'
        var amountWithCouponApplied = price || 0
        var amountExVat = amountWithCouponApplied
        var amountExVatFormatted = Billy.money(amountExVat, organization.get('subscriptionCurrency'))
        var vat = !organization.get('isDk') && organization.get('registrationNo') ? 0 : amountExVat * 0.25
        var vatFormatted = Billy.money(vat, organization.get('subscriptionCurrency'))
        var totalAmount = amountExVat + vat
        var totalAmountFormatted = Billy.money(totalAmount, organization.get('subscriptionCurrency'))
        var amountPerPeriodEn = isYearly ? '1 year' : '1 month'
        var amountPerPeriodDa = isYearly ? '1 år' : '1 måned'
        var subscriptionPeriod = isEnglish ? subscriptionPeriodEn : subscriptionPeriodDa
        var providerTheme = ENV.theme

        var amountPerPeriod = isEnglish ? amountPerPeriodEn : amountPerPeriodDa

        var paymentVariables = {
            amount: amountWithCouponApplied,
            billingStart: billingStartDate,
            period: subscriptionPeriodEn || '',
            subscriptionDiscount: subscriptionDiscount,
            subscriptionPeriod: capitalize(subscriptionPeriod),
            subscriptionPlan: capitalize(subscriptionPlan),
            subscriptionPrice: subscriptionPrice,
            amountExVat: amountExVat,
            amountExVatFormatted: amountExVatFormatted,
            vat: vat,
            vatFormatted: vatFormatted,
            totalAmount: totalAmount,
            totalAmountFormatted: totalAmountFormatted,
            amountPerPeriod: amountPerPeriod,
            cardOnly: !!cardOnly,
            productName: '',
            providerTheme: providerTheme
        }

        return paymentVariables
    },

    getPaymentContinueUrl: function(options, orgUrl) {
        if (orgUrl) {
            return window.location.origin + '/' + orgUrl + '/settings/subscription/?payment_successful=1'
        }
        var url = window.location.href
        var continueUrl = options.continueUrl

        if (!continueUrl) {
            continueUrl = url + (url.indexOf('?') > 0 ? '&' : '?') + 'payment_successful=1'
        } else {
            continueUrl = continueUrl + '?payment_successful=1'
        }

        return continueUrl
    },

    getPaymentCancelUrl: function(options) {
        var url = window.location.href
        var cancelUrl = options.cancelUrl

        if (!cancelUrl) {
            cancelUrl = url + (url.indexOf('?') > 0 ? '&' : '?') + 'payment_cancelled=1'
        } else {
            cancelUrl = cancelUrl + '?payment_cancelled=1'
        }

        return cancelUrl
    },

    createCardSubscription: function(params) {
        var organizationId = this.get('organization.id')
        var url = '/integrations/billing/' + organizationId + '/subscribe'

        return this.get('api').postData(url, params, { raw: true })
    },

    startSubscription: function(params) {
        var organizationId = this.get('organization.id')
        var url = '/integrations/billing/' + organizationId + '/subscribe/start'

        return this.get('api').postData(url, params, { raw: true })
    },

    subscribeCard: function(paymentOptions) {
        var organization = this.get('organization')
        var organizationId = organization.get('id')
        var organizationURL = organization.get('url')
        var options = paymentOptions || {}
        var subscription = this.get('organizationSubscription')
        var subscriptionExpires = subscription && subscription.get('data.organization.subscriptionExpires')
        var continueUrl = this.getPaymentContinueUrl(options, organizationURL)
        var cancelUrl = this.getPaymentCancelUrl(options)
        var saveHistory = options.saveHistory
        var plan = options.plan
        var price = options.price
        var period = options.period
        var cardOnly = options.cardOnly ? options.cardOnly : true
        var billingStart = subscriptionExpires && moment(subscriptionExpires).isAfter(new Date()) ? subscriptionExpires : new Date()
        var self = this
        var variables = this.getPaymentVariables(plan, period, cardOnly, billingStart, price)

        tracking.emitAnalyticsEvent('credit_card', 'credit_card_submission_started')

        var cardSubscriptionParams = {
            currency: organization.get('subscriptionCurrency').get('id'),
            organizationId: organizationId,
            amount: variables.amount,
            language: self.get('user.quickpayLanguage'),
            continueUrl: continueUrl,
            cancelUrl: cancelUrl,
            variables: JSON.stringify(variables),
            plan: plan,
            period: period,
            startSubscription: plan && period ? 1 : 0,
            reusePaymentMethod: plan && period ? 1 : 0,
            couponCode: options.couponCode
        }

        return this.createCardSubscription(cardSubscriptionParams).then(function(subscriptionResponse) {
            if (subscriptionResponse.paymentRequired) {
                if (saveHistory) {
                    window.location = subscriptionResponse.url
                } else {
                    window.location.replace(subscriptionResponse.url)
                }
            } else {
                window.location = continueUrl
            }
        })
    },

    calculateTotalAmountForAddons: function(addons) {
        return addons.reduce(function(acc, obj) { return acc + obj.unitPrice }, 0)
    },

    purchaseAddons: function(addons, continueUrl) {
        var self = this
        var organization = this.get('organization')
        var annualReportService = this.get('annualReport')
        var url = '/integrations/billing/' + organization.get('id') + '/addons/buy'
        var amount = this.calculateTotalAmountForAddons(addons)
        var providerTheme = ENV.theme

        var payload = {
            currency: organization.get('subscriptionCurrency').get('id'),
            organizationId: organization.get('id'),
            amount: amount,
            language: self.get('user.quickpayLanguage'),
            continueUrl: continueUrl || self.getPaymentContinueUrl({}),
            cancelUrl: self.getPaymentCancelUrl({}),
            variables: JSON.stringify({ cardOnly: true, providerTheme: providerTheme }),
            addons: annualReportService.applyCouponPassedFromUrl(addons)
        }

        return this.get('api').request('POST', url, {
            payload: payload
        })
            .then(function(purchaseResult) {
                if (purchaseResult.paymentRequired) {
                    window.location = purchaseResult.url
                }
                return purchaseResult
                // if not required, just proceed
            })
    },

    makeDefault: function(subscriptionId) {
        var self = this
        var organization = this.get('organization')
        var organizationId = organization.get('id')

        return self.quickpayUpdate(organizationId, subscriptionId, { isDefault: true })
    },

    cancel: function(subscriptionId) {
        var self = this
        var organization = this.get('organization')
        var organizationId = organization.get('id')

        return self.quickpayCancel(organizationId, subscriptionId)
    }
})

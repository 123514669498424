var invoice = BD.Model.extend({
    organization: BD.belongsTo('Billy.Organization'),
    externalId: BD.attr('string', { readonly: true }),
    contact: BD.belongsTo('Billy.Contact'),
    type: BD.attr('string', { readonly: true }),
    referencedExternalInvoice: BD.belongsTo('Billy.ExternalInvoice'),
    creditNotes: BD.hasMany('Billy.ExternalInvoice', 'referencedExternalInvoice'),
    description: BD.attr('string', { readonly: true }),
    entryDate: BD.attr('date', { readonly: true }),
    dueDate: BD.attr('datetime', { readonly: true }),
    currency: BD.belongsTo('Billy.Currency'),
    exchangeRate: BD.attr('number', { readonly: true }),
    amount: BD.attr('number', { readonly: true }),
    balance: BD.attr('number', { readonly: true }),
    number: BD.attr('string', { readonly: true }),
    isVoided: BD.attr('boolean', { readonly: true }),
    createdTime: BD.attr('datetime', { readonly: true }),
    modifiedTime: BD.attr('datetime', { readonly: true }),
    balanceModifiers: BD.hasMany('Billy.BalanceModifier', 'subject')
})

invoice.reopenClass({
    qProperties: [
        'number',
        'amount',
        'balance',
        'contact.name'
    ]
})

module.exports = invoice

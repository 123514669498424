module.exports = Em.View.extend({
    templateName: 'bill/edit',

    classNames: ['bill-edit-view'],

    onKeyDown: function(event) {
        if (event.keyCode === 13 && (event.metaKey || event.ctrlKey)) {
            this.get('controller').send('approve')
        }
    },

    onKeyDownBound: null,

    didInsertElement: function() {
        this.sizeElements()
        this.get('controller').on('lineAdded', this, this.onLineAdded)
        this.get('controller').addObserver('lines.length', this, this.linesLengthDidChange)
        this.$(':input:not(button):first').focus()
        this.set('onKeyDownBound', this.onKeyDown.bind(this))

        // We want the tab order to go from the last input field in the form to the first button in the top, and back with shift + Tab
        // I couldn't find a nice way to do it with tabindex without adding tabindex to _everything_ on the page, so I didn't do that.
        this.$('.section-header button:first').on('keydown', this.onFirstButtonKeydown.bind(this))
        this.$().delegate('input', 'keydown', this.onInputKeydown.bind(this))
        document.addEventListener('keydown', this.onKeyDownBound)
    },

    willDestroyElement: function() {
        this.get('controller').removeObserver('lines.length', this, this.linesLengthDidChange)
        this.get('controller').off('lineAdded', this, this.onLineAdded)
        this.set('onKeyDownBound', null)
        document.removeEventListener('keydown', this.onKeyDownBound)
    },

    linesLengthDidChange: function() {
        Em.run.scheduleOnce('afterRender', this, this.sizeElements)
    },

    sizeElements: function() {
        if (this.get('isDestroying')) {
            return
        }

        var mainHeight = document.querySelector('.user-main').offsetHeight
        var sectionBody = document.querySelector('.section-bill-lines')
        var headerHeight = document.querySelector('.bill-edit-view .section-header').offsetHeight
        var attachments = document.querySelector('.bill-edit-attachments')
        var maxFormHeight = Math.round((mainHeight - headerHeight) / 2)
        var sectionBodyStyle = window.getComputedStyle(sectionBody)
        var formHeight = Math.min(maxFormHeight, sectionBody.querySelector('.content-width').offsetHeight + 1 * sectionBodyStyle.getPropertyValue('padding-top').replace('px', '') + 1 * sectionBodyStyle.getPropertyValue('padding-bottom').replace('px', ''))
        sectionBody.style.height = formHeight + 'px'
        attachments.style.top = (headerHeight + formHeight) + 'px'

        /*
        <hack> for Cheome
        It seems that a rendering bug was introduced somewhere around Chrome v41. Safari or Firefox was not affected.
        When clicking draft bills from the /bills route (the overview), the large size attachment thumbnail would not appear.
        When inspecting the DOM, all the elements were clearly there, and was styled as they were supposed to.
        The image would appear if the user resized the window, or if you changed any css property in Dev Tools.
        It worked when accessing a bill directly through the URL (or by reloading the page)
        These lines forces Chrome to perform a layout, which fixes the problem.
        Reported by a user here: https://billysbilling.desk.com/agent/case/18926
        Fix source: http://stackoverflow.com/a/3485654/575896
        */
        attachments.style.display = 'none'
        // eslint-disable-next-line no-unused-expressions
        attachments.offsetHeight // no need to store this anywhere, the reference is enough
        attachments.style.display = ''
        // </hack>
    },

    onLineAdded: function() {
        var sectionBody = this.$('.section-body')

        Em.run.scheduleOnce('afterRender', this, this.focusLastLine)
        Em.run.scheduleOnce('afterRender', this, function() {
            sectionBody.scrollTop(sectionBody.outerHeight())
        })
    },

    focusLastLine: function() {
        this.$('.line:last() input:first()').focus()
    },

    onFirstButtonKeydown: function(e) {
        if (e.keyCode === 9 && e.shiftKey) { // Tab
            e.preventDefault()
            this.$('.lines input').last().focus()
        }
    },

    onInputKeydown: function(e) {
        if (e.keyCode === 9 && !e.shiftKey) { // Tab
            if (e.target === this.$('.lines input').last()[0]) {
                // This is the last input in the form, so we want to tab to the Approve button
                e.preventDefault()
                $('.section-header button').first().focus()
            }
        }
    }
})

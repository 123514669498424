module.exports = Em.Route.extend({
    model: function(params) {
        var token = params.token
        return Em.RSVP.hash({
            token: token
        })
    },

    setupController: function(controller, model) {
        controller.set('token', model.token)
    }
})

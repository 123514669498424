module.exports = Em.CollectionView.extend({
    classNames: ['table-item'],

    createChildView: function(viewClass, attrs) {
        var column = attrs.content
        var view = this._super(column.get('headerCellViewClass'), {
            column: column,
            content: this.get('parentView.context'),
            contentIndex: attrs.contentIndex
        })
        return view
    }
})

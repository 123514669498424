var ListController = require('billy-webapp/ui/list/controller')

module.exports = ListController.extend({
    queryParams: {
        date: 'date',
        page: 'page',
        pageSize: 'pageSize',
        searchQuery: 'searchQuery',
        sortDirection: 'sortDirection',
        sortProperty: 'sortProperty'
    },

    date: null,
    page: null,
    pageSize: null,
    searchQuery: null,
    sortDirection: null,
    sortProperty: null,

    needs: ['user'],

    organization: Em.computed.alias('controllers.user.activeOrganization'),

    customActions: {
        navigate: function(e) {
            var route = e.detail.route
            var props = e.detail.props || { queryParams: {} }
            this.transitionToRoute(route, props)
        }
    }
})

var _ = require('lodash')
var moment = require('momentjs')
var RecurringInvoice = require('../models/recurring-invoice')
var Quote = require('../models/quote')

var paths = {
    'Billy.Invoice': [
        'contact',
        'currency',
        'organization'
    ],
    'Billy.InvoiceReminder': [
        'contact',
        'currency',
        'organization'
    ],
    'Billy.Contact': [
        'city',
        'state',
        'zipcode'
    ]
}

module.exports = serialize

function serialize(model) {
    var serialized = {}
    var explorePaths = paths[model.constructor.toString()]

    if (model instanceof RecurringInvoice || model instanceof Quote) {
        explorePaths = paths['Billy.Invoice']
    }

    // Add the ID since it's not a property
    serialized.id = model.get('id')

    // Transform model
    model.eachAttribute(function(key, meta) {
        var value = model.get(key)
        if (value) {
            switch (meta.type) {
            case 'date':
                // Make sure that the date is in the local timezone, so date formatting will work
                value = moment(value.format('YYYY-MM-DD')).toDate()
                break
            case 'datetime':
                value = value.toDate()
                break
            default:
            }
        }
        serialized[key] = value
    })

    // Recurse, if necessary
    if (explorePaths && explorePaths.length) {
        _.each(explorePaths, function(path) {
            var subModel = model.get(path)
            if (subModel) {
                serialized[path] = serialize(subModel)
            }
        })
    }
    return serialized
}

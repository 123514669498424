module.exports = Em.View.extend({
    templateName: 'ui/list/actions',

    classNames: ['list-actions'],

    currentContext: null,

    willDestroyElement: function() {
        this._super()
        // Hacky hack, because in Ember 1.8 it won't remove the element when we move it around (which is done in body-view-mixin.js)
        var el = this.get('element')
        if (el) {
            el.parentNode.removeChild(el)
        }
    }
})

var model = BD.Model.extend({
    name: BD.attr('string'),
    exchangeRate: BD.attr('number')
})

model.reopenClass({
    qProperties: ['id', 'name']
})

module.exports = model

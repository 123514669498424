$(function() {
    $(Billy.rootElement).delegate('button.dropdown', 'click', function(e) {
        var button = $(e.currentTarget)
        var group = button.parent('.button-group')
        var menu = group.find('.dropdown-menu')
        var isRight = menu.hasClass('right')
        if (menu.hasClass('open')) {
            hideMenu(menu, button)
            return
        }
        menu.addClass('open')
        button.addClass('dropdown-active')
        menu.position({
            my: isRight ? 'right top+4' : 'left top+4',
            at: isRight ? 'right bottom+4' : 'left bottom+4',
            of: button,
            offset: '0 0',
            collision: 'flip'
        })
        $(Billy.rootElement).one('mousedown', function(e) {
            if (e.within(button) || e.within(menu)) {
                return
            }
            hideMenu(menu, button)
        })
        menu.one('click', function() {
            hideMenu(menu, button)
        })
    })

    function hideMenu(menu, button) {
        menu.removeClass('open')
        button.removeClass('dropdown-active')
    }
})

var dnd = require('../utils/dnd')
var InfiniteScrollView = require('../mixins/infinite-scroll-view')

module.exports = Em.View.extend(InfiniteScrollView, {
    classNames: ['possible-subjects'],

    scrollSelector: '.subjects',

    itemHeight: 92,

    setupDroppable: function() {
        this._droppable = dnd.droppable(this.$('.subjects'), {
            accept: function(draggable) {
                var otherView = draggable.view
                return otherView instanceof require('./bank-sync-v2-match-subject-association')
            },

            drop: function(e, draggable) {
                var otherView = draggable.view
                if (otherView.get('isDestroying')) {
                    return
                }
                Ember.run(function() {
                    otherView.get('controller').send('remove')
                })
            }
        })
    }.on('didInsertElement'),

    teardownDroppable: function() {
        if (this._droppable) {
            this._droppable.destroy()
        }
    }.on('willDestroyElement')
})

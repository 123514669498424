var trackingSegmentPage = module.exports = {}

var uuidCheckRegexp = /\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\//
var noParamsRoutes = ['password_reset', 'organization-invitation']

/**
 * Condenses the route into a version that is
 * friendly for the Segment tracking Page() function
 */
trackingSegmentPage.getRouteProperty = function(router) {
    try {
        var pathname = location.pathname
        var hash = location.hash
        var firstPathSegment = pathname.split('/')[1]
        var noParamRoute = noParamsRoutes.includes(firstPathSegment)

        // if this is no param route, we should just return it's name (i.e. organization-invitation, password_reset)
        if (noParamRoute) {
            return firstPathSegment
        }

        var routeProperty = pathname.substring(pathname.indexOf('/', 1) + 1)
        var idInParams = this.getIdInParams(router.state.params)
        var idInHash = uuidCheckRegexp.test(hash)

        if (idInParams.length) {
            idInParams.forEach(function(id) {
                var idArray = id.split('.')
                var coreRoute = idArray[0]
                var paramRoute = idArray[1]
                var idToRemove = router.state.params[coreRoute][paramRoute]

                // single case in which umbrella is the first id in route, needs to be handled separately
                if (coreRoute === 'umbrella') {
                    routeProperty = routeProperty.replace(idToRemove, 'umbrellas')
                } else {
                    routeProperty = routeProperty.replace(idToRemove, 'id')
                }
            })
        }

        // detects ids in hash routes, applies to React routes like under vouchers/
        if (idInHash) {
            routeProperty = routeProperty + '/id'
        }

        return routeProperty
    } catch(err) {
        console.warn(err)
        return ''
    }
}

/**
 * Searches one level deep in object for keys which
 * end with '_id' and returns the key
 */
trackingSegmentPage.getIdInParams = function(params) {
    var keysWithId = []

    Object.keys(params).forEach(function(outerKey) {
        var innerObject = params[outerKey]
        if (typeof innerObject === 'object' && !Array.isArray(innerObject)) {
            Object.keys(innerObject).forEach(function(innerKey) {
                if (/^(.*)_id$/.test(innerKey)) {
                    keysWithId.push(outerKey + '.' + innerKey)
                }
            })
        }
    })

    return keysWithId
}

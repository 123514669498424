module.exports = DS.Transform.extend({
    model: null,

    serialize: function(records) {
        if (!records) {
            return []
        }
        return records.map(function(record) {
            return record.get('id')
        })
    },

    deserialize: function(ids) {
        var model = this.model
        if (!ids) {
            return []
        }
        return ids.map(function(id) {
            return model.find(id)
        })
    }
})

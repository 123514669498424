var t = require('i18n').t

module.exports = require('ember-super-field').types.Model.extend({
    modelClass: Billy.Contact,

    sortProperty: 'name',

    inputValuePath: 'nameWithContactNo',

    noOptionsFoundText: t('ui.fields.superfield.no_contacts_found'),

    create: function(name, callback, component) {
        var organization = this.get('userController.activeOrganization')
        var existingContact = component && component.get('contact')
        var contact = existingContact || Billy.Contact.createRecord({
            organization: organization,
            name: name,
            country: organization.get('country'),
            type: 'company'
        })

        var isCustomer = this.get('field.isCustomer')
        var isSupplier = this.get('field.isSupplier')
        if (isCustomer === true) {
            contact.set('isCustomer', isCustomer)
        }
        if (isSupplier === true) {
            contact.set('isSupplier', isSupplier)
        }

        contact.resetClean()

        this.container.lookup('component:contactEditor')
            .set('content', contact)
            .set('typesHidden', true)
            .set('callback', callback)
            .show()
    },

    optionViewClass: require('ember-super-field').GroupedOptionView.extend({
        template: Ember.Handlebars.compile('<div class="name" data-cy="customer-list-option">{{nameWithContactNo}}</div>')
    }),

    query: function() {
        var query = {
            organization: this.get('userController.activeOrganization')
        }

        var isCustomer = this.get('field.isCustomer')
        var isSupplier = this.get('field.isSupplier')
        if (isCustomer === true) {
            query.isCustomer = isCustomer
        }
        if (isSupplier === true) {
            query.isSupplier = isSupplier
        }
        query.isArchived = false
        return query
    }.property('userController.activeOrganization', 'field.isCustomer', 'field.isSupplier')
})

var BillyApiClient = require('billy-api-client')
var i18n = require('i18n')

module.exports = new BillyApiClient({
    requestAdapter: {
        send: function(hash) {
            var req
            var p = new Em.RSVP.Promise(function(resolve, reject) {
                var complete = hash.complete
                hash.complete = function() {
                    var args = arguments
                    Em.run(function() {
                        if (complete) {
                            complete.apply(hash.context, args)
                        }
                    })
                }
                var success = hash.success
                hash.success = function() {
                    var args = arguments
                    Em.run(function() {
                        if (success) {
                            success.apply(hash.context, args)
                        }
                    })
                }
                var error = hash.error
                hash.error = function() {
                    var args = arguments
                    Em.run(function() {
                        if (error) {
                            error.apply(hash.context, args)
                        }
                    })
                }

                req = $.ajax(hash)
                req
                    .then(function(payload) {
                        Em.run(function() {
                            resolve(payload)
                        })
                    }, function(xhr, textStatus, errorThrown) {
                        Em.run(function() {
                            var e = new Error('Got ' + textStatus + ' for ' + hash.type + ' ' + hash.url)
                            e.name = 'BillyApiError'
                            e.xhr = xhr
                            e.textStatus = textStatus
                            e.errorThrown = errorThrown
                            reject(e)
                        })
                    })
            })
            p.abort = function() {
                req.abort()
            }
            return p
        }
    },
    apiUrl: ENV.isTest ? '/v2' : ENV.apiUrl,
    storageAdapter: new BillyApiClient.storageAdapters.CookieStorageAdapter({
        namespace: ENV.isTest ? 'billy_api_TEST' : 'billy_api',
        secure: window.location.protocol !== 'http:'
    }),
    language: i18n.locale()
})

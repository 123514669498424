var t = require('i18n').t

module.exports = Em.Route.extend({
    model: function() {
        var invoice = this.modelFor('invoice')
        var creditNote = Billy.Invoice.createRecord({
            organization: this.modelFor('organization'),
            type: 'creditNote',
            entryDate: moment(),
            creditedInvoice: invoice,
            templateId: invoice.get('templateId')
        })
        // Make sure that the invoice and its lines are loaded
        return invoice.promise
            .then(function() {
                return invoice.get('lines').promise
            })
            .then(function(lines) {
                creditNote.setProperties({
                    contact: invoice.get('contact'),
                    contactPerson: invoice.get('contactPerson'),
                    currency: invoice.get('currency'),
                    taxMode: invoice.get('taxMode')
                })
                lines.forEach(function(record) {
                    var properties = record.getProperties(
                        'product',
                        'description',
                        'quantity',
                        'unitPrice',
                        'taxRate',
                        'priority',
                        'discountMode',
                        'discountText',
                        'discountValue'
                    )
                    properties.description = properties.description ? t('invoices.credit.line_description', { description: properties.description }) : ''
                    properties.invoice = creditNote
                    Billy.InvoiceLine.createRecord(properties)
                })
                return creditNote
            })
    }
})

var t = require('i18n').t

function FiscalYear(organization, startDate, endDate) {
    this.organization = organization
    this.startDate = startDate
    this.endDate = endDate
}

module.exports = FiscalYear

FiscalYear.fromDate = function(organization, date) {
    date = moment(date)
    var firstFiscalYearStart = organization.get('firstFiscalYearStart')
    var firstFiscalYearEnd = organization.get('firstFiscalYearEnd')
    var startDate
    var endDate
    if (!date.isAfter(firstFiscalYearEnd)) {
        startDate = moment(firstFiscalYearStart)
        endDate = moment(firstFiscalYearEnd)
    } else {
        var fiscalYearEndMonth = organization.get('fiscalYearEndMonth')
        var endYear = date.year()
        var endMonth = fiscalYearEndMonth
        if (date.month() > fiscalYearEndMonth) {
            endYear++
        }
        endDate = moment().year(endYear).month(endMonth - 1).endOf('month')
        startDate = moment(endDate).subtract(1, 'year').add(1, 'day')
    }
    return new FiscalYear(organization, startDate, endDate)
}

FiscalYear.fromYear = function(organization, year) {
    var date = moment().year(year).month(organization.get('fiscalYearEndMonth') - 1)
    return FiscalYear.fromDate(organization, date)
}

FiscalYear.parse = function(organization, value) {
    var match = value.match(/^fiscalyear:(.+),(.+)$/i)
    if (!match) {
        throw new Error('Invalid FiscalYear format: ' + value)
    }
    var organizationId = match[1]
    if (organizationId !== organization.get('id')) {
        throw new Error('FiscalYear organization ID mismatch, expected: ' + organization.get('id') + ', actual: ' + organizationId)
    }
    var year = match[2]
    return FiscalYear.fromYear(organization, year)
}

FiscalYear.prototype.stringify = function() {
    return 'fiscalyear:' + this.organization.get('id') + ',' + this.endDate.year()
}

FiscalYear.prototype.prev = function() {
    return FiscalYear.fromDate(this.organization, moment(this.startDate).subtract(1, 'day'))
}

FiscalYear.prototype.next = function() {
    return FiscalYear.fromDate(this.organization, moment(this.endDate).add(1, 'day'))
}

FiscalYear.prototype.getText = function() {
    return t('period.formatted.fiscalyear', { year: this.endDate.year() })
}

module.exports = DS.Transform.extend({
    model: null,

    serialize: function(record) {
        return record ? record.get('id') : null
    },

    deserialize: function(id) {
        return id ? this.model.find(id) : null
    }
})

var snapshotInvoiceMock = require('../utils/snapshot-invoice-mock')

module.exports = Em.Route.extend({
    templates: Ember.inject.service(),

    userOrganizations: Ember.inject.service(),

    beforeModel: function() {
        var self = this
        var organizationLocale = this.get('userOrganizations').get('activeOrganization').get('locale.id')
        var documentLayout = this.get('api').request('GET', '/documentLayouts/invoice.liquid', { headers: { 'accept-language': organizationLocale } })

        return new Em.RSVP.Promise(function(resolve, reject) {
            documentLayout.then(function(payload) {
                self.set('documentLayout', payload)
                resolve()
            }, reject)
        })
    },

    model: function() {
        var self = this
        var templates = this.get('templates')
        var organization = this.modelFor('organization_settings')
        var organizationLocale = this.get('userOrganizations').get('activeOrganization').get('locale.id')

        return templates.load()
            .then(function() {
                return templates.all().map(function(template) {
                    return Ember.create({
                        isDefault: template.id === organization.get('defaultTemplateId'),
                        thumbHTML: snapshotInvoiceMock(template, self.get('documentLayout'), organization, 'invoice-document', organizationLocale),
                        data: template
                    })
                })
            })
    },

    setupController: function(controller, model) {
        var organization = this.modelFor('organization_settings')

        controller.set('model', model)

        this.get('api').request('GET', '/v2/organizations/' + organization.get('id') + '/standardTemplate')
            .then(function(payload) {
                controller.set('standardTemplate', payload.standardTemplate)
            })
    },

    actions: {
        reloadTemplates: function() {
            this.refresh()
        }
    }
})

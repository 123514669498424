var _ = require('lodash')
var OrganizationSettings = require('../utils/organization-settings')

module.exports = Em.Object.extend({
    api: Em.inject.service(),

    user: function() {
        return this.container.lookup('controller:user')
    }.property(),

    userOrganizations: Em.inject.service(),

    organization: Em.computed.oneWay('userOrganizations.activeOrganization'),

    steps: function() {
        var currentRouteName = this.get('currentRouteName')
        var user = this.get('user')
        var steps = [
            {
                name: 'welcome',
                completed: !_.isEmpty(user.get('email')) && !_.isEmpty(user.get('phone')) // Force this step to completion whenever there is basic user info.
            }, {
                name: 'company_info',
                completed: this.hasOrgSettings() // Force this step to completion whenever we're in org scope.
            }, {
                name: 'fiscal_year',
                completed: this.hasOrgSettings('setupGuideFiscalYear')
            }, {
                name: 'invoicing',
                completed: this.hasOrgSettings('setupGuideInvoicing', 'setupGuideUploadLogo')
            }, {
                name: 'logo',
                completed: this.hasOrgSettings('setupGuideUploadLogo')
            }, {
                name: 'payment_details',
                completed: this.hasOrgSettings('setupGuidePaymentDetails')
            }
        ]

        return steps.map(function(step) {
            step.current = (
                currentRouteName === step.name ||
                (currentRouteName === 'logo' && step.name === 'invoicing') // Hidden logo step should be reflected in invoice settings step.
            )
            return step
        })
    }.property('currentRouteName', 'user', 'organization.settings'),

    hasOrgSettings: function() {
        var settingNames = Array.prototype.slice.call(arguments)
        var organizationSettings = this.get('organization.settings')

        if (!organizationSettings) return false

        if (settingNames.length === 0) return true // No names were passed, so we only care if _any_ settings exist at all.

        return settingNames.reduce(function(acc, settingName) {
            return acc && organizationSettings.get(settingName) === '1'
        }, true)
    },

    completeStep: function() {
        var self = this
        var currentStepIndex = this.get('currentStepIndex')

        return this.get('api').post('/v2/startGuide/progress', {
            payload: {
                // Convert from zero-based numbering, and add one for the "fake" first step that was completed on the laning page.
                step: currentStepIndex + 2,
                organizationId: this.get('organization.id')
            }
        })
            .then(function(payload) {
                if (payload.settings) {
                    // Reload all organization settings (endpoint returns all settings)
                    var organization = self.get('organization')
                    organization.set('settings', OrganizationSettings.create({
                        organization: organization,
                        data: payload.settings || {}
                    }))
                }
            })
    },

    currentStepIndex: function() {
        return _.findIndex(this.get('steps'), { name: this.get('currentRouteName') })
    }.property('currentRouteName', 'steps'),

    nextStep: function() {
        // The next step, in terms this service understands.
        // **Not to be confused with `currentStepIndex + 2` above, which is nonzero-based, and includes a "fake" first step.**
        return 'startguide.' + this.get('steps')[this.get('currentStepIndex') + 1].name
    }.property('steps', 'currentStepIndex'),

    setCurrentRouteName: function(routeName) {
        this.set('currentRouteName', routeName.replace(/^startguide\./, ''))
    },

    reset: function() {
        this.get('userOrganizations').reset()
    },

    // Determine if the given step is nested under an organization route
    isOrganizationBoundStep: function(step) {
        // Logo is included, but should never be shown
        return [
            'fiscal_year',
            'invoicing',
            'logo',
            'payment_details'
        ].includes(step)
    }
})

module.exports = BD.Model.extend({
    organization: BD.belongsTo('Billy.Organization', { readonly: true }),
    createdTime: BD.attr('datetime', { readonly: true }),
    dueDate: BD.attr('date'),
    invoiceNo: BD.attr('number', { readonly: true }),
    isCreditNote: BD.attr('boolean', { readonly: true }),
    creditedInvoice: BD.belongsTo('Billy.OrganizationInvoice', { readonly: true }),
    state: BD.attr('string'),
    currency: BD.belongsTo('Billy.Currency'),
    amount: BD.attr('number', { readonly: true }),
    tax: BD.attr('number', { readonly: true }),
    printUrl: BD.attr('string', { readonly: true }),
    paymentTransaction: BD.belongsTo('Billy.QuickpayTransaction', { readonly: true })
})

var CATEGORY_KEY_TO_BADGE_TYPE = {
    draft: 'info-highlighted',
    overdue: 'warning',
    unpaid: 'attention',
    paid: 'success'
}

module.exports = Em.Object.extend({
    categoryKey: null,

    badgeType: function() {
        var categoryKey = this.get('categoryKey')

        return CATEGORY_KEY_TO_BADGE_TYPE[categoryKey] || 'info'
    }.property('categoryKey')
})

var i18n = require('i18n')
var LiquidHelper = require('../utils/liquid-helper')
var addressFormat = require('address-format')
var t = require('i18n').t

module.exports = function(template, documentLayout, organization, phrase, locale) {
    locale = locale || i18n.locale()
    var liquidHelper = new LiquidHelper()
    var mockData = mockSnapshot(template, locale, organization)
    var html = liquidHelper.render(documentLayout, mockData)
    return (phrase) ? namespaceCSS(html, template.id, phrase) : html
}

function mockSnapshot(template, locale, organization) {
    // merge mock data with organization data
    var data = {
        id: 'UfrTZ8dlSKqwAiYQU8IUug',
        createdTime: '2015-09-08T16:56:05Z',
        updatedTime: null,
        type: 'invoice',
        entryDate: moment().format('YYYY-MM-DD'),
        dueDate: moment().add(8, 'days').format('YYYY-MM-DD'),
        state: 'approved',
        taxMode: 'excl',
        headerMessage: '',
        footerMessage: '',
        netAmount: 1200,
        tax: 300,
        grossAmount: 1500,
        currencyCode: organization.get('baseCurrency.id'),
        taxLines: [{
            name: null,
            rate: 0.25,
            amount: 300
        }],
        taxableAmount: '1000',
        nonTaxableAmount: '200',
        invoiceNo: '1234',
        balance: 0,
        isPaid: true,
        fi71: '000000000012344',
        locale: locale,
        organization: {
            id: organization.get('id'),
            name: organization.get('name'),
            address: {
                address: organization.get('street'),
                address2: '',
                postalCode: organization.get('zipcode'),
                city: organization.get('city'),
                stateCode: '',
                state: organization.get('state.name'),
                countryCode: organization.get('country.id'),
                country: organization.get('country.name'),
                formatted: addressFormat({
                    address: organization.get('street'),
                    city: organization.get('city'),
                    subdivision: organization.get('state.name'),
                    postalCode: organization.get('zipcode'),
                    countryCode: organization.get('country.name')
                }).join('\n')
            },
            registrationNo: organization.get('registrationNo'),
            phone: organization.get('phone'),
            fax: organization.get('fax'),
            email: organization.get('email'),
            logoUrl: organization.get('logoUrl'),
            taxType: organization.get('hasVat') ? 'vat' : 'tax',
            taxTypeTerm: organization.get('hasVat') ? t('vat') : t('tax'),
            locale: locale
        },
        contact: {
            id: 'Z9XKIkMoTKKoohGiGVSMng',
            name: t('template_preview_window.dummy_customer_name'),
            billingAddress: {
                address: t('template_preview_window.dummy_customer_address'),
                address2: '',
                postalCode: 'her',
                city: 'her',
                stateCode: '',
                state: organization.get('state.name'),
                countryCode: '',
                country: organization.get('country.name'),
                formatted: addressFormat({
                    address: t('template_preview_window.dummy_customer_address'),
                    city: t('template_preview_window.dummy_customer_city'),
                    subdivision: organization.get('state.name'),
                    postalCode: t('template_preview_window.dummy_customer_zip'),
                    countryCode: organization.get('country.name')
                }).join('\n')
            },
            shippingAddress: null,
            registrationNo: '',
            customerNo: '',
            ean: '',
            locale: null
        },
        attContactPerson: null,
        lines: [
            {
                productName: t('template_preview_window.dummy_product_name1'),
                description: t('template_preview_window.dummy_product_description1'),
                quantity: 2,
                unitPrice: 500,
                discountMode: 'none',
                discountText: null,
                discountValue: 0,
                currentTaxRate: 0.25,
                amount: 1000,
                discountAmount: 0
            },
            {
                productName: t('template_preview_window.dummy_product_name2'),
                description: t('template_preview_window.dummy_product_description2'),
                quantity: 1,
                unitPrice: 200,
                discountMode: 'none',
                discountText: null,
                discountValue: 0,
                currentTaxRate: 0.25,
                amount: 200,
                discountAmount: 0
            }
        ],
        attachments: [],
        flags: {},
        bankAccount: {
            bankName: null,
            routingNo: null,
            accountNo: null,
            swift: null,
            iban: null
        },
        balanceModifiers: [{
            type: 'daybookTransaction',
            id: 'F8AoLJhFQ2Ke1AsswBjjjg',
            entryDate: moment().format('YYYY-MM-DD'),
            description: t('balance_modifier.type.daybooktransaction'),
            amount: -1500
        }],
        template: { variables: {} }
    }

    // merge the default variables with the locale centric variables
    var vars = _({})
        .merge(template.variables.default, template.variables[locale])
        .mapValues(function(value) {
            return value == null ? '' : value + ''
        })
        .mapValues(function(value) {
            return _.replace(value, '{{invoiceNo}}', data.invoiceNo)
                .replace('{{balance}}', data.balance)
                .replace('{{isPaid}}', data.isPaid)
                .replace('{{fi71}}', data.fi71)
        })
        .value()

    data.template.variables = vars
    return data
}

function namespaceCSS(HTMLdocument, namespace, phrase) {
    var re = new RegExp(phrase, 'g')
    return HTMLdocument.replace(re, phrase + '-' + namespace)
}

var OrganizationBrand = require('../constants/organizationBrand')

var AGERAS_APP_URL = window.ENV ? window.ENV.AGERAS_APP_URL : process.env.AGERAS_APP_URL
var BILLY_APP_URL = window.ENV ? window.ENV.BILLY_APP_URL : process.env.BILLY_APP_URL

function redirectToBrand(brand, organizationUrl) {
    var redirectUrl =
      brand === OrganizationBrand.Billy ? BILLY_APP_URL + '/' + organizationUrl : AGERAS_APP_URL + '/' + organizationUrl

    window.location.replace(redirectUrl)
}

module.exports = {
    redirectToBrand: redirectToBrand
}

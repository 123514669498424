var t = require('i18n').t
var NOTIFICATION_KEYS = require('../notificationKeys')
var isValidRegistrationNumber = require('../utils/is-valid-registration-number')

module.exports = Em.Route.extend({
    queryParams: [
        'mobilepaystatus',
        'mobilepayerror'
    ],

    agerasLoan: Em.inject.service(),

    userOrganizations: Em.inject.service(),

    organization: Em.computed.alias('userOrganizations.activeOrganization'),

    setup: function(context) {
        if (context.invoice && this.didFindByInclude !== true) {
            BD.store.findByIdInclude(context.invoice.constructor, context.invoice.get('id'), 'invoice.delivery')
        }
        this._super.apply(this, arguments)
    },

    beforeModel: function() {
        var self = this
        var invoice = this.modelFor('invoice')

        return new Em.RSVP.Promise(function(resolve, reject) {
            invoice.promise.then(function() {
                if (invoice.get('isDraft')) {
                    self.transitionTo('invoice.edit', invoice)
                } else {
                    resolve()
                }
            }, reject)
        })
    },

    model: function() {
        var invoice = this.modelFor('invoice')
        return Em.RSVP.hash({
            invoice: invoice
        })
    },

    setupController: function(controller, models, transition) {
        var params = transition.queryParams
        var mobilepayMessage = null

        if (params.mobilepaystatus === 'success') {
            mobilepayMessage = t('payment_methods.mobilepay.success')
            this.container.lookup('util:notification').success(NOTIFICATION_KEYS.MOBILEPAY_CREATE, mobilepayMessage)
        } else if (params.mobilepaystatus === 'error') {
            switch (params.mobilepayerror) {
            case 'E_INSUFFICIENT_SCOPE':
                mobilepayMessage = t('payment_methods.mobilepay.error.insufficient_scope')
                break
            case 'E_INVALID_STATE':
                mobilepayMessage = t('payment_methods.mobilepay.error.invalid_state')
                break
            case 'E_MISSING_ISSUER':
                mobilepayMessage = t('payment_methods.mobilepay.error.missing_issuer')
                break
            default:
                mobilepayMessage = t('payment_methods.mobilepay.failed')
                break
            }
            this.container.lookup('util:notification').warn(NOTIFICATION_KEYS.MOBILEPAY_CREATE, mobilepayMessage)
        }

        this._super.apply(this, arguments)
        controller.set('model', models.invoice)
        controller.set('isFactoringEnabled', false)
        controller.set('isLoanEnabledForInvoice', false)
        controller.loadHtml()
        controller.activate()

        if (isValidRegistrationNumber(this.get('organization.registrationNo'))) {
            controller.set('isFactoringEnabled', false)
            controller.set('isLoanEnabledForInvoice', false)
            this.get('agerasLoan').getInvoiceFactoring(
                models.invoice.get('id'),
                models.invoice.get('invoiceNo'),
                models.invoice.get('currency.id'),
                models.invoice.get('grossAmount'),
                models.invoice.get('dueDate')
            ).then(function(res) {
                var enabled = !!res.payout_amount
                controller.set('isFactoringEnabled', models.financingEnabled && !models.financingDismissed)
                controller.set('isLoanEnabledForInvoice', enabled)
                controller.set('loanPayoutAmount', res.payout_amount)
                controller.set('loanCurrency', models.invoice.get('currency.id'))
                controller.set('estimateId', res.id)
                controller.set('invoiceSubmittedForLoan', !!res.application)
                controller.set('loanAppliedDate', moment(res.created_at).format('MMM DD, YYYY'))
            })
        }
    },

    resetController: function(controller) {
        controller.set('justApproved', false)
    },

    actions: {
        invalidateModel: function() {
            this.refresh()
        }
    }
})

module.exports = Em.Route.extend(require('../mixins/dirty-route'), {
    agerasLoan: Em.inject.service(),

    userOrganizations: Em.inject.service(),

    organization: Em.computed.alias('userOrganizations.activeOrganization'),

    model: function() {
        var invoice = this.modelFor('invoice')
        return Em.RSVP.hash({
            invoice: invoice
        })
    },

    afterModel: function(model) {
        var self = this

        if (model.invoice.get('isVoided')) {
            self.transitionTo('invoice', model.invoice)
        }
    },

    setupController: function(controller, models) {
        this._super.apply(this, arguments)
        controller.set('model', models.invoice)
        controller.set('financingEnabled', false) // reset state
        controller.set('isApproveInvoiceInfoDismissed', this.get('organization.settings.isApproveInvoiceInfoDismissed') === '1')
    }
})

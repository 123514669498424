var numeral = require('numeral')
var Column = require('./column')

module.exports = Column.extend({
    align: 'right',

    format: function(value) {
        return numeral(value).format('0,0.[00000000]')
    }
})

var storage = require('storage')
var storageToken = 'hide-bookkeeping-hero'

module.exports = Em.Object.extend({
    isHidden: storage(storageToken) === 'true',

    setResourceCenterLauncherHidden: function(value) {
        var shouldHideUserflowChat = value !== undefined ? !!value : this.get('isHidden')

        // update local state
        this.set('isHidden', shouldHideUserflowChat)
        storage(storageToken, shouldHideUserflowChat)

        // set state in userflow
        if (window.userflow && window.userflow.setResourceCenterLauncherHidden) {
            window.userflow.setResourceCenterLauncherHidden(shouldHideUserflowChat)
        }
    }
})

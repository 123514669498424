var t = require('i18n').t

module.exports = [
    Em.O({
        value: 'Production',
        name: t('annual_report.additional_info.activity_options.production')
    }),
    Em.O({
        value: 'Trade',
        name: t('annual_report.additional_info.activity_options.trade')
    }),
    Em.O({
        value: 'Financial',
        name: t('annual_report.additional_info.activity_options.financial')
    }),
    Em.O({
        value: 'Service',
        name: t('annual_report.additional_info.activity_options.service')
    }),
    Em.O({
        value: 'Other',
        name: t('annual_report.additional_info.activity_options.other')
    })
]

var Cookies = require('js-cookie')
var getUserType = require('./get-user-type')
var localeToLang = require('i18n').localeToLang

var isIntercomActive = false

var tracking = module.exports = {}

tracking.hasAnalyticsCode = false

/**
 * Tracks a visit / page view, if analytics is configured
 *
 * Currently this method will only emit a page view in
 * Google Analytics
 */
tracking.trackVisit = function(uri) {
    var analyticsId = ENV.GOOGLE_ANALYTICS_ID

    if (analyticsId) {
        tracking._getAnalyticsPageViewCode(analyticsId, uri)
    }
}

/**
 * Tracks a partner adds signup, if analytics is configured
 *
 * This is currently only intended to be  used if a signup
 * comes from the `external_signup` route
 */
tracking.trackPartnerAddsSignup = function(user, root) {
    if (!ENV.PARTNER_ADS_PROGRAM_ID) {
        return
    }

    var paid = Cookies.get('paid')
    var pacid = Cookies.get('pacid')

    if (!paid || !pacid) {
        return
    }

    var html = tracking._getPartnerAddsCode(ENV.PARTNER_ADS_PROGRAM_ID, user.get('id'), paid, pacid)
    $(root).append('<div class="track-partner-adds-signup-code">' + html + '</div>')
}

tracking._getPartnerAddsCode = function(programId, userId, paid, pacid) {
    return '<img src="https://www.partner-ads.com/dk/leadtracks2s.php?programid=' + programId + '&type=lead&partnerid=' + paid + '&pacid=' + pacid + '&uiv=' + userId + '" width="1" height="1">\n'
}

tracking._getAnalyticsCode = function(accountId) {
    // NOTE(nicklasmoeller): This semicolon on the next line is
    // strictly necessary. If omitted, the build will break, as
    // a result of the minification process we currently use.
    tracking.hasAnalyticsCode = true;

    /* eslint-disable */
    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script','//www.google-analytics.com/analytics.js','ga')
    /* eslint-enable */

    ga('create', accountId, 'auto')
    ga('require', 'displayfeatures')
}

tracking._getAnalyticsPageViewCode = function(account, uri) {
    if (!tracking.hasAnalyticsCode) {
        tracking._getAnalyticsCode(account)
    }
    ga('send', 'pageview', uri)
}

tracking.getAnalyticsEventCode = function(account, eventPart1, eventPart2) {
    if (!tracking.hasAnalyticsCode) {
        tracking._getAnalyticsCode(account)
    }
    ga('send', 'event', eventPart1, eventPart2)
}

/*
This whole module is a little messy. Methods named `get*` which have side
effects fx. This method is a clean break to allow easily emitting Google
Analytics events.
*/
/**
 * Emits a Google Analytics event
 *
 * `category`: required
 * `action`: required
 * `label`: optional
 * `value`: optional
 */
tracking.emitAnalyticsEvent = function(category, action, label, value) {
    try {
        var analyticsId = ENV.GOOGLE_ANALYTICS_ID
        if (!analyticsId) {
            return
        }
        if (!tracking.hasAnalyticsCode) {
            tracking._getAnalyticsCode(analyticsId)
        }
        ga('send', 'event', category, action, label, value)
    } catch(e) {
        /* eslint-disable no-console */
        console.error('tracking.emitAnalyticsEvent failed:')
        console.error(e)
        /* eslint-enable no-console */
    }
}

/**
 * Gets user type for tracking based on activeMembership data
 */
tracking.getUserType = getUserType

var getIdentificationData = function(user, activeMembership, organization) {
    var signedUpAt = user.get('createdTime')

    var userType = getUserType(activeMembership)

    var identification = {
        name: user.get('name'),
        signed_up_at: signedUpAt ? signedUpAt.format() : null,
        email: user.get('email'),
        locale_code: user.get('locale'),
        is_support_staff: window.location.host.startsWith('support'),
        userType: userType,
        npsScore: null,
        is_beta_candidate: organization.get('settings.isBetaCandidate') === '1'
    }

    return identification
}

/**
 * Initializes Userflow for organizations
 */
tracking.startUserflow = function(user, org, userUmbrellas, activeMembership) {
    if (!window.userflow || !ENV.USERFLOW_TOKEN) {
        console.warn('Userflow token is not present. Error grouping.')
        return
    }

    var isTrustedPartner = false

    userUmbrellas
        .all()
        .then(function(umbrellas) {
            isTrustedPartner = umbrellas.some(function(umbrella) {
                return umbrella.isTrustedPartner
            })
        })
        .then(function() {
            var identification = getIdentificationData(user, activeMembership, org)

            window.userflow.init(ENV.USERFLOW_TOKEN)
            window.userflow.identify(user.get('id'), identification)
            window.userflow.group(org.get('id'), {
                name: org.get('name'),
                isTrustedPartner: isTrustedPartner,
                eligibleForLoan: true,
                randomBucket: org.get('randomBucket'),
                coaType: org.get('chartOfAccountId'),
                vatPeriod: org.get('salesTaxPeriod').toLowerCase(),
                url: org.get('url'),
                createdAt: org.get('createdTime').format(),
                type: 'organization',
                subscriptionProductPlan: org.get('subscriptionProductPlan')
            })
            // show or hide resource center (Bookkeeping Hero) depending on privacy settings
            window.userflow.on('resourceCenterChanged', function() {
                Billy.__container__.lookup('service:userflow').setResourceCenterLauncherHidden()
            })
        })
}

/**
 * Initializes Userflow for organizations that come from external sources (Ageras brand)
 */
tracking.startUserflowAgeras = function(user) {
    var externalId = user.get('externalId')
    if (!window.userflow || !ENV.USERFLOW_TOKEN) {
        console.warn('Userflow token is not present. Error grouping.')
        return
    }

    if (!externalId) {
        console.warn('Cannot start Userflow for Ageras, external id is not present.')
        return
    }

    window.userflow.init(ENV.USERFLOW_TOKEN)
    window.userflow.identify(externalId)
    // show or hide resource center (Bookkeeping Hero) depending on privacy settings
    window.userflow.on('resourceCenterChanged', function() {
        Billy.__container__.lookup('service:userflow').setResourceCenterLauncherHidden()
    })
}

/**
 * Initializes Userflow for umbrellas
 */
tracking.startUmbrellaUserflow = function(activeMembership, activeUmbrella, api, user) {
    if (!window.userflow || !ENV.USERFLOW_TOKEN) {
        console.warn('Userflow token is not present. Error grouping.')
        return
    }

    api.getData('/umbrellas/' + activeUmbrella.id + '/organizations')
        .then(function(organizations) {
            var identification = getIdentificationData(user, activeMembership)

            window.userflow.init(ENV.USERFLOW_TOKEN)
            window.userflow.identify(user.get('id'), identification)
            window.userflow.group(activeUmbrella.id, {
                name: '(Admin) ' + activeUmbrella.name,
                type: 'admin',
                registrationNo: activeUmbrella.registrationNo,
                hasLogo: !!activeUmbrella.logoFileId,
                customerCount: organizations.length
            })
        })
}

tracking.stopUserflow = function() {
    if (!window.userflow) {
        return
    }

    window.userflow.reset()
}

/**
 * Starts intercom tracking
 */
tracking.startIntercomTracking = function(user, appId, userHash) {
    if (window.Intercom && appId && userHash) {
        var userLanguage = localeToLang(user.get('locale'))
        isIntercomActive = true
        window.Intercom('boot', {
            app_id: appId,
            user_id: user.get('id'),
            user_hash: userHash,
            language_override: userLanguage
        })
    }
}

/**
 * Stops intercom tracking
 */
tracking.stopIntercomTracking = function() {
    if (window.Intercom && isIntercomActive) {
        isIntercomActive = false
        window.Intercom('shutdown')
    }
}

/**
 * Refreshes Hubspot chat widget
 */
tracking.refreshHubspotWidget = function() {
    if (window.HubSpotConversations && window.HubSpotConversations.widget) {
        setTimeout(function() {
            window.HubSpotConversations.widget.refresh()
        }, 0)
    }
}

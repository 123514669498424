module.exports = Em.View.extend({
    templateName: 'bills/draft-bill',

    tagName: 'tr',

    classNames: ['draft-bill'],

    classNameBindings: ['bill.checked:is-selected', ':is-clickable'],

    bill: Ember.computed.alias('content'),

    draftBill: Ember.computed.alias('bill.draftBill'),

    hasAttachments: Em.computed.gt('draftBill.attachments.length', 0),

    click: function(event) {
        var isCheckboxClicked = event.target.type === 'checkbox'

        if (!isCheckboxClicked) {
            this.get('controller').send('transitionToDraftBill', this.get('draftBill'))
        }
    },

    actions: {
        openReceipt: function(url) {
            // Open receipt in a new window
            window.open(url)
        }
    }
})

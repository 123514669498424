module.exports = BD.Model.extend({
    organization: BD.belongsTo('Billy.Organization'),
    flatFee: BD.attr('number'),
    percentageFee: BD.attr('number'),
    feeCurrency: BD.belongsTo('Billy.Currency'),
    sendEmail: BD.attr('boolean'),
    associations: BD.hasMany('Billy.InvoiceReminderAssociation', 'reminder', { isEmbedded: true }),
    contact: BD.belongsTo('Billy.Contact'),
    contactPerson: BD.belongsTo('Billy.ContactPerson'),
    emailSubject: BD.attr('string'),
    emailBody: BD.attr('string'),
    message: BD.attr('string'),
    copyToUser: BD.belongsTo('Billy.User'),
    downloadUrl: BD.attr('string')
})

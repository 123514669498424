var t = require('i18n').t

module.exports = require('ember-super-field').types.Model.extend({

    inputValuePath: 'name',
    iconPath: 'iconUrl',
    modelClass: Billy.Country,
    sortProperty: 'name',

    noOptionsFoundText: t('ui.fields.superfield.no_countries_found'),

    optionViewClass: require('ember-super-field').OptionView.extend({
        template: Ember.Handlebars.compile(
            '<div class="image"><img {{bind-attr src="iconUrl"}}/></div>' +
            '<div class="image-content">' +
                '<div class="name">{{name}}</div>' +
            '</div>'
        )
    })

})

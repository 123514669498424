var t = require('i18n').t

module.exports = require('ember-super-field').types.Model.extend({

    inputValuePath: 'shortInvoiceNoWithCustomer',
    modelClass: Billy.ExternalInvoice,
    sortProperty: 'number',
    sortDirection: 'ASC',

    noOptionsFoundText: t('ui.fields.superfield.no_invoices_found'),

    optionHeight: 64,
    optionViewClass: require('ember-super-field').OptionView.extend({
        template: Ember.Handlebars.compile(
            '<div class="name">{{shortInvoiceNoWithCustomer}}</div>' +
            '<div class="meta">{{money balance currencyBinding="currency"}}</div>'
        )
    }),

    query: function() {
        return {
            organization: this.get('userController.activeOrganization'),
            isVoided: false,
            isPaid: false
        }
    }.property('userController.activeOrganization')
})

var NOTIFICATION_KEYS = require('../notificationKeys')

module.exports = Em.Object.extend({
    isLoaded: false,

    data: null,

    currencyDefaults: null,

    api: Em.inject.service(),

    userOrganizations: Em.inject.service(),

    organization: Em.computed.oneWay('userOrganizations.activeOrganization'),

    dkkPaymentMethodTypes: ['stripe'],

    invalidate: function() {
        var data = this.get('data')
        if (data && data.destroy) {
            data.destroy()
        }
        this.set('isLoaded', false)
            .set('data', null)
            .set('currencyDefaults', null)
    }.observes('organization'),

    filterPaymentMethods: function(methods) {
        return methods.filter(function(paymentMethod) {
            return !paymentMethod.get('isDisabled')
        })
    },

    load: function() {
        var self = this
        var organization = this.get('organization')
        var query = {
            organization: organization.get('id')
        }
        this._loadPromise = this.store.find('paymentMethod', query)
            .then(function(paymentMethods) {
                self.set('data', self.filterPaymentMethods(paymentMethods))
                    .set('currencyDefaults', self.extractCurrencyDefaults(paymentMethods))
                    .set('isLoaded', true)
                return paymentMethods
            })
        return this._loadPromise
    },

    loadSafe: function() {
        this.load()
            .catch(function(e) {
                // Payment methods load failed, but will not throw an error
            })
    },

    ensureLoaded: function() {
        return this._loadPromise || this.load()
    },

    extractCurrencyDefaults: function(paymentMethods) {
        var currencyDefaults = paymentMethods.get('meta.currencyDefaults')
        if (!currencyDefaults) {
            return []
        }
        return currencyDefaults.map(function(d) {
            return {
                currencyCode: d.currencyCode,
                paymentMethod: paymentMethods.findBy('id', d.paymentMethodId)
            }
        })
    },

    find: function(id) {
        if (!this.get('isLoaded')) {
            throw new Error('Payment methods have not been loaded yet.')
        }
        var item = this.get('data').findBy('id', id)
        return item
    },

    editPaymentMethod: function(paymentMethod) {
        this.container.lookup('component:payment-method-editor')
            .set('paymentMethod', paymentMethod)
            .show()
    },

    createPaymentMethod: function(organization, currencyCode) {
        var self = this
        return new Em.RSVP.Promise(function(resolve) {
            var paymentMethod = self.createPaymentMethodRecord(organization)
            self.container.lookup('component:payment-method-editor')
                .set('paymentMethods', self.get('data') || [])
                .set('paymentMethod', paymentMethod)
                .set('currencyCode', currencyCode)
                .set('callback', function(paymentMethod) {
                    self.get('data').pushObject(paymentMethod)
                    resolve(paymentMethod)
                })
                .show()
        })
    },

    activatePaymentMethod: function(paymentMethodName, organization, currencyCode) {
        var self = this
        return new Em.RSVP.Promise(function(resolve) {
            var paymentMethod = self.createPaymentMethodRecord(organization)
            var componentName = 'component:payment-method-' + paymentMethodName + '-setup'
            self.container.lookup(componentName)
                .set('paymentMethods', self.get('data') || [])
                .set('paymentMethod', paymentMethod)
                .set('currencyCode', currencyCode)
                .set('callback', function(paymentMethod) {
                    self.get('data').pushObject(paymentMethod)
                    resolve(paymentMethod)
                })
                .show()
        })
    },

    createPaymentMethodRecord: function(organization) {
        return this.get('store').createRecord('paymentMethod', {
            organization: organization,
            isDefault: true
        })
    },

    openStripeSetup: function(paymentMethod) {
        var self = this
        return new Em.RSVP.Promise(function(resolve) {
            self.container.lookup('component:payment-method-stripe-setup')
                .set('paymentMethod', paymentMethod)
                .set('callback', function(paymentMethod) {
                    self.get('data').pushObject(paymentMethod)
                    resolve(paymentMethod)
                })
                .show()
        })
    },

    destroyPaymentMethod: function(paymentMethod) {
        var self = this
        this.get('data').removeObject(paymentMethod)
        return new Em.RSVP.Promise(function(resolve, reject) {
            paymentMethod.destroyRecord()
                .then(function() {
                    self.set('currencyDefaults', self.get('currencyDefaults').rejectBy('paymentMethod', paymentMethod))
                    resolve()
                }, function(e) {
                    self.get('data').addObject(paymentMethod)
                    var message = Em.get(e, 'errors.self.0') || e.message
                    self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.PAYMENT_METHOD_REMOVE, message)
                    reject()
                })
        })
    },

    destroyPaymentMethodByType: function(paymentMethodType) {
        var paymentMethod = this.get('data').findBy('type', paymentMethodType)
        return this.destroyPaymentMethod(paymentMethod)
    },

    formatCurrencyDefaultUrl: function(currencyCode, paymentMethod) {
        return '/organizations/' + this.get('organization.id') + '/paymentMethodCurrencyDefaults/' + currencyCode + '/' + paymentMethod.get('id')
    },

    findCurrencyDefault: function(currencyCode, paymentMethod) {
        return this.get('currencyDefaults').find(function(d) {
            return d.currencyCode === currencyCode && d.paymentMethod === paymentMethod
        })
    },

    putCurrencyDefault: function(currencyCode, paymentMethod) {
        var self = this
        var def
        return this.ensureLoaded()
            .then(function() {
                if (self.findCurrencyDefault(currencyCode, paymentMethod)) {
                    return
                }
                def = {
                    currencyCode: currencyCode,
                    paymentMethod: paymentMethod
                }
                self.get('currencyDefaults').addObject(def)
                return self.get('api').request('PUT', self.formatCurrencyDefaultUrl(currencyCode, paymentMethod))
            })
            .catch(function(e) {
                self.get('currencyDefaults').removeObject(def)
                self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.PAYMENT_CURRENCY, e.message)
            })
    },

    deleteCurrencyDefault: function(currencyCode, paymentMethod) {
        var self = this
        var def
        return this.ensureLoaded()
            .then(function() {
                def = self.findCurrencyDefault(currencyCode, paymentMethod)
                self.get('currencyDefaults').removeObject(def)
                return self.get('api').request('DELETE', self.formatCurrencyDefaultUrl(currencyCode, paymentMethod))
            })
            .catch(function(e) {
                self.get('currencyDefaults').addObject(def)
                self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.PAYMENT_DELETE, e.message)
            })
    },

    getDefaultPaymentMethods: function(currencyCode) {
        var self = this
        return this.ensureLoaded()
            .then(function() {
                var currencyDefaults = self.get('currencyDefaults').filterBy('currencyCode', currencyCode)

                return currencyDefaults.length
                    ? currencyDefaults.mapBy('paymentMethod')
                    : self.get('data').filterBy('isDefault', true)
            })
    },

    getPaymentMethodsForCurrency: function(currencyCode) {
        if (currencyCode === 'DKK') {
            return this.get('dkkPaymentMethodTypes')
        }

        return []
    }
})

var features = Em.A([
    'backgroundsize',
    'borderradius',
    'boxshadow',
    'csstransforms3d',
    'csstransitions',
    'draganddrop',
    'flexbox',
    'fontface',
    'hashchange',
    'history',
    'localstorage'
])

function allSupported() {
    return features.every(isSupported)
}

function isSupported(feature) {
    return (feature in Modernizr)
}

module.exports = {
    allSupported: allSupported,
    isSupported: isSupported
}

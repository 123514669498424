var Column = require('./column')
var NestedHeaderCellView = require('../table/nested-header-cell-view')
var NestedCellView = require('../table/nested-cell-view')

module.exports = Column.extend({
    headerCellViewClass: NestedHeaderCellView,

    cellViewClass: NestedCellView,

    controllerClass: null,

    flex: function() {
        return this.get('columns').reduce(function(flex, column) {
            return flex + column.get('flex')
        }, 0)
    }.property(),
    width: function() {
        return this.get('columns').reduce(function(width, column) {
            return width + column.get('width')
        }, 0)
    }.property(),

    init: function() {
        this._super()
        Ember.assert('You need to set a `controllerClass` for a nested table column.', this.get('controllerClass'))
    }
})

module.exports = Em.Object.extend({
    api: Ember.inject.service(),

    getUmbrellas: function(organizationId) {
        return this.get('api').request('GET', '/organizations/' + organizationId + '/umbrellas')
            .then(function(payload) {
                return payload.data
            })
    }
})

var t = require('i18n').t
var resolveMimeType = require('./resolve-mime-type')

// New way of uploading files through files-api and direct to S3 POST
module.exports = Em.Object.extend({
    file: null,

    aborted: false,

    progress: 0,

    url: null,

    downloadUrl: null,

    upload: function() {
        var self = this
        return this.createFile()
            .then(function(payload) {
                if (self.get('aborted')) {
                    return
                }
                var data = payload.data
                self.set('url', data.url)
                self.set('downloadUrl', data.downloadUrl)
                return self.uploadFile({
                    url: data.uploadUrl,
                    form: data.form
                })
            })
            .then(function() {
                return {
                    url: self.get('url'),
                    downloadUrl: self.get('downloadUrl')
                }
            })
    },

    createFile: function() {
        var self = this
        var file = this.get('file')
        var accessToken = this.container.lookup('api:billy').storageAdapter.getValue('accessToken')
        var organizationId = this.container.lookup('controller:user').get('activeOrganization.id')
        return new Em.RSVP.Promise(function(resolve, reject) {
            self.xhr = $.ajax({
                type: 'POST',
                url: (ENV.isTest ? '' : ENV.newApiUrl) + '/files',
                headers: {
                    authorization: 'Bearer ' + accessToken,
                    'content-type': 'application/json'
                },
                dataType: 'json',
                data: JSON.stringify({
                    data: {
                        contentType: resolveMimeType(file.name, file.type),
                        name: file.name,
                        size: file.size,
                        organizationId: organizationId
                    }
                })
            })
                .then(function(payload) {
                    Em.run(function() {
                        resolve(payload)
                    })
                }, function(xhr) {
                    Em.run(function() {
                        reject(self.errorFromXhr(xhr))
                    })
                })
        })
    },

    uploadFile: function(opts) {
        var self = this
        var url = opts.url
        var form = opts.form || {}
        var file = this.get('file')

        return new Em.RSVP.Promise(function(resolve, reject) {
            var xhr = self.xhr = new XMLHttpRequest()
            var upload = xhr.upload

            var fd = new FormData()
            for (var k in form) {
                if (Object.prototype.hasOwnProperty.call(form, k)) {
                    fd.append(k, form[k])
                }
            }
            fd.append('file', file)

            xhr.addEventListener('readystatechange', function() {
                if (xhr.readyState !== 4) {
                    return
                }

                var status = xhr.status
                if (status >= 200 && status <= 299) {
                    resolve()
                } else {
                    if (self.get('aborted')) {
                        var e = new Error('File upload aborted by user')
                        e.code = 'FILE_UPLOAD_ABORTED'
                        reject(e)
                    } else {
                        reject(self.errorFromXhr(xhr))
                    }
                }
            }, false)

            upload.addEventListener('progress', function(e) {
                if (e.lengthComputable) {
                    var p = Math.round((e.loaded / e.total) * 1000) / 1000
                    self.set('progress', p)
                }
            }, false)
            upload.addEventListener('load', function() {
                self.set('progress', 1)
            }, false)

            upload.addEventListener('error', function(xhr) {
                reject(self.errorFromXhr(xhr))
            }, false)

            xhr.open('POST', url, true)
            xhr.send(fd)
        })
    },

    errorFromXhr: function(xhr) {
        var msg = Ember.get(xhr, 'responseJSON.errors.0.detail') || t('attachments_changeset.upload_failed')
        var e = new Error(msg)
        e.code = 'FILE_UPLOAD_ERROR'
        return e
    },

    abort: function() {
        this.set('aborted', true)
        if (this.xhr) {
            this.xhr.abort()
        }
    }
})

var moment = require('moment')

module.exports = function(startDate, endMonth) {
    startDate = moment(startDate || '2016-07-02')
    var possibleDates = Em.A()

    for (var i = 1; i <= 18; i++) {
        startDate.add(1, 'month')
        var lastDayNextMonth = startDate.clone().startOf('month').subtract(1, 'day')

        if (lastDayNextMonth.month() + 1 === endMonth) {
            possibleDates.push(Em.Object.create({
                sortable: lastDayNextMonth.format('YYYY-MM-DD'),
                readable: lastDayNextMonth.format('DD. MMMM YYYY')
            }))
        }
    }

    return possibleDates
}

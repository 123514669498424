var billyApi = require('./billy-api')

var batchSize = 50
var maxAttempts = 15

var isLoading = false
var queue = []
var pollTimer
var attempts = 0

module.exports = Em.ArrayProxy.extend({
    file: null,

    name: null,

    isLoaded: false,

    content: null,

    init: function() {
        this._super()
        enqueue(this)
    },

    setThumbnails: function(thumbnails) {
        var self = this
        var promises = []
        thumbnails.forEach(function(image) {
            promises.push(image.preload())
        })
        Em.RSVP.all(promises).then(function() {
            self.set('content', thumbnails)
            self.set('isLoaded', true)
        })
    },

    willDestroy: function() {
        // eslint-disable-next-line
        dequeue(item)
        this._super()
    }
})

function enqueue(item) {
    queue.push(item)
    attempts = 0
    delayPoll(1)
}

function dequeue(item) {
    queue.removeObject(item)
}

function delayPoll(time) {
    if (!isLoading && queue.length > 0) {
        clearTimeout(pollTimer)
        pollTimer = setTimeout(poll, time)
    }
}

function poll() {
    if (isLoading) {
        return
    }

    if (queue.length === 0) {
        isLoading = false
        return
    }

    isLoading = true
    attempts++

    var fileIds = queue
        .map(function(item) {
            return item.get('file.id')
        })

    // Batch into requests of `batchSize` files
    var promises = []
    var url
    for (var i = 0; i < fileIds.length; i += batchSize) {
        url = '/files/' + fileIds.slice(i, i + batchSize) + '/thumbnails/webapp'
        promises.push(billyApi.get(url).then(pollSuccess, pollError))
    }
    Em.RSVP.Promise.all(promises)
        .then(pollDone)
}

function pollSuccess(payload) {
    payload = payload || {}
    var files = payload.files || []
    var map = payload.thumbnailsForFile || {}

    var item
    var thumbnailIds
    var thumbnails
    for (var i = 0; i < queue.length; i++) {
        item = queue[i]

        // Remove item if it's been destroyed
        if (item.get('isDestroying')) {
            queue.splice(i, 1)
            i--
            continue
        }

        // Check if thumbnails are ready
        thumbnailIds = map[item.get('file.id')]
        if (!thumbnailIds || thumbnailIds.length === 0) {
            // If not, let them stay in the queue
            continue
        }

        // Update the item
        thumbnails = Billy.File.loadMany(files.filter(containsFileId.bind(null, thumbnailIds)))
        item.setThumbnails(thumbnails)

        // Remove the item from the queue
        queue.splice(i, 1)
        i--
    }
}

function pollError(e) {
    // eslint-disable-next-line no-console
    console.error('Thumbnail poll failed: ', e)
}

function pollDone() {
    isLoading = false
    if (attempts < maxAttempts) {
        delayPoll(waitTimeForAttempt(attempts))
    }
}

function waitTimeForAttempt(attempts) {
    return (Math.pow(attempts, 0.5)) * 1000
}

function containsFileId(ids, file) {
    return ids.contains(file.id)
}

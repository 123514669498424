var model = BD.Model.extend({
    organization: BD.belongsTo('Billy.Organization'),
    name: BD.attr('string'),
    accountNo: BD.attr('number'),
    description: BD.attr('string'),
    group: BD.belongsTo('Billy.AccountGroup'),
    nature: BD.belongsTo('Billy.AccountNature'),
    systemRole: BD.attr('string'),
    currency: BD.belongsTo('Billy.Currency'),
    taxRate: BD.belongsTo('Billy.TaxRate'),
    isPaymentEnabled: BD.attr('boolean'),
    isBankAccount: BD.attr('boolean'),
    isArchived: BD.attr('boolean'),
    bank: BD.belongsTo('Billy.Bank'),
    bankName: BD.attr('string'),
    bankRoutingNo: BD.attr('string'),
    bankAccountNo: BD.attr('string'),
    bankSwift: BD.attr('string'),
    bankIban: BD.attr('string'),
    predefinedAccount: BD.belongsTo('Billy.PredefinedAccount')
})

model.reopenClass({
    qProperties: ['name', 'accountNo', 'description']
})

module.exports = model

var t = require('i18n').t
var moment = require('momentjs')

var get = Em.get

var FiscalYear = module.exports = Em.Object.extend({
    name: function() {
        var value = get(this, 'value')
        var match = value.match(/^fiscalyear:(.+),(.+)$/)
        if (!match) {
            return '?'
        }
        return t('period.formatted.fiscalyear', { year: match[2] })
    }.property('value')
})

FiscalYear.getCurrentYear = function(organization) {
    var today = FiscalYear.today()
    var firstFiscalYearEnd = get(organization, 'firstFiscalYearEnd')
    if (today.isBefore(firstFiscalYearEnd)) {
        return firstFiscalYearEnd.year()
    }

    var year = today.year()
    if (today.month() + 1 > get(organization, 'fiscalYearEndMonth')) {
        year++
    }
    return year
}

FiscalYear.getCurrentPeriod = function(organization) {
    return FiscalYear.create({
        value: 'fiscalyear:' + get(organization, 'id') + ',' + FiscalYear.getCurrentYear(organization)
    })
}

FiscalYear.today = function() {
    return moment.utc().startOf('day')
}

var _ = require('lodash')
var functionProxy = require('function-proxy')
var i18n = require('i18n')
var i18nContext = require('i18n-context')
var i18nLocaleContext = i18nContext('ember_text_field', {
    en_US: require('../locales/en_US'),
    da_DK: require('../locales/da_DK'),
    fr_FR: require('../locales/fr_FR')
})
var t = i18nLocaleContext.t
var cleanT = i18n.t

module.exports = Em.Component.extend(require('ember-tooltip').Tooltipable, require('ember-field-mixin'), {
    layout: require('../templates/text-field'),

    classNameBindings: [':text-field', 'isDKLanguage:is-dk-language', 'appLanguage', 'small', 'search', 'block', 'value:has-value', 'hasFocus:focus', 'grow', 'reset:has-reset', 'alignClass', 'picker1Icon:pickerfield', 'picker2Icon:dual-pickerfield', 'disabled', 'readonly', 'required', 'flexClass', 'autofill:autofilled'],

    attributeBindings: ['style'],

    placeholder: i18nContext.tProperty('placeholder'),

    isDKLanguage: function() {
        return i18n.locale() === 'da_DK'
    }.property(),

    name: null,

    inputNamePrefix: '',

    tip: i18nContext.tProperty('tip'),

    inputDataCy: function() {
        return '' + _.toLower(this.get('name')) + '-input-field'
    }.property('name'),

    inputName: function() {
        return this.get('inputNamePrefix') + this.get('name')
    }.property('name', 'inputNamePrefix'),

    disabled: false,

    readonly: false,

    inputReadonly: Em.computed.alias('readonly'),

    required: false,

    minLength: 0,

    maxLength: null,

    autocomplete: null,

    inputType: 'text',

    inputmode: null,

    width: null,

    flex: null,

    flexClass: function() {
        var flex = this.get('flex')
        return flex ? 'flex-' + flex : null
    }.property('flex'),

    resetValue: '',

    selectOnFocus: false,

    small: false,

    search: false,

    searchPlaceholder: cleanT('search'),

    showAlwaysIcon: false,

    block: false,

    align: 'left',

    onBlur: null,

    autofill: false,

    // optional form controller that is used to trigger validation
    // on fields
    formController: null,

    alignClass: function() {
        return this.get('align') === 'right' ? 'align-right' : null
    }.property('align'),

    style: function() {
        var s = []
        var width = this.get('width')
        if (width) {
            s.push('width:' + width + 'px;')
        }
        return s.join(' ')
    }.property('width'),

    didInsertElement: function() {
        this._super()
        var self = this
        var input = this.$('input')
        this.adjustPadding()
        input.keydown(function(e) {
            Em.run(function() {
                self.didPressKeyDown(e)
            })
        })
        input.focus(function() {
            Em.run(function() {
                self.didFocus()
            })
        })
        input.blur(function() {
            Em.run(function() {
                self.didBlur()
            })
        })
        input.on('paste', function() {
            Em.run(function() {
                typeof self.didPaste === 'function' && self.didPaste()
            })
        })
        // Prevent loss of focus on picker click
        this.$('.picker1, .picker2').on('mousedown', functionProxy(this._preventDefaultEvent, this))

        // if formController is passed then listen to 'validateForm' event
        if (self.get('formController')) {
            self.get('formController').on('validateForm', this, this.checkError)
        }
    },    

    willDestroyElement: function() {
        this._super()
        this.$('.picker1, .picker2').on('mousedown', functionProxy(this._preventDefaultEvent, this))
    },

    willDestroy: function() {
        this._super()
        Em.run.cancel(this._bufferTimeout)

        if (this.get('formController')) {
            this.get('formController').off('validateForm', this, this.checkError)
        }
    },

    eventManager: {
        click: function(e, view) {
            e.stopPropagation()
            if (e.within('.prefix') || e.within('.suffix')) {
                view.focus()
            }
        }
    },

    _preventDefaultEvent: function(e) {
        e.preventDefault()
    },

    didPressKeyDown: function(e) {
        switch (e.keyCode) {
        // Escape
        case 27:
            this.didPressEscape()
            break
        // Enter
        case 13:
            this.didPressEnter()
            break
        }
    },
    didPressEscape: function() {
        if (this.get('reset')) {
            this.set('inputValue', '')
        }
    },

    didPressEnter: function() {
        if (typeof this.get('onEnter') === 'function') {
            this.get('onEnter').call(this, this.get('inputValue'))
        }
    },

    hasFocus: false,
    focus: function() {
        this.$('input').focus()
    },
    didFocus: function() {
        this.set('hasFocus', true)
        if (this.get('selectOnFocus')) {
            this.$('input').one('mouseup', function() { return false })
            this.$('input').select()
        }
    },
    didBlur: function() {
        this.set('hasFocus', false)
        this.checkError()
        if (!this.get('error')) {
            this.reformatInputValue()
        }

        if (this.get('onBlur') !== null) {
            this.sendAction('onBlur', this.get('_value'), this)
        }
    },

    _value: '',
    value: function(key, value) {
        if (arguments.length >= 2) {
            value = this.manipulateValue(value)
            if (value !== this.get('_value')) {
                this.set('_value', value)
                var inputValue = this.formatInputValue(value)
                this.set('_inputValue', inputValue)
            }
        } else {
            value = this.get('_value')
        }
        return value
    }.property('_value'),
    manipulateValue: function(value) {
        return value
    },

    allowUnformatInputValue: true,
    _inputValue: '',
    inputValue: function(key, inputValue) {
        if (arguments.length >= 2) {
            this.set('_inputValue', inputValue)
            if (this.get('allowUnformatInputValue')) {
                var value = this.unformatInputValue(inputValue)
                this.set('_value', value)
            } else {
                if (Em.isEmpty(inputValue)) {
                    this.set('_value', null)
                }
            }
        } else {
            inputValue = this.get('_inputValue')
        }
        return inputValue
    }.property('_inputValue'),
    inputValueDidChange: function() {
        this.resetError()
        this.updateMirror()
        this.fireBuffer()
    }.observes('inputValue'),

    formatInputValue: function(value) {
        return value
    },
    unformatInputValue: function(inputValue) {
        return inputValue
    },
    validateInputValue: function(inputValue) {
        var required = this.get('required')
        var minLength = this.get('minLength')
        var maxLength = this.get('maxLength')

        if (Em.isEmpty(inputValue) && required) {
            throw new Error(t('required'))
        } else if (!Em.isEmpty(inputValue)) {
            if (!Em.isEmpty(minLength) && inputValue.length < minLength) {
                throw new Error(t('must_be_longer', { number: minLength }))
            }
            if (!Em.isEmpty(maxLength) && inputValue.length > maxLength) {
                throw new Error(t('must_be_shorter', { number: maxLength }))
            }
        }
    },
    reformatInputValue: function() {
        this.set('_inputValue', this.formatInputValue(this.get('value')))
    }.on('didInsertElement'),
    checkError: function() {
        try {
            this.validateInputValue(this.get('inputValue'))
        } catch(e) {
            this.set('error', e.message)
        }
    },
    resetError: function() {
        this.set('error', null)
    },

    bufferDelay: 200,
    _bufferedValue: null,
    bufferedValue: function(key, bufferedValue) {
        if (arguments.length >= 2) {
            this.set('value', bufferedValue)
            this.set('_bufferedValue', bufferedValue)
            return bufferedValue
        } else {
            return this.get('_bufferedValue')
        }
    }.property('_bufferedValue'),
    fireBuffer: function() {
        var self = this
        Em.run.cancel(this._bufferTimeout)
        this._bufferTimeout = Em.run.later(function() {
            self.set('_bufferedValue', self.get('inputValue'))
        }, this.get('bufferDelay'))
    },

    grow: false,

    hasCreate: false,

    mirrorValue: null,
    updateMirror: function() {
        if (this.get('grow')) {
            this.set('mirrorValue', this.get('inputValue') || this.get('placeholder') || ' ')
        }
    }.on('didInsertElement'),

    setupPickerMouseEvents: function() {
        var self = this
        var picker1Tooltip = self.get('picker1Tooltip')
        if (picker1Tooltip) {
            this.$('.picker1').mouseenter(function() {
                self.showTooltip(picker1Tooltip)
            })
            this.$('.picker1').mouseleave(self.container.lookup('util:tooltip').scheduleHide)
        }

        var picker2Tooltip = self.get('picker2Tooltip')
        if (picker2Tooltip) {
            this.$('.picker2').mouseenter(function() {
                self.showTooltip(picker2Tooltip)
            })
            this.$('.picker2').mouseleave(self.container.lookup('util:tooltip').scheduleHide)
        }
    }.on('didInsertElement'),

    icon: function() {
        if (!this.get('search') && !this.get('showAlwaysIcon') && this.get('_inputValue')) {
            return null
        }

        if (this.get('search')) {
            return 'icons/magnifier'
        }

        return null
    }.property('search', '_inputValue'),

    iconIsSvg: function() {
        var icon = this.get('icon')
        return (icon && !icon.match(/\.(png|jpg)$/))
    }.property('icon'),

    prefix: i18nContext.tProperty('prefix'),
    suffix: i18nContext.tProperty('suffix'),

    picker1Icon: null,
    picker2Icon: null,

    picker1Tooltip: i18nContext.tProperty('picker1Tooltip'),
    picker2Tooltip: i18nContext.tProperty('picker2Tooltip'),

    hasPreCt: function() {
        return this.get('prefix')
    }.property('prefix', 'icon'),
    hasPostCt: function() {
        return (this.get('suffix') || this.get('reset') || this.get('picker1Icon') || this.get('icon'))
    }.property('suffix', 'reset', 'picker1Icon', 'icon'),

    actions: {
        didClickPreOrPostCt: function() {
            this.focus()
        },

        didClickPicker1: function() {
            if (!this.get('disabled') && !this.get('readonly')) {
                this.sendAction('picker1Clicked')
                this.container.lookup('util:tooltip').hide()
                this.didClickPicker1()
            }
        },

        didClickPicker2: function() {
            if (!this.get('disabled') && !this.get('readonly')) {
                this.sendAction('picker2Clicked')
                this.container.lookup('util:tooltip').hide()
                this.didClickPicker2()
            }
        },

        didClickReset: function() {
            if (!this.get('value')) {
                return
            }

            this.set('value', this.get('resetValue'))
            this.focus()
        },

        didClickCreate: function() {
            var self = this
            var type = this.get('type')
            var create = type.get('create')
            Ember.assert('No create method set on superfield type.', create)
            this.hideSelector()
            create.call(type, this.get('inputValue'), function(value) {
                self.selectOption(value)
            }, this)
        }
    },

    didClickPicker1: Em.K,

    didClickPicker2: Em.K,

    adjustPadding: function() {
        if (this.get('_state') !== 'inDOM') {
            return
        }

        var left = 0
        if (this.get('hasPreCt')) {
            if (this.get('prefix')) {
                left += this.$('.prefix').width() + 24
            }
            if (this.get('icon')) {
                left += 31
            }
        } else {
            left = 12
        }

        var right = 0
        if (this.get('hasPostCt')) {
            if (this.get('suffix')) {
                right += this.$('.suffix').width() + 24
            }
            if (this.get('reset')) {
                right += 26
            }
            if (this.get('hasCreate')) {
                right += 62
            }
            if (this.get('picker1Icon')) {
                if (this.get('small')) {
                    if (this.get('suffix')) {
                        right += 14
                    } else {
                        right += 26
                    }
                } else {
                    if (this.get('suffix')) {
                        right += 22
                    } else {
                        right += 34
                    }
                }
            }
        } else {
            right = 12
        }

        var input = this.$('input')
        var mirror = this.$('.mirror')
        var css = {
            paddingLeft: left,
            paddingRight: right
        }
        input.css(css)
        if (mirror.length) {
            mirror.css(css)
        }
    },
    paddingDependenciesDidChange: function() {
        Em.run.schedule('afterRender', this, this.adjustPadding)
    }.observes('prefix', 'suffix', 'reset', 'picker1Icon', 'icon'),

    showTooltip: function(message) {
        this.container.lookup('util:tooltip').scheduleShow(this, message, 'topRight')
    },

    innerTextFieldClass: require('./inner-text-field')
})

module.exports.locale = i18nLocaleContext.locale

module.exports.lang = function() {
    console.warn('.lang() is deprecated. Use .locale() instead')
    return i18nLocaleContext.locale.apply(null, arguments)
}

var locales = {
    en_US: require('../locales/en_US'),
    da_DK: require('../locales/da_DK'),
    fr_FR: require('../locales/fr_FR')
}
var functionProxy = require('function-proxy')
var highestZIndex = require('highest-z-index')
var i18nContext = require('i18n-context')('ember_file_uploader', locales)
var t = i18nContext.t
var tProperty = i18nContext.tProperty
var QueueItem = require('./queue-item')
var manager = require('./manager')
var config = require('./config')
var svg = require('ember-svg').get

module.exports = Ember.Component.extend({
    layout: require('../templates/file-uploader'),

    classNameBindings: [':file-uploader'],

    queue: null,
    multiple: true,

    isUploading: false,

    method: 'POST',

    url: '/files',

    shouldScan: false,

    organizationId: null,

    headers: null,

    isPublic: false,

    isWriteEnabled: true,

    allowDrag: function () {
        return this.get('isWriteEnabled')
    }.property('isWriteEnabled'),
    allowDrop: function () {
        return this.get('isWriteEnabled')
    }.property('isWriteEnabled'),
    dragTip: tProperty('dragTip', t('or_drag_and_drop')),
    dropTip: tProperty('dropTip', t('drop_files_here')),
    dropSelector: null,
    dropOverlaySelector: null,
    dropAreaSize: 'large',
    sendAsFormData: false,
    formDataParams: {},

    fileSizeLimit: 10, // Max file for particular file uploader
    apiFileSizeLimit: 10, // Max file size allowed by the API

    inputSelector: null,

    accept: null,

    reportProgress: false,

    buttonIcon: null,
    buttonText: tProperty('buttonText', function() {
        var customButtonText = this.get('customButtonText')
        if (customButtonText) return customButtonText
        return this.get('multiple') ? t('select_files') : t('select_file')
    }).property('multiple'),
    buttonStyle: null,
    buttonSize: 'small',

    batch: null,

    thumbnailNames: '',

    abortOnError: false,

    setupQueue: function() {
        this.set('queue', [])
    }.on('init'),

    didInsertElement: function() {
        this.setupFileInput()
        if (this.get('allowDrag')) {
            this.setupDropTarget()
        }
    },

    stopEvent: function(e) {
        e.stopPropagation()
        e.preventDefault()
    },
    findBySelector: function(selector, what) {
        var el = this.$().closest(selector)
        if (el.length) {
            return el
        }
        el = $(selector)
        if (el.length) {
            return el
        }
        throw new Error('No file-uploader ' + what + ' was found by the selector ' + selector)
    },

    setupFileInput: function() {
        var self = this
        var selector = this.get('inputSelector')
        var parent
        var input
        var accept = Em.Handlebars.Utils.escapeExpression(this.get('accept'))

        if (!this.get('isWriteEnabled')) {
            return
        }

        if (selector) {
            parent = this.findBySelector(selector, 'input parent')
            parent.addClass('file-uploader-input-parent')
        } else {
            parent = this.$('.file-uploader-input-parent')
        }
        parent.append('<input type="file" class="file-uploader-input" accept="' + accept + '"' + (this.get('multiple') ? ' multiple' : '') + '/>')
        input = parent.find('> .file-uploader-input')
        if (selector) {
            this.set('externalInput', input)
        }
        input.change(function(e) {
            if (self.get('uploadOnsubmit')) {
                var files = input[0].files
                for (var i = 0; i < files.length; i++) {
                    var file = files.item(i)
                    self.makePreview(file)
                }
            } else {
                Em.run(function() {
                    self.enqueue(input[0].files)
                    input[0].value = null
                })
            }
        })
        // Add .hover class to the upload button, since it's masked by the invisible file input field
        parent.mouseenter(function() {
            parent.find('.button').addClass('hover')
        })
        parent.mouseleave(function() {
            parent.find('.button').removeClass('hover')
        })
        // Add .focus class to the upload button, when the input is focused for the same reason
        input.focus(function() {
            parent.find('.button').addClass('focus')
        })
        input.blur(function() {
            parent.find('.button').removeClass('focus')
        })
    },

    getDropTarget: function() {
        var selector = this.get('dropSelector')
        if (selector) {
            return this.findBySelector(selector, 'drop target')
        } else {
            return this.$()
        }
    },
    getDropOverlayTarget: function() {
        var selector = this.get('dropOverlaySelector')
        if (!selector) {
            selector = this.get('dropSelector')
        }
        if (selector) {
            return this.findBySelector(selector, 'ember-drop-overlay-target')
        } else {
            return this.$()
        }
    },
    getDropOverlay: function() {
        var overlay = this.get('dropOverlay')

        if (!overlay) {
            var dropTarget = this.getDropOverlayTarget()
            var dropAreaSize = this.get('dropAreaSize')
            var iconSize = ['small', 'large'].indexOf(dropAreaSize) === -1 ? 'small' : dropAreaSize
            var arrow = this.get('allowDrop') ? '<div class="icon">' + svg('icons/arrow-down-' + iconSize) + '</div>' : ''

            dropTarget.append(
                '<div class="file-uploader-overlay ' + dropAreaSize + '">' +
                '<div class="content">' +
                '<div class="drop-tip">' + arrow + '<div class="text">' + this.get('dropTip') + '</div></div>' +
                '<div class="escape-tip">' + t('escape_tip') + '</div>' +
                '</div>' +
                '</div>'
            )
            overlay = dropTarget.find(' > .file-uploader-overlay')
            this.set('dropOverlay', overlay)
        }
        return overlay
    },

    setupDropTarget: function() {
        this.addOrRemoveDropTargetEvents('on')
    },

    addOrRemoveDropTargetEvents: function(method) {
        var dropTarget = this.getDropTarget()
        var body = $(this.container.lookup('application:main').get('rootElement'))
        body[method]('dragenter', functionProxy(this.onBodyDragEnter, this))
        body[method]('dragleave', functionProxy(this.onBodyDragLeave, this))
        body[method]('dragover', functionProxy(this.onBodyDragOver, this))
        dropTarget[method]('dragover', functionProxy(this.onDragOver, this))
        dropTarget[method]('dragleave', functionProxy(this.onDragLeave, this))
        if (this.get('allowDrop')) {
            body[method]('drop', functionProxy(this.onBodyDrop, this))
            dropTarget[method]('drop', functionProxy(this.onDrop, this))
        } else {
            body[method]('drop', functionProxy(this.onDisabledDrop, this))
            dropTarget[method]('drop', functionProxy(this.onDisabledDrop, this))
        }
    },

    onDisabledDrop: function(e) {
        this.hideDropOverlay()
        return false
    },
    onBodyDragEnter: function(e) {
        this.ignoreNextBodyDragLeave = true
        this.showDropOverlay()
        return false
    },
    onBodyDragLeave: function(e) {
        if (this.ignoreNextBodyDragLeave) {
            this.ignoreNextBodyDragLeave = false
        } else {
            this.hideDropOverlay()
        }
    },
    onBodyDragOver: function(e) {
        this.stopEvent(e)
        return false
    },
    onBodyDrop: function(e) {
        this.hideDropOverlay()
        this.stopEvent(e)
    },
    onDragOver: function(e) {
        this.getDropOverlay().addClass('hover')
    },
    onDragLeave: function(e) {
        this.getDropOverlay().removeClass('hover')
    },
    onDrop: function(e) {
        var self = this
        var files = e.dataTransfer.files
        if (this.get('uploadOnsubmit')) {
            for (var i = 0; i < files.length; i++) {
                var file = files.item(i)
                this.makePreview(file)
            }
        } else {
            Em.run(function() {
                self.enqueue(files)
            })
        }
    },
    showDropOverlay: function() {
        var dropTarget = this.getDropOverlayTarget()
        var overlay = this.getDropOverlay()

        overlay.css('display', 'block')
        overlay.css('z-index', 1 + highestZIndex(overlay.siblings()))
        overlay.height(dropTarget.outerHeight())
        overlay.width(dropTarget.outerWidth())
        overlay.position({
            my: 'top left',
            at: 'top left',
            of: dropTarget
        })
    },
    hideDropOverlay: function() {
        var dropTarget = this.getDropOverlayTarget()
        var overlay = this.getDropOverlay()
        overlay.css('display', 'none')
    },

    willDestroyElement: function() {
        var overlay = this.get('dropOverlay')
        var externalInput = this.get('externalInput')
        // Overlay
        if (overlay) {
            overlay.remove()
        }
        // External input
        if (externalInput) {
            externalInput.parent().removeClass('file-uploader-input-parent')
            externalInput.remove()
        }
        // Drop target
        if (this.get('allowDrag')) {
            this.addOrRemoveDropTargetEvents('off')
        }
    },

    makePreview: function(file) {
        var self = this
        var preview
        var reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onloadend = function() {
            var data = reader.result
            self.sendAction('createPreview', { name: file.name, data: data, fileType: file.type, file: file })
        }
    },

    convertMegabytesToBytes: function(megabytes) {
        return megabytes * 1024 * 1024
    },

    getFileSizeLimitInMB: function() {
        var fileSizeLimit = this.get('fileSizeLimit') || 10
        var apiFileSizeLimit = this.get('apiFileSizeLimit') || 10
        return fileSizeLimit < apiFileSizeLimit ? fileSizeLimit : apiFileSizeLimit
    },

    isFileSizeLimitExceeded: function(fileSize) {
        var sizeLimit = this.getFileSizeLimitInMB()
        return fileSize > this.convertMegabytesToBytes(sizeLimit)
    },

    isSomeFileExceededLimit: false,

    enqueue: function(file, isFromArray) {
        var self = this
        var item
        var batch = this.get('batch')
        var container = this.container
        reportProgress = this.get('reportProgress')

        if (this.get('isSomeFileExceededLimit')) {
            return
        }

        if (Em.isArray(file)) {
            Array.prototype.forEach.call(file, function(f) {
                if (this.get('isSomeFileExceededLimit')) {
                    return
                }

                this.enqueue(f, true)
            }, this)

            this.set('isSomeFileExceededLimit', false)
            return
        }

        if (!this.get('multiple') && this.get('queue.length') > 0) {
            return
        }

        if (this.isFileSizeLimitExceeded(file.size)) {
            if (isFromArray) {
                this.set('isSomeFileExceededLimit', true)
            }

            var sizeLimit = this.getFileSizeLimitInMB()

            container.lookup('util:notification').notify('file-size-limit', t('upload_file_size_error', {
                size: sizeLimit
            }), 'error')

            return
        }

        this.sendAction('didStartUploading', reportProgress ? {
            browserFile: file,
            uploaded: false
        } : true)

        this.set('isUploading', true)
        item = QueueItem.create({
            fileUploader: this,
            file: file,
            sendAsFormData: this.get('sendAsFormData'),
            formDataParams: this.get('formDataParams')
        })
        item.on('upload', function(billyFile, payload) {
            if (payload && payload.data) {
                payload.data.file = file
            }

            BD.store.sideload(payload)

            self.sendAction('didUploadFile', {
                file: billyFile,
                payload: payload,
                browserFile: file
            })
        })
        item.on('error', function(payload, errorDetails) {
            self.sendAction('uploadFailed', {
                file: item.file,
                payload: payload
            })

            var status = errorDetails && errorDetails.status
            var errorMessage = t('upload_failed')

            // Handle too large request error
            if (status === 413) {
                var apiFileSizeLimit = self.get('apiFileSizeLimit')
                errorMessage = t('upload_file_size_error', { size: apiFileSizeLimit })
            }

            container.lookup('util:notification').notify('file-size-limit', errorMessage, 'error')
            self.removeQueueItem(item)
        })
        item.on('done', function(item) {
            self.sendAction('didUploadFinish', {
                file: item.file
            })
            if (self.get('isDestroyed')) {
                return
            }
            self.removeQueueItem(item)
        })
        this.get('queue').pushObject(item)
        if (batch) {
            batch.pushObject(item)
        }
        manager.enqueue(item)
    },

    removeQueueItem: function(item) {
        this.get('queue').removeObject(item)
        if (this.get('queue.length') === 0) {
            this.set('isUploading', false)
            this.sendAction('doneUploading')
        }
    },

    uploadButtonIsVisible: function() {
        return (!this.get('inputSelector') && (this.get('multiple') || this.get('queue.length') === 0))
    }.property('inputSelector', 'multiple', 'queue.@each'),

    queueItemViewClass: require('./queue-item-view')
})

module.exports.Batch = require('./batch')

module.exports.locale = i18nContext.locale

module.exports.lang = function() {
    console.warn('.lang() is deprecated. Use .locale() instead')
    return i18nContext.locale.apply(null, arguments)
}

module.exports.setHeader = function(key, value) {
    config.headers[key] = value
}
module.exports.clearHeader = function(key) {
    delete (config.headers[key])
}
module.exports.clearHeaders = function() {
    config.headers = {}
}

var batmask = require('batmask')
var t = require('i18n').t
// Actor name below is used to find access token required to start reminder service. This actor is being created in React: react-app/src/modules/integrations/elements/DebtCollectionModalsGroup/constants/debtCollectionActor.ts. Those names have to be the same here and in React file.
var debtCollectionActorName = 'Advokaternes Inkasso Service integration'
var cloudCollectApiKey = ENV.CLOUD_COLLECT_API_KEY
var cloudCollectStartReminderServiceUrl = ENV.CLOUD_COLLECT_URL + '/integrations/billy/start-reminder-service/'
var NOTIFICATION_KEYS = require('../notificationKeys')

module.exports = Em.Object.extend({
    userOrganizations: Em.inject.service(),

    organization: Em.computed.oneWay('userOrganizations.activeOrganization'),

    api: Ember.inject.service(),

    _postDebtCollection: function(token, contactId, organizationId) {
        return $.ajax({
            type: 'POST',
            url: cloudCollectStartReminderServiceUrl,
            dataType: 'json',
            xhrFields: {
                withCredentials: true
            },
            cache: false,
            beforeSend: function(xhr) {
                xhr.setRequestHeader('Authorization', 'Bearer ' + cloudCollectApiKey)
            },
            data: JSON.stringify({
                accessToken: token,
                contactId: contactId,
                organizationId: organizationId
            }),
            contentType: 'application/json; charset=utf-8'
        })
    },

    _postToTimeline: function(invoice) {
        var timelineUrl = '/organizations/' + invoice.get('organization.id') + '/invoices/' + invoice.get('id') + '/timeline'
        this.get('api').request('POST', timelineUrl, {
            payload: {
                data: {
                    comment: t('ais.did_send_to_debt_collection')
                }
            }
        })
    },

    _getActorToken: function(actor) {
        return actor.get('accessTokens').promise
            .then(function(tokens) {
                return tokens.get('firstObject.material')
            })
    },

    _findToken: function() {
        var self = this
        var organization = this.get('organization')
        return Billy.OAuthActor.findByQuery({
            organizationId: organization.get('id')
        }).promise
            .then(function(actors) {
                var actor = actors.findBy('name', debtCollectionActorName)
                if (actor) {
                    return self._getActorToken(actor)
                } else {
                    return null
                }
            })
    },

    sendDebtCollection: function(invoice) {
        var self = this
        var contactId = invoice.get('contact.id')
        var organizationId = invoice.get('organization.id')

        batmask.maskDelayed()

        return this._findToken()
            .then(function(token) {
                self._postDebtCollection(token, contactId, organizationId)
                    .then(function() {
                        self.container.lookup('util:notification').success(NOTIFICATION_KEYS.AIS_DEBT_COLLECTION_SEND, t('ais.did_send_to_debt_collection'))
                        self._postToTimeline(invoice)
                    }, function() {
                        self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.AIS_DEBT_COLLECTION_SEND, t('ais.api_error'))
                    })
            })
            .finally(function() {
                batmask.unmask()
            })
    }
})

var t = require('i18n').t
var storage = require('storage')
var Cookies = require('js-cookie')
var cookieKey = require('../constants/cookieKey')
var browserReport = require('../utils/supported-browsers')
var tracking = require('../utils/tracking')
var CustomEvent = require('../constants/customEvent')

module.exports = Em.Controller.extend({
    COUPON_CODES: [{
        code: 'igGcvakeWn',
        discount: 15,
        year: 2022
    }, {
        code: 'opstartbilly',
        discount: 100,
        year: 2022
    }],

    needs: ['user'],

    api: Em.inject.service(),

    user: Em.computed.oneWay('controllers.user'),

    userOrganizations: Em.inject.service(),

    segment: Em.inject.service(),

    loginEvent: Em.inject.service(),

    userUmbrellas: Em.inject.service(),

    campaign: Ember.inject.service(),

    couponOffer: Ember.inject.service(),

    organizationCardSubscription: Ember.inject.service(),

    bankConnections: Em.inject.service(),

    sideMenu: Em.inject.service(),

    activeUmbrella: Em.computed.alias('sideMenu.activeUmbrella'),

    organization: Em.computed.oneWay('userOrganizations.activeOrganization'),

    isLoading: false,

    bankConnectionErrors: [],

    collectPassedCoupons: function() {
        var queryString = window.location.search
        var urlParams = new URLSearchParams(queryString)
        var couponFromUrl = urlParams.get('coupon')

        if (couponFromUrl) {
            var validCoupon = this.COUPON_CODES.filter(function(coupon) {
                return coupon.code === couponFromUrl
            })
            Cookies.set(cookieKey.couponARDiscount, validCoupon, { expires: new Date('2023-12-31') })
        }
    }.on('init'),

    queryParams: {
        planSelectedFromSignup: 'plan_selected_from_signup'
    },

    setWootricVars: function() {
        if (!window.wootric) {
            return
        }

        window.Wootric = {
            thankYouLinks: {
                thank_you_link_text_list: {
                    promoter_thank_you_link_text: t('trustpilot_review_us')
                },
                thank_you_link_url_list: {
                    promoter_thank_you_link_url: ENV.TRUSTPILOT_BGL_URL + '?p=' + encodeURIComponent(this.get('user.trustpilotReviewData'))
                }
            }
        }
    }.observes('user.model.{trustpilotReviewData}'),

    segmentIdentification: function() {
        var organization = this.get('organization')

        this.get('segment').identify(organization)
    }.observes('organization.{id}'),

    loginEventHandling: function() {
        this.get('loginEvent').processLoginEvent()
    }.observes('organization.{id}'),

    userflow: function() {
        if (!this.get('user.id')) {
            return
        }

        this.initUserflow()
    }.observes('userOrganizations.activeMembership', 'user.{id}'),

    initUserflow: function() {
        var api = this.get('api')
        var org = this.get('organization')
        var user = this.get('user')
        var userUmbrellas = this.get('userUmbrellas')
        var activeMembership = this.get('userOrganizations.activeMembership')
        var activeUmbrella = this.get('activeUmbrella')

        if (!org && !activeUmbrella) {
            return
        }

        if (activeUmbrella) {
            tracking.startUmbrellaUserflow(activeMembership, activeUmbrella, api, user)
        }

        if (!org || !userUmbrellas || !user || !activeMembership) {
            console.error('Could not start UserFlow tracking because of missing data.')
            return
        }

        if (org.get('isBrandBilly')) {
            tracking.startUserflow(user, org, userUmbrellas, activeMembership)
        } else {
            tracking.startUserflowAgeras(user)
        }
    }.observes('activeUmbrella.id'),

    hasOrganizationInfoBars: function() {
        var user = this.get('user')
        var organization = this.get('organization')
        // Check logged in user
        if (!user || !user.get('isAuthenticated') || !organization) {
            return false
        }
        // Check user owns the organization
        if (!organization || !user.get('userOrganizations.activeMembership.isOwner')) {
            return false
        }
        // Check for no lock
        return !organization.get('isLocked')
    }.property('user.model', 'user.isAuthenticated', 'organization', 'userOrganizations.activeMembership.isOwner', 'organization.isLocked'),

    hasTrialExpirationBar: function() {
        if (!this.get('hasOrganizationInfoBars') || this.get('hasCampaignOfferBar')) {
            return false
        }

        var organization = this.get('organization')

        // Don't show for free orgs or non-trials or trials with activation already set
        if (
            organization.get('consolidatedBilling') ||
            !organization.get('isSubscriptionPlanPaid') ||
            !organization.get('isTrial') ||
            organization.get('activationDate')
        ) {
            return false
        }

        // Check if the bar has been closed previously by the user
        if (storage('hideTrialExpirationWarningBar:' + organization.get('id')) === 'true') {
            return false
        }

        if (organization.get('defaultActiveCardSubscription')) {
            return false
        }

        return true
    }.property('hasOrganizationInfoBars', 'organization.{isSubscriptionPlanPaid,isTrial,cardSubscriptions,isSubscriptionBankPayer,consolidatedBilling,defaultActiveCardSubscription}', 'hasCampaignOfferBar'),

    hasMissingCardBar: function() {
        if (!this.get('hasOrganizationInfoBars') || this.get('hasCampaignOfferBar') || this.get('hasTrialExpirationBar')) {
            return false
        }

        var organization = this.get('organization')
        var isPendingTrial = organization.get('isTrial') && !organization.get('activationDate') // trial with no scheduled upgrade

        // Don't show fo free orgs or trials
        if (!organization.get('isSubscriptionPlanPaid') || isPendingTrial || !(organization.get('subscriptionPrice') > 0)) {
            return false
        }

        // Check if the bar has been closed previously by the user
        if (storage('hideMissingCardWarningBar:' + organization.get('id')) === 'true') {
            return false
        }

        // Don't show to back payers or consolidated billing payers
        if (organization.get('isSubscriptionBankPayer') || organization.get('consolidatedBilling')) {
            return false
        }

        if (!this.get('organizationCardSubscription').isApplicable() || organization.get('activeCardSubscriptions.length')) {
            return false
        }

        return true
    }.property('hasOrganizationInfoBars', 'organization.{isSubscriptionPlanPaid,isTrial,subscriptionPrice,activeCardSubscriptions,isSubscriptionBankPayer,consolidatedBilling}', 'hasCampaignOfferBar', 'hasTrialExpirationBar', 'organizationCardSubscription'),

    hasOverdueInvoiceBar: function() {
        if (!this.get('hasOrganizationInfoBars') || this.get('hasMissingCardBar')) {
            return false
        }
        var organization = this.get('organization')
        var defaultActiveCardSubscription = organization.get('defaultActiveCardSubscription')
        var hadTimeToRecharge = defaultActiveCardSubscription &&
            moment.utc().subtract(1, 'hour').isAfter(moment(defaultActiveCardSubscription.updatedTimestamp))
        var hasUnpaidInvoices = organization.get('unpaidInvoices').any(function(invoice) {
            // Due date is in the past
            var pastDue = moment.utc().isAfter(invoice.get('dueDate'))
            // Created over 3 hours ago
            var hadTimeToBePaid = moment.utc().subtract(3, 'hour').isAfter(invoice.get('createdTime'))

            return pastDue || hadTimeToBePaid
        })

        // Check for non trial organization with unpaid invoices
        return !organization.get('isTrial') && hadTimeToRecharge && hasUnpaidInvoices
    }.property('hasOrganizationInfoBars', 'hasMissingCardBar', 'organization.isTrial', 'organization.defaultActiveCardSubscription', 'organization.unpaidInvoices', 'organization.unpaidInvoices.isLoaded'),

    hasCampaignOfferBar: function() {
        if (!this.get('hasOrganizationInfoBars')) {
            return false
        }
        var campaign = this.get('campaign')
        return campaign.isApplicable()
    }.property('hasOrganizationInfoBars', 'campaign', 'campaign.isLoaded'),

    hasCouponOfferBar: function() {
        if (!this.get('hasOrganizationInfoBars')) {
            return false
        }

        if (!this.get('organization.isTrial')) {
            return false
        }

        var couponOffer = this.get('couponOffer')
        return couponOffer.isApplicable()
    }.property('hasOrganizationInfoBars', 'couponOffer', 'couponOffer.isLoaded'),

    hasBrowserUnsupportedBar: function() {
        if (browserReport.supported) {
            return false
        }

        // Check if the bar has been closed previously by the user
        if (window.sessionStorage && window.sessionStorage.getItem('hideBrowserUnsupportedBar') === 'true') {
            return false
        }

        return true
    }.property('hasOrganizationInfoBars'),

    hasSupportExtraBusyBar: function() {
        // Check if the bar has been closed previously by the user
        if (window.sessionStorage && window.sessionStorage.getItem('hideSupportExtraBusyBar') === 'true') {
            return false
        }

        // Toggle this to true/false to show/hide the bar
        return false
    }.property('hasOrganizationInfoBars'),

    hasMaintenanceBar: function() {
        // Check if the bar has been closed previously by the user
        if (window.sessionStorage && window.sessionStorage.getItem('hideMaintenanceBar20210410') === 'true') {
            return false
        }

        return true
    }.property('hasOrganizationInfoBars'),

    loadBankConnections: function() {
        var self = this
        var bankConnections = this.get('bankConnections')
        var bankConnectionErrors = []

        if (!this.get('hasOrganizationInfoBars')) {
            return
        }

        bankConnections.ensureLoaded().then(function(connections) {
            connections.each(function(connection) {
                if (connection.get('errorCode')) {
                    bankConnectionErrors.push({
                        accountId: connection.get('accountId'),
                        errorCode: connection.get('errorCode'),
                        errorMessage: connection.get('errorMessage'),
                        referenceId: connection.get('referenceId'),
                        type: 'bankConnection'
                    })
                }
            })
            return bankConnections.getBankSessions(self.get('organization.id'))
        })
            .then(function(sessions) {
                sessions.each(function(session) {
                    if (session.errorCode) {
                        var account = bankConnections.findByReferenceId(session.id)
                        var accountId = account ? account.get('accountId') : null

                        bankConnectionErrors.push({
                            accountId: accountId,
                            errorCode: session.errorCode,
                            errorMessage: session.errorMessage,
                            referenceId: session.id,
                            type: 'session'
                        })
                    }
                })

                self.set('bankConnectionErrors', bankConnectionErrors)
            })
    }.property('hasOrganizationInfoBars'),

    setUpThemeClassName: function() {
        var THEME_LAYOUT_CLASS_PREFIX = 'theme-layout'

        window.addEventListener(CustomEvent.ThemeLayoutChanged, function(event) {
            var bodyClassNames = Array.from(document.body.classList)
            var newTheme = event.detail

            bodyClassNames.forEach(function(className) {
                if (className.includes(THEME_LAYOUT_CLASS_PREFIX)) {
                    document.body.classList.remove(className)
                }
            })

            var newClassName = THEME_LAYOUT_CLASS_PREFIX + '-' + newTheme
            document.body.classList.add(newClassName)
        })
    }.on('init')
})

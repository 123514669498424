module.exports = require('ember-super-field').types.Model.extend({
    modelClass: Billy.SalesTaxMetaField,

    sortProperty: 'priority',

    query: function() {
        return {
            organization: this.get('userController.activeOrganization')
        }
    }.property('userController.activeOrganization')
})

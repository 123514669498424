var HeaderCellView = require('./header-cell-view')
var HeaderCollectionView = require('./header-collection-view')

module.exports = HeaderCellView.extend({
    templateName: 'ui/list/table/nested-header-cell',

    extraClass: 'nested-table-cell',

    controller: null,

    init: function() {
        this._super()
        var controller = this.get('column.controllerClass').create({
            columns: this.get('column.columns')
        })
        this.set('controller', controller)
    },

    headerCollectionViewClass: HeaderCollectionView
})

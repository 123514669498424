var NOTIFICATION_KEYS = require('../notificationKeys')
var t = require('i18n').t

module.exports = Em.Object.extend({
    api: Em.inject.service(),

    user: function() {
        return this.container.lookup('controller:user')
    }.property(),

    userOrganizations: Em.inject.service(),

    organization: Em.computed.oneWay('userOrganizations.activeOrganization'),

    financingUrl: function() {
        return '/integrations/agerasfinance/organizations/' + this.get('organization.id')
    }.property('organization'),

    goToApplicationForm: function() {
        var self = this

        return self.get('api').getData(self.get('financingUrl') + '/customer', {}, { raw: true })
            .then(function(data) {
                var accessToken = data.token.accessToken

                var trailingURL = 'customer_id=' + self.get('organization.id') + '&user_id=' + self.get('user.id') + '&redirect_uri=' + window.location.origin + window.location.pathname + '?application_sent=1'

                var url = ENV.AGERAS_FINANCE_FRONTEND_URL + '/invoice-financing?' + trailingURL + '#token=' + accessToken
                window.location.replace(url)
            })
    },

    approveInvoiceFactoring: function(estimateId) {
        var self = this

        return self.get('api').post(self.get('financingUrl') + '/invoice-financing-estimates/' + estimateId + '/actions/approve')
            .catch(function(e) {
                if (e.code === 409) {
                    console.error(t('financing.estimate_already_approved'))
                    self.container.lookup('util:notification').notify(NOTIFICATION_KEYS.FINANCING_ESTIMATE_ALREADY_APPROVED, t('financing.estimate_already_approved'))
                } else {
                    throw e
                }
            })
    },

    getEstimateSimulation: function(invoiceCurrency, invoiceAmount, invoiceDueDate) {
        var self = this

        return new Em.RSVP.Promise(function(resolve, reject) {
            return self.get('api').post(self.get('financingUrl') + '/invoice-financing-estimates/actions/simulate', {
                payload: {
                    invoice_due: encodeURIComponent(invoiceDueDate),
                    invoice_currency: invoiceCurrency,
                    invoice_amount: invoiceAmount
                }
            })
                .then(function(simulation) {
                    if (simulation.payout_amount) {
                        return resolve(simulation)
                    }

                    return resolve(null)
                })
        })
    },

    getInvoiceFactoring: function(invoiceId, invoiceNo, invoiceCurrency, invoiceAmount, invoiceDueDate) {
        var self = this

        return new Em.RSVP.Promise(function(resolve, reject) {
            return self.get('api').post(self.get('financingUrl') + '/invoice-financing-estimates', {
                payload: {
                    invoice_due: invoiceDueDate,
                    invoice_currency: invoiceCurrency,
                    invoice_amount: invoiceAmount,
                    invoice_number: invoiceNo,
                    external_invoice_id: invoiceId,
                    company_registration_number: self.get('organization.registrationNo')
                }
            })
                .then(resolve)
                .catch(function(e) {
                    console.error(e)
                    if (e.status === 400 || e.status === 404 || e.status === 409) {
                        return resolve({ data: {} })
                    }

                    return reject(e)
                })
        }).then(function(loan) {
            return loan
        })
    },

    getCustomer: function() {
        var self = this

        return self.get('api').getData(self.get('financingUrl') + '/customer', {}, { raw: true })
            .then(function(payload) {
                return payload && payload.customer
            }, function() {
                return false
            })
    },

    hasCustomerIncompleteData: function() {
        var self = this

        return self.getCustomer().then(function(customer) {
            return !customer.id || customer.approval_state === 'draft'
        })
    },

    isFinancingEnabled: function() {
        var self = this
        return Em.RSVP.all([
            self.getCustomer()
        ]).then(function(results) {
            var customer = results[0]

            return !customer || (customer.approval_state !== 'rejected' && customer.approval_state !== 'customer_rejected')
        })
    },

    isFinancingDismissed: function() {
        var api = this.get('api')
        var url = '/v2/organizations/' + this.get('organization.id') + '/settings/billy'

        return api.getData(url, undefined, { raw: true })
            .then(function(payload) {
                var dismissedDate = payload.settings.financingDismissedDate

                return !!dismissedDate
            }, function() {
                return false
            })
    }
})

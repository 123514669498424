module.exports = Em.Route.extend({
    userOrganizations: Em.inject.service(),

    model: function(params) {
        var self = this
        var id = params.organization_id
        return this.get('userOrganizations').all()
            .then(function(orgs) {
                var org = orgs.findBy('organizationId', id)
                if (org) {
                    self.transitionTo('dashboard', org.url)
                } else {
                    self.transitionTo('organizations')
                }
            })
    }
})

var model = BD.Model.extend({
    zipcode: BD.attr('string'),
    city: BD.belongsTo('Billy.City'),
    state: BD.belongsTo('Billy.State'),
    country: BD.belongsTo('Billy.Country'),
    latitude: BD.attr('number'),
    longitude: BD.attr('number')
})
model.reopenClass({
    qProperties: ['zipcode']
})
module.exports = model

module.exports = Em.Route.extend({
    userOrganizations: Ember.inject.service(),

    coupons: Ember.inject.service(),

    model: function(params) {
        var self = this
        var code = params.code
        var url = params.url
        var organization
        return this.get('userOrganizations').all()
            .then(function(allOrganizations) {
                var organizationId = allOrganizations.findBy('url', url).organizationId
                return Billy.Organization.find(organizationId).promise
            })
            .then(function(org) {
                organization = org
                return self.get('coupons').getCoupon(organization.get('id'), code)
            })
            .then(function(coupon) {
                return {
                    code: code,
                    coupon: coupon,
                    organization: organization
                }
            })
    },

    setupController: function(controller, props) {
        controller.set('code', props.code)
        controller.set('coupon', props.coupon)
        controller.set('organization', props.organization)
    }
})

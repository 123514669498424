require('ember-i18n');
var _ = require('lodash')

var localeFactory = require('./locale-factory'),
    tFactory = require('./t-factory'),
    tProperty = require('./t-property');

var contexts = {},
    defaultLocale = 'en_US';

module.exports = context;
module.exports.getContexts = getContexts;
module.exports.setAllLocales = setAllLocales;
module.exports.tProperty = tProperty;

function context(contextName, locales) {
    var containerContextName = 'context:' + (contextName || ''); //We need this, since `null` is not a valid hash key

    var m = contexts[containerContextName];

    if (m) {
        if (!_.every(_.keys(m.__locales), function(key) { return m.__locales[key] === locales[key] })) {
            throw new Error('You cannot redefine the i18n context `'+contextName+'` with a different locales');
        }
        return m;
    }

    m = {
        __locales: locales,
        locale: localeFactory(contextName, locales),
        t: tFactory(contextName === null ? '' : contextName+'.'),
        tProperty: tProperty,
        destroy: destroyFactory(containerContextName)
    };

    contexts[containerContextName] = m;

    m.locale(defaultLocale);

    return m;
}

function getContexts() {
    var allContexts = [];

    for (var k in contexts) {
        if (contexts.hasOwnProperty(k)) {
            allContexts.push(contexts[k]);
        }
    }

    return allContexts;
}

function setAllLocales(newLocale) {
    defaultLocale = newLocale;

    context.getContexts().forEach(function(c) {
        c.locale(newLocale);
    });
}

function destroyFactory(containerContextName) {
    return function() {
        delete contexts[containerContextName];
    };
}

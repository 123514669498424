var model = BD.Model.extend({
    name: BD.attr('string'),
    aliases: BD.attr('string'),
    icon: BD.attr('string')
})
model.reopenClass({
    qProperties: ['id', 'name', 'aliases']
})

module.exports = model

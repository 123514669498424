module.exports = Em.View.extend({
    registerListeners: function() {
        var el = this.$()

        el.delegate('input[name="allSelected"]', 'change', this.onAllCheckboxChange.bind(this))
        el.delegate('.x-table tbody input[type="checkbox"]', 'change', this.onCheckboxChange.bind(this))
    }.on('didInsertElement'),

    onAllCheckboxChange: function(e) {
        Em.run(this, function() {
            e.target.checked = !e.target.checked // Revert the user's action. The controller will set this
            this.get('controller').send('toggleSelectAll')
        })
    },

    onCheckboxChange: function(e) {
        var index = $(e.target).closest('tr').index()
        Em.run.join(this, function() {
            e.target.checked = !e.target.checked // Revert the user's action. The controller will set this
            this.get('controller').send('toggleSelect', index)
        })
    }
})

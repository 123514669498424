// moment.js language configuration
// language : tamil (ta)
// author : Arjunkumar Krishnamoorthy : https://github.com/tk120404

(function (factory) {
    if (typeof define === 'function' && define.amd) {
        define(['moment'], factory); // AMD
    } else if (typeof exports === 'object') {
        module.exports = factory(require('../moment')); // Node
    } else {
        factory(window.moment); // Browser global
    }
}(function (moment) {
    /*var symbolMap = {
            '1': '௧',
            '2': '௨',
            '3': '௩',
            '4': '௪',
            '5': '௫',
            '6': '௬',
            '7': '௭',
            '8': '௮',
            '9': '௯',
            '0': '௦'
        },
        numberMap = {
            '௧': '1',
            '௨': '2',
            '௩': '3',
            '௪': '4',
            '௫': '5',
            '௬': '6',
            '௭': '7',
            '௮': '8',
            '௯': '9',
            '௦': '0'
        }; */

    return moment.lang('ta', {
        months : 'ஜனவரி_பிப்ரவரி_மார்ச்_ஏப்ரல்_மே_ஜூன்_ஜூலை_ஆகஸ்ட்_செப்டெம்பர்_அக்டோபர்_நவம்பர்_டிசம்பர்'.split("_"),
        monthsShort : 'ஜனவரி_பிப்ரவரி_மார்ச்_ஏப்ரல்_மே_ஜூன்_ஜூலை_ஆகஸ்ட்_செப்டெம்பர்_அக்டோபர்_நவம்பர்_டிசம்பர்'.split("_"),
        weekdays : 'ஞாயிற்றுக்கிழமை_திங்கட்கிழமை_செவ்வாய்கிழமை_புதன்கிழமை_வியாழக்கிழமை_வெள்ளிக்கிழமை_சனிக்கிழமை'.split("_"),
        weekdaysShort : 'ஞாயிறு_திங்கள்_செவ்வாய்_புதன்_வியாழன்_வெள்ளி_சனி'.split("_"),
        weekdaysMin : 'ஞா_தி_செ_பு_வி_வெ_ச'.split("_"),
        longDateFormat : {
            LT : "HH:mm",
            L : "DD/MM/YYYY",
            LL : "D MMMM YYYY",
            LLL : "D MMMM YYYY, LT",
            LLLL : "dddd, D MMMM YYYY, LT"
        },
        calendar : {
            sameDay : '[இன்று] LT',
            nextDay : '[நாளை] LT',
            nextWeek : 'dddd, LT',
            lastDay : '[நேற்று] LT',
            lastWeek : '[கடந்த வாரம்] dddd, LT',
            sameElse : 'L'
        },
        relativeTime : {
            future : "%s இல்",
            past : "%s முன்",
            s : "ஒரு சில விநாடிகள்",
            m : "ஒரு நிமிடம்",
            mm : "%d நிமிடங்கள்",
            h : "ஒரு மணி நேரம்",
            hh : "%d மணி நேரம்",
            d : "ஒரு நாள்",
            dd : "%d நாட்கள்",
            M : "ஒரு மாதம்",
            MM : "%d மாதங்கள்",
            y : "ஒரு வருடம்",
            yy : "%d ஆண்டுகள்"
        },
/*        preparse: function (string) {
            return string.replace(/[௧௨௩௪௫௬௭௮௯௦]/g, function (match) {
                return numberMap[match];
            });
        },
        postformat: function (string) {
            return string.replace(/\d/g, function (match) {
                return symbolMap[match];
            });
        },*/
        ordinal : function (number) {
            return number + 'வது';
        },


// refer http://ta.wikipedia.org/s/1er1      

        meridiem : function (hour, minute, isLower) {
            if (hour >= 6 && hour <= 10) {
                return " காலை";
            } else   if (hour >= 10 && hour <= 14) {
                return " நண்பகல்";
            } else    if (hour >= 14 && hour <= 18) {
                return " எற்பாடு";
            } else   if (hour >= 18 && hour <= 20) {
                return " மாலை";
            } else  if (hour >= 20 && hour <= 24) {
                return " இரவு";
            } else  if (hour >= 0 && hour <= 6) {
                return " வைகறை";
            }
        },
        week : {
            dow : 0, // Sunday is the first day of the week.
            doy : 6  // The week that contains Jan 1st is the first week of the year.
        }
    });
}));

var model = BD.Model.extend({
    organization: BD.belongsTo('Billy.Organization'),
    daybook: BD.belongsTo('Billy.Daybook'),
    createdTime: BD.attr('datetime', { readonly: true }),
    entryDate: BD.attr('date'),
    voucherNo: BD.attr('string'),
    description: BD.attr('string'),
    extendedDescription: BD.attr('string'),
    apiType: BD.attr('string'),
    state: BD.attr('string'),
    type: BD.attr('string', { defaultValue: 'entry' }),
    priority: BD.attr('number'),
    lines: BD.hasMany('Billy.DaybookTransactionLine', 'daybookTransaction', { isEmbedded: true }),
    attachments: BD.hasMany('Billy.Attachment', 'owner', { isEmbedded: true })
})

model.reopenClass({
    supportsBulkSave: true
})

module.exports = model

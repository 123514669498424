module.exports = Em.ObjectController.extend({

    isSelectable: true,

    listControllerSelectionDidChange: function() {
        var newChecked = this.get('listController.selection').contains(this.get('content'))
        if (newChecked !== this.get('checked')) {
            this.set('checked', newChecked)
        }
    }.on('init').observes('listController.selection.@each'),

    extraClass: null,

    checked: false,
    onCheckedChange: function() {
        if (this.get('checked')) {
            this.get('listController').addSelected(this.get('content'))
        } else {
            this.get('listController').removeSelected(this.get('content'))
        }
    }.observes('checked'),

    click: function() {
        var listController = this.get('listController')
        var click = listController.get('click')
        if (!click) {
            return
        }
        click.call(listController, this.get('content'))
    },

    toString: function() {
        return '<list-item-controller>'
    }
})

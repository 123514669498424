var locales = {
    en_US: require('../locales/en_US'),
    da_DK: require('../locales/da_DK'),
    fr_FR: require('../locales/fr_FR')
}
var functionProxy = require('function-proxy')
var i18nContext = require('i18n-context')('ember_file_uploader', locales)
var t = i18nContext.t
var config = require('./config')

module.exports = Em.Object.extend(Em.Evented, {
    fileUploader: null,
    file: null,
    sendAsFormData: false,
    formDataParams: {},

    isTransferring: false,
    isProcessing: false,
    isCompleted: false,
    isFailed: false,
    progress: 0,
    status: '',

    uploadRequest: null,

    upload: function() {
        var file = this.get('file')
        var method = this.get('fileUploader.method')
        var url = this.get('fileUploader.url')
        var sendAsFormData = this.get('sendAsFormData')
        var formDataParams = this.get('formDataParams')
        var uploadRequest = new FileUploadRequest(method, url, file, sendAsFormData, formDataParams)
        var additionalHeaders = config.headers
        var uploaderHeaders = this.get('fileUploader.headers')
        this.set('isTransferring', true)
        this.set('uploadRequest', uploadRequest)
        uploadRequest.on('readystatechange', functionProxy(this.onReadyStateChange, this))
        uploadRequest.on('progress', functionProxy(this.onUploadProgress, this))
        uploadRequest.on('load', functionProxy(this.onUploadLoad, this))
        uploadRequest.on('error', functionProxy(this.onUploadError, this))
        uploadRequest.setRequestHeader('X-Filename', file.name)
        uploadRequest.setRequestHeader('X-File-Size', file.size)
        uploadRequest.setRequestHeader('X-Thumbnail-Names', this.get('fileUploader.thumbnailNames'))

        if (this.get('fileUploader.isPublic')) {
            uploadRequest.setRequestHeader('X-Public', true)
        }

        if (this.get('fileUploader.shouldScan')) {
            uploadRequest.setRequestHeader('X-Should-Scan', true)
        }

        if (this.get('fileUploader.organizationId')) {
            uploadRequest.setRequestHeader('X-OrganizationId', this.get('fileUploader.organizationId'))
        }

        var k
        for (k in additionalHeaders) {
            if (additionalHeaders.hasOwnProperty(k)) {
                uploadRequest.setRequestHeader(k, additionalHeaders[k])
            }
        }
        for (k in uploaderHeaders) {
            if (uploaderHeaders.hasOwnProperty(k)) {
                uploadRequest.setRequestHeader(k, uploaderHeaders[k])
            }
        }
        uploadRequest.send()
    },
    onReadyStateChange: function() {
        var self = this
        Em.run(function() {
            var uploadRequest = self.get('uploadRequest')
            var payload
            var file
            if (uploadRequest.readyState === 4) {
                try {
                    payload = JSON.parse(uploadRequest.responseText)
                } catch(exception) {
                    payload = uploadRequest.responseText
                    self.handleError()
                }
                self.set('isProcessing', false)
                self.set('isCompleted', true)
                if (uploadRequest.status >= 200 && uploadRequest.status < 300) {
                    if (payload && payload.files && payload.files.length) {
                        file = BD.store.load(Billy.File, payload.files[0])
                    }
                    self.trigger('upload', file, payload)
                } else {
                    var errorDetails = {
                        status: uploadRequest.status
                    }
                    if (uploadRequest.status === 422) {
                        errorDetails.message = payload.errorMessage
                    }
                    self.handleError(payload, errorDetails)
                }
                self.trigger('done', self)
            }
        })
    },
    onUploadProgress: function(e) {
        var self = this
        Em.run(function() {
            if (e.lengthComputable) {
                self.set('progress', Math.round((e.loaded / e.total) * 1000) / 1000)
            }
        })
    },
    onUploadLoad: function() {
        var self = this
        Em.run(function() {
            self.set('progress', 1)
            self.set('isProcessing', true)
            self.set('isTransferring', false)
        })
    },
    onUploadError: function() {
        var self = this
        Em.run(function() {
            self.handleError()
        })
    },
    handleError: function(payload, errorDetails) {
        var message = errorDetails && errorDetails.message
        this.set('isFailed', true)
        this.set('isTransferring', false)
        this.set('error', message || t('upload_failed'))
        this.trigger('error', payload, errorDetails)
        if (this.get('fileUploader.abortOnError')) {
            this.trigger('done', this)
        }
    },

    abort: function() {
        var uploadRequest = this.get('uploadRequest')
        if (uploadRequest) {
            uploadRequest.abort()
        }
        this.trigger('done', this)
    }
})

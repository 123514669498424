var moment = require('momentjs')
var RecurringInvoiceEditRoute = require('./recurring-invoice-edit')
var AuthorizedRoute = require('../mixins/authorized-route')
var Scope = require('../utils/scope')

module.exports = RecurringInvoiceEditRoute.extend(AuthorizedRoute, {
    scopes: [Scope.RecurringInvoiceRead],

    model: function(params) {
        var store = this.store
        var organization = this.modelFor('organization')
        var invoice = store.createRecord('recurring-invoice', {
            organization: organization,
            entryDate: moment(),
            state: 'open',
            recurringInterval: 'R/' + moment().add(1, 'day').hour(12).minute(0).second(0).format() + '/P1M',
            recurringSpawnMode: 'email',
            currency: organization.get('baseCurrency'),
            templateId: organization.get('defaultTemplateId')
        })

        var duplicatePromise = null

        if (params.fromQuoteId) {
            var query = {
                id: params.fromQuoteId,
                organization: organization.get('id')
            }

            duplicatePromise = this.store.find('quote', query)
                .then(function(quotes) {
                    if (quotes.get('length') === 0) {
                        throw new Error('The quote was not found.')
                    }

                    return quotes.objectAt(0)
                })
        }

        if (duplicatePromise) {
            // If we need to duplicate a quote/invoice we load it first and then copy all its values
            return duplicatePromise
                .then(function(duplicate) {
                    invoice.setProperties(duplicate.getProperties('contact', 'attContactPerson', 'taxMode', 'currency', 'templateId', 'orderNo'))

                    if (duplicate instanceof Billy.Invoice) {
                        invoice.setProperties(duplicate.getProperties('type', 'contactMessage', 'paymentTermsMode', 'paymentTermsDays'))
                        return duplicate.get('lines').promise
                    } else {
                        invoice.setProperties({
                            contactMessage: duplicate.get('headerMessage')
                        })
                        return duplicate.get('lines')
                    }
                })
                .then(function(foundLines) {
                    // "Map" to simple array
                    var priority = 1
                    var lines = []

                    foundLines.forEach(function(line) {
                        lines.push(line)
                    })

                    lines.sortBy('priority')
                    lines.forEach(function(line) {
                        var properties = line.getProperties(
                            'product',
                            'description',
                            'quantity',
                            'unitPrice',
                            'taxRate',
                            'currentTaxRate',
                            'discountText',
                            'discountMode',
                            'discountValue'
                        )

                        properties.invoice = invoice
                        properties.priority = priority++
                        properties.recurringInvoice = invoice

                        store.createRecord('recurring-invoice-line', properties)
                    })

                    return invoice
                })
        }

        if (params.newContactId) {
            invoice.set('contact', Billy.Contact.find(params.newContactId))
        }

        return invoice
    },

    resetController: function(controller) {
        controller.set('fromQuoteId', null)
    }
})

module.exports = BD.Model.extend({
    organization: BD.belongsTo('Billy.Organization'),
    contact: BD.belongsTo('Billy.Contact'),
    scheduledInvoice: BD.belongsTo('Billy.ScheduledInvoice'),
    product: BD.belongsTo('Billy.Product'),
    description: BD.attr('string'),
    quantity: BD.attr('number'),
    unitPrice: BD.attr('number'),
    discountText: BD.attr('string'),
    discountMode: BD.attr('string'),
    discountValue: BD.attr('number'),
    isRecurring: BD.attr('boolean')
})

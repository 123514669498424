var t = require('i18n').t
var batmask = require('batmask')
var Scope = require('../utils/scope')

module.exports = Em.Object.extend({
    auth: Ember.inject.service(),

    isLoaded: false,

    data: null,

    api: Em.inject.service(),

    userOrganizations: Em.inject.service(),

    organization: Em.computed.oneWay('userOrganizations.activeOrganization'),

    invalidate: function() {
        this.set('isLoaded', false)
            .set('data', null)
        this._loadPromise = null
    }.observes('organization'),

    load: function() {
        var self = this
        var organization = this.get('organization')
        this._loadPromise = Em.RSVP.all([
            this.get('api').getData('/organizations/' + organization.get('id') + '/bankConnections'),
            this.get('api').getData('/spiir/organizations/' + organization.get('id') + '/sessions')
        ])
            .then(function(response) {
                var connections = response[0]
                var sessions = response[1]

                var bankConnections = connections
                    .map(function(c) {
                        // Everything we get from the server must be connected
                        c.isConnected = true
                        c.session = sessions.findBy('id', c.referenceId)

                        return c
                    })
                    .map(self._decorateBankConnection.bind(this))

                self.set('data', bankConnections)
                self.set('isLoaded', true)

                return bankConnections
            })

        return this._loadPromise
    },

    _decorateBankConnection: function(data) {
        var c = Ember.Object.create(data)
        c.account = Billy.Account.find(data.accountId)
        return c
    },

    ensureLoaded: function() {
        return this._loadPromise || this.load()
    },

    findByReferenceId: function(referenceId) {
        return this.get('data').findBy('id', referenceId)
    },

    find: function(accountId) {
        var self = this
        return this.ensureLoaded()
            .then(function() {
                return self.findSync(accountId)
            })
    },

    findSync: function(accountId) {
        if (accountId instanceof Billy.Account) {
            accountId = accountId.get('id')
        }
        if (!this.get('isLoaded')) {
            throw new Error('Bank connections have not been loaded yet.')
        }
        var bankConnection = this.get('data').findBy('accountId', accountId)
        if (!bankConnection) {
            bankConnection = this._decorateBankConnection({
                accountId: accountId,
                isConnected: false
            })
            this.get('data').pushObject(bankConnection)
        }
        return bankConnection
    },

    _formatUrl: function(account) {
        return '/organizations/' + account.get('organization.id') + '/accounts/' + account.get('id') + '/bankConnection'
    },

    withAuthorization: function(onAuthorized) {
        return this.get('auth').withAuthorization(
            onAuthorized,
            Scope.BankSyncWrite,
            'bank_connections_connect_bank'
        )
    },

    updateBankConnection: function(account, changes) {
        return this.withAuthorization(function() {
            var bankConnection = this.findSync(account)
            return this.get('api').request('PATCH', this._formatUrl(account), {
                payload: {
                    data: changes
                }
            })
                .then(function(payload) {
                    bankConnection.setProperties(payload.data)
                    bankConnection.set('isConnected', true)
                    return bankConnection
                })
        }.bind(this))
    },

    disconnectBankConnection: function(account) {
        return this.withAuthorization(function() {
            var self = this
            var bankConnection = this.findSync(account)
            return self.get('api').request('DELETE', this._formatUrl(account))
                .then(function() {
                    bankConnection.set('isConnected', false)
                })
        }.bind(this))
    },

    setup: function(account, type) {
        return this.withAuthorization(function() {
            var self = this

            // If the account is new (not saved on server yet) then we have to do that first
            if (account.get('isNew')) {
                return this.container.lookup('util:dialog').confirm(null, t('bank_connection.save_before_setup'))
                    .then(function() {
                        batmask.maskDelayed()
                        return account.save()
                    })
                    .then(function() {
                        batmask.unmask()
                        return self.setup(account, type)
                    }, function(e) {
                        batmask.unmask()
                        throw e
                    })
            }

            return this.container.lookup('component:bank-connection-setup-' + type.replace(/_/g, '-'))
                .set('account', account)
                .showPromise()
        }.bind(this))
    },

    getBankIntegrations: function(organizationId) {
        return this.get('api').getData('/organizations/' + organizationId + '/bankConnections')
    },

    deleteBankIntegration: function(organizationId, accountId) {
        return this.get('api').delete('/organizations/' + organizationId + '/accounts/' + accountId + '/bankConnection')
    },

    getErrorReason: function(errorCode) {
        if (!errorCode) return null

        switch (errorCode) {
        case 'E_SESSION_EXPIRED':
            return t('bank_connection.error.expired_session')
        case 'E_LOGIN_FAILED':
            return t('bank_connection.error.login_failed')
        case 'E_INVALID_ACCOUNT':
            return t('bank_connection.error.invalid_account')
        default:
            return t('bank_connection.error.unknown')
        }
    },

    deleteEmptyBankConnections: function() {
        var organization = this.get('organization')
        var self = this

        return Em.RSVP.all([
            self.getBankSessions(organization.get('id')),
            self.getBankIntegrations(organization.get('id'))
        ])
            .then(function(result) {
                var bankSessions = result[0]
                var bankIntegrations = result[1]
                var banksToDisconnect = []

                bankSessions.forEach(function(session) {
                    var bankSessionId = session.id

                    var existingAccountConnection = bankIntegrations.find(function(integration) {
                        return integration.referenceId === bankSessionId
                    })

                    if (!existingAccountConnection) {
                        banksToDisconnect.push(session)
                    }
                })

                return banksToDisconnect
            }).then(function(banks) {
                banks.forEach(function(bankSession) {
                    self.deleteSession(organization.get('id'), bankSession.id)
                })
            })
    },

    /*
     * Nordic API
     */

    getBankSessions: function(organizationId) {
        return this.get('api').getData('/spiir/organizations/' + organizationId + '/sessions')
    },

    renewNordicApi: function(organizationId, bankId) {
        this.withAuthorization(function() {
            var host = [window.location.protocol, '//', window.location.host].join('')
            var url = (!ENV.isTest ? ENV.newApiUrl : host) + '/spiir/organizations/' + organizationId + '/login'
            var redirectUrl = host + location.pathname + '?fromRenewing=true'
            var query = _.map(
                {
                    access_token: this.container.lookup('api:billy').storageAdapter.getValue('accessToken'),
                    bankId: bankId,
                    tid: Date.now(),
                    redirect_url: redirectUrl
                },
                function(val, key) {
                    return key + '=' + encodeURIComponent(val)
                }
            ).join('&')

            window.location.replace(url + '?' + query)
        }.bind(this))
    },

    deleteSession: function(organizationId, sessionId) {
        return this.withAuthorization(function() {
            return this.get('api').delete('/spiir/organizations/' + organizationId + '/sessions/' + sessionId)
        }.bind(this))
    },

    connectBank: function(account) {
        var self = this

        if (!account) {
            console.error('No account specified for bank connection')
            return
        }

        self.setup(account, 'nordic_api')
    }
})

var _ = require('lodash')

module.exports = Em.Object.extend({
    api: Em.inject.service(),

    userOrganizations: Em.inject.service(),

    organization: Em.computed.alias('userOrganizations.activeOrganization'),

    fetchOrganizationSettings: function() {
        var api = this.get('api')
        var url = '/v2/organizations/' + this.get('organization.id') + '/settings/billy'

        return api.getData(url, undefined, { raw: true })
    },

    hasSettingEnabled: function(organizationSettings, featureName) {
        return _.get(organizationSettings, 'beta:' + featureName) === '1'
    },

    getHasFeature: function(featureName) {
        var self = this

        return this.fetchOrganizationSettings().then(function(response) {
            var organizationSettings = response.settings
            return self.hasSettingEnabled(organizationSettings, featureName)
        })
    },

    getHasMultipleFeatures: function(featuresNameList) {
        var self = this

        return this.fetchOrganizationSettings().then(function(response) {
            var organizationSettings = response.settings
            var hasFeaturesResponse = _.reduce(featuresNameList, function(result, featureName) {
                result[featureName] = self.hasSettingEnabled(organizationSettings, featureName)
                return result
            }, {})

            return hasFeaturesResponse
        })
    }
})

var model = BD.Model.extend({
    organization: BD.belongsTo('Billy.Organization'),
    abbreviation: BD.attr('string'),
    name: BD.attr('string'),
    rate: BD.attr('number'),
    appliesToSales: BD.attr('boolean'),
    appliesToPurchases: BD.attr('boolean'),
    isPredefined: BD.attr('boolean'),
    predefinedTag: BD.attr('string'),
    isActive: BD.attr('boolean'),
    netAmountMetaField: BD.belongsTo('Billy.SalesTaxMetaField'),
    deductionComponents: BD.hasMany('Billy.TaxRateDeductionComponent', 'taxRate', { isEmbedded: true }),
    description: BD.attr('string'),
    taxRateGroup: BD.attr('string'),
    priority: BD.attr('number')
})

model.reopenClass({
    qProperties: ['name', 'abbreviation', 'description']
})

module.exports = model

module.exports.open = function(url) {
    var win = window.open(url)
    return {
        close: function() {
            win.close()
        },
        setLocation: function(location) {
            win.location.href = location
        }
    }
}

module.exports = Em.Route.extend({
    userOrganizations: Ember.inject.service(),

    model: function(params) {
        var self = this
        var code = params.code
        return this.get('userOrganizations').all()
            .then(function(allOrganizations) {
                var organizations = allOrganizations.filterBy('level', 'owner')
                if (organizations.length === 1) {
                    self.replaceWith('coupon_redemption', code, organizations[0].url)
                } else {
                    return {
                        code: code,
                        organizations: organizations
                    }
                }
            })
    },

    setupController: function(controller, props) {
        controller.set('code', props.code)
        controller.set('organizations', props.organizations)
    }
})

var t = require('i18n').t

module.exports = function(container, title, path, options) {
    var host = ENV.isTest ? '' : ENV.newApiUrl
    var url = host + path
    var accessToken = container.lookup('api:billy').storageAdapter.getValue('accessToken')
    return $.ajax({
        type: 'POST',
        url: url,
        contentType: 'application/json',
        headers: {
            Authorization: 'Bearer ' + accessToken
        },
        data: options ? JSON.stringify(options) : undefined
    })
        .then(function(payload, status, xhr) {
            var progressUrl = xhr.getResponseHeader('Location')
            ExportTask.create({
                container: container,
                progressUrl: progressUrl,
                title: title,
                text: t('export_task.starting')
            })
        })
}

var ExportTask = Billy.BackgroundTask.extend({
    progressUrl: null,

    init: function() {
        this._super()
        this.eventSource = new EventSource(this.get('progressUrl'))
        this.eventSource.addEventListener('message', this.onMessage.bind(this), false)
    },

    onMessage: function(e) {
        var exp
        try {
            exp = JSON.parse(e.data)
        } catch(err) {
            return
        }

        if (exp.isCompleted) {
            this.eventSource.close()
            this.set('icon', 'icons/tick')
                .set('text', t('export_task.click_to_download'))
                .set('click', function() {
                    document.location.href = exp.downloadUrl
                })
        } else {
            var text = []
            if (exp.progressText) {
                text.push(exp.progressText)
            }
            if (exp.eta) {
                text.push(timeLeft(exp.eta))
            }
            this.set('progress', exp.progress)
            if (text.length) {
                this.set('text', text.join(', '))
            }
        }
    },

    progress: 0,

    closeOnClick: false
})

function timeLeft(eta) {
    eta = moment(eta)
    var seconds = Math.ceil(eta.diff(moment()) / 1000)
    var tl
    if (seconds < 5) {
        tl = t('export_task.few_seconds')
    } else if (seconds > 5 && seconds < 60) {
        tl = t('export_task.seconds', { count: seconds })
    } else {
        tl = eta.fromNow()
    }
    return t('export_task.time_left', { time: tl })
}

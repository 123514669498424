var storage = require('storage')
var AuthorizedRoute = require('../mixins/authorized-route')
var Scope = require('../utils/scope')

module.exports = Em.Route.extend(AuthorizedRoute, {
    scopes: [Scope.DaybookWrite],

    beforeModel: function() {
        var self = this
        var organization = this.modelFor('organization')
        var defaultDaybookId = storage('defaultDaybookId_' + organization.get('id'))

        var findFirstDaybook = function() {
            return Billy.Daybook.find({
                organizationId: organization.get('id'),
                isArchived: false
            }).promise
                .then(function(daybooks) {
                    var daybook = daybooks.objectAt(0)
                    if (daybook) {
                        self.replaceWith('daybook', daybook)
                    }
                })
        }
        if (defaultDaybookId) {
            return Billy.Daybook.find(defaultDaybookId).promise
                .then(function(daybook) {
                    self.replaceWith('daybook', daybook)
                }, findFirstDaybook)
        } else {
            return findFirstDaybook()
        }
    }
})

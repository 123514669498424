var billyApi = require('./billy-api')

module.exports = function() {
    return new Em.RSVP.Promise(function(resolve, reject) {
        billyApi.get('/ipLocation', {
            success: function(payload) {
                resolve(payload.country)
            },
            error: reject
        })
    })
}

var DAYBOOK_TRANSATION_ORIGINATOR_TYPES = [
    'entry',
    'invoicePayment',
    'billPayment',
    'externalInvoicePayment'
]

module.exports = function(originatorType) {
    if (DAYBOOK_TRANSATION_ORIGINATOR_TYPES.indexOf(originatorType) !== -1) {
        return 'daybookTransaction'
    }

    return originatorType
}

var t = require('i18n').t

module.exports = require('./basic').extend({
    modelClass: null,
    hasRawResult: true,

    api: Em.inject.service(),

    isLoading: false,

    isLoaded: true,

    q: null,

    minSignsToEnable: undefined,

    setQ: function(q, instant) {
        var minSignsToEnable = this.get('minSignsToEnable')

        if (minSignsToEnable !== undefined && q.length < minSignsToEnable) {
            return
        }

        if (q === '' && instant) {
            this.resetContent();
            return
        }
        this.set('isLoading', true);
        this.set('isLoaded', false);
        this.set('q', q);
        Em.run.debounce(this, this.fetchContent, 500)
    },

    updateContent: function(data) {
        var parser = this.get('parser');
        this.set('isLoading', false);
        this.set('isLoaded', true);
        this.set('content', parser(data));
    },

    fetchContent: function() {
        var self = this;
        var url = this.get('url');
        var q = this.get('q');
        var record = this.get('field.record');

        if (_.isEmpty(q)) {
            self.updateContent([]);
        }

        else if (!this.get('isDestroyed')) {
            this.get('api').request('GET', url.call(this, q, record))
                .then(function(res) {
                    self.updateContent(res.data);
                })
                .catch(function() {
                    self.updateContent([]);
                })
        }
    },

    resetContent: function() {
        this.set('content', null);
    },

    willDestroy: function() {
        this._super();
        this.resetContent();
    }
});

var Window = require('ember-window');
var sanitizeHtml = require('sanitize-html')

module.exports = Window.extend({
    attributeBindings: ['cy:data-cy'],

    cy: 'dialog-window',

    layout: Window.proto().layout,

    template: require('../templates/dialog-window'),

    closable: false,

    title: '',
    message: '',
    messageSafe: function() {
        return sanitizeHtml(this.get('message')).htmlSafe()
    }.property('message'),

    options: [],

    leftOptions: Em.computed.filterBy('options', 'align', 'left'),
    rightOptions: Em.computed.filterBy('options', 'align', 'right'),
    centerOptions: Em.computed.filter('options', function(option) {
        return (!option.align || option.align === 'center');
    }),

    actions: {
        clickOption: function(option) {
            this.trigger('clickedOption', option);
        }
    }
});

var serializer = require('../utils/model-serializer')

module.exports = function(invoice, overrides) {
    var snapshot = serializer(invoice)

    // Billy-api still calls it contactMessage, new invoices-resource and invoice-snapshots-api call it headerMessage
    snapshot.headerMessage = snapshot.contactMessage

    snapshot.lines = []
    invoice.get('lines').forEach(function(line) {
        var lineSnapshot = serializer(line)
        lineSnapshot.productName = line.get('product.name')
        snapshot.lines.push(lineSnapshot)
    })

    if (overrides) {
        _.merge(snapshot, overrides)
    }

    var entryDate = snapshot.entryDate ? moment(snapshot.entryDate) : null
    var lastMonth = entryDate ? moment(entryDate).subtract(1, 'month') : null
    var nextMonth = entryDate ? moment(entryDate).add(1, 'month') : null
    _.merge(snapshot, {
        week: entryDate ? entryDate.format('W') : '',
        month: entryDate ? entryDate.format('MMMM') : '',
        year: entryDate ? entryDate.format('YYYY') : '',
        lastMonth: lastMonth ? lastMonth.format('MMMM') : '',
        lastMonthYear: lastMonth ? lastMonth.format('YYYY') : '',
        nextMonth: nextMonth ? nextMonth.format('MMMM') : '',
        nextMonthYear: nextMonth ? nextMonth.format('YYYY') : ''
    })

    return snapshot
}

module.exports = BD.Model.extend({
    organization: BD.belongsTo('Billy.Organization'),
    createdTime: BD.attr('datetime', { readonly: true }),
    name: BD.attr('string'),
    lines: BD.hasMany('Billy.BankLine', 'match', { readonly: true }),
    earliestLineEntryDate: BD.attr('date'),
    latestLineEntryDate: BD.attr('date'),
    linesCount: BD.attr('number'),
    reconciledLinesCount: BD.attr('number')
})

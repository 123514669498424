module.exports = function(url, params) {
    if (isURLSameOrigin(url)) {
        return url
    }

    params = params || {}
    if (params.standard) {
        switch (params.standard) {
        case 'attachment-s':
            params.size = '96x128'
            params.size = '192x256'
            break
        default:
            // eslint-disable-next-line no-console
            console.warn('Unknown image thumbnail standard: ' + params.standard)
            params.size = '256x256'
            break
        }
        delete params.standard
    }

    var originalUrl = new URL(url)

    var bucket = getBucketFromHostname(originalUrl.hostname) || getBucketFromUrl(url)
    var paramsKeys = Object.keys(removeUndefinedKeysFromObject(params))

    var finalUrl = bucket ? compileBaseImageProxyUrl(bucket, originalUrl) : originalUrl

    paramsKeys.forEach(function(paramKey) {
        finalUrl.searchParams.set(paramKey, params[paramKey])
    })

    return finalUrl.toString()
}

function compileBaseImageProxyUrl(bucket, originalUrl) {
    // IE11 compatibility
    // eslint-disable-next-line
    var imageProxyUrl = new URL(ENV.IMAGE_PROXY_URL)
    imageProxyUrl.pathname = bucket + originalUrl.pathname

    originalUrl.searchParams.forEach(function(value, param) {
        imageProxyUrl.searchParams.set(param, value)
    })

    return imageProxyUrl
}

function getBucketFromHostname(hostname) {
    var match = hostname.match(/^([^\.]+)\.s3\.([a-z0-9\-\.]*)?amazonaws\.com$/)

    if (match) {
        return match[1]
    }

    return ''
}

function getBucketFromUrl(url) {
    if (url.includes(ENV.CLOUD_FRONT_URL)) {
        return ENV.CLOUD_FRONT_BUCKET
    }
}

function isURLSameOrigin(url) {
    return url.includes(window.location.origin)
}

function removeUndefinedKeysFromObject(obj) {
    return JSON.parse(JSON.stringify(obj))
}

var _ = require('lodash')

var t = require('i18n').t

module.exports = require('ember-super-field').types.Model.extend({
    modelClass: Billy.Account,

    sortProperty: 'accountNo',

    inputValuePath: 'nameWithAccountNo',

    selectorMinWidth: 300,

    noOptionsFoundText: t('ui.fields.superfield.no_accounts_found'),

    groupBy: 'group.name',

    groupName: function(account) {
        var group = account.get('group')
        var name = group && group.get('name')

        return name
    },

    groupSort: function(a, b) {
        var ag = a.items[0].get('group')
        var bg = b.items[0].get('group')
        if (!ag || !bg) {
            return 0
        }

        var priority = parseInt(ag.get('priority') || 0, 10) - parseInt(bg.get('priority') || 0, 10)
        if (priority !== 0) {
            return priority
        }

        var accountNo = parseInt(ag.get('accountNo') || 0, 10) - parseInt(bg.get('accountNo') || 0, 10)
        if (accountNo !== 0) {
            return accountNo
        }

        return (ag.get('name') || '').localeCompare(bg.get('name'))
    },

    parser: function(data) {
        var currencyToFilter = this.get('field.currencyToFilter')

        if (currencyToFilter) {
            var contentFiltered = {}
            var index = 0

            data.forEach(function(item) {
                if (currencyToFilter.get('id') !== item.get('currency.id')) {
                    contentFiltered[index] = item
                    index++
                }
            })

            data.set('_content', contentFiltered)
            data.set('length', _.size(contentFiltered))
            return data
        }

        return data
    },

    optionViewClass: require('ember-super-field').GroupedOptionView.extend({
        template: Ember.Handlebars.compile(
            '<div class="name" data-cy="revenue-category-list-option-name">{{nameWithAccountNo}}</div>' +
            '<div class="meta" data-cy="revenue-category-list-option-description">{{description}}</div>'
        )
    }),

    query: function() {
        var query = {
            organization: this.get('userController.activeOrganization'),
            isArchived: false
        }
        var natures = this.get('field.accountNatures')
        if (natures) {
            query.nature = natures
        }
        var isSalesApplicable = this.get('field.isSalesApplicable')
        if (typeof isSalesApplicable !== 'undefined') {
            query.isSalesApplicable = isSalesApplicable
        }
        return query
    }.property('userController.activeOrganization', 'field.accountNatures')
})

module.exports = Em.View.extend({
    classNames: ['dashboard-widget'],

    classNameBindings: ['sizeClass', 'framed:dashboard-widget-framed', 'scroll:dashboard-widget-scroll'],

    sizeClass: function() {
        var size = this.get('size')
        return size > 1 ? 'dashboard-widget-size-' + size : null
    }.property('size'),

    size: 1,

    framed: true,

    scroll: false
})

var numeral = require('numeral')
var t = require('i18n').t

var currencySymbols = {
    AUD: { prefix: '$' },
    CAD: { prefix: '$' },
    DKK: { suffix: ' DKK' },
    EUR: { prefix: '€' },
    GBP: { prefix: '£' },
    NOK: { suffix: ' kr.' },
    SEK: { suffix: ' kr.' },
    USD: { prefix: '$' }
}

Billy.money = function(value, currencyId, format) {
    // Format number
    var v = numeral(value).format(format || '0,0.00')

    // If there's no currency we don't need to touch the number
    if (!currencyId) {
        return v
    }

    // Handle Billy.Currency
    if (currencyId instanceof Billy.Currency) {
        currencyId = currencyId.get('id')
    }

    // Find prefix and suffix
    var prefix = ''
    var suffix = ''
    var baseCurrencyId = Billy.__container__.lookup('controller:user').get('activeOrganization.baseCurrency.id')
    var symbol = currencySymbols[currencyId]
    if ((!baseCurrencyId || (currencyId === baseCurrencyId)) && symbol) {
        prefix = symbol.prefix || ''
        suffix = symbol.suffix || ''
    } else {
        suffix = ' ' + currencyId
    }

    // Move negative-minus in front of prefix?
    var minus = ''
    var prefixEndsWithSpace = prefix.substring(prefix.length - 1) === ' '
    var isNegative = v.substring(0, 1) === '-'
    if (prefix && !prefixEndsWithSpace && isNegative) {
        minus = '-'
        v = v.substring(1)
    }

    return minus + prefix + v + suffix
}

Billy.moneyRecurring = function(value, currencyId, format, period) {
    period = period || 'monthly'
    var suffix = t('money.recurring_' + period)
    return Billy.money(value, currencyId, format) + suffix
}

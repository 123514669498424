module.exports = Em.View.extend({
    registerModifierClickListener: function() {
        this.$().delegate('a.invoice-document-balance-modifier-link', 'click', this.didClickBalanceModifier.bind(this))
    }.on('didInsertElement'),

    htmlDidChange: function() {
        Em.run.scheduleOnce('afterRender', this, this.interceptDocumentHtml)
    }.observes('controller.html'),

    interceptDocumentHtml: function() {
        var doc = this.$('.document-paper > div')

        // Wrap all balance modifiers in links
        doc.find('.invoice-document-balance-modifier').each(function(idx, el) {
            $(el).wrap('<a class="link invoice-document-balance-modifier-link"></a>')
        })
    },

    didClickBalanceModifier: function(e) {
        var span = $('span', e.currentTarget)
        var type = span.data('type')
        var id = span.data('id')
        var modifier
        if (type === 'bankPayment') {
            modifier = Billy.BankPayment.find(id)
        } else if (type === 'contactBalancePayment') {
            modifier = Billy.ContactBalancePayment.find(id)
        } else if (type === 'invoice') {
            modifier = Billy.Invoice.find(id)
        } else if (type === 'invoiceLateFee') {
            modifier = Billy.InvoiceLateFee.find(id)
        } else if (type === 'daybookTransaction') {
            modifier = Billy.DaybookTransaction.find(id)
        } else {
            // eslint-disable-next-line no-console
            console.error('No modifier type found for `' + type + '`.')
            return
        }
        this.container.lookup('router:main').transitionTo(modifier.get('route'), modifier)
    }
})

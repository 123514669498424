var highestZIndex = require('highest-z-index')

var ANIMATION_DURATION = 300
var MODAL_MASK_CLICK_TOLERANCE = 1000
var TOP_OFFSET_INCREMENT = 10
var stack = []

function register(win) {
    deregister(win)
    var topWin = stack.get('lastObject')
    if (win.get('win') === null) {
        win.set('topOffset', topWin ? TOP_OFFSET_INCREMENT + topWin.get('topOffset') : 0)
    }
    stack.pushObject(win)
}

function deregister(win) {
    stack.removeObject(win)
}

// Catch focus and delegate to latest window
Ember.$(document).focusin(function(e) {
    if (!stack.length) {
        return
    }
    if ($(e.target).closest('.ember-window-allow-focus')) {
        return
    }
    Em.run(function() {
        stack[stack.length - 1].focus()
    })
})

module.exports = Em.Component.extend({
    layout: require('../templates/window-layout'),

    classNames: ['window', 'layer'],

    classNameBindings: ['closable:window-closable', 'animated:window-animated', 'externalCloseIcon:window-external-close'],

    attributeBindings: ['style'],

    animated: true,

    animationDuration: function() {
        return this.get('animated') ? ANIMATION_DURATION : 0
    }.property('animated'),

    externalCloseIcon: false,

    viewportPadding: 0,

    topOffset: null,

    title: '',

    isModal: true,

    focusSelector: ':input:not([disabled]):first',

    modalSelector: '.window-modal',

    modalMask: null,

    applyModalMask: true,

    closable: true,

    zIndex: null,

    width: 600,

    fullHeight: false,

    isClosing: false,

    willClose: function() {

    },

    init: function() {
        this._super()
        register(this)
    },

    style: function() {
        var s = []
        var zIndex = this.get('zIndex')
        s.push('z-index:' + zIndex + ';')
        return s.join(' ')
    }.property('zIndex'),

    show: function() {
        var self = this
        var zIndex = highestZIndex() + 2 // We add 2 so we can put the modal mask directly below the window

        this.set('zIndex', zIndex)
        this.appendTo(this.container.lookup('application:main').get('rootElement'))
        if (this.get('isModal')) {
            var modalMask = this.get('modalMask') // this window may have replaced another window and inherited its modal mask
            var applyModalMask = this.get('applyModalMask')

            if (!modalMask && applyModalMask) {
                modalMask = this.container.lookup('component:modal-mask')
                this.set('modalMask', modalMask)
                modalMask.set('zIndex', zIndex - 1)
                modalMask.show()
                // Wait a second until listening for click events on modal mask. If user double clicks on an item which opens
                // the window, we don't want to hide it again, which the second click would otherwise do.
                modalMask.on('click', this, this.didClickModalMask)
                setTimeout(function() {}, 1000)
            }

        }
        return new Em.RSVP.Promise(function(resolve) {
            self.one('didShow', function() {
                resolve()
            })
        })
    },

    didClickModalMask: function() {
        if (Date.now() - this.get('modalMask.insertTime') >= MODAL_MASK_CLICK_TOLERANCE) {
            if (this.get('closable')) {
                this.cancel()
            } else {
                this._pulse()
            }
        }
    },

    cancel: function() {
        if (this.trigger('willCancel') === false) {
            return
        }
        this.one('didClose', function() {
            this.trigger('didCancel')
        })
        this.close()
    },

    close: function() {
        var self = this
        var willClosePromise = this.trigger('willClose')

        if (!willClosePromise) {
            return this.doClose()
        }
        return willClosePromise.then(function() {
            return self.doClose()
        })
    },

    doClose: function() {
        var self = this
        this.set('isClosing', true)
        return this.animateDestroy().then(function() {
            self.trigger('didClose')
            self.destroy()
        })
    },

    replaceWith: function(other) {
        var modalMask = this.get('modalMask')
        if (modalMask) {
            other.set('modalMask', modalMask)
            this.set('modalMask', null)
            modalMask.off('click', this, this.didClickModalMask)
        }

        other.set('topOffset', this.get('topOffset'))

        this.set('animated', false)
        other.set('animated', false)
        other.one('didShow', function() {
            other.set('animated', true)
        })

        this.cancel()
    },

    willDestroy: function() {
        deregister(this)

        var modalMask = this.get('modalMask')
        if (modalMask && !modalMask.get('isAnimateDestroying')) {
            modalMask.destroy()
        }
    },

    didInsertElement: function() {
        this._super()
        var el = this.$()
        var focusSelector = this.get('focusSelector')
        var modalSelector = this.get('modalSelector')
        var fullHeight = this.get('fullHeight')
        var modal = el.find(modalSelector)

        if (modal) {
            modal.width(this.get('width') + 'px')
        }

        if (fullHeight) {
            el.addClass('full-height')
        }

        el.addClass('visible')
        Em.run.later(this, function() {
            this.trigger('didShow')
        }, this.get('animationDuration'))
        if (focusSelector) {
            Em.run.next(this, function() {
                this.$(focusSelector).focus()
            }, 0)
        }
    },

    willDestroyElement: function() {
        this._super()
    },

    _pulse: function() {
        var el = this.$()
        el.addClass('pulse')
        setTimeout(function() {
            el.removeClass('pulse')
        }, 100)
    },

    animateDestroy: function() {
        var self = this
        return new Em.RSVP.Promise(function(resolve) {
            var modalMask = self.get('modalMask')
            var el = self.$()

            if (modalMask) {
                modalMask.animateDestroy()
            }
            el.removeClass('visible')
            Em.run.later(self, function() {
                self.destroy()
                resolve()
            }, self.get('animationDuration'))
        })
    },

    focus: function() {
        if (this.get('element') && !this.get('element').contains(document.activeElement)) {
            this.$(':tabbable:input:first').focus()
        }
    },

    didKeyDown: function(e) {
        var key = e.keyCode || e.which
        if (key === 9) {
            // Prevent tabbing outside the window
            var tabbable = this.$(':tabbable')
            var finalTabbable = tabbable[e.shiftKey ? 'first' : 'last']()[0]
            if (finalTabbable === document.activeElement || this.get('element') === document.activeElement) {
                e.preventDefault()
                tabbable[e.shiftKey ? 'last' : 'first']()[0].focus()
            }
        }
    }.on('keyDown'),

    actions: {
        cancel: function() {
            this.cancel()
        },
        close: function() {
            this.close()
        }
    },

    // Hack since Ember.Component does not support {{yield}} when there is no parentView
    _yield: function() {
        return Em.View.prototype._yield.apply(this, arguments)
    }
})

module.exports.hasWindows = function() {
    return stack.length > 0 && stack.any(function(w) { return !w.get('isClosing') })
}

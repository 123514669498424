var reactDirtyRoute = require('../mixins/react-dirty-route')
var _ = require('lodash')

module.exports = Em.Route.extend(reactDirtyRoute, {
    beforeModel: function(transition) {
        // Do not redirect when user is already in the umbrella module when refreshing the page.
        // The check is based on the url having "umbrellas/:umbrella_id"
        var basePath = /\/umbrellas\/([a-f0-9\-]{36})#\//
        if (window.location.href.match(basePath)) {
            return
        }

        var self = this
        var targetName = _.get(transition, 'targetName')
        var queryParams = _.get(transition, 'queryParams')

        var targetNameToRedirectedRoute = {
            inbox: '/inbox',
            voucher_inbox: '/inbox/voucher-inbox',
            umbrella_invoices: '/invoices',
            umbrella_organizations: '/customers',
            umbrella_profile: '/profile',
            umbrella_roles: '/roles',
            umbrella_subscriptions: '/subscriptions',
            umbrella_users: '/users',
            umbrella_user_settings: '/settings/user',
            umbrella_user_info: '/settings/user/profile',
            umbrella_privacy: '/settings/user/privacy',
            umbrella_notification_settings: '/settings/user/notifications'
        }

        var redirectedRoute = targetNameToRedirectedRoute[targetName] || '/customers'

        var queryParamsCombined = _.assign(
            { initialRoute: redirectedRoute },
            queryParams
        )

        return self.transitionTo('umbrella', {
            queryParams: queryParamsCombined
        })
    }
})

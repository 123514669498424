module.exports = Em.Route.extend({
    queryParams: {
        audit_trail: {
            replace: true
        }
    },

    model: function(params) {
        return Billy.SalesTaxReturn.find(params.sales_tax_return_skat_id).promise // make sure we don't start the route until the record is fully loaded
    },

    setupController: function(controller, model) {
        controller.set('model', model)
        controller.loadAuditTrail()
    }
})

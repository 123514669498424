var eventBus = require('../utils/event-bus')

module.exports = Em.Object.extend({
    api: Ember.inject.service(),

    setupListeners: function() {
        eventBus.on('logout', this, this.reset)
    }.on('init'),

    willDestroy: function() {
        this._super()
        eventBus.off('logout', this, this.reset)
    },

    all: function() {
        if (this.allPromise) {
            return this.allPromise
        }
        this.allPromise = this.get('api').request('GET', '/user/umbrellas')
            .then(function(payload) {
                return payload.data
            })
        return this.allPromise
    },

    reset: function() {
        delete this.allPromise
    }
})

module.exports = BD.Model.extend({
    organization: BD.belongsTo('Billy.Organization'),
    contact: BD.belongsTo('Billy.Contact'),
    originator: BD.belongsToReference(),
    createdTime: BD.attr('datetime'),
    entryDate: BD.attr('date'),
    amount: BD.attr('number'),
    side: BD.attr('side'),
    currency: BD.belongsTo('Billy.Currency'),
    isVoided: BD.attr('boolean'),
    associations: BD.hasMany('Billy.BalanceModifier', 'modifier', { isEmbedded: true })
})

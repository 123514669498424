var f = require('float')

Billy.Discount = {

    calculate: function(mode, value, amount) {
        if (!mode) {
            return 0
        }
        if (mode === 'cash') {
            return f.round(value)
        } else if (mode === 'percent') {
            return f.round(amount * value / 100)
        } else {
            throw new Error('Unknown discount mode `' + mode + '`')
        }
    },

    subtract: function(mode, value, amount) {
        return f.round(amount - Billy.Discount.calculate(mode, value, amount))
    }

}

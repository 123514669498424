var dnd = require('../utils/dnd')

module.exports = Em.View.extend({
    templateName: 'bank-sync/match',

    classNames: ['match'],

    classNameBindings: ['controller.isFulfilled:fulfilled', 'controller.hasMatchSuggestion:fulfilled'],

    didInsertElement: function() {
        var self = this

        this._linesDroppable = dnd.droppable(this.$('.lines'), {
            accept: function(draggable) {
                if (self.get('controller.isFulfilled')) {
                    return false
                }
                var otherView = draggable.view
                if (otherView instanceof require('./bank-sync-match-line')) {
                    return otherView.get('controller.match') !== self.get('controller.model')
                }
                return false
            },

            drop: function(e, draggable) {
                var otherView = draggable.view
                if (otherView.get('isDestroying')) {
                    return
                }
                Ember.run(function() {
                    self.get('controller').didDropLine(otherView.get('controller.model'))
                })
            }
        })

        this._subjectsDroppable = dnd.droppable(this.$('.subjects'), {
            accept: function(draggable) {
                if (self.get('controller.isFulfilled') || self.get('controller.hasMatchSuggestion')) {
                    return false
                }
                var otherView = draggable.view
                if (otherView instanceof require('./bank-sync-possible-subject')) {
                    return true
                }
                if (otherView instanceof require('./bank-sync-match-subject-association')) {
                    return otherView.get('controller.model.match') !== self.get('controller.model')
                }
                return false
            },

            drop: function(e, draggable) {
                var otherView = draggable.view
                if (otherView.get('isDestroying')) {
                    return
                }
                Ember.run(function() {
                    if (otherView instanceof require('./bank-sync-possible-subject')) {
                        self.get('controller').didDropSubject(otherView.get('controller.subject'))
                    } else {
                        self.get('controller').didDropSubjectAssociation(otherView.get('controller.model'))
                    }
                })
            }
        })
    },

    willDestroyElement: function() {
        if (this._linesDroppable) {
            this._linesDroppable.destroy()
        }
        if (this._subjectsDroppable) {
            this._subjectsDroppable.destroy()
        }
    },

    _inlineCreatorDidChange: function() {
        Em.run.scheduleOnce('afterRender', this, this._focusFirstInlineCreatorField)
    }.observes('controller.inlineCreator'),

    _focusFirstInlineCreatorField: function() {
        var field = this.$('.no-subjects input:eq(0)')
        if (field) {
            field.focus()
        }
    },

    actions: {
        didClickDifference: function() {
            var self = this
            var differencePopover = this.get('differencePopover')
            if (!differencePopover) {
                differencePopover = this.container.lookup('component:bank-sync-difference-popover')
                this.set('differencePopover', differencePopover)
                differencePopover.set('matchController', this.get('controller'))
                differencePopover.one('willDestroyElement', function() {
                    self.set('differencePopover', null)
                })
                differencePopover.one('didSelect', function(differenceType) {
                    self.get('controller').didSelectDifferenceType(differenceType)
                    differencePopover.destroy()
                })
                differencePopover.show(this, this.$('.difference'))
            }
        }
    }
})

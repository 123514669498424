var ActionsView = require('../actions-view')

module.exports = Em.Mixin.create({
    classNames: ['table-body'],

    itemSelector: '> .table-item',

    rowHeight: Ember.computed.alias('controller.tableRowHeight'),

    classNameBindings: ['controller.click:clickable', 'controller.isSelectable:is-selectable'],

    attributeBindings: ['style'],

    itemControllerClass: Em.computed.alias('controller.itemControllerClass'),

    style: function() {
        var controller = this.get('controller')
        if (controller.get('allowOverflow')) {
            return 'width:' + controller.get('width') + 'px;'
        }
    }.property('controller.{allowOverflow,width}'),

    didInsertElement: function() {
        this._super()
        var self = this
        if (this.get('controller.listActions')) {
            this.actionsView = this.createChildView(ActionsView, {
                controller: this.get('controller'),
                listActions: this.get('controller.listActions')
            })
            this.actionsView.append()
            this.$().delegate(this.get('itemSelector'), 'mouseenter', function(e) {
                self.showActionsFor(e.currentTarget)
            })
            this.$().delegate(this.get('itemSelector'), 'mouseleave', function() {
                self.hideActions()
            })
            this.$().mouseleave(function() {
                self.hideActions()
            })
        }
    },
    willDestroyElement: function() {
        this._super()
        if (this.actionsView) {
            this.actionsView.destroy()
        }
    },

    showActionsFor: function(el) {
        var placeholder = $(el).find('.actions-placeholder')
        this.actionsView.$().addClass('visible')
        this.actionsView.$().appendTo(placeholder)
        this.activeActionsView = Ember.View.views[el.id]
        this.actionsView.set('currentContext', this.activeActionsView.get('context'))
    },

    hideActions: function() {
        if (this.actionsView) {
            this.actionsView.$().removeClass('visible')
            this.actionsView.$().appendTo(this.container.lookup('application:main').rootElement)
        }
    },

    createChildController: function(properties) {
        properties.listController = this.get('controller')
        return this._super(properties)
    },
    willDestroyItemView: function(itemView) {
        if (this.activeActionsView === itemView) {
            this.hideActions()
        }
    },

    qDidChange: function() {
        var sectionBody = $('.section-body')
        // TODO: Should scroll to the top of the table body
        sectionBody.scrollTop(0)
    }.observes('controller.records.q')
})

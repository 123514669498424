var t = require('i18n').t

var model = BD.Model.extend({
    organization: BD.belongsTo('Billy.Organization'),
    name: BD.proxyAttr('string', function(value) {
        if (this.get('isPredefined')) {
            return t('sales_tax_rulesets.' + this.get('predefinedTag') + '.name')
        } else {
            return value
        }
    }),
    abbreviation: BD.attr('string'),
    description: BD.proxyAttr('string', function(value) {
        if (this.get('isPredefined')) {
            return t('sales_tax_rulesets.' + this.get('predefinedTag') + '.description')
        } else {
            return value
        }
    }),
    fallbackTaxRate: BD.belongsTo('Billy.TaxRate'),
    isPredefined: BD.attr('boolean'),
    predefinedTag: BD.attr('string'),
    rules: BD.hasMany('Billy.SalesTaxRule', 'ruleset', { isEmbedded: true, sortProperty: 'priority' })
})

model.reopenClass({
    qProperties: ['name', 'description']
})

module.exports = model

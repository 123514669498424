var events = ['close', 'error', 'message', 'open']

module.exports = Em.ObjectProxy.extend({

    /*
    * {
    *    url: 'String'
    *    type: 'String' (such as 'open', 'message', 'close', and 'error')
    *    callback: The function to envoke
    *    context: The context of the function
    * }
    */
    listeners: null,

    protocols: null,

    init: function() {
        this._super()
        this.listeners = []
        this.setupInternalListeners()
    },

    /*
    * Adds a callback function into the listeners array which will
    * be invoked later whenever a given `type` event happens.
    *
    * type: must be either 'open', 'message', 'close', 'error'
    */
    on: function(type, callback, context) {
        if (!events.contains(type)) {
            throw new Error(type + ' is not a recognized event name. Please use on of the following: ' + events.join(', '))
        }

        if (typeof callback !== 'function') {
            throw new Error('The second argument must be a function.')
        }

        this.get('listeners').push({ url: this.socket.url, type: type, callback: callback, context: context })
    },

    /*
    * Removes a callback function from the listeners array. This callback
    * will not longer be invoked when the given `type` event happens.
    */
    off: function(type, callback) {
        this.set('listeners', this.get('listeners').filter(function(listeners) {
            return !(listeners.callback === callback && listeners.type === type)
        }))
    },

    /*
    * Message is the message which will be passed into the native websockets send method
    * and shouldStringify is a boolean which determines if we should call JSON.stringify on
    * the message.
    */
    send: function(message, shouldStringify) {
        shouldStringify = shouldStringify || false

        if (shouldStringify && JSON && JSON.stringify) {
            message = JSON.stringify(message)
        }

        // assert('Cannot send message to the websocket while it is not open.', this.readyState() === WebSocket.OPEN);

        if (this.readyState() !== WebSocket.OPEN) {
            throw new Error('Cannot send message to the websocket while it is not open.')
        }

        this.socket.send(message)
    },

    close: function() {
        this.socket.close()
    },

    reconnect: function() {
        this.set('socket', new WebSocket(this.socket.url, this.get('protocols')))
        this.setupInternalListeners()
    },

    setupInternalListeners: function() {
        var self = this

        events.forEach(function(eventName) {
            self.socket['on' + eventName] = function(event) {
                Ember.run(function() {
                    var activeListeners = self.listeners.filter(function(listener) {
                        return listener.url === event.currentTarget.url && listener.type === eventName
                    })

                    // TODO: filter active listeners for contexts that are not destroyed
                    activeListeners.forEach(function(item) {
                        if (item.context) {
                            item.callback.call(item.context, event)
                        } else {
                            item.callback(event)
                        }
                    })
                })
            }
        })
    },

    /*
    * A helper method to get access to the readyState of the websocket.
    */
    readyState: function() {
        return this.socket.readyState
    }
})


module.exports = function retry(fn, retriesLeft, interval) {
    return new Promise(function(resolve, reject) {
        return fn()
            .then(resolve)
            .catch(function(error) {
                if (retriesLeft === 1) {
                    reject(error)
                    return
                }

                setTimeout(function() {
                    retry(fn, retriesLeft - 1, interval).then(resolve, reject)
                }, interval)
            })
    })
}

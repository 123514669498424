module.exports = BD.Model.extend({
    chartOfAccount: BD.belongsTo('Billy.ChartOfAccount', { readonly: true }),
    nature: BD.belongsTo('Billy.AccountNature', { readonly: true }),
    accountNo: BD.attr('number', { readonly: true }),
    name: BD.attr('string', { readonly: true }),
    type: BD.attr('string', { readonly: true }),
    sumFrom: BD.attr('string', { readonly: true }),
    style: BD.attr('string', { readonly: true }),
    priority: BD.attr('number', { readonly: true }),
    intervalFrom: BD.attr('number', { readonly: true }),
    intervalTo: BD.attr('number', { readonly: true }),
    allowPaymentAmounts: BD.attr('boolean', { readonly: true })
})

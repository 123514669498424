var model = module.exports = BD.Model.extend({
    organization: BD.belongsTo('Billy.Organization'),
    nature: BD.belongsTo('Billy.AccountNature'),
    accountNo: BD.attr('number'),
    name: BD.attr('string'),
    type: BD.attr('string'),
    sumFrom: BD.attr('string'),
    style: BD.attr('string'),
    priority: BD.attr('number'),
    intervalFrom: BD.attr('number'),
    intervalTo: BD.attr('number'),
    allowPaymentAmounts: BD.attr('boolean'),
    predefinedAccountGroup: BD.belongsTo('Billy.PredefinedAccountGroup')
})

model.reopenClass({
    qProperties: ['name', 'description']
})

var moment = require('momentjs')
var QuoteEditRoute = require('./quote-edit')

module.exports = QuoteEditRoute.extend({

    model: function(params) {
        var store = this.store
        var organization = this.modelFor('organization')
        var quote = store.createRecord('quote', {
            organization: organization,
            entryDate: moment(),
            state: 'open',
            templateId: organization.get('defaultTemplateId')
        })
        if (params.fromQuoteId) {
            return findQuote(store, params.fromQuoteId, organization.get('id'))
                .then(function(fromQuote) {
                    quote.setProperties(fromQuote.getProperties('type', 'contact', 'attContactPerson', 'dueDate', 'headerMessage', 'footerMessage', 'taxMode', 'currency', 'templateId', 'orderNo'))
                    return fromQuote.get('lines')
                })
                .then(function(lines) {
                    lines.forEach(function(line) {
                        var properties = line.getProperties('product', 'description', 'quantity', 'unitPrice', 'taxRate', 'currentTaxRate', 'discountText', 'discountMode', 'discountValue', 'priority')
                        properties.quote = quote
                        store.createRecord('quote-line', properties)
                    })
                    return quote
                })
        } else {
            quote.setProperties({
                type: params.newType || 'quote',
                currency: organization.get('baseCurrency')
            })
            store.createRecord('quote-line', {
                quote: quote,
                quantity: 1,
                priority: 1
            })
            return quote
        }
    },

    resetController: function(controller) {
        controller.set('fromQuoteId', null)
    }
})

function findQuote(store, id, organizationId) {
    var quote = store.getById('quote', id)
    if (quote) {
        return Em.RSVP.resolve(quote)
    }
    var query = {
        id: id,
        organization: organizationId
    }
    return store.find('quote', query)
        .then(function(quotes) {
            if (quotes.get('length') === 0) {
                throw new Error('The quote was not found.')
            }
            return quotes.objectAt(0)
        })
}

module.exports = BD.Model.extend({
    ruleset: BD.belongsTo('Billy.SalesTaxRuleset', { isParent: true }),
    country: BD.belongsTo('Billy.Country'),
    state: BD.belongsTo('Billy.State'),
    city: BD.belongsTo('Billy.City'),
    countryGroup: BD.belongsTo('Billy.CountryGroup'),
    contactType: BD.attr('string'),
    taxRate: BD.belongsTo('Billy.TaxRate'),
    priority: BD.attr('number')
})

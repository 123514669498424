var NestedColumn = require('../columns/nested')

module.exports = Em.View.extend({
    templateName: 'ui/list/table/total',

    classNames: ['table-item table-total'],

    classNameBindings: ['content.itemClass'],

    labelViewClass: null,

    labelStyle: function() {
        var firstColumnName = _.keys(this.get('content.values'))[0]
        var columns = this.get('controller.visibleColumns')
        var props
        props = {
            flex: 0,
            width: 0
        }
        this._calcLabelProps(props, columns, firstColumnName)
        return this._getCellStyle(props)
    }.property(),

    _calcLabelProps: function(props, columns, firstColumnName) {
        var wasFound = false
        columns.find(function(column) {
            if (column.get('name') === firstColumnName) {
                wasFound = true
                return true
            }
            if (column instanceof NestedColumn) {
                return this._calcLabelProps(props, column.get('columns'), firstColumnName)
            } else {
                props.flex += column.get('flex')
                props.width += column.get('width')
                return false
            }
        }, this)
        return wasFound
    },

    cells: function() {
        var self = this
        var cells = []
        _.each(this.get('content.values'), function(value, name) {
            var column = self.get('controller').findColumn(name)
            cells.push({
                style: self._getCellStyle(column.getProperties(['flex', 'width'])) + 'text-align:' + column.get('align') + ';',
                value: value
            })
        })
        return cells
    }.property('content'),

    _getCellStyle: function(props) {
        var columns = this.get('controller.visibleColumns')
        var totalFlex = 0
        var totalWidth = 0
        var style = ''
        columns.forEach(function(column) {
            totalFlex += column.get('flex')
            totalWidth += column.get('width')
        })
        if (props.flex) {
            style += 'width:' + Math.round(props.flex / totalFlex * 100) + '%;'
            style += 'padding-right:' + (Billy.constants.cellPadding + Math.round(props.flex / totalFlex * totalWidth) - props.width) + 'px;'
            style += 'margin-right:' + (-(Math.round(props.flex / totalFlex * totalWidth) - props.width)) + 'px;'
        } else {
            style += 'width:' + (props.width) + 'px;'
        }
        return style
    },

    hasLastColumn: function() {
        var names = _.keys(this.get('content.values'))
        var lastName = names[names.length - 1]
        return lastName === this.get('controller.visibleColumns.lastObject.name')
    }.property()
})

var functionProxy = require('function-proxy')
var dnd = require('../utils/dnd')
var scrollDetection = require('../utils/scroll-detection')

module.exports = Em.View.extend({
    templateName: 'bank-sync-v2/abstract-item',

    classNames: ['item'],

    classNameBindings: ['specialClasses', 'controller.canBeRemoved:has-remove', 'controller.isSelectable:is-selectable', 'controller.isSelected:selected'],

    specialClasses: null,

    draggableHelperClass: null,

    didInsertElement: function() {
        var self = this
        var $self = this.$()
        this._draggable = dnd.draggable(this.$(), {
            helper: function() {
                var el = $self.clone()
                el.find('.item-actions').remove()
                el.width($self.outerWidth())
                el.addClass('draggable-helper')
                el.removeClass('male-up')
                el.removeClass('male-left')
                el.addClass(self.get('draggableHelperClass'))
                return el
            },

            appendTo: $self.closest('.bank-sync-v2'),

            zIndex: 10000,

            cancel: '.remove-tool',

            start: function(draggable) {
                $self.addClass('draggable-active')
                scrollDetection.start(draggable.getHelperEl())
            },

            stop: function() {
                $self.removeClass('draggable-active')
                scrollDetection.stop()
            },

            view: this
        })
    },

    _hijackCheckbox: function() {
        this.$().delegate('input[name="isSelected"]', 'change', functionProxy(this._didChangeIsSelected, this))
    }.on('didInsertElement'),

    _didChangeIsSelected: function(e) {
        var controller = this.controller
        Em.run(function() {
            e.target.checked = !e.target.checked
            controller.send('toggleSelect')
        })
    },

    willDestroyElement: function() {
        if (this._draggable) {
            this._draggable.destroy()
        }
    }
})

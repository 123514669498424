var t = require('i18n').t

module.exports = require('ember-super-field').types.Model.extend({
    modelClass: Billy.AccountGroup,

    sortProperty: 'sortValue',

    inputValuePath: 'name',

    noOptionsFoundText: t('ui.fields.superfield.no_account_groups_found'),

    optionViewClass: require('ember-super-field').OptionView.extend({
        template: Ember.Handlebars.compile(
            '<div class="name">{{name}}</div>'
        )
    }),

    query: function() {
        var query = {
            organization: this.get('userController.activeOrganization'),
            type: 'group'
        }
        var natures = this.get('field.accountNatures')
        if (natures) {
            query.nature = natures
        }
        return query
    }.property('userController.activeOrganization', 'field.groupNatures')
})

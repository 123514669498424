module.exports = Em.View.extend({

    templateName: 'ui/list/table/cell',

    init: function() {
        this._super()
        this.bind('rawValue', 'controller.' + this.get('column.name'))
        var cellCssClassPath = this.get('column.cellCssClassPath')
        if (cellCssClassPath) {
            this.bind('cellClass', 'controller.' + cellCssClassPath)
        }
    },

    classNames: ['table-cell'],
    classNameBindings: ['rawClass'],
    extraClass: null,
    rawClass: function() {
        var c = []
        var align = this.get('column.align')
        var extraClass = this.get('extraClass')
        var cellClass = this.get('cellClass')
        if (align !== 'left') {
            c.push('align-' + align)
        }
        if (this.get('column.multiline')) {
            c.push('multiline')
        }
        if (extraClass) {
            c.push(extraClass)
        }
        if (cellClass) {
            c.push(cellClass)
        }
        return c.join(' ')
    }.property('cellClass'),

    attributeBindings: ['style', 'cy:data-cy', 'icon-status'],

    cy: function() {
        return _.kebabCase(_.toLower(this.get('column.name') + '-value'))
    }.property(),

    style: function() {
        return this.get('column.style')
    }.property(),

    value: function() {
        var value = this.get('column').format(this.get('rawValue'))
        if (value === '' || value === null || value === undefined || (value instanceof Handlebars.SafeString && value.string.length === 0)) {
            return ''
        }
        return value
    }.property('rawValue')

})

module.exports = Em.Route.extend({
    include: 'transaction.postings',
    modelQueryParams: {
        withCreator: true
    },

    resetController: function(controller) {
        this._super.apply(this, arguments)
        controller.set('isEditingAttachments', false)
        controller.set('previousAttachments', null)
    }
})

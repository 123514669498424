var storage = require('storage')
var CellView = require('../table/cell-view')
var HeaderCellView = require('../table/header-cell-view')

module.exports = Em.Object.extend({
    // Injected in list-controller
    controller: null,

    visible: function(key, visible) {
        if (!this.get('hideable')) {
            return true
        }
        var controller = this.get('controller')
        if (!controller) {
            return true
        }
        var storageKey = 'columnVisibility:' + controller.get('storageKey') + '_' + this.get('name')
        if (arguments.length > 1) {
            storage(storageKey, visible)
            return visible
        } else {
            var storageVisible = storage(storageKey)
            if (storageVisible) {
                return storageVisible === 'true'
            }
            return this.get('defaultVisible')
        }
    }.property('controller', 'hideable', 'defaultVisible'),

    defaultVisible: true,

    hideable: true,

    align: 'left',

    width: null,
    flex: null,
    flexWidth: null,
    left: null,
    right: null,

    multiline: false,
    sortable: false,

    cellViewClass: CellView,
    headerCellViewClass: HeaderCellView,

    init: function() {
        this._super()
        Ember.assert('No width or flex set for column `' + this.get('name') + '`.', this.get('width') || this.get('flex'))
    },

    style: function() {
        var s = []
        var width = this.get('width')
        var flexWidth = this.get('flexWidth')
        var paddingRight = this.get('paddingRight')
        var marginRight = this.get('marginRight')
        if (!Ember.isEmpty(flexWidth)) {
            s.push('width:' + flexWidth + '%;')
        } else if (!Ember.isEmpty(width)) {
            s.push('width:' + width + 'px;')
        }
        if (!Ember.isEmpty(paddingRight)) {
            s.push('padding-right:' + paddingRight + 'px;')
        }
        if (!Ember.isEmpty(marginRight)) {
            s.push('margin-right:' + marginRight + 'px;')
        }
        return s.join('')
    }.property('width', 'flexWidth', 'paddingRight', 'marginRight'),

    format: function(value) {
        return value
    },

    isAlignRight: Em.computed.equal('align', 'right')

})

var tracking = require('../utils/tracking')

module.exports = Em.Route.extend({
    api: Em.inject.service(),

    startGuide: Em.inject.service(),

    userOrganizations: Em.inject.service(),

    organization: Em.computed.alias('userOrganizations.activeOrganization'),

    beforeModel: function() {
        // HACK: On this page, there's a super-field, which we need to
        // order values on. We're using a sort macro, which can sort
        // local records, but before that can happen, we need these
        // records saved in our store, which is done here
        this.get('api').request('GET', '/v2/industryGroups')
            .then(function(payload) {
                Billy.IndustryGroup.loadAll(payload.industryGroups)
            })

        this.get('startGuide').setCurrentRouteName(this.get('routeName'))
    },

    setupController: function(controller) {
        controller.setProperties({
            organization: this.get('organization'),
            enterCompany: this.get('organization') && !this.get('organization.isNew'),
            manualCompanyEntry: this.get('organization') && !this.get('organization.isNew'),
            confirmCompany: this.get('organization') && !!this.get('organization.isNew'),
            isCVRValid: null
        })
    },

    actions: {
        goToNextStep: function(organization) {
            this.transitionTo(this.get('startGuide.nextStep'), organization)
            tracking.emitAnalyticsEvent('conversion', 'signup')
        }
    }
})

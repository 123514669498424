var t = require('i18n').t

module.exports = require('ember-super-field').types.Model.extend({
    modelClass: Billy.City,
    sortProperty: 'name',

    noOptionsFoundText: t('ui.fields.superfield.no_cities_found'),

    optionViewClass: require('ember-super-field').OptionView.extend({
        template: Ember.Handlebars.compile(
            '<div class="name">{{view.cityFormatted}}</div>'
        ),
        cityFormatted: function() {
            var name = this.get('content.name')
            var stateCode = this.get('content.state.stateCode') || ''
            return name + (name && stateCode ? ', ' : '') + stateCode
        }.property('content.name', 'content.state.stateCode')
    }),

    query: function() {
        return {
            include: 'city.state',
            country: this.get('field.country')
        }
    }.property('field.country')
})

module.exports = BD.Model.extend({
    scheme: BD.belongsTo('Billy.InvoiceReminderScheme', { isParent: true, immutable: true }),
    createdTime: BD.attr('datetime', { readonly: true }),
    actionNo: BD.attr('number', { readonly: true }),
    daysPastDue: BD.attr('number'),
    subject: BD.attr('string'),
    body: BD.attr('string'),
    flatFee: BD.attr('number'),
    percentageFee: BD.attr('number')
})

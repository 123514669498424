var t = require('i18n').t

module.exports.get = function() {
    return [
        Em.O({
            value: 'month',
            name: t('organization.sales_tax_period.month')
        }),
        Em.O({
            value: 'quarter',
            name: t('organization.sales_tax_period.quarter')
        }),
        Em.O({
            value: 'halfYear',
            name: t('organization.sales_tax_period.half_year')
        })
    ]
}

module.exports = function getThumbnailUrl(fileUrl, options) {
    var size = options.size || '64x64'
    var fit = options.fit || 'cover'
    var page = options.page || '0' // PDF and GIF

    if (new RegExp(ENV.IMAGE_PROXY_URL).test(fileUrl)) {
        var url = new URL(fileUrl)
        url.searchParams.append('size', size)
        url.searchParams.append('fit', fit)
        url.searchParams.append('page', page)
        fileUrl = url.toString()
    }

    return fileUrl
}

/*
This service acts as the container for holding which item is currently active
in the left menu.
*/
module.exports = Em.Object.extend({
    activeOrganization: null,

    activeUmbrella: null,

    withSideNavbar: false,

    activateOrganization: function(organization) {
        this.set('activeUmbrella', null)
        this.set('activeOrganization', organization)
        this.set('withSideNavbar', organization.get('isSourceZervant'))
    },

    activateUmbrella: function(umbrella) {
        this.set('activeOrganization', null)
        this.set('activeUmbrella', umbrella)
        this.set('withSideNavbar', false)
    },

    deactivate: function() {
        this.set('activeOrganization', null)
        this.set('activeUmbrella', null)
        this.set('withSideNavbar', false)
    }
})

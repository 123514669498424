var t = require('i18n').t

module.exports = require('ember-super-field').types.Model.extend({
    modelClass: Billy.ContactPerson,

    sortProperty: 'name',

    inputValuePath: 'nameWithEmail',

    noOptionsFoundText: t('ui.fields.superfield.no_contact_persons_found'),

    create: function(name, callback) {
        var email = ''
        if (name && name.match(/@/)) { // Very simple email regex. Any string @
            email = name
            name = ''
        }
        var contact = this.get('field.query.contact')
        var contactPerson = Billy.ContactPerson.createRecord({
            contact: contact,
            firstName: name,
            lastName: name,
            email: email
        })
        this.container.lookup('component:contactPersonEditor').set('content', contactPerson).set('callback', callback).show()
    },

    optionViewClass: require('ember-super-field').OptionView.extend({
        template: Ember.Handlebars.compile('<div class="name" data-cy="contact-person-list-option">{{nameWithEmail}}</div>')
    })
})

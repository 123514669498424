var moment = require('momentjs')
var createConversionBalanceReportTypes = require('../utils/create-conversion-balance-report-types')

module.exports = Em.Route.extend({
    model: function() {
        var organization = this.controllerFor('user').get('activeOrganization')
        return new Em.RSVP.Promise(function(resolve, reject) {
            Billy.ConversionBalance.filter({
                query: {
                    organization: organization
                },
                remoteQuery: {
                    include: 'conversionBalance.lines'
                }
            }).promise.then(function(conversionBalances) {
                var conversionBalance = conversionBalances.objectAt(0)
                conversionBalances.destroy()
                if (!conversionBalance) {
                    conversionBalance = Billy.ConversionBalance.createRecord({
                        organization: organization,
                        conversionDate: moment()
                    })
                }
                resolve(conversionBalance)
            }, reject)
        })
    },

    setupController: function(controller, conversionBalance) {
        var organization = this.controllerFor('user').get('activeOrganization')
        controller.set('model', conversionBalance)
        controller.set('reportTypes', createConversionBalanceReportTypes(organization, conversionBalance))
    }
})

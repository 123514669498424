var Cookies = require('js-cookie')
var getDeviceType = require('./get-device-type')
var qs = require('qs')

var cookiesKey = 'billy-signup-analytics'

var cookiesSettings = {
    expires: 14,
    domain: '.billy.dk' // accessible on subdomains
}

function setData(data) {
    Cookies.set(cookiesKey, data, cookiesSettings)
}

function getData() {
    return Cookies.get(cookiesKey)
}

function removeData() {
    return Cookies.remove(cookiesKey)
}

function getSignupAnalyticsData() {
    var data = getData()
    return data ? { analytics: data } : undefined
}

function removeSignupAnalyticsData() {
    removeData()
}

function trackSignupAnalyticsData(url) {
    var currentData = getSignupAnalyticsData()

    // We don't want to override it when it has been already stored
    if (!url && currentData && currentData.analytics) {
        return
    }

    var sourceRaw = url || window.location.href
    setData(sourceRaw)
}

function getInternalAttributes(medium) {
    var utmSource = 'web-app'
    var utmDevice = getDeviceType()
    var utmMedium = medium

    return qs.stringify({
        utm_source: utmSource,
        utm_device: utmDevice,
        utm_medium: utmMedium
    })
}

module.exports = {
    getInternalAttributes: getInternalAttributes,
    getSignupAnalyticsData: getSignupAnalyticsData,
    removeSignupAnalyticsData: removeSignupAnalyticsData,
    trackSignupAnalyticsData: trackSignupAnalyticsData
}

var t = require('i18n').t

module.exports = require('ember-super-field').types.Model.extend({
    modelClass: Billy.Zipcode,
    sortProperty: 'zipcode',
    inputValuePath: 'zipcode',

    noOptionsFoundText: t('ui.fields.superfield.no_zipcodes_found'),

    optionHeight: 64,
    optionViewClass: require('ember-super-field').OptionView.extend({
        template: Ember.Handlebars.compile(
            '<div class="name">{{zipcode}}</div>' +
            '<div class="meta">{{view.cityStateCodeFormatted}}</div>'
        ),
        cityStateCodeFormatted: function() {
            var cityName = this.get('content.city.name') || ''
            var stateCode = this.get('content.state.stateCode') || ''
            return cityName + (cityName && stateCode ? ', ' : '') + stateCode
        }.property('content.city.name', 'content.state.stateCode')
    }),

    query: function() {
        return {
            include: 'zipcode.city,zipcode.state',
            country: this.get('field.country'),
            state: this.get('field.countryState')
        }
    }.property('field.country', 'field.countryState')
})

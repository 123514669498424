var t = require('i18n').t

module.exports = Em.View.extend({
    templateName: 'bank-import/column-selector',

    classNames: ['table-cell bank-import-column-selector'],
    classNameBindings: ['columnIndexClass'],

    attributeBindings: ['style'],

    ic: Em.computed.alias('controller.ic'),

    init: function() {
        this._super()
        this.indexesDidChange()
    },

    columnIndexClass: function() {
        return 'bank-import-column-selector-' + this.get('content')
    }.property('content'),

    indexesDidChange: function() {
        var value = null
        var index = this.get('content')
        var ic = this.get('ic');
        ['date', 'description', 'amount'].find(function(col) {
            if (index === ic.get(col + 'Index')) {
                value = col
                return true
            }
        })
        if (this.get('value') !== value) {
            this._ignoreNextValueChange = true
            this.set('value', value)
        }
    }.observes('ic.dateIndex', 'ic.descriptionIndex', 'ic.amountIndex'),

    value: null,

    valueWillChange: function() {
        this._prevValue = this.get('value')
    }.observesBefore('value'),

    valueDidChange: function() {
        if (this._ignoreNextValueChange) {
            this._ignoreNextValueChange = false
            return
        }
        var ic = this.get('ic')
        var prevValue = this._prevValue
        var value = this.get('value')
        var index = this.get('content')
        if (value) {
            ic.set(value + 'Index', index)
        }
        if (prevValue) {
            ic.set(prevValue + 'Index', null)
        }
    }.observes('value'),

    options: function() {
        return Em.A([
            Em.Object.create({
                value: 'date',
                name: t('bank_sync.entry_date')
            }),
            Em.Object.create({
                value: 'description',
                name: t('bank_sync.description')
            }),
            Em.Object.create({
                value: 'amount',
                name: t('bank_sync.amount')
            })
        ])
    }.property(),

    edit: function() {
        this.set('value', null)
    }
})

var Scope = require('../utils/scope')

module.exports = Em.View.extend({
    auth: Em.inject.service(),

    templateName: 'daybook/edit-transaction',

    classNames: ['daybook-edit-transaction'],

    classNameBindings: ['controller.focused:daybook-edit-transaction-focused', 'controller.selected:daybook-edit-transaction-selected'],

    showAttachmentsPopover: function() {
        var constraints = this.container.lookup('service:constraints')

        // @todo doubled scopes-check
        if (!this.get('auth').isAuthorized(Scope.BillAttachmentWrite) || !constraints.hasScope('voucher')) {
            constraints.showUpgradeOverlay('', 'daybook_attachment')
            return
        }

        // Warning: This is a little whack in the pack. Not nice in the pice
        var self = this
        var p = this.container.lookup('component:daybook-attachments-popover')
        this._attachmentsPopover = p
        p.set('transaction', this.get('controller.model'))
        p.on('didAttachFile', function() {
            self.get('controller').save()
        })
        p.on('showReceipts', function() {
            p.destroy()
            self.showReceipts()
        })
        p.one('willDestroyElement', function() {
            self._attachmentsPopover = null
            self.get('controller').save()
        })
        p.show(this, this.$('.attachments-field'))
        Em.run.schedule('afterRender', function() {
            p.$('.file-uploader-input').focus()
            p.$('button, input').on('keydown', function(e) {
                if (e.keyCode === 27) {
                    e.preventDefault()
                    p.destroy()
                    self.$('input[name="text"]').focus()
                }
            })
        })
    },

    showReceipts: function() {
        var controller = this.get('controller')
        var selector = this.container.lookup('component:choose-receipt-modal')
        selector.show()
        selector.on('didSelect', function(attachment) {
            controller.stealAttachment(attachment)
        })
    },

    willDestroyElement: function() {
        this._super()
        if (this._attachmentsPopover) {
            this._attachmentsPopover.destroy()
        }
    },

    actions: {
        toggleAttachments: function() {
            var p = this._attachmentsPopover
            if (p) {
                p.destroy()
            } else {
                this.showAttachmentsPopover()
            }
        }
    }
})

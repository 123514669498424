var filterOrganizationProperties = require('../utils/filter-organization-properties')
var filterOrganizationSettingsProperties = require('../utils/filter-organization-settings-properties')
var formatDateOrganizationProperties = require('../utils/format-date-organization-properties')

module.exports = Em.Object.extend({
    userOrganizations: Em.inject.service(),
    organization: Em.computed.alias('userOrganizations.activeOrganization'),

    updateOrganization: function(updateOrganizationData) {
        var organization = this.get('organization')
        var filteredOrganizationData = filterOrganizationProperties(updateOrganizationData)
        var filteredAndFormattedOrganizationData = formatDateOrganizationProperties(filteredOrganizationData)

        organization.setProperties(filteredAndFormattedOrganizationData)
    },

    updateOrganizationSettings: function(updateOrganizationSettingsData) {
        var organization = this.get('organization')
        var organizationSettings = organization.get('settings')
        var filteredOrganizationSettingsData = filterOrganizationSettingsProperties(updateOrganizationSettingsData)

        for (var key in filteredOrganizationSettingsData) {
            if (filteredOrganizationSettingsData.hasOwnProperty(key)) {
                var value = filteredOrganizationSettingsData[key]
                organizationSettings.setRaw(key, value)
            }
        }
    }
})

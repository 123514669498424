var model = BD.Model.extend({
    organization: BD.belongsTo('Billy.Organization'),
    transaction: BD.belongsTo('Billy.Transaction', { readonly: true }),
    entryDate: BD.attr('date', { readonly: true }),
    text: BD.attr('string', { readonly: true }),
    account: BD.belongsTo('Billy.Account', { readonly: true }),
    amount: BD.attr('number', { readonly: true }),
    side: BD.attr('side', { readonly: true }),
    currency: BD.belongsTo('Billy.Currency', { readonly: true }),
    balance: BD.attr('number', { readonly: true }),
    isBankMatched: BD.attr('boolean', { readonly: true }),
    isVoided: BD.attr('boolean', { readonly: true }),
    priority: BD.attr('number', { readonly: true }),
    baseAmount: BD.attr('number', { readonly: true })
})

model.reopenClass({
    qProperties: ['text', 'transaction.originatorName']
})

module.exports = model

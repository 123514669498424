var moment = require('moment')
var getFiscalYear = require('./get-fiscal-year')

/**
 * Be advised - the month as passed is 1-indexed, but new Date() and fiscalYear expect 0-index
 *
 * @param {string} periodText
 * @param {{firstFiscalYearStart: Date, firstFiscalYearEnd: Date}} options
 * @returns {{start: Date, end: Date}}
 */
module.exports = function(periodText, options) {
    options = options || {}
    var parts = periodText.split(':', 2)
    var value = parts[1]
    var date

    switch (parts[0]) {
    // Formatted as "month:yyyy-mm"
    case 'month':
        var yearAndMonth = value.split('-')
        date = new Date(parseInt(yearAndMonth[0]), parseInt(yearAndMonth[1]) - 1)
        return {
            start: moment(date).startOf('month').toDate(),
            end: moment(date).endOf('month').toDate()
        }

    // Formatted as "quarter:yyyy-q"
    case 'quarter':
        var yearAndQuarter = value.split('-')

        // Technically we can use endOf('quarter') but this solution is good enough as the other one could not be made
        // to work the way the docs mention
        date = new Date(parseInt(yearAndQuarter[0]), 3 * (parseInt(yearAndQuarter[1]) - 1), 1)
        return {
            start: moment(date).startOf('quarter').toDate(),
            end: moment(date)
                .startOf('quarter')
                .add(3, 'months')
                .subtract(1, 'days')
                .endOf('day').toDate()
        }

    // Formatted as "year:yyyy"
    case 'year':
        date = new Date(parseInt(value), 1)
        return {
            start: moment(date).startOf('year').toDate(),
            end: moment(date).endOf('year').toDate()
        }

    // Formatted as "fiscalyear:organizationId,yyyy", uses options.fiscalYearStartMonth where it has been extracted
    // from the organization
    case 'fiscalyear':
        var year = value.split(',').pop()
        var firstFiscalYearStart = options.firstFiscalYearStart || moment(year).startOf('year')
        var firstFiscalYearEnd = options.firstFiscalYearEnd || moment(year).endOf('year')
        var fiscalYear = getFiscalYear(year, firstFiscalYearStart, firstFiscalYearEnd)
        return {
            start: fiscalYear.startDate,
            end: fiscalYear.endDate
        }

    // Formatted as "dates:yyyy-mm-dd...yyyy-mm-dd
    case 'dates':
        var dateStrings = value.split('...')
        var startDateParts = dateStrings[0].split('-')
        var endDateParts = dateStrings[1].split('-')
        return {
            start: new Date(parseInt(startDateParts[0]), parseInt(startDateParts[1]) - 1, parseInt(startDateParts[2])),
            end: new Date(parseInt(endDateParts[0]), parseInt(endDateParts[1]) - 1, parseInt(endDateParts[2]))
        }
    }

    throw new Error('Unrecognized date period format: ' + periodText)
}

// moment.js language configuration
// language : malayalam (ml)
// author : Floyd Pink : https://github.com/floydpink

(function (factory) {
    if (typeof define === 'function' && define.amd) {
        define(['moment'], factory); // AMD
    } else if (typeof exports === 'object') {
        module.exports = factory(require('../moment')); // Node
    } else {
        factory(window.moment); // Browser global
    }
}(function (moment) {
    return moment.lang('ml', {
        months : 'ജനുവരി_ഫെബ്രുവരി_മാർച്ച്_ഏപ്രിൽ_മേയ്_ജൂൺ_ജൂലൈ_ഓഗസ്റ്റ്_സെപ്റ്റംബർ_ഒക്ടോബർ_നവംബർ_ഡിസംബർ'.split("_"),
        monthsShort : 'ജനു._ഫെബ്രു._മാർ._ഏപ്രി._മേയ്_ജൂൺ_ജൂലൈ._ഓഗ._സെപ്റ്റ._ഒക്ടോ._നവം._ഡിസം.'.split("_"),
        weekdays : 'ഞായറാഴ്ച_തിങ്കളാഴ്ച_ചൊവ്വാഴ്ച_ബുധനാഴ്ച_വ്യാഴാഴ്ച_വെള്ളിയാഴ്ച_ശനിയാഴ്ച'.split("_"),
        weekdaysShort : 'ഞായർ_തിങ്കൾ_ചൊവ്വ_ബുധൻ_വ്യാഴം_വെള്ളി_ശനി'.split("_"),
        weekdaysMin : 'ഞാ_തി_ചൊ_ബു_വ്യാ_വെ_ശ'.split("_"),
        longDateFormat : {
            LT : "A h:mm -നു",
            L : "DD/MM/YYYY",
            LL : "D MMMM YYYY",
            LLL : "D MMMM YYYY, LT",
            LLLL : "dddd, D MMMM YYYY, LT"
        },
        calendar : {
            sameDay : '[ഇന്ന്] LT',
            nextDay : '[നാളെ] LT',
            nextWeek : 'dddd, LT',
            lastDay : '[ഇന്നലെ] LT',
            lastWeek : '[കഴിഞ്ഞ] dddd, LT',
            sameElse : 'L'
        },
        relativeTime : {
            future : "%s കഴിഞ്ഞ്",
            past : "%s മുൻപ്",
            s : "അൽപ നിമിഷങ്ങൾ",
            m : "ഒരു മിനിറ്റ്",
            mm : "%d മിനിറ്റ്",
            h : "ഒരു മണിക്കൂർ",
            hh : "%d മണിക്കൂർ",
            d : "ഒരു ദിവസം",
            dd : "%d ദിവസം",
            M : "ഒരു മാസം",
            MM : "%d മാസം",
            y : "ഒരു വർഷം",
            yy : "%d വർഷം"
        },
        meridiem : function (hour, minute, isLower) {
            if (hour < 4) {
                return "രാത്രി";
            } else if (hour < 12) {
                return "രാവിലെ";
            } else if (hour < 17) {
                return "ഉച്ച കഴിഞ്ഞ്";
            } else if (hour < 20) {
                return "വൈകുന്നേരം";
            } else {
                return "രാത്രി";
            }
        }
    });
}));

module.exports = Em.Object.extend({
    api: Ember.inject.service(),

    isLoaded: false,

    data: null,

    userOrganizations: Em.inject.service(),

    organization: Em.computed.oneWay('userOrganizations.activeOrganization'),

    _baseURL: function() {
        var organization = this.get('organization')
        return '/v2/organizations/' + organization.get('id') + '/couponOffer'
    }.property('organization'),

    invalidate: function() {
        this.set('isLoaded', false)
            .set('data', null)
    }.observes('organization'),

    load: function() {
        var self = this
        this._loadPromise = this.get('api').request('GET', this.get('_baseURL'))
            .then(function(payload) {
                self.set('data', payload.couponOffer)
            }, function() {
                self.set('data', null)
            })
            .finally(function() {
                self.set('isLoaded', true)
            })
        return this._loadPromise
    },

    loadSafe: function() {
        this.load()
            .catch(function(e) {
                // Coupon load failed, but will not throw an error
            })
    },

    ensureLoaded: function() {
        return this._loadPromise || this.load()
    },

    getContent: function() {
        if (!this.get('isLoaded')) {
            throw new Error('Coupon has not been loaded yet.')
        }
        return Ember.copy(this.get('data'), true)
    },

    isApplicable: function() {
        return Ember.keys(this.get('data') || {}).length > 0
    }
})

/**
 * Will take a numeric string with either comma or period as decimal separator or thousand separator.
 *
 * It only works with numbers that are expected to have 0, 1 or 2 decimals.
 *
 * Examples:
 *
 * ```javascript
 * parseNumber('12.345,6'); //12345.60
 * parseNumber('12.345,67'); //12345.67
 * parseNumber('-12,345.67'); //-12345.67
 * parseNumber('1.000'); //1000.00
 * parseNumber('1,000'); //1000.00
 * ```
 *
 * @param {String} input A number as a string with 0-2 decimals.
 * @returns {number}
 */
module.exports = function(input) {
    input = String(input || '')
    var match = input.match(/([\.,])\d{1,2}$/) || input.match(/([\.,])\d{4,}$/) // The last digits after a `,` or `.` are decimals if there are 1, 2, or 4+ of them. If there are 3, the dot is treated as a thousand separator.
    var unformatted
    if (match) {
        var decimalSep = match[1]
        var thousandSep = decimalSep === '.' ? ',' : '.'
        unformatted = input.replace(new RegExp(thousandSep.replace('.', '\\.'), 'g'), '').replace(new RegExp(decimalSep.replace('.', '\\.'), 'g'), '.')
    } else {
        unformatted = input.replace(/[\.,]/g, '')
    }
    return unformatted * 1
}

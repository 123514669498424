/**
This module will return a random UUID
*/

module.exports.getRandom = function() {
    return getUUID()
}

module.exports.getRandomPrefixed = function() {
    return 'RAN_' + getUUID()
}

function getUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0
        var v = c === 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16)
    })
}

// moment.js language configuration
// language : thai (th)
// author : Kridsada Thanabulpong : https://github.com/sirn

(function (factory) {
    if (typeof define === 'function' && define.amd) {
        define(['moment'], factory); // AMD
    } else if (typeof exports === 'object') {
        module.exports = factory(require('../moment')); // Node
    } else {
        factory(window.moment); // Browser global
    }
}(function (moment) {
    return moment.lang('th', {
        months : "มกราคม_กุมภาพันธ์_มีนาคม_เมษายน_พฤษภาคม_มิถุนายน_กรกฎาคม_สิงหาคม_กันยายน_ตุลาคม_พฤศจิกายน_ธันวาคม".split("_"),
        monthsShort : "มกรา_กุมภา_มีนา_เมษา_พฤษภา_มิถุนา_กรกฎา_สิงหา_กันยา_ตุลา_พฤศจิกา_ธันวา".split("_"),
        weekdays : "อาทิตย์_จันทร์_อังคาร_พุธ_พฤหัสบดี_ศุกร์_เสาร์".split("_"),
        weekdaysShort : "อาทิตย์_จันทร์_อังคาร_พุธ_พฤหัส_ศุกร์_เสาร์".split("_"), // yes, three characters difference
        weekdaysMin : "อา._จ._อ._พ._พฤ._ศ._ส.".split("_"),
        longDateFormat : {
            LT : "H นาฬิกา m นาที",
            L : "YYYY/MM/DD",
            LL : "D MMMM YYYY",
            LLL : "D MMMM YYYY เวลา LT",
            LLLL : "วันddddที่ D MMMM YYYY เวลา LT"
        },
        meridiem : function (hour, minute, isLower) {
            if (hour < 12) {
                return "ก่อนเที่ยง";
            } else {
                return "หลังเที่ยง";
            }
        },
        calendar : {
            sameDay : '[วันนี้ เวลา] LT',
            nextDay : '[พรุ่งนี้ เวลา] LT',
            nextWeek : 'dddd[หน้า เวลา] LT',
            lastDay : '[เมื่อวานนี้ เวลา] LT',
            lastWeek : '[วัน]dddd[ที่แล้ว เวลา] LT',
            sameElse : 'L'
        },
        relativeTime : {
            future : "อีก %s",
            past : "%sที่แล้ว",
            s : "ไม่กี่วินาที",
            m : "1 นาที",
            mm : "%d นาที",
            h : "1 ชั่วโมง",
            hh : "%d ชั่วโมง",
            d : "1 วัน",
            dd : "%d วัน",
            M : "1 เดือน",
            MM : "%d เดือน",
            y : "1 ปี",
            yy : "%d ปี"
        }
    });
}));

var get = Em.get

module.exports = DS.RESTSerializer.extend({
    normalizePayload: function(payload) {
        delete payload.links
        return payload
    },

    extractArray: function(store, primaryType, payload) {
        if (payload.data && !Array.isArray(payload.data)) {
            payload.data = [payload.data]
        }
        payload[primaryType.typeKey] = payload.data || []
        delete payload.data
        return this._super(store, primaryType, payload)
    },

    extractSingle: function(store, primaryType, payload) {
        payload[primaryType.typeKey] = payload.data || {}
        delete payload.data
        return this._super(store, primaryType, payload)
    },

    normalizeAttributes: function(type, hash) {
        type.eachAttribute(function(key, attr) {
            if (attr.options.apiKey) {
                hash[key] = hash[attr.options.apiKey]
                delete hash[attr.options.apiKey]
            }
        }, this)
    },

    keyForRelationship: function(key) {
        return key + 'Id'
    },

    serializeIntoHash: function(data, type, record, options) {
        data.data = this.serialize(record, options)
    },

    serialize: function(record, options) {
        var json = {}
        var changedAttributes = record.changedAttributes()
        var inFlight = record._inFlightAttributes
        var isNew = record.get('isNew')

        if (options && options.includeId) {
            var id = get(record, 'id')

            if (id) {
                json[get(this, 'primaryKey')] = id
            }
        }

        record.eachAttribute(function(key, attr) {
            var value = get(record, key)
            // TODO: Should it be an option to not include clean attributes?
            if (typeof value !== 'undefined' && (isNew || changedAttributes[key] || Object.prototype.hasOwnProperty.call(inFlight, key))) {
                this.serializeAttribute(record, json, key, attr)
                if (attr.options.apiKey) {
                    json[attr.options.apiKey] = json[key]
                    delete json[key]
                }
            }
        }, this)

        record.eachRelationship(function(key, relationship) {
            if (relationship.kind === 'belongsTo') {
                var value = get(record, key)
                if (typeof value !== 'undefined' && (isNew || changedAttributes[key] || Object.prototype.hasOwnProperty.call(inFlight, key))) {
                    this.serializeBelongsTo(record, json, relationship)
                }
            } else if (relationship.kind === 'hasMany') {
                this.serializeHasMany(record, json, relationship)
            }
        }, this)

        return json
    },

    extractErrors: function(store, type, payload) {
        var normalizedErrors = {}
        if (payload && Array.isArray(payload.errors)) {
            payload.errors.forEach(function(err) {
                var detail = err.detail
                if (!detail) {
                    return
                }
                var pointer = err.pointer
                var errorKey
                if (pointer) {
                    errorKey = pointer.replace(/^\/data\//, '')
                    type.eachAttribute(function(key, attr) {
                        if (attr.options.apiKey === errorKey) {
                            errorKey = attr.name
                        }
                    })
                } else {
                    errorKey = 'self'
                }
                if (normalizedErrors[errorKey]) {
                    normalizedErrors[errorKey].push(err.detail)
                } else {
                    normalizedErrors[errorKey] = [err.detail]
                }
            })
        } else {
            Ember.warn('`errors` was not an array in normalizeErrors')
        }
        return normalizedErrors
    }
})

var AuthorizedRoute = require('../mixins/authorized-route')
var Scope = require('../utils/scope')

module.exports = Em.Route.extend(AuthorizedRoute, {
    scopes: [Scope.RecurringInvoiceRead],

    activate: function() {
        this._super()
        this.controllerFor('recurring.invoices.index').activate()
    },

    deactivate: function() {
        this._super()
        this.controllerFor('recurring.invoices.index').deactivate()
    },

    setupController: function(controller) {
        controller.loadRecords()
    }
})

var _ = require('lodash')

module.exports = Em.Object.extend({
    api: Em.inject.service(),

    userOrganizations: Em.inject.service(),

    organization: Em.computed.alias('userOrganizations.activeOrganization'),

    fetchOrganizationSettings: function() {
        var api = this.get('api')
        var url = '/v2/organizations/' + this.get('organization.id') + '/settings/billy'

        return api.getData(url, undefined, { raw: true })
    },

    hasSettingEnabled: function(settingKey) {
        return this.fetchOrganizationSettings().then(function(response) {
            var organizationSettings = response.settings
            return _.get(organizationSettings, settingKey) === '1'
        })
    }
})

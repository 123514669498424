var t = require('i18n').t
var tracking = require('../utils/tracking')
var NOTIFICATION_KEYS = require('../notificationKeys')
var NOTIFICATION_VARIANT = require('../notificationVariant')

module.exports = Em.Route.extend({
    startGuide: Em.inject.service(),

    couponOffer: Ember.inject.service(),

    beforeModel: function() {
        var self = this
        var couponOffer = this.get('couponOffer')

        this.get('startGuide').setCurrentRouteName(this.get('routeName'))

        return couponOffer.load()
            .then(function() {
                self.set('isCouponOfferAvailable', couponOffer.isApplicable())
                self.set('couponData', self.get('couponOffer').getContent())
            })
    },

    setupController: function(controller) {
        var notification = this.container.lookup('util:notification')

        controller.set('couponOffer', this.get('couponOffer'))
        controller.set('isCouponOfferAvailable', this.get('isCouponOfferAvailable'))
        controller.set('couponData', this.get('couponData'))

        // Catch the redirect from quickpay back to our app
        if (controller.get('payment_failed')) {
            notification.warn(controller.get('message'))
        } else if (controller.get('payment_cancelled')) {
            notification.notify(NOTIFICATION_KEYS.PAYMENT_CANCELLED, t('startguide_payment_details.payment_cancelled'), NOTIFICATION_VARIANT.INFO)
        } else if (controller.get('payment_successful')) {
            notification.success(t('startguide_payment_details.payment_successful'))
            tracking.emitAnalyticsEvent('credit_card', 'credit_card_entered')
            controller.send('onPaymentDetailsSuccess')
        }
    },

    resetController: function(controller) {
        controller.set('payment_failed', undefined)
            .set('message', undefined)
            .set('payment_cancelled', undefined)
            .set('payment_successful', undefined)
            .set('activate', undefined)
    }
})

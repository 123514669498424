// moment.js language configuration
// language : chuvash (cv)
// author : Anatoly Mironov : https://github.com/mirontoli

(function (factory) {
    if (typeof define === 'function' && define.amd) {
        define(['moment'], factory); // AMD
    } else if (typeof exports === 'object') {
        module.exports = factory(require('../moment')); // Node
    } else {
        factory(window.moment); // Browser global
    }
}(function (moment) {
    return moment.lang('cv', {
        months : "кăрлач_нарăс_пуш_ака_май_çĕртме_утă_çурла_авăн_юпа_чӳк_раштав".split("_"),
        monthsShort : "кăр_нар_пуш_ака_май_çĕр_утă_çур_ав_юпа_чӳк_раш".split("_"),
        weekdays : "вырсарникун_тунтикун_ытларикун_юнкун_кĕçнерникун_эрнекун_шăматкун".split("_"),
        weekdaysShort : "выр_тун_ытл_юн_кĕç_эрн_шăм".split("_"),
        weekdaysMin : "вр_тн_ыт_юн_кç_эр_шм".split("_"),
        longDateFormat : {
            LT : "HH:mm",
            L : "DD-MM-YYYY",
            LL : "YYYY [çулхи] MMMM [уйăхĕн] D[-мĕшĕ]",
            LLL : "YYYY [çулхи] MMMM [уйăхĕн] D[-мĕшĕ], LT",
            LLLL : "dddd, YYYY [çулхи] MMMM [уйăхĕн] D[-мĕшĕ], LT"
        },
        calendar : {
            sameDay: '[Паян] LT [сехетре]',
            nextDay: '[Ыран] LT [сехетре]',
            lastDay: '[Ĕнер] LT [сехетре]',
            nextWeek: '[Çитес] dddd LT [сехетре]',
            lastWeek: '[Иртнĕ] dddd LT [сехетре]',
            sameElse: 'L'
        },
        relativeTime : {
            future : function (output) {
                var affix = /сехет$/i.exec(output) ? "рен" : /çул$/i.exec(output) ? "тан" : "ран";
                return output + affix;
            },
            past : "%s каялла",
            s : "пĕр-ик çеккунт",
            m : "пĕр минут",
            mm : "%d минут",
            h : "пĕр сехет",
            hh : "%d сехет",
            d : "пĕр кун",
            dd : "%d кун",
            M : "пĕр уйăх",
            MM : "%d уйăх",
            y : "пĕр çул",
            yy : "%d çул"
        },
        ordinal : '%d-мĕш',
        week : {
            dow : 1, // Monday is the first day of the week.
            doy : 7  // The week that contains Jan 1st is the first week of the year.
        }
    });
}));

module.exports = Em.Object.extend({
    segment: Em.inject.service(),

    loginNeedsTracking: false,

    setLoginNeedsTracking: function() {
        this.set('loginNeedsTracking', true)
    },

    processLoginEvent: function() {
        if (this.get('loginNeedsTracking')) {
            this.get('segment').track('Signed In')
            this.set('loginNeedsTracking', false)
        }
    }
})

module.exports = Em.Route.extend(require('../mixins/dirty-route'), {
    templates: Ember.inject.service(),

    beforeModel: function() {
        var self = this
        var documentLayouts = []

        return Billy.Locale.all().promise
            .then(function(locales) {
                return Ember.RSVP.all(locales.map(function(locale) {
                    return self.get('api').request('GET', '/documentLayouts/invoice.liquid', { headers: { 'accept-language': locale.id } })
                        .then(function(documentLayout) {
                            documentLayouts.push(Ember.create({
                                id: locale.id,
                                data: documentLayout
                            }))
                        })
                }))
                    .then(function() {
                        self.set('documentLayouts', documentLayouts)
                    })
            })
    },

    model: function(params) {
        var templates = this.get('templates')
        return templates.load()
            .then(function() {
                return templates.find(params.template_id)
            })
    },

    setupController: function(controller, model) {
        controller.set('documentLayouts', this.get('documentLayouts'))
        controller.fork(model)
    },

    resetController: function(controller) {
        controller.handleObservers('remove')
        controller.set('standardAttachmentFile', null)
        controller.set('standardAttachmentFileThumbnail', null)
        controller.set('activeSection', controller.get('sections')[0])
    }
})

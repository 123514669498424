module.exports = function(filename) {
    filename = filename
        .replace(/æ/g, 'ae')
        .replace(/Æ/g, 'Ae')
        .replace(/ø/g, 'oe')
        .replace(/Ø/g, 'Oe')
        .replace(/å/g, 'aa')
        .replace(/Å/g, 'Aa')
        .replace(/é/g, 'e')
        .replace(/É/g, 'E')
        .replace(/[^a-z0-9\_ \.-]/ig, '')
        .trim()
    if (Em.isEmpty(filename)) {
        throw new Error('Filename is empty after stripping invalid chars.')
    }
    filename = filename.replace(/ +/g, '-')
    return filename
}

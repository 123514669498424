var SUPPORTED_BROWSERS = {
    Chrome: 76,
    Firefox: 69,
    Safari: 12,
    Edge: 16
}

function getCurrentBrowser() {
    // https://stackoverflow.com/questions/5916900/how-can-you-detect-the-version-of-a-browser
    navigator.browserSpecs = (function() {
        var ua = navigator.userAgent; var tem
        var M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
        if (/trident/i.test(M[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(ua) || []
            return { name: 'IE', version: (tem[1] || '') }
        }
        if (M[1] === 'Chrome') {
            tem = ua.match(/\b(OPR|Edge)\/(\d+)/)
            if (tem != null) return { name: tem[1].replace('OPR', 'Opera'), version: tem[2] }
        }
        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
        if ((tem = ua.match(/version\/(\d+)/i)) != null) { M.splice(1, 1, tem[1]) }
        return { name: M[0], version: M[1] }
    })()

    return navigator.browserSpecs
}

module.exports = (function() {
    var currentBrowser = getCurrentBrowser()
    var report = {
        name: currentBrowser.name,
        version: currentBrowser.version,
        supported: true
    }

    if (!SUPPORTED_BROWSERS[currentBrowser.name] ||
        SUPPORTED_BROWSERS[currentBrowser.name] > parseInt(currentBrowser.version)) {
        report.supported = false
    }

    return report
}())

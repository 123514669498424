module.exports = Em.Route.extend(require('../mixins/dirty-route'), {
    setupController: function() {
        var organization = this.modelFor('organization')
        var creditNote = Billy.Invoice.createRecord({
            organization: organization,
            type: 'creditNote',
            entryDate: moment(),
            currency: this.modelFor('organization').get('baseCurrency'),
            templateId: organization.get('defaultTemplateId')
        })
        Billy.InvoiceLine.createRecord({
            invoice: creditNote,
            quantity: 1
        })
        creditNote.resetClean()
        this.set('controller.model', creditNote)
    }
})

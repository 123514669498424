module.exports = Em.View.extend({
    templateName: 'invoice/edit',

    didInsertElement: function() {
        this.$(':input:not(button):first').focus()
    },

    // TODO: Dragging isn't working
    checkDraggables: function(lineViewContext) {
        var self = this
        var childViews = this.$('.lines-ct').children('.ember-view')
        if (childViews.length === lineViewContext.get('controller.lines.length')) {
            childViews.each(function(idx, el) {
                var childView = Ember.View.views[$(el).attr('id')]
                self.initDraggable(childView)
            })
        }
    },
    initDraggable: function(lineViewContext) {
        var dragEl = lineViewContext.$()
        var all = dragEl.parent().children('.ember-view')
        var parentOffsets = {}
        var newLineIdx
        var animationDuration = 100
        all.css('top', 0)
        dragEl.draggable({
            axis: 'y',
            containment: '.lines-ct',
            start: function() {
                parentOffsets = {}
                var top = dragEl.parent().offset().top
                all.each(function(idx, el) {
                    el = $(el)
                    el.css('top', 0)
                    parentOffsets[idx] = el.offset().top - top
                    el.data('data-line', idx)
                    el.data('targetTop', 0)
                    el.attr('data-line', idx)
                })
                newLineIdx = dragEl.data('data-line')
            },
            drag: function() {
                var prevEl = dragEl.prevAll('.ember-view')
                var nextEl = dragEl.nextAll('.ember-view')
                nextEl.each(function(idx, el) {
                    el = $(el)
                    if (Number(el.css('top')) < 0 && dragEl.offset().top <= el.offset().top) {
                        if (el.data('targetTop') === 0) {
                            return
                        }
                        newLineIdx = el.data('data-line') - 1
                        el.animate({
                            top: 0
                        }, animationDuration)
                        el.data('targetTop', 0)
                    } else if (dragEl.offset().top >= el.offset().top) {
                        if (el.data('targetTop') === -el.outerHeight()) {
                            return
                        }
                        newLineIdx = el.data('data-line')
                        el.animate({
                            top: -el.outerHeight()
                        }, animationDuration)
                        el.data('targetTop', -el.outerHeight())
                    }
                })
                prevEl.each(function(idx, el) {
                    el = $(el)
                    if (Number(el.css('top')) > 0 && dragEl.offset().top >= el.offset().top) {
                        if (el.data('targetTop') === 0) {
                            return
                        }
                        newLineIdx = el.data('data-line') + 1
                        el.animate({
                            top: 0
                        }, animationDuration)
                        el.data('targetTop', 0)
                    } else if (dragEl.offset().top <= el.offset().top) {
                        if (el.data('targetTop') === el.outerHeight()) {
                            return
                        }
                        newLineIdx = el.data('data-line')
                        el.animate({
                            top: el.outerHeight()
                        }, animationDuration)
                        el.data('targetTop', el.outerHeight())
                    }
                })
            },
            stop: function() {
                if (newLineIdx === dragEl.data('data-line')) {
                    dragEl.animate({ top: 0 }, animationDuration)
                } else {
                    dragEl.animate({ top: parentOffsets[newLineIdx] - parentOffsets[dragEl.data('data-line')] }, animationDuration, function() {
                        // Sory by y-offset
                        var sorted = {}
                        all.each(function(idx, el) {
                            el = $(el)
                            sorted[el.offset().top] = el
                        })
                        all.css({ top: 0 })
                        var priority = 0
                        for (var i in sorted) {
                            if (Object.prototype.hasOwnProperty.call(sorted, i)) {
                                var line = Ember.View.views[sorted[i].attr('id')].get('line')
                                line.set('priority', priority)
                                priority++
                            }
                        }
                    })
                }
            }
        })
    }
})

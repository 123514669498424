module.exports = Em.Object.extend({
    api: Em.inject.service(),

    userOrganizations: Em.inject.service(),

    organization: Em.computed.alias('userOrganizations.activeOrganization'),

    putState: function(state, value) {
        var organizationId = this.get('organization.id')

        return this.get('api').requestV2('PUT', '/v2/organizations/' + organizationId + '/states/billy/' + state, {
            payload: {
                value: value
            }
        })
    },

    getStateValue: function(state) {
        var organizationId = this.get('organization.id')

        return this
            .get('api').request('GET', '/v2/organizations/' + organizationId + '/states/billy/' + state)
            .then(function(data) {
                return data.states[state] ? data.states[state].value : undefined
            })
            .catch(function() {
                return undefined
            })
    }
})

var model = BD.Model.extend({
    name: BD.attr('string'),
    hasStates: BD.attr('boolean'),
    hasFiniteStates: BD.attr('boolean'),
    hasFiniteZipcodes: BD.attr('boolean'),
    icon: BD.attr('string'),
    locale: BD.belongsTo('Billy.Locale'),
    currency: BD.belongsTo('Billy.Currency')
})

model.reopenClass({
    qProperties: ['id', 'name']
})

module.exports = model

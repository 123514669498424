var model = BD.Model.extend({
    name: BD.attr('string'),
    county: BD.attr('string'),
    state: BD.belongsTo('Billy.State'),
    country: BD.belongsTo('Billy.Country')
})

model.reopenClass({
    qProperties: ['zipcode']
})

module.exports = model

module.exports = BD.Model.extend({
    createdTime: BD.attr('datetime', { readonly: true }),
    fileName: BD.attr('string'),
    fileSize: BD.attr('number'),
    fileType: BD.attr('string'),
    isImage: BD.attr('boolean'),
    isPdf: BD.attr('boolean'),
    imageWidth: BD.attr('number'),
    imageHeight: BD.attr('number'),
    downloadUrl: BD.attr('string'),
    dataJson: BD.attr('string')
})

var functionProxy = require('function-proxy')
var InfiniteScrollView = require('../mixins/infinite-scroll-view')

module.exports = Em.View.extend(InfiniteScrollView, {
    classNames: ['matches'],

    itemHeight: 92,

    _hijackCheckbox: function() {
        this.$().delegate('input[name="selectAll"]', 'change', functionProxy(this._didClickAreAllSelected, this))
    }.on('didInsertElement'),

    _didClickAreAllSelected: function(e) {
        e.target.checked = !e.target.checked
        this.controller.send('toggleSelectAll')
    }
})

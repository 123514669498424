var moment = require('momentjs')

var FISCAL_YEAR = ''// process.argv[2]
var FIRST_FISCAL_YEAR_END = '2014-12-31' // Read from Organization.firstFiscalYearEnd
var FIRST_FISCAL_YEAR_START = '2013-05-01' // Read from Organization.firstFiscalYearStart

if (!FISCAL_YEAR || !/^\d{4}-\d{2}-\d{2}$/.test(FISCAL_YEAR)) {
    throw new Error('Just must supply one argument with a DATE value (YYYY-MM-DD).')
}

function getFiscalStart(today, firstStartDate, firstEndDate) {
    if (!today || !/^\d{4}-\d{2}-\d{2}$/.test(today) ||
          !firstEndDate || !/^\d{4}-\d{2}-\d{2}$/.test(firstEndDate) ||
          !firstStartDate || !/^\d{4}-\d{2}-\d{2}$/.test(firstStartDate)) {
        throw new Error('Just must supply three arguments with a DATE value (YYYY-MM-DD).')
    }

    var number = 1

    var a = moment(firstEndDate)
    var b = moment(today)

    if (b.diff(a) <= 0) {
        return {
            number: number,
            date: moment(firstStartDate)
        }
    }

    while (b.diff(a.clone().add(1, 'year')) > 0) {
        a = a.add(1, 'year')
        number += 1
    }

    a.add(1, 'day')
    number += 1

    return {
        number: number,
        date: a
    }
}

var c = getFiscalStart(FISCAL_YEAR, FIRST_FISCAL_YEAR_START, FIRST_FISCAL_YEAR_END)

// eslint-disable-next-line no-console
console.log('Current start date for fiscal year', c.date.format('YYYY-MM-DD'), 'no', c.number)

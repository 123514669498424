module.exports = BD.Model.extend({
    organization: BD.belongsTo('Billy.Organization'),
    contact: BD.belongsTo('Billy.Contact'),
    createdTime: BD.attr('datetime', { readonly: true }),
    entryDate: BD.attr('date'),
    cashAmount: BD.attr('number'),
    cashSide: BD.attr('side'),
    cashAccount: BD.belongsTo('Billy.Account'),
    cashExchangeRate: BD.attr('number'),
    subjectCurrency: BD.belongsTo('Billy.Currency'),
    feeAmount: BD.attr('number'),
    feeAccount: BD.belongsTo('Billy.Account'),
    isVoided: BD.attr('boolean'),
    associations: BD.hasMany('Billy.BalanceModifier', 'modifier', { isEmbedded: true }),
    contactBalancePostings: BD.hasMany('Billy.ContactBalancePosting', 'originator')
})

module.exports = BD.Model.extend({
    daybookTransaction: BD.belongsTo('Billy.DaybookTransaction', { isParent: true }),
    text: BD.attr('string'),
    account: BD.belongsTo('Billy.Account'),
    taxRate: BD.belongsTo('Billy.TaxRate'),
    paidInvoice: BD.belongsTo('Billy.Invoice'),
    paidExternalInvoice: BD.belongsTo('Billy.ExternalInvoice'),
    paidBill: BD.belongsTo('Billy.Bill'),
    contraAccount: BD.belongsTo('Billy.Account'),
    side: BD.attr('side'),
    amount: BD.attr('number'),
    baseAmount: BD.attr('number'),
    currency: BD.belongsTo('Billy.Currency'),
    priority: BD.attr('number')
})

var model = BD.Model.extend({
    invoice: BD.belongsTo('Billy.Invoice', { isParent: true }),
    product: BD.belongsTo('Billy.Product'),
    description: BD.attr('string'),
    quantity: BD.attr('number'),
    unitPrice: BD.attr('number'),
    amount: BD.attr('number'),
    taxRate: BD.belongsTo('Billy.TaxRate'),
    tax: BD.attr('number'),
    priority: BD.attr('number'),
    discountText: BD.attr('string'),
    discountMode: BD.attr('string'),
    discountValue: BD.attr('number')
})

model.reopenClass({
    TAX_PRECISION: 6
})

module.exports = model

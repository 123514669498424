var t = require('i18n').t

module.exports = require('ember-super-field').types.Model.extend({

    inputValuePath: 'id',
    modelClass: Billy.Currency,
    sortProperty: 'id',

    noOptionsFoundText: t('ui.fields.superfield.no_currencies_found'),

    optionHeight: 64,
    optionViewClass: require('ember-super-field').OptionView.extend({
        template: Ember.Handlebars.compile(
            '<div class="name" data-cy="currency-list-option-name">{{id}}</div>' +
            '<div class="meta" data-cy="currency-list-option-description">{{name}}</div>'
        )
    })

})

var AuthorizedRoute = require('../mixins/authorized-route')
var Scope = require('../utils/scope')

module.exports = Em.Route.extend(AuthorizedRoute, {
    scopes: [Scope.DaybookWrite],

    setupController: function(controller) {
        controller.set('daybook', this.modelFor('daybook'))
        controller.loadTransactions()
        this.controllerFor('daybook-balance-accounts').loadRecords()
    }
})

var TotalView = require('./total-view')

module.exports = Em.View.extend({
    templateName: 'ui/list/table/table',

    classNames: ['table'],

    classNameBindings: ['controller.totals.length:table-with-totals'],

    smallFont: false,

    totalViewClass: TotalView
})

module.exports = require('ember-super-field').types.Model.extend({
    modelClass: Billy.SalesTaxRuleset,

    sortProperty: 'name',

    optionHeight: 64,

    optionViewClass: require('ember-super-field').OptionView.extend({
        template: Ember.Handlebars.compile(
            '<div class="name" data-cy="vat-list-option-name">{{name}}</div>' +
            '<div class="meta" data-cy="vat-list-option-description">{{description}}</div>'
        )
    }),

    query: function() {
        return {
            organization: this.get('userController.activeOrganization')
        }
    }.property('userController.activeOrganization')
})

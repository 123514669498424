module.exports = Em.Route.extend({
    queryParams: {
        q: {
            replace: true
        },
        tasks: {
            replace: true
        },
        sales_tax_period: {
            replace: true
        },
        drafts: {
            replace: true
        },
        open_sales_tax: {
            replace: true
        }
    },

    userOrganizations: Em.inject.service(),

    model: function() {
        return this.get('userOrganizations').all()
    },

    setupController: function(controller, rawOrganizations) {
        var self = this
        var organizationModels = rawOrganizations.map(function(data) {
            var model = self.container.lookup('util:my-organization-model', { singleton: false })
            model.set('data', data)
            return model
        })
        controller.set('organizations', organizationModels)
        controller.set('q', '')
    }
})

var _ = require('lodash')
var i18nContext = require('i18n-context')
var t = require('i18n').t

module.exports = Em.Component.extend(require('ember-tooltip').Tooltipable, require('ember-field-mixin'), {
    layout: require('../templates/text-area'),

    error: null,

    name: null,

    onBlur: null,

    maxWidth: null,

    classNameBindings: [':text-field', ':text-area', 'value:has-value', 'hasFocus:focus', 'grow', 'disabled', 'required', 'flexClass', 'block'],

    inputDataCy: function() {
        return '' + _.toLower(this.get('name')) + '-input-field'
    }.property('name'),


    init: function() {
        this._super()
        this.updateMirror()
    },

    didInsertElement: function() {
        var self = this
        var textarea = this.$('textarea')

        if (this.get('grow')) {
            var mirror = this.$('.mirror')
            var minLines = this.get('minLines')
            var maxLines = this.get('maxLines')
            var lineHeight = parseInt(mirror.css('line-height'))
            var vpadding = parseInt(mirror.css('border-top-width')) + parseInt(mirror.css('padding-top')) + parseInt(mirror.css('padding-bottom')) + parseInt(mirror.css('border-bottom-width'))
            var css = {}
            css.minHeight = vpadding + lineHeight * minLines
            if (maxLines) {
                css.maxHeight = vpadding + lineHeight * maxLines
            }
            mirror.css(css)
        }
        textarea.focus(function() {
            Em.run(function() {
                self.didFocus()
            })
        })
        textarea.blur(function() {
            Em.run(function() {
                self.didBlur()
            })
        })
    },

    value: '',
    placeholder: i18nContext.tProperty('placeholder'),

    disabled: false,

    required: false,

    minLines: 1,
    maxLines: null,

    flex: null,

    flexClass: function() {
        var flex = this.get('flex')
        return flex ? 'flex-' + flex : null
    }.property('flex'),

    block: false,

    valueDidChange: function() {
        this.resetError()
        this.updateMirror()
    }.observes('value'),

    grow: false,
    mirrorValue: null,
    updateMirror: function() {
        if (this.get('grow')) {
            this.set('mirrorValue', this.get('value') || this.get('placeholder'))
        }
    },

    hasFocus: false,

    focus: function() {
        this.$('input').focus()
    },

    didFocus: function() {
        this.set('hasFocus', true)
    },

    didBlur: function() {
        this.set('hasFocus', false)
        this.checkError()
        if (this.get('onBlur') !== null) {
            this.sendAction('onBlur', this.get('value'), this)
        }
    },

    checkError: function() {
        try {
            this.validateInputValue(this.get('value'))
        } catch(e) {
            this.set('error', e.message)
        }
    },

    resetError: function() {
        this.set('error', null)
    },

    validateInputValue: function(value) {
        var required = this.get('required')

        if (Em.isEmpty(value) && required) {
            throw new Error(t('required_field'))
        }
    },

    innerTextAreaClass: require('./inner-text-area')
})

var model = BD.Model.extend({
    account: BD.belongsTo('Billy.Account'),
    entryDate: BD.attr('date'),
    amount: BD.attr('number'),
    side: BD.attr('side'),
    differenceType: BD.attr('string'),
    feeAccount: BD.belongsTo('Billy.Account'),
    isApproved: BD.attr('boolean'),
    approvedTime: BD.attr('datetime'),
    lines: BD.hasMany('Billy.BankLine', 'match'),
    subjectAssociations: BD.hasMany('Billy.BankLineSubjectAssociation', 'match')
})

model.reopenClass({
    supportsBulkSave: true
})

module.exports = model

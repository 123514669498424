module.exports = Ember.HistoryLocation.extend({
    implementation: 'custom_location',

    /**
        Used to set state on first call to setURL

        @private
        @method initState
    */
    initState: function() {
        Em.set(this, 'history', Em.get(this, 'history') || window.history)
        this.replaceState(this.formatURL(this.getURL() + window.location.hash))
    }
})

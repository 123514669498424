var CellView = require('./cell-view')
var TableBodyView = require('./body-view')

module.exports = CellView.extend({
    templateName: 'ui/list/table/nested-cell',

    extraClass: 'nested-table-cell',

    controller: null,

    init: function() {
        this._super()
        var controller = this.get('column.controllerClass').create({
            parent: this.get('context'),
            columns: this.get('column.columns')
        })
        this.set('controller', controller)
    },

    bodyRowClass: TableBodyView
})

var absoluteRouteUrl = module.exports = function(container) {
    var base = absoluteRouteUrl.protocol + '//' + absoluteRouteUrl.hostname + (absoluteRouteUrl.port ? ':' + absoluteRouteUrl.port : '')
    var router = container.lookup('router:main')
    var path = router.generate.apply(router, Array.prototype.slice.call(arguments, 1))
    return base + path
}

absoluteRouteUrl.protocol = window.location.protocol
absoluteRouteUrl.hostname = window.location.hostname
absoluteRouteUrl.port = window.location.port

var _ = require('lodash')

module.exports = Em.View.extend({
    templateName: 'ui/list/table/header-cell',

    classNames: ['table-cell'],

    classNameBindings: ['rawClass'],

    cy: function() {
        return _.kebabCase(_.toLower('header-' + this.get('column.name')))
    }.property('column.name'),

    extraClass: null,

    rawClass: function() {
        var c = []
        var align = this.get('column.align')
        var extraClass = this.get('extraClass')
        if (align !== 'left') {
            c.push('align-' + align)
        }
        if (this.get('controller.sortProperty') === this.get('column.name')) {
            if (this.get('controller.sortDirection') === 'DESC') {
                c.push('sort-desc')
            } else {
                c.push('sort-asc')
            }
        }
        if (extraClass) {
            c.push(extraClass)
        }
        return c.join(' ')
    }.property('controller.sortProperty', 'controller.sortDirection'),

    attributeBindings: ['style'],

    style: function() {
        return this.get('column.style')
    }.property(),

    actions: {
        onClick: function() {
            var name = this.get('column.name')
            var dir = 'ASC'
            if (name === this.get('controller.sortProperty')) {
                dir = this.get('controller.sortDirection') === 'ASC' ? 'DESC' : 'ASC'
            }
            this.set('controller.sortProperty', name)
            this.set('controller.sortDirection', dir)
        }
    }
})

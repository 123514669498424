var model = BD.Model.extend({
    organization: BD.belongsTo('Billy.Organization'),
    name: BD.attr('string'),
    description: BD.attr('string'),
    reminderLimit: BD.attr('number'),
    isDefault: BD.attr('boolean'),
    invoiceMinDueDate: BD.attr('date'),
    sendNotifications: BD.attr('boolean'),
    actions: BD.hasMany('Billy.InvoiceReminderSchemeAction', 'scheme', { isEmbedded: true }),
    createdTime: BD.attr('datetime', { readonly: true })
})

model.reopenClass({
    qProperties: ['name', 'description']
})

module.exports = model

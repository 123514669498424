var t = require('i18n').t

module.exports = require('ember-super-field').types.Model.extend({
    modelClass: Billy.Bank,

    sortProperty: 'name',

    noOptionsFoundText: t('ui.fields.superfield.no_banks_found'),

    query: function() {
        var countryId = this.get('userController.activeOrganization.country.id')

        if (['FO', 'GL'].contains(countryId)) {
            countryId = 'DK'
        }

        return {
            countryId: countryId
        }
    }.property()
})

var qs = require('qs')

module.exports = Em.Object.extend({
    userOrganizations: Em.inject.service(),

    organization: Em.computed.oneWay('userOrganizations.activeOrganization'),

    api: Ember.inject.service(),

    data: null,

    isEnabled: Em.computed.bool('data.enabled'),

    load: function() {
        var self = this

        self._loadPromise = new Em.RSVP.Promise(function(resolve, reject) {
            self.getOrganizationSettings(self.get('organization.id'))
                .then(resolve, reject)
        })
            .then(function(config) {
                var data = Em.Object.create(config)

                self.set('isLoaded', true)
                self.set('data', data)

                return data
            })

        return self._loadPromise
    },

    loadSafe: function() {
        return this.load()
            .catch(function(e) {
                // Recurring invoices load failed, but will not throw an error
            })
    },

    ensureLoaded: function() {
        return this._loadPromise || this.load()
    },

    getOrganizationSettings: function(organizationId) {
        return this.get('api')
            .getData('/recurringInvoices/organizations/' + organizationId)
    },

    updateOrganizationSettings: function(organizationId, updates, preserveSpawns) {
        var self = this
        var queryString = qs.stringify(
            preserveSpawns ? { preserveSpawns: true } : {},
            { addQueryPrefix: true }
        )

        return self.get('api')
            .putData(
                '/recurringInvoices/organizations/' + organizationId + queryString,
                updates
            )
    }
})

var t = require('i18n').t

var ReportType = Em.Object.extend({
    isLoaded: function() {
        var isLoaded = true
        if (!this.get('natures') || this.get('natures.length') === 0) {
            isLoaded = false
        } else {
            isLoaded = this.get('natures').every(function(nature) {
                return nature.get('accounts')
            })
        }
        return isLoaded
    }.property('natures.@each.accounts'),

    debitBalance: function() {
        if (!this.get('isLoaded')) {
            return 0
        }
        return this.get('natures').reduce(function(balance, nature) {
            if (nature.get('nature.normalBalance.isDebit')) {
                balance += nature.get('balance')
            }
            return balance
        }, 0)
    }.property('isLoaded', 'natures.@each.balance'),
    creditBalance: function() {
        if (!this.get('isLoaded')) {
            return 0
        }
        return this.get('natures').reduce(function(balance, nature) {
            if (nature.get('nature.normalBalance.isCredit')) {
                balance += nature.get('balance')
            }
            return balance
        }, 0)
    }.property('isLoaded', 'natures.@each.balance')
})

var Nature = Em.Object.extend({
    organization: null,
    conversionBalance: null,
    nature: null,

    realAccounts: function() {
        var organization = this.get('organization')
        return Billy.Account.filter({
            query: {
                organization: organization,
                nature: this.get('nature'),
                currency: organization.get('baseCurrency')
            },
            sortProperty: 'accountNo'
        })
    }.property('organization', 'organization.baseCurrency', 'nature'),

    accounts: Em.computed.map('realAccounts', function(account) {
        return Account.create({
            conversionBalance: this.get('conversionBalance'),
            account: account
        })
    }),

    isLoaded: Em.computed.alias('realAccounts.isLoaded'),

    childBalances: Em.computed.mapBy('accounts', 'balance'),
    balance: Em.computed.sum('childBalances'),

    willDestroy: function() {
        this.get('realAccounts').destroy()
    }
})

var Account = Em.Object.extend({
    conversionBalance: null,

    account: null,

    relatedLine: function() {
        var account = this.get('account')
        return this.get('conversionBalance.lines').find(function(line) {
            return line.get('account') === account
        })
    }.property('account', 'conversionBalance.lines.@each.account'),

    balance: function(key, balance) {
        var line = this.get('relatedLine')
        if (arguments.length === 1) {
            return line ? line.get('amount') : null
        } else {
            if (Em.isEmpty(balance) || balance === 0) {
                if (line) {
                    line.deleteRecord()
                }
                return null
            } else {
                if (line) {
                    line.set('amount', balance)
                } else {
                    Billy.ConversionBalanceLine.createRecord({
                        conversionBalance: this.get('conversionBalance'),
                        account: this.get('account'),
                        amount: balance
                    })
                    // `relatedLine` should automatically be recomputed, since `conversionBalance.lines.@each` is changed
                }
                return balance
            }
        }
    }.property('relatedLine.amount')
})

module.exports = function(organization, conversionBalance) {
    return [
        ReportType.create({
            name: t('conversion_balance.income_statement_accounts'),
            natures: [
                Nature.create({
                    organization: organization,
                    conversionBalance: conversionBalance,
                    nature: Billy.AccountNature.find('revenue')
                }),
                Nature.create({
                    organization: organization,
                    conversionBalance: conversionBalance,
                    nature: Billy.AccountNature.find('expense')
                })
            ]
        }),
        ReportType.create({
            name: t('conversion_balance.balance_accounts'),
            natures: [
                Nature.create({
                    organization: organization,
                    conversionBalance: conversionBalance,
                    nature: Billy.AccountNature.find('asset')
                }),
                Nature.create({
                    organization: organization,
                    conversionBalance: conversionBalance,
                    nature: Billy.AccountNature.find('equity')
                }),
                Nature.create({
                    organization: organization,
                    conversionBalance: conversionBalance,
                    nature: Billy.AccountNature.find('liability')
                })
            ]
        })
    ]
}

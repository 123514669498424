var t = require('i18n').t

module.exports = require('ember-super-field').types.Model.extend({

    modelClass: Billy.Daybook,
    sortProperty: 'name',

    createTip: t('ui.fields.superfield.create_daybook'),

    noOptionsFoundText: t('ui.fields.superfield.no_daybooks_found'),

    query: function() {
        return {
            organization: this.get('userController.activeOrganization')
        }
    }.property('userController.activeOrganization')
})

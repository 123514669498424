var postingSides = require('posting-sides')

BD.transforms.side = {
    deserialize: function(serialized) {
        if (Em.isNone(serialized)) {
            return null
        }
        return postingSides.resolveSide(serialized)
    },
    serialize: function(deserialized) {
        return Em.isNone(deserialized) ? null : deserialized.value
    }
}

var MOBILE_WITH_LIMIT = 768

function isMobileDevice() {
    var isMobile = false

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        isMobile = true
    }

    return isMobile
}

function isMobileResolution() {
    var browserWidth = document.body.offsetWidth
    return browserWidth < MOBILE_WITH_LIMIT
}

function getDeviceType() {
    var isMobile = isMobileDevice()
    var isMobileRes = isMobileResolution()
    var device = 'desktop'

    if (isMobile) {
        if (isMobileRes) {
            device = 'mobile'
        } else {
            device = 'tablet'
        }
    }

    return device
}

module.exports = getDeviceType

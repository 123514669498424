var model = BD.Model.extend({
    organization: BD.belongsTo('Billy.Organization'),
    type: BD.attr('string', { defaultValue: 'invoice' }),
    createdTime: BD.attr('datetime', { readonly: true }),
    approvedTime: BD.attr('datetime', { readonly: true }),
    contact: BD.belongsTo('Billy.Contact'),
    attContactPerson: BD.belongsTo('Billy.ContactPerson'),
    entryDate: BD.attr('date'),
    paymentTermsMode: BD.attr('string'),
    paymentTermsDays: BD.attr('number'),
    dueDate: BD.attr('date'),
    state: BD.attr('string', { defaultValue: 'draft' }),
    sentState: BD.attr('string'),
    invoiceNo: BD.attr('string'),
    taxMode: BD.attr('string'),
    amount: BD.attr('number', { readonly: true }),
    grossAmount: BD.attr('number', { readonly: true }),
    tax: BD.attr('number', { readonly: true }),
    currency: BD.belongsTo('Billy.Currency'),
    exchangeRate: BD.attr('number'),
    balance: BD.attr('number', { readonly: true }),
    isPaid: BD.attr('boolean', { readonly: true }),
    creditedInvoice: BD.belongsTo('Billy.Invoice'),
    contactMessage: BD.attr('string'),
    templateId: BD.attr('string'),
    lineDescription: BD.attr('string', { readonly: true }),
    downloadUrl: BD.attr('string', { readonly: true }),
    orderNo: BD.attr('string'),
    quoteId: BD.attr('string'),
    delivery: BD.attr('object'),
    creditNotes: BD.hasMany('Billy.Invoice', 'creditedInvoice'),
    lines: BD.hasMany('Billy.InvoiceLine', 'invoice', { isEmbedded: true }),
    attachments: BD.hasMany('Billy.Attachment', 'owner', { isEmbedded: true }),
    lateFees: BD.hasMany('Billy.InvoiceLateFee', 'invoice', { isEmbedded: true }),
    transactions: BD.hasMany('Billy.Transaction', 'originator'),
    balanceModifiers: BD.hasMany('Billy.BalanceModifier', 'subject'),
    commentAssociations: BD.hasMany('Billy.CommentAssociation', 'owner'),
    paymentMethods: BD.attr('object', { defaultValue: function() { return [] } })
})

model.reopenClass({
    qProperties: [
        'invoiceNo',
        'contact.name'
    ]
})

module.exports = model

module.exports = Em.View.extend({
    templateName: 'bills/draft-bill-header-column',

    tagName: 'td',

    classNameBindings: ['sortClass'],

    attributeBindings: ['style'],

    column: Ember.computed.alias('content'),

    sortClass: function() {
        var classes = []

        if (this.get('controller.sortProperty') === this.get('column.name')) {
            if (this.get('controller.sortDirection') === 'DESC') {
                classes.push('sort-desc')
            } else {
                classes.push('sort-asc')
            }
        }

        return classes.join(' ')
    }.property('controller.sortProperty', 'controller.sortDirection'),

    style: function() {
        var styles = []
        var width = this.get('column.width')

        if (!Ember.isEmpty(width)) {
            styles.push('width: ' + width + 'px;')
        }

        return styles.join(' ')
    }.property('width')
})

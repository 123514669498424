module.exports = Em.Route.extend({
    model: function() {
        var client = this.container.lookup('api:billy')
        var organization = this.modelFor('organization')
        return Em.RSVP.hash({
            payload: client.get('/organizations/' + organization.get('id') + '/badTaxRateUsage'),
            correctionTransactions: Billy.DaybookTransaction.find({
                organizationId: organization.get('id'),
                apiType: 'billy.dkTaxRateCorrection201503',
                state: 'approved'
            }).promise
        })
    },

    setupController: function(controller, hash) {
        var payload = hash.payload
        controller.set('records', Em.ArrayController.create({
            container: this.container,
            itemController: 'tax-rate-correction-item',
            model: payload.records
        }))
        controller.set('taxRateAccountId', payload.taxRateAccountId)
        controller.set('selection', [])
        var correctionTransaction = hash.correctionTransactions.objectAt(0)
        controller.set('correctionTransaction', correctionTransaction)
        controller.set('isSolved', !!correctionTransaction)
    }
})

module.exports = BD.Model.extend({
    account: BD.belongsTo('Billy.Account'),
    amount: BD.attr('number'),
    bill: BD.belongsTo('Billy.Bill', { isParent: true }),
    bookkeepingTag: BD.attr('string'),
    description: BD.attr('string'),
    priority: BD.attr('number'),
    product: BD.belongsTo('Billy.Product'),
    quantity: BD.attr('number'),
    tax: BD.attr('number', { readonly: true }),
    taxRate: BD.belongsTo('Billy.TaxRate')
})

var storage = require('storage')
var OrganizationSettings = require('../utils/organization-settings')
var eventBus = require('../utils/event-bus')
var get = require('lodash').get
var redirectToBrand = require('../utils/brand').redirectToBrand

var bootstrapModels = [
    Billy.Contact,
    Billy.Product,
    Billy.AccountGroup,
    Billy.Account,
    Billy.TaxRate,
    Billy.TaxRateDeductionComponent,
    Billy.SalesTaxRuleset,
    Billy.SalesTaxRule,
    Billy.Daybook,
    Billy.OrganizationOwner
]

module.exports = Em.Object.extend({
    templates: Ember.inject.service(),

    paymentMethods: Ember.inject.service(),

    campaign: Ember.inject.service(),

    customEvent: Ember.inject.service(),

    couponOffer: Ember.inject.service(),

    api: Ember.inject.service(),

    cvr: Ember.inject.service(),

    recurringInvoices: Ember.inject.service(),

    organizationSubscription: Ember.inject.service(),

    organizationCardSubscription: Ember.inject.service(),

    activeOrganization: null,

    activeMembership: null,

    setupListeners: function() {
        eventBus.on('logout', this, this.reset)
    }.on('init'),

    willDestroy: function() {
        this._super()
        eventBus.off('logout', this, this.reset)
    },

    bootstrapByUrl: function(url) {
        var self = this
        var organization = this.get('activeOrganization')
        if (organization && organization.get('url') === url) {
            return Em.RSVP.Promise.resolve(organization)
        }

        if (this._currentReq) {
            if (this.get('_bootstrappingUrl') === url) {
                return this._currentPromise
            }
            this._currentReq.abort()
        }
        this.set('_bootstrappingUrl', url)

        this._currentReq = this.get('api').request('GET', '/v2/organizations/' + url + '/bootstrap')
        this._currentPromise = this._currentReq
            .then(function(payload) {
                var organizationBrand = get(payload, 'organization.brand')

                if (organizationBrand && organizationBrand !== ENV.theme) {
                    redirectToBrand(organizationBrand, url)
                    return Promise.resolve()
                }

                var organization = BD.store.load(Billy.Organization, payload.organization)

                if (window.DD_RUM) {
                    window.DD_RUM.onReady(function() {
                        window.DD_RUM.setGlobalContext('organization', {
                            id: organization.id,
                            name: organization.get('name'),
                            url: organization.get('url'),
                            isTrial: organization.get('isTrial'),
                            hasVat: organization.get('hasVat'),
                            invoiceNoMode: organization.get('invoiceNoMode'),
                            isLocked: organization.get('isLocked'),
                            isTerminated: organization.get('isTerminated'),
                            localeId: organization.get('localeId'),
                            lockedCode: organization.get('lockedCode'),
                            lockedReason: organization.get('lockedReason'),
                            ownerUmbrellaId: organization.get('ownerUmbrellaId'),
                            salesTaxPeriod: organization.get('salesTaxPeriod'),
                            subscriptionPeriod: organization.get('subscriptionPeriod'),
                            subscriptionPlan: organization.get('subscriptionPlan')
                        })
                    })
                }

                // Settings
                organization.set('settings', OrganizationSettings.create({
                    organization: organization,
                    data: payload.settings || {},
                    customEvent: self.get('customEvent')
                }))

                // Load all included models
                self.unloadBootstrappedModels()
                bootstrapModels.forEach(function(type) {
                    var root = BD.pluralize(Em.get(type, 'root'))
                    if (BD.store.allOfTypeIsLoaded(type)) {
                        BD.store.unloadAllByType(type)
                    }
                    if (payload[root]) {
                        BD.store.loadAll(type, payload[root])
                    }
                })

                self.set('activeOrganization', organization)

                var membership = payload.membership || {}
                membership.isOwner = membership.level === 'owner'
                self.set('activeMembership', membership)

                self.set('_bootstrappingUrl', null)
                self.set('lastOrganizationUrl', url)
                delete self._currentReq

                // We don't need to wait for these request, but we want the data to be available at runtime eventually
                self.get('templates').loadSafe()
                self.get('paymentMethods').loadSafe()
                self.get('campaign').loadSafe()
                self.get('couponOffer').loadSafe()
                self.get('organizationSubscription').loadSafe()
                self.get('recurringInvoices').loadSafe()
                self.get('cvr').loadSafe()

                var organizationCardSubscription = self.get('organizationCardSubscription')
                organizationCardSubscription.loadSafe()
                    .then(function() {
                        // Set card subscriptions on organization model when they are loaded
                        organization.set('cardSubscriptions', organizationCardSubscription.getContent())
                    })

                // Return the organization
                return organization
            })
            .catch(function(e) {
                if (e.type === 'API_ERROR' && e.status === 404) {
                    self.set('lastOrganizationUrl', '')
                }
                throw e
            })
        return this._currentPromise
    },

    lastOrganizationUrl: function(key, url) {
        if (arguments.length === 1) {
            return storage('lastOrganizationUrl')
        } else {
            storage('lastOrganizationUrl', url)
            return url
        }
    }.property(),

    getOrganizationById: function(id) {
        return this.get('api').request('GET', '/v2/organizations/' + id)
    },

    bootstrappingOrganization: function() {
        var all = this.get('_all')
        var bootstrappingUrl = this.get('_bootstrappingUrl')
        if (all && bootstrappingUrl) {
            return all.find(function(org) {
                return org.url === bootstrappingUrl
            })
        }
    }.property('_bootstrappingUrl', '_all'),

    _all: null,

    all: function() {
        if (this.allPromise) {
            return this.allPromise
        }
        var self = this
        this.allPromise = this.get('api').request('GET', '/user/organizations')
            .then(function(payload) {
                var all = payload.data
                self.set('_all', all)
                return all
            })
        return this.allPromise
    },

    resetAll: function() {
        delete this.allPromise
        this.set('_all', null)
    },

    reset: function() {
        this.unloadBootstrappedModels()
        this.set('activeOrganization', null)
        this.set('activeMembership', null)
        this.resetAll()
    },

    unloadBootstrappedModels: function() {
        bootstrapModels.forEach(function(type) {
            if (BD.store.allOfTypeIsLoaded(type)) {
                BD.store.unloadAllByType(type)
            }
        })
    }
})

var Column = require('./column')
var IconCellView = require('../table/icon-cell-view')

module.exports = Column.extend({
    align: 'center',

    width: 28,

    cellViewClass: IconCellView
})

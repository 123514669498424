module.exports = Em.Mixin.create({
    value: null,

    types: null,

    selectorAlign: 'left',

    click: function() {
        this.showSelector();
    },

    showSelector: function() {
        var self = this,
            selector = this.get('selector');
        if (!selector) {
            selector = this.container.lookup('component:periodSelector');
            this.set('selector', selector);
            selector.set('value', this.get('value'))
                .set('align', this.get('selectorAlign'))

            if (this.get('matchWidth')) {
                selector.set('matchWidth', this.get('matchWidth'))
            }

            if (this.get('eventContext')) {
                selector.set('eventContext', this.get('eventContext'))
            }

            selector.on('select', this, this.selectorDidSelect);
            selector.one('willDestroyElement', function() {
                self.set('selector', null);
            });
            var typeKeys = this.get('types')
            if (typeKeys) {
                var types = selector.get('types').filter(function(type) {
                    return typeKeys.indexOf(type.key) >= 0;
                })
                selector.set('types', types);
            }
            selector.show(this);
        }
    },

    hideSelector: function() {
        var selector = this.get('selector');
        if (selector) {
            selector.destroy();
        }
    },

    selectorDidSelect: function(value) {
        this.hideSelector();
        this.set('value', value);
    }
});

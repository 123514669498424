var FiscalYear = require('../utils/period-selector/fiscal-year-period')

module.exports = Em.Route.extend({
    startGuide: Em.inject.service(),

    userUmbrellas: Em.inject.service(),

    organizationUmbrellas: Em.inject.service(),

    queryParams: null,

    connections: [],

    beforeModel: function(transition) {
        this.set('queryParams', transition.queryParams)
        this.get('startGuide').setCurrentRouteName(this.get('routeName'))
    },

    // HACK: This route hook will not be called in the organization-route mixin if you are coming from a dynamic route (/org_url/locked). Thus we have to do the check here as well
    afterModel: function() {
        var queryParams = this.get('queryParams')
        var organization = this.controllerFor('user').get('activeOrganization')

        if (organization.get('isLocked')) {
            this.replaceWith('organization.locked', organization)
            return
        }

        // Check for a return redirect from MobilePay, if condition met - trigger a transition to apps page
        if (queryParams.hasOwnProperty('mobilepaystatus')) {
            this.transitionTo('integrations', { queryParams: queryParams })
            return
        }

        this.transitionTo('dashboard-react', { queryParams: queryParams })
    },

    setupController: function(controller) {
        var organization = this.modelFor('organization')
        var period = controller.get('period')

        if (!controller.get('period') || (period instanceof FiscalYear && period.value.indexOf(organization.get('id')) === -1)) {
            controller.set('period', FiscalYear.getCurrentPeriod(organization))
        }
    }
})

var AuthorizedRoute = require('../mixins/authorized-route')
var Scope = require('../utils/scope')

module.exports = Em.Route.extend(AuthorizedRoute, {
    scopes: [Scope.RecurringInvoiceRead],

    model: function(params) {
        var invoice = this.store.getById('recurring-invoice', params.recurring_invoice_id)
        if (invoice) {
            return invoice
        }
        return this.store.find('recurring-invoice', { organizationId: this.modelFor('organization').id, id: params.recurring_invoice_id })
            .then(function(invoices) {
                return invoices.objectAt(0)
            })
    },

    afterModel: function(invoice) {
        // Make sure lines are loaded
        return invoice.get('lines')
    }
})
